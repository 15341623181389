import { SchemaOf, ValidationError } from 'yup';

export type FormErrors<T> = {
  [K in keyof T]?: string;
};

export const getValidationErrors = <T>(err: ValidationError): FormErrors<T> => {
  const validationErrors: FormErrors<T> = {};
  err.inner.forEach(error => {
    if (error.path) validationErrors[error.path as keyof T] = error.message;
  });
  return validationErrors;
};

export interface ValidationResponse<T> {
  data: T | null;
  errors: FormErrors<T> | null;
}

export const validateData = async <T>(
  schema: SchemaOf<T>,
  data: T
): Promise<ValidationResponse<T>> => {
  const response: ValidationResponse<T> = {
    data: null,
    errors: null,
  };

  try {
    const result = await schema.validate(data, { abortEarly: false });
    response.data = result as T;
  } catch (e) {
    const err: ValidationError = e as any;
    response.errors = getValidationErrors(err);
  }
  return response;
};
