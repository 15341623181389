import React, { FC } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Outlet } from 'react-router-dom';

import ThemeToggler from '../../components/ThemeToggler/ThemeToggler';

import { lightTheme as theme } from '../../theme';
import assets from '../../assets';

interface IProps {
  children?: React.ReactNode;
}

const LoginLayout: FC<IProps> = () => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  return (
    <Container
      style={{
        mixBlendMode: 'multiply',
        backgroundColor: activeTheme === 'dark' ? '#141619' : '',
        backgroundBlendMode: activeTheme === 'light' ? 'multiply' : '',
      }}
    >
      <div className='left'>
        <div className='logo'>
          <img src={assets.gamefiedNewLogo} alt='' />
        </div>
        <GameCard>
          <div className='image'>
            <img src={assets.hero1} alt='' />
          </div>
          <div className='info'>
            <p className='font-size-25'>Axie Infinity</p>

            <div className='row'>
              <p className='chip font-size-8'>Strategy</p>
              <p className='chip font-size-8'>Cards</p>
            </div>

            <p className='font-size-8'>
              Axie Infinity is a non-fungible token-based online video game
              developed by Vietnamese studio Sky Mavis, known for its in-game
              economy which uses Ethereum-based cryptocurrencies.
            </p>
          </div>
        </GameCard>
        <GameCard>
          <div className='image'>
            <img src={assets.hero2} alt='' />
          </div>
          <div className='info'>
            <p className='font-size-25'>Crypto Motors</p>

            <div className='row'>
              <p className='chip font-size-8'>Strategy</p>
              <p className='chip font-size-8'>Cards</p>
            </div>

            <p className='font-size-8'>
              CryptoMotors are built by putting together professional automotive
              design with the latest blockchain technology. Each vehicle is
              one-of-a kind and has its own ID, stats and features.
            </p>
          </div>
        </GameCard>
        <GameCard>
          <div className='image'>
            <img src={assets.benji} alt='' />
          </div>
          <div className='info'>
            <p className='font-size-25'>Benji Bananas</p>

            <div className='row'>
              <p className='chip font-size-8'>Action</p>
              <p className='chip font-size-8'>Casual</p>
            </div>

            <p className='font-size-8'>
              The best action-adventure game on your Android phone! And it's
              free! Exciting and fun physics based adventure game!
            </p>
          </div>
        </GameCard>
        <StyledLink>
          <img
            src={activeTheme === 'light' ? assets.addLight : assets.add}
            alt=''
          />
          <p className='font-size-14'>Signup or Sign in to Discover more</p>
        </StyledLink>
        <Toggler>
          <img
            style={{ mixBlendMode: 'screen' }}
            src={activeTheme === 'light' ? assets.moonLight : assets.moon}
            alt=''
          />
          <ThemeToggler />
        </Toggler>
      </div>
      <div className='right'>
        <Outlet />
      </div>
    </Container>
  );
};

export default LoginLayout;

const Container = styled.div`
  background-image: ${({ theme }) => theme.color.gradient.login},
    url(${assets.loginBackground});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  mix-blend-mode: multiply;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 110px;
  padding-block: 25px;

  & .left {
    .logo {
      & img {
        width: 120px;
      }
    }
    .font-size-20 {
      color: ${({ theme }) => theme.color.text.secondary};
      margin-block: 15px 30px;
    }
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    padding-block: 40px;
    & .left {
      .font-size-20 {
        color: ${({ theme }) => theme.color.text.secondary};
        margin-block: 20px 50px;
      }
      .logo {
        & img {
          width: unset;
        }
      }
    }
  }
`;

const GameCard = styled.div`
  width: 280px;
  height: 100px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  position: relative;
  margin-block: 20px;

  &:last-of-type {
    margin-bottom: 15px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.color.background.gameCard};
    border-radius: 12px;
    mix-blend-mode: soft-light;
  }

  & .image {
    height: 100%;
    min-width: 33.89%;
    border-radius: 12px;
    overflow: hidden;
    z-index: 2;

    & img {
      width: 100%;
      object-fit: cover;
    }
  }

  & .info {
    flex-grow: 1;
    z-index: 2;
    padding-right: 5px;

    & p {
      color: ${({ theme }) => theme.color.text.primary};
    }

    & p.font-size-8 {
      color: ${({ theme }) => theme.color.text.secondary};
    }

    & .row {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-block: 8px 10px;
    }

    & p.chip {
      padding: 3px 7px;
      background-color: #464b50;
      border-radius: 8px;
      color: #ffffff;
    }
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    width: 340px;
    height: 120px;
    border-radius: 24px;
    margin-block: 30px;
    &:last-of-type {
      margin-bottom: 20px;
    }
    gap: 15px;
    &::before {
      border-radius: 20px;
    }
    & .image {
      border-radius: 20px;
      overflow: hidden;
      z-index: 2;

      & img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
`;

const StyledLink = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & p {
    color: ${({ theme }) => theme.color.text.secondary};
  }
`;

const Toggler = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 20px;

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    gap: 24px;
    margin-top: 40px;
  }
`;
