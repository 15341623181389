import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import CrossIcon from '../../assets/icons/CrossIcon';
import ActionButton from '../../components/ActionButton/ActionButton';
import InputField from '../../components/InputField/InputField';
import Select from '../../components/Select/Select';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';

import { lightTheme } from '../../theme';
import { IGame } from '../../types/api';
import { getGuildGames } from '../../api';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../hooks/useTheme';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  game: string;
  afterUpdate: () => void;
}

const AdminGuildRevenue: FC<IProps> = ({
  open,
  setOpen,
  game,
  afterUpdate,
}) => {
  const { theme } = useTheme();
  const dispatch = useDispatch<Dispatch>();
  const loading = useSelector((state: RootState) => state.guilds.loading);
  const user = useSelector((state: RootState) => state.auth.user);
  const guild = useSelector(
    (state: RootState) => state.guilds.selectedRevenueGuild
  );
  const [gameOptions, setGameOptions] = useState<IGame[]>([]);
  const [gamesLoading, setGamesLoading] = useState(false);
  const [adminSplit, setAdminSplit] = useState(guild?.admin_revenue_split || 0);
  const [guildSplit, setGuildSplit] = useState(guild?.guild_revenue_split || 0);
  const [maxValue] = useState(
    guild!.guild_revenue_split + guild!.admin_revenue_split
  );

  const handleAdminSplitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const count = +e.currentTarget.value;
    if (count <= maxValue) {
      setAdminSplit(count);
      const addedValue = count - guild!.admin_revenue_split;
      if (addedValue > guild!.admin_revenue_split) {
        if (addedValue > guild!.guild_revenue_split) {
          setGuildSplit(addedValue - guild!.guild_revenue_split);
        } else {
          setGuildSplit(guild!.guild_revenue_split - addedValue);
        }
      } else {
        setGuildSplit(guild!.guild_revenue_split - addedValue);
      }
    }
  };

  const handleGuildSplit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const count = +e.currentTarget.value;
    if (count <= maxValue) {
      setGuildSplit(count);
      const addedValue = count - guild!.guild_revenue_split;
      if (addedValue > guild!.admin_revenue_split) {
        if (addedValue > addedValue - guild!.admin_revenue_split) {
          setAdminSplit(addedValue - guild!.admin_revenue_split);
        } else {
          setAdminSplit(guild!.admin_revenue_split - addedValue);
        }
      } else {
        setAdminSplit(guild!.admin_revenue_split - addedValue);
      }
    }
  };

  const [formData, setFormData] = useState({
    admin: `${user?.username} ${user?.last_name}` || '',
    guild: guild?.name || '',
    game: game,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGetGuildGames = async () => {
    try {
      setGamesLoading(true);
      const { data } = await getGuildGames(guild!.id);
      setGameOptions(data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setGamesLoading(false);
    }
  };

  const handleSaveChanges = () => {
    dispatch.guilds.handleUpdateAdminGuildRevenue({
      data: {
        gameId: formData.game,
        guildId: guild!.id,
        admin_percentage: adminSplit,
        guild_percentage: guildSplit,
      },
      setOpen,
      afterUpdate,
    });
  };

  useEffect(() => {
    if (guild) {
      handleGetGuildGames();
    }
    // eslint-disable-next-line
  }, [guild]);
  return (
    <DialogLayout open={open}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>Revenue Split</p>
          <div className='clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <p className='font-size-14 text-secondary text-bold subtitle'>
          Set Revenue Split between Gamified and Guild.
        </p>
        <FieldsGrid>
          {gamesLoading ? (
            <div className='row justify-center' style={{ height: '50px' }}>
              <ClipLoader size={15} color={theme.color.text.primary} />
            </div>
          ) : (
            <Select
              title='Game'
              label='game'
              options={gameOptions.map(game => ({
                label: game.name,
                value: game.id,
              }))}
              value={formData.game}
              onChange={handleChange}
            />
          )}

          <div />
          <InputField
            label='Admin'
            placeholder=''
            type='text'
            onChange={handleChange}
            value={formData.admin}
            readOnly
            name='admin'
          />
          <InputField
            label='Admin’s Split'
            placeholder=''
            max={100}
            min={0}
            type='number'
            onChange={handleAdminSplitChange}
            value={adminSplit}
            name='admin_revenue_split'
          />
          <InputField
            inputClassName='capitalize'
            label='Guild'
            placeholder=''
            readOnly
            type='text'
            onChange={handleChange}
            value={formData.guild}
            name='guild'
          />
          <InputField
            label='Guild’s Split'
            placeholder=''
            max={100}
            min={0}
            type='number'
            onChange={handleGuildSplit}
            value={guildSplit}
            name='guild_revenue_split'
          />
        </FieldsGrid>
        <div className='divider'></div>
        <ButtonContainer>
          <ActionButton
            onClick={() => setOpen(false)}
            variant='primary'
            className='btn btn-primary'
            disabled={loading}
          >
            Cancel
          </ActionButton>
          <ActionButton
            variant='primary'
            onClick={handleSaveChanges}
            className='btn btn-secondary'
            disabled={loading}
            loading={loading}
          >
            Save Changes
          </ActionButton>
        </ButtonContainer>
      </Container>
    </DialogLayout>
  );
};

export default AdminGuildRevenue;

const Container = styled.div`
  padding: 30px;
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  width: 740px;

  & .subtitle {
    margin-top: 9px;
    opacity: 0.6;
  }
  & .divider {
    border-top: 1px solid ${({ theme }) => theme.color.text.secondary + '33'};
  }
`;

const FieldsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 14px;
  margin-block: 30px;

  & label {
    font-weight: 700;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  & .btn {
    height: 44px;
    width: 150px;
    border-radius: 20px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    & .btn {
      height: 55px;
      width: 198px;
      border-radius: 30px;
    }
  }
`;
