import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const UploadIcon: FC<{ [x: string]: any }> = ({ ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  return (
    <svg
      width='24'
      height='20'
      viewBox='0 0 24 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M15.68 15.0274C15.5522 15.0276 15.4257 15.0025 15.3076 14.9536C15.1895 14.9046 15.0823 14.8329 14.992 14.7424L11.792 11.5424L8.59202 14.7424C8.40914 14.9229 8.16229 15.0237 7.90535 15.0229C7.6484 15.022 7.40222 14.9196 7.22053 14.7379C7.03884 14.5562 6.9364 14.31 6.93555 14.0531C6.93471 13.7961 7.03553 13.5493 7.21602 13.3664L11.107 9.4754C11.2895 9.29294 11.537 9.19043 11.795 9.19043C12.0531 9.19043 12.3005 9.29294 12.483 9.4754L16.374 13.3664C16.5101 13.5025 16.6028 13.6758 16.6403 13.8646C16.6778 14.0533 16.6586 14.249 16.5849 14.4267C16.5113 14.6045 16.3866 14.7565 16.2266 14.8634C16.0666 14.9703 15.8785 15.0274 15.686 15.0274H15.68Z'
        fill={activeTheme === 'light' ? '#ffffff' : '#C3CBD2'}
      />
      <path
        d='M11.7899 19.8913C11.5319 19.8913 11.2844 19.7888 11.1019 19.6064C10.9194 19.4239 10.8169 19.1764 10.8169 18.9183V10.1643C10.8127 10.034 10.8348 9.90417 10.8818 9.78253C10.9288 9.66089 10.9997 9.54993 11.0905 9.45626C11.1812 9.36258 11.2898 9.2881 11.4099 9.23723C11.5299 9.18637 11.659 9.16016 11.7894 9.16016C11.9198 9.16016 12.0489 9.18637 12.1689 9.23723C12.289 9.2881 12.3976 9.36258 12.4884 9.45626C12.5791 9.54993 12.65 9.66089 12.697 9.78253C12.744 9.90417 12.7661 10.034 12.7619 10.1643V18.9183C12.7619 19.1762 12.6595 19.4236 12.4773 19.606C12.295 19.7885 12.0478 19.8911 11.7899 19.8913Z'
        fill={activeTheme === 'light' ? '#ffffff' : '#C3CBD2'}
      />
      <path
        d='M19.9507 17.3519C19.7345 17.3519 19.5245 17.2798 19.3539 17.1472C19.1832 17.0145 19.0616 16.8288 19.0081 16.6194C18.9547 16.4099 18.9726 16.1887 19.0588 15.9905C19.1451 15.7923 19.295 15.6285 19.4847 15.5249C20.2435 15.111 20.843 14.4563 21.1884 13.6639C21.5339 12.8716 21.6056 11.9867 21.3924 11.1491C21.1792 10.3114 20.6931 9.56857 20.0109 9.03781C19.3286 8.50706 18.4891 8.21858 17.6247 8.21791H16.3997C16.1841 8.21793 15.9746 8.14635 15.8041 8.0144C15.6336 7.88246 15.5117 7.69762 15.4577 7.48891C15.2002 6.49232 14.7201 5.56708 14.0535 4.78276C13.3869 3.99843 12.5512 3.37544 11.6092 2.96063C10.6672 2.54581 9.64334 2.34997 8.61472 2.38783C7.5861 2.42569 6.57944 2.69627 5.67046 3.17921C4.76147 3.66215 3.9738 4.34489 3.36669 5.1761C2.75957 6.0073 2.3488 6.96534 2.16527 7.97816C1.98174 8.99098 2.03022 10.0322 2.30708 11.0236C2.58393 12.015 3.08194 12.9307 3.76367 13.7019C3.92718 13.8963 4.00822 14.1469 3.98946 14.4001C3.97071 14.6534 3.85366 14.8894 3.66331 15.0575C3.47296 15.2257 3.2244 15.3127 2.97073 15.3001C2.71705 15.2875 2.47838 15.1761 2.30567 14.9899C1.45145 14.0223 0.821774 12.8778 0.461732 11.6383C0.101691 10.3988 0.0201961 9.09502 0.223085 7.82034C0.425975 6.54566 0.908227 5.33162 1.6353 4.26517C2.36238 3.19871 3.31629 2.30624 4.42871 1.65165C5.54114 0.997071 6.78455 0.59658 8.06989 0.478866C9.35523 0.361151 10.6507 0.529126 11.8635 0.970758C13.0763 1.41239 14.1765 2.11675 15.0852 3.0334C15.9939 3.95005 16.6886 5.05629 17.1197 6.27291H17.6257C18.9237 6.27157 20.185 6.70298 21.2104 7.4989C22.2357 8.29483 22.9664 9.40985 23.2869 10.6676C23.6075 11.9254 23.4996 13.2542 22.9803 14.4437C22.461 15.6333 21.56 16.6158 20.4197 17.2359C20.2756 17.3136 20.1143 17.3535 19.9507 17.3519Z'
        fill={activeTheme === 'light' ? '#ffffff' : '#C3CBD2'}
      />
      <path
        d='M15.68 15.0274C15.5522 15.0276 15.4257 15.0025 15.3076 14.9536C15.1895 14.9046 15.0823 14.8329 14.992 14.7424L11.792 11.5424L8.59202 14.7424C8.40914 14.9229 8.16229 15.0237 7.90535 15.0229C7.6484 15.022 7.40222 14.9196 7.22053 14.7379C7.03884 14.5562 6.9364 14.31 6.93555 14.0531C6.93471 13.7961 7.03553 13.5493 7.21602 13.3664L11.107 9.4754C11.2895 9.29294 11.537 9.19043 11.795 9.19043C12.0531 9.19043 12.3005 9.29294 12.483 9.4754L16.374 13.3664C16.5101 13.5025 16.6028 13.6758 16.6403 13.8646C16.6778 14.0533 16.6586 14.249 16.5849 14.4267C16.5113 14.6045 16.3866 14.7565 16.2266 14.8634C16.0666 14.9703 15.8785 15.0274 15.686 15.0274H15.68Z'
        fill={activeTheme === 'light' ? '#ffffff' : '#C3CBD2'}
      />
    </svg>
  );
};

export default UploadIcon;
