import { createModel } from '@rematch/core';
import axios from 'axios';
import { RootModel } from '.';
import { allRoles } from '../../api';
import { IRole } from '../../types/api';

interface IState {
  loading: boolean;
  roles: IRole[];
}

export const roles = createModel<RootModel>()({
  name: 'roles',
  state: {
    loading: false,
    roles: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setRoles(state, payload: IRole[]) {
      state.roles = payload;
    },
  },
  effects: dispatch => ({
    async handleGetAllRoles() {
      try {
        dispatch.roles.setLoading(true);
        const { data } = await allRoles();
        dispatch.roles.setRoles(data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          console.log(errorMessage);
        }
      } finally {
        dispatch.roles.setLoading(false);
      }
    },
  }),
});
