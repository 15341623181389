import React, { FC } from 'react';

import CheckboxCheck from '../../assets/icons/CheckboxCheck';
import CheckboxUncheck from '../../assets/icons/CheckboxUncheck';

interface IProps {
  checked: boolean;
  iconClassName?: string;
  [x: string]: any;
}

const Checkbox: FC<IProps> = ({ checked, iconClassName, ...rest }) => {
  return (
    <span {...rest}>
      {checked ? (
        <CheckboxCheck className='iconClassName' />
      ) : (
        <CheckboxUncheck className='iconClassName' />
      )}
    </span>
  );
};

export default Checkbox;
