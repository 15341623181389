import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const TwichIcon: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M2.36407 0L0.99707 3.483V17.72H5.84707V20.293H8.57407L11.1471 17.72H15.0881L20.3881 12.42V0H2.36407ZM18.5711 11.51L15.5411 14.54H10.6971L8.12107 17.113V14.54H4.03107V1.816H18.5711V11.51ZM15.5411 5.3V10.6H13.7241V5.3H15.5411ZM10.6941 5.3V10.6H8.87707V5.3H10.6941Z'
        fill={fill || theme.color.text.primary}
      />
    </svg>
  );
};

export default TwichIcon;
