import React, { useState, useEffect, useMemo } from 'react';

import styled from 'styled-components';
import { useTable } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';

import Select from '../../../components/Select/Select';
import SearchBar from '../../../components/SearchBar/SearchBar';
import UserRow from './UserRow';

import { lightTheme as theme } from '../../../theme';
import useRole from '../../../hooks/useRole';
import { rolesEnum } from '../../../types/enums';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../../hooks/useTheme';
import { IRole } from '../../../types/api';

const tableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Role',
    accessor: 'role',
  },
  {
    Header: 'Games',
    accessor: 'games',
  },
  {
    Header: 'Total assets',
    accessor: 'noOfAssets',
  },
  {
    Header: 'Revenue Split',
    accessor: 'revenue',
  },
  {
    Header: 'Next Payout',
    accessor: 'payout',
  },
];

const UsersTable = () => {
  const { role } = useRole();
  const { theme } = useTheme();
  const dispatch = useDispatch<Dispatch>();
  const users = useSelector((state: RootState) => state.users.dashboardUsers);
  const user = useSelector((state: RootState) => state.auth.user);
  const roles = useSelector((state: RootState) => state.roles.roles);
  const games = useSelector((state: RootState) => state.games.games);

  const [search, setSearch] = useState('');
  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  const [roleOptions, setRoleOptions] = useState<IRole[]>([]);

  const loading = useSelector((state: RootState) => state.users.loading);

  const columns = useMemo(() => tableColumns, []);

  const tableInstance = useTable({
    // @ts-ignore
    columns,
    data: users,
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    tableInstance;

  const [filters, setFilters] = useState({
    game: '',
    role: '',
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (role?.name === rolesEnum.Admin) {
      dispatch.games.handleGetAllGames();
    } else {
      if (currentGuild) {
        dispatch.games.handleGetAllGames(currentGuild.id);
      }
    }
    // eslint-disable-next-line
  }, [currentGuild, user, role]);

  useEffect(() => {
    if (role?.name === rolesEnum.Admin) {
      let query = '';
      if (filters.game) {
        query = `${query}&gameId=${filters.game}`;
      }
      if (filters.role) {
        query = `${query}&roleId=${filters.role}`;
      }
      dispatch.users.getDashboardUsers(query);
    } else {
      if (currentGuild) {
        let query = `guildId=${currentGuild.id}`;
        if (filters.game) {
          query = `${query}&gameId=${filters.game}`;
        }
        if (filters.role) {
          query = `${query}&roleId=${filters.role}`;
        }
        dispatch.users.getDashboardUsers(query);
      }
    }
    // eslint-disable-next-line
  }, [currentGuild, user, role, filters]);

  useEffect(() => {
    if (roles.length) {
      const roleFilterOptions = roles.filter(
        role => role.name !== rolesEnum.Admin
      );
      setRoleOptions(roleFilterOptions);
    }
  }, [roles]);

  return (
    <Container>
      <div className='row justify-space-between'>
        <p className='font-size-18 text-primary'>Users</p>
        <div className='row' style={{ gap: '4px' }}>
          {games.length > 0 && (
            <Select
              options={games.map(game => ({
                label: game.name,
                value: game.id,
              }))}
              label='Games'
              value={filters.game}
              onChange={handleChange}
              name='game'
            />
          )}

          <Select
            inputClassName='capitalize'
            options={roleOptions.map(role => ({
              label: role.name,
              value: role.id,
            }))}
            label='Role'
            value={filters.role}
            onChange={handleChange}
            name='role'
          />
          <SearchBar value={search} onChange={handleSearch} name='search' />
        </div>
      </div>
      {loading && !users.length ? (
        <div className='row justify-center' style={{ height: '300px' }}>
          <ClipLoader size={50} color={theme.color.text.primary} />
        </div>
      ) : (
        <TableContainer>
          {!users.length ? (
            <p className='font-size-14 text-primary'>No user Found</p>
          ) : (
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr
                    className='header-row'
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps()}
                        className='font-size-12 text-primary text-semibold'
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <UserRow search={search} key={row.original.id} row={row} />
                  );
                })}
              </tbody>
            </table>
          )}
        </TableContainer>
      )}
    </Container>
  );
};

export default UsersTable;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    margin-top: 30px;
    padding: 30px;
  }
`;

const TableContainer = styled.div`
  margin-top: 13px;

  & table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    & tr {
      height: 50px;
      &.header-row {
        height: 40px;
        border: none;
        & th {
          text-align: left;
          background-color: ${({ theme }) => theme.color.background.headerRow};
          &:first-of-type {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding-left: 12px;
          }
          &:last-of-type {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-right: 12px;
          }
        }
      }
      & td {
        background-color: ${({ theme }) => theme.color.background.row};
        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          padding-left: 12px;
        }
        &:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          padding-right: 12px;
        }
      }
    }
  }
`;
