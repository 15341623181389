import { createModel } from '@rematch/core';
import { RootModel } from '.';

interface IState {
  loading: boolean;
}

export const loadingDialog = createModel<RootModel>()({
  name: 'loadingDialog',
  state: {
    loading: false,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
  },
});
