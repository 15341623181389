import React, { FC } from 'react';

import styled from 'styled-components';
import CloseIcon from '../../assets/icons/CloseIcon';
import { IScreenshot } from '../../types/interface';
import ScreenshotPicker from './ScreenshotPicker';

interface IProps {
  screenshots: Array<IScreenshot>;
  addedScreenshots: File[];
  setAddedScreenshots: React.Dispatch<React.SetStateAction<File[]>>;
  onDelete: (id: string) => Promise<void>;
  screenshotError?: string;
}

const Screenshots: FC<IProps> = ({
  screenshots,
  addedScreenshots,
  setAddedScreenshots,
  onDelete,
  screenshotError,
}) => {
  const handleRemoveAddedScreenShot = (screenshot: File) => {
    setAddedScreenshots(addedScreenshots.filter(pic => pic !== screenshot));
  };

  return (
    <Container>
      <div className='image-picker'>
        <p className='font-size-18 text-primary text-semibold'>
          Add In-Game Screenshots
        </p>
        <div className='divider'></div>
        <div className='picker'>
          <ScreenshotPicker
            screenshots={screenshots}
            addedScreenshots={addedScreenshots}
            setAddedScreenshots={setAddedScreenshots}
            screenshotError={screenshotError}
          />
        </div>
      </div>
      <div className='screenshots'>
        {screenshots.map((screenshot, i) => (
          <ScreenShot key={i}>
            <div className='image-container'>
              <img src={screenshot.screenshot_url} alt='' />
            </div>
            <div
              className='row justify-center clickable'
              onClick={() => onDelete(screenshot.id)}
            >
              <p className='font-size-12 text-primary text-semibold'>delete</p>
              <CloseIcon className='close-icon' />
            </div>
          </ScreenShot>
        ))}

        {addedScreenshots.map((screenshot, i) => (
          <ScreenShot key={i}>
            <div className='image-container'>
              <img src={URL.createObjectURL(screenshot)} alt='' />
            </div>
            <div className='row justify-center'>
              <p className='font-size-12 text-primary text-semibold'>
                Axie_infinity.SVG
              </p>
              <CloseIcon
                className='close-icon'
                onClick={() => handleRemoveAddedScreenShot(screenshot)}
              />
            </div>
          </ScreenShot>
        ))}
      </div>
    </Container>
  );
};

export default Screenshots;

const Container = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: stretch;
  gap: 15px;
  & .image-picker {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.background.row};
    border-radius: 10px;
    padding-block: 35px 15px;
    padding-inline: 15px;

    & .picker {
      flex-grow: 1;
    }

    & .divider {
      margin-block: 21px 19px;
      border-top: 1px solid ${({ theme }) => theme.color.background.headerRow};
    }
  }

  & .screenshots {
    flex: 3;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
  }
`;

const ScreenShot = styled.div`
  & .image-container {
    aspect-ratio: 1.6/1;
    background-color: ${({ theme }) => theme.color.background.row};
    border-radius: 10px;
    margin-bottom: 8px;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .row {
    gap: 12px;
    & .close-icon {
      width: 9px;
      cursor: pointer;
    }
  }
`;
