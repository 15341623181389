import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import CloseIcon from '../../assets/icons/CloseIcon';
import CrossIcon from '../../assets/icons/CrossIcon';
import Select from '../../components/Select/Select';
import ActionButton from '../../components/ActionButton/ActionButton';
import DialogLayout from '../../layouts/DialogLayout/DialogLayout';

import {
  checkGasFee,
  getGameNftContracts,
  getUserById,
  getUserGames,
  getUserNfts,
  pullbackNfts,
} from '../../api';

import { darkTheme, lightTheme, lightTheme as theme } from '../../theme';
import { IScholarNfts, ISingleUser } from '../../types/interface';
import NftImage from '../../components/NftImage/NftImage';
import { IGame, IPullbackNfts } from '../../types/api';
import axios from 'axios';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}

const ManageAssets: FC<IProps> = ({ open, setOpen }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme = activeTheme === 'light' ? lightTheme : darkTheme;

  const userId = useParams().id;
  const dispatch = useDispatch<Dispatch>();
  const [game, setGame] = useState('');
  const [chain, setChain] = useState('');
  const [user, setUser] = useState<ISingleUser | null>(null);
  const [chainOptions, setChainOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const [chainsLoading, setChainsLoading] = useState(false);
  const [nftsLoading, setNftsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  const supportedChains = useSelector(
    (state: RootState) => state.config.nftContracts
  );

  const [userGames, setUserGames] = useState<IGame[]>([]);

  const [userNfts, setUserNfts] = useState<IScholarNfts[]>([]);
  const [selectedNfts, setSelectedNfts] = useState<IScholarNfts[]>([]);

  const handleSelectNft = (nft: IScholarNfts) => {
    const _allNfts = userNfts.filter(
      _nft => _nft.token_hash !== nft.token_hash
    );
    setSelectedNfts(prev => [...prev, nft]);
    setUserNfts([..._allNfts]);
  };

  const handleDeSelectNft = (nft: IScholarNfts) => {
    const _selected = selectedNfts.filter(
      _nft => _nft.token_hash !== nft.token_hash
    );
    setUserNfts(prev => [...prev, nft]);
    setSelectedNfts([..._selected]);
  };

  const handleGameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const gameId = e.currentTarget.value;
    setGame(gameId);
  };

  const handleChainChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setChain(e.currentTarget.value);
  };

  const handleGetUser = async () => {
    try {
      if (userId) {
        const { data } = await getUserById(userId);
        setUser(data);
      }
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const handleGetGameChainIds = async () => {
    try {
      setChainsLoading(true);
      const options: Array<{ label: string; value: string }> = [];
      const { data } = await getGameNftContracts(game);
      data.forEach(contract => {
        supportedChains.forEach(c => {
          if (c.chain === contract.chain) {
            const exist = options.find(op => op.value === c.chain);
            if (!exist) {
              options.push({ label: c.name, value: c.chain });
            }
          }
        });
      });
      setChainOptions([...options]);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setChainsLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGetNfts = async () => {
    try {
      setNftsLoading(true);
      setSelectedNfts([]);
      const sNfts: IScholarNfts[] = [];
      const { data } = await getUserNfts({
        public_address: user!.account.starz_wallet_address,
        game_id: game,
        chain_id: chain,
        userId: user!.id,
      });
      data.forEach(e => {
        e.Nfts.forEach(nft => {
          sNfts.push({
            ...nft,
            contract: e.contract,
            game_id: e.game_id,
            contract_id: e.contract_id,
          });
        });
      });

      setUserNfts(sNfts);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setNftsLoading(false);
    }
  };

  const handlePullback = async () => {
    try {
      setLoading(true);
      const pullbackData = selectedNfts.map(nft => {
        return {
          token_id: nft.token_id!,
          contract_id: nft.contract_id!,
          amount: nft.amount,
        };
      });

      const data: IPullbackNfts = {
        Nfts: pullbackData,
        game_id: game,
        chain: chain,
        guildId: currentGuild!.id,
        userId: user!.id,
      };
      const res = await checkGasFee({ ...data, isPullBack: true });
      if (res.data) {
        await pullbackNfts(data);
      }
      setOpen(false);
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data.message;
        dispatch.gasAlert.open({
          message: errorMessage,
          isPullback: true,
          scholarWalletAddress: user!.account.starz_wallet_address,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGetUserGames = async () => {
    try {
      const { data } = await getUserGames(userId!);
      setUserGames(data);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (game) {
      handleGetGameChainIds();
    }
    // eslint-disable-next-line
  }, [game]);

  useEffect(() => {
    if (userGames.length) {
      setGame(userGames[0].id);
    }
    // eslint-disable-next-line
  }, [userGames]);

  useEffect(() => {
    handleGetUser();
    handleGetUserGames();
    if (!supportedChains.length) {
      dispatch.config.handleGetAllSupportedChains();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (game && chain) {
      handleGetNfts();
    }
    // eslint-disable-next-line
  }, [game, chain]);

  return (
    <DialogLayout open={open}>
      <Content>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>
            Manage assets for Alfa Junkie
          </p>
          <div className='icon clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <InnerContainer>
          <div className='row'>
            <div className='flex-1'>
              <Select
                containerClassName='select'
                label='Game'
                value={game}
                onChange={handleGameChange}
                options={
                  userGames.map(game => ({
                    label: game.name,
                    value: game.id,
                  })) || []
                }
              />
            </div>
            <div className='flex-1'>
              {chainsLoading ? (
                <div className='row justify-center'>
                  <ClipLoader size={20} color={theme.color.text.primary} />
                </div>
              ) : (
                <Select
                  containerClassName='select'
                  label="NFT's"
                  value={chain}
                  onChange={handleChainChange}
                  options={chainOptions}
                />
              )}
            </div>
          </div>
          <div className='asset-container'>
            <p className='font-size-14 text-primary text-semibold'>
              Pull back assets
            </p>
            <SelectedAssets>
              {selectedNfts.map(nft => (
                <div key={nft.token_hash} className='asset'>
                  <div className='image'>
                    <NftImage size={20} container nftData={nft} />
                  </div>
                  <div
                    className='icon clickable'
                    onClick={() => handleDeSelectNft(nft)}
                  >
                    <CloseIcon />
                  </div>
                </div>
              ))}
            </SelectedAssets>
            <div className='divider'></div>
            <div className='row justify-space-between'>
              <p className='font-size-14 text-primary text-semibold'>
                All assets
              </p>
              <p className='font-size-14 text-primary text-semibold'>
                Select all
              </p>
            </div>
            <div className='wrapper'>
              {nftsLoading ? (
                <div className='row justify-center' style={{ height: '100px' }}>
                  <ClipLoader size={40} color={theme.color.text.primary} />
                </div>
              ) : (
                <Assets>
                  {userNfts.map(nft => (
                    <div
                      className='asset clickable'
                      onClick={() => handleSelectNft(nft)}
                      key={nft.token_hash}
                    >
                      <div className='image'>
                        <NftImage nftData={nft} container size={120} />
                      </div>
                    </div>
                  ))}
                </Assets>
              )}
            </div>
          </div>
        </InnerContainer>
        <ButtonContainer>
          <ActionButton
            onClick={handleClose}
            variant='primary'
            className='btn btn-primary'
            disabled={loading}
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handlePullback}
            variant='primary'
            className='btn btn-secondary'
            loading={loading}
            disabled={loading}
          >
            Confirm
          </ActionButton>
        </ButtonContainer>
      </Content>
    </DialogLayout>
  );
};

export default ManageAssets;

const Content = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  width: 650px;
  padding: 24px 20px;
`;

const InnerContainer = styled.div`
  background-color: ${({ theme }) => theme.color.background.headerRow};
  padding: 15px;
  margin-top: 20px;
  border-radius: 8px;

  & .row {
    gap: 10px;
    margin-bottom: 14px;
  }

  & .select {
    width: 100%;
  }

  & .asset-container {
    background-color: ${({ theme }) => theme.color.background.row};
    padding: 14px 15px;
    border-radius: 8px;

    & .divider {
      margin-block: 20px 14px;
      border-top: 1px solid ${({ theme }) => theme.color.text.dim + '33'};
    }

    & .wrapper {
      max-height: 200px;
      overflow-y: auto;
    }
  }
`;

const SelectedAssets = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;

  & .asset {
    position: relative;
    & .image {
      height: 70px;
      width: 70px;
      background-color: ${({ theme }) => theme.color.background.headerRow};
      border-radius: 8px;
      overflow: hidden;

      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    & .icon {
      height: 15px;
      width: 15px;
      position: absolute;
      right: -8px;
      top: -8px;
      border-radius: 2px solid ${({ theme }) => theme.color.background.row};
      & svg {
        width: 13px;
        height: 13px;
      }
    }
  }
`;

const Assets = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  & .asset {
    & .image {
      height: 60px;
      width: 60px;
      background-color: ${({ theme }) => theme.color.background.headerRow};
      border-radius: 8px;
      overflow: hidden;

      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 12px;

  & .btn {
    height: 44px;
    width: 150px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .btn {
      height: 50px;
      width: 192px;
    }
  }
`;
