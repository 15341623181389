import React, { FC, useState } from 'react';

import styled from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';

import ArrowDownIcon from '../../assets/icons/ArrowDownIcon';
import ThemeToggler from '../../components/ThemeToggler/ThemeToggler';

import { lightTheme as theme } from '../../theme';
import { logout } from '../../api';

const ProfilePopup: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const [open, setOpen] = useState(false);

  const user = useSelector((state: RootState) => state.auth.user);

  const handleLogout = async () => {
    dispatch.auth.logOut();
    await logout();
  };
  return (
    <Popup onClick={() => setOpen(true)}>
      <MoreOptions>
        <div className='indicator'></div>
        <ArrowDownIcon />
      </MoreOptions>
      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Menu>
            <div className='row'>
              <p className='font-size-14 text-primary'>{user?.username}</p>
            </div>
            <div className='row' style={{ gap: '15px' }}>
              <p className='font-size-14 text-primary'>Dark mode</p>
              <ThemeToggler />
            </div>
            <div className='row clickable'>
              <Link to='/dashboard/settings' className='anchor'>
                <p className='font-size-14 text-primary'>Settings</p>
              </Link>
            </div>
            <div className='row clickable' onClick={handleLogout}>
              <p className='font-size-14 text-primary'>Logout</p>
            </div>
          </Menu>
        </ClickAwayListener>
      )}
    </Popup>
  );
};

export default ProfilePopup;

const Popup = styled.div`
  position: relative;
  display: flex;
  gap: 15px;
  align-items: center;
`;

const MoreOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  & .indicator {
    height: 30px;
    width: 30px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.color.text.danger};
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .indicator {
      height: 44px;
      width: 44px;
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  width: 163px;
  background-color: ${({ theme }) => theme.color.background.menu};
  padding: 0px 10px;
  transform: translateY(100%);
  border-radius: 10px;
  z-index: 100;

  & .row {
    padding-block: 7px;
    border-bottom: 1px solid ${({ theme }) => theme.color.text.dim + '66'};
    padding-inline: 4px;

    &:first-of-type {
      padding-inline: 0px;
    }
    &:last-of-type {
      border-bottom: none;
    }

    & .wallet-address {
      padding: 6px 9px;
      display: flex;
      align-items: center;
      gap: 4px;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.color.text.dim + '1a'};
      width: 100%;
      border-radius: 15px;

      & .icon {
        cursor: pointer;
      }
    }
  }
`;
