import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import CopyIcon from '../../assets/icons/CopyIcon';
import CrossIcon from '../../assets/icons/CrossIcon';
import ActionButton from '../../components/ActionButton/ActionButton';
import SearchBar from '../../components/SearchBar/SearchBar';
import Select from '../../components/Select/Select';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';
import { toast } from 'react-toastify';
import { IAcceptedScholarshipUsers } from '../../types/interface';
import { checkGasFee, getGameNftContracts } from '../../api';
import axios from 'axios';
import { ITransferMassNfts } from '../../types/api';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  contract_id: string;
  gameId: string;
  handleGetNfts: () => void;
  name: string;
}

const TransferAsset: FC<IProps> = ({
  open,
  setOpen,
  contract_id,
  gameId,
  handleGetNfts,
  name,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  const [selectedUser, setSelectedUser] =
    useState<IAcceptedScholarshipUsers | null>(null);

  const selectedNft = useSelector(
    (state: RootState) => state.vault.selectedNft
  );

  const selectedNfts = useSelector(
    (state: RootState) => state.vault.selectedNfts
  );
  const isTransferMultiple = !!selectedNfts.length;

  const loading = useSelector((state: RootState) => state.vault.loading);

  const users = useSelector(
    (state: RootState) => state.vault.acceptedScholarshipUsers
  );

  const [filters, setFilters] = useState({
    game: gameId,
    search: '',
  });

  const [chain, setChain] = useState('');
  const [gasChecking, setGasChecking] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectUser = (user: IAcceptedScholarshipUsers) => {
    setSelectedUser(user);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ): void => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleCopyAddress = (e: React.MouseEvent, address: string) => {
    e.stopPropagation();
    navigator.clipboard.writeText(address);
    toast.success('Address copied successfully');
  };

  const handleTransferNft = async () => {
    try {
      setGasChecking(true);
      if (isTransferMultiple) {
        const apiData: ITransferMassNfts = {
          Nfts: selectedNfts.map(nft => ({
            token_id: nft.token_id,
            contract_id: contract_id,
            amount: nft.amount || '0',
          })),
          gameId: filters.game,
          guildId: currentGuild!.id,
          userId: selectedUser!.id,
        };
        const gasFeeData = { ...apiData };
        // @ts-ignore
        delete gasFeeData.gameId;
        // @ts-ignore
        gasFeeData.game_id = filters.game;
        // @ts-ignore
        const res = await checkGasFee({
          ...gasFeeData,
          chain: chain,
          isPullBack: false,
        });
        if (res.data) {
          dispatch.vault.handleTransferMultipleNfts({
            data: apiData,
            onSuccess: handleGetNfts,
            setOpen,
          });
        }
        dispatch.vault.setSelectedNfts([]);
      } else {
        if (currentGuild && filters.game && selectedNft && selectedUser) {
          const data = {
            Nfts: [
              {
                token_id: selectedNft.token_id!,
                contract_id: contract_id,
                amount: selectedNft.amount,
              },
            ],
            game_id: filters.game,
            chain: chain,
            guildId: currentGuild!.id,
            userId: selectedUser.id,
          };
          const res = await checkGasFee({ ...data, isPullBack: false });
          if (res.data) {
            dispatch.vault.handleTransferNft({
              data: {
                contractId: contract_id,
                gameId: filters.game,
                guildId: currentGuild?.id,
                tokenId: selectedNft?.token_id,
                userId: selectedUser?.id,
              },
              onSuccess: handleGetNfts,
              setOpen,
            });
          }
        }
      }
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data.message;
        dispatch.errorModal.setOpen({
          title: 'Error!',
          description: errorMessage,
        });
      }
    } finally {
      setGasChecking(false);
    }
  };

  const handleGameNftContracts = async () => {
    try {
      const { data } = await getGameNftContracts(filters.game);
      const contract = data.find(c => c.id === contract_id);
      setChain(contract?.chain || '');
    } catch (err: any) {}
  };

  useEffect(() => {
    if (filters.game && currentGuild) {
      dispatch.vault.handleGetAcceptedScholarshipUsers({
        gameId: filters.game,
      });
      handleGameNftContracts();
    }
    // eslint-disable-next-line
  }, [currentGuild, filters]);
  return (
    <DialogLayout open={open}>
      <Content>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>
            Transfer assets {name}
          </p>
          <div className='icon clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <div className='row filters'>
          <Select
            label='Game'
            value={filters.game}
            onChange={handleChange}
            name='game'
            options={
              currentGuild?.Games.map(game => ({
                label: game.name,
                value: game.id,
              })) || []
            }
          />
          <div className='flex-1'>
            <SearchBar
              value={filters.search}
              onChange={handleChange}
              name='search'
              className='searchBar'
              containerStyles={{ width: '100%' }}
              readOnly
            />
          </div>
        </div>
        <TableContainer>
          <table>
            <thead>
              <tr className='header-row'>
                <th className='font-size-12 text-primary text-semibold'>
                  User
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Email address
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Wallet address
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <React.Fragment key={user.id}>
                  <tr style={{ height: '5px' }} />
                  <tr
                    className={classNames(
                      'clickable ',
                      user.id === selectedUser?.id && 'active'
                    )}
                    onClick={() => handleSelectUser(user)}
                  >
                    <td className='font-size-12 text-primary text-semibold capitalize'>
                      {user.username}
                    </td>
                    <td className='font-size-12 text-primary text-semibold'>
                      {user.email}
                    </td>
                    <td className='font-size-12 text-primary text-semibold'>
                      <div className='row wallet-address '>
                        <p className='font-size-12'>{user.wallet_address}</p>
                        <div
                          className='icon clickable'
                          onClick={(e: React.MouseEvent) =>
                            handleCopyAddress(e, user.wallet_address)
                          }
                        >
                          <CopyIcon />
                        </div>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </TableContainer>
        <ButtonContainer>
          <ActionButton
            onClick={handleClose}
            variant='primary'
            className='btn primary'
            disabled={loading || gasChecking}
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleTransferNft}
            variant='primary'
            className='btn secondary'
            loading={loading || gasChecking}
            disabled={loading || gasChecking}
          >
            Confirm
          </ActionButton>
        </ButtonContainer>
      </Content>
    </DialogLayout>
  );
};

export default TransferAsset;

const Content = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  padding: 29px 20px;
  width: 650px;

  & .filters {
    gap: 20px;
    margin-block: 16px;
    & .searchBar {
      width: 100%;
    }
  }
`;

const TableContainer = styled.div`
  margin-top: 13px;
  background-color: ${({ theme }) => theme.color.background.row};
  padding: 15px;
  border-radius: 10px;

  & table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    table-layout: fixed;

    & tr {
      height: 50px;
      &.header-row {
        height: 40px;
        border: none;
        & th {
          text-align: left;
          background-color: ${({ theme }) =>
            theme.color.background.button.primary.bg};
          color: ${({ theme }) =>
            theme.color.background.button.primary.color} !important;
          &:first-of-type {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding-left: 12px;
          }
          &:last-of-type {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-right: 12px;
          }
        }
      }
      & td {
        background-color: ${({ theme }) => theme.color.background.headerRow};
        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          padding-left: 12px;
        }
        &:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          padding-right: 12px;
        }

        & .wallet-address {
          gap: 6px;
          & p {
            max-width: 28ch;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      &.active {
        background-color: ${({ theme }) =>
          theme.color.background.button.primary.bg + '66'};
        td {
          background-color: ${({ theme }) =>
            theme.color.background.button.primary.bg + '66'};
          color: ${({ theme }) => theme.color.background.button.primary.color};
        }
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 16px;
  & .btn {
    height: 50px;
    width: 192px;

    &.primary {
      background-color: ${({ theme }) => theme.color.background.row};
    }

    &.secondary {
      background-color: ${({ theme }) =>
        theme.color.background.button.primary.bg};
      color: ${({ theme }) => theme.color.background.button.primary.color};
    }
  }
`;
