import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  getPermissionConfig,
  getUserPermissions,
  updatePermission,
} from '../../api';
import { IPermissionConfig, IUpdatePermission } from '../../types/api';
import {
  IFeature,
  IModule,
  IPermission,
  IPermissionRole,
  IRolePermissions,
  IUserPermissions,
} from '../../types/interface';

interface IState {
  loading: boolean;
  modules: IModule[];
  features: IFeature[];
  roles: IPermissionRole[];
  rolePermissions: IRolePermissions[];
  permissions: IPermission[];
  userPermissions: IUserPermissions[];
  userPermissionLoading: boolean;
}

export const permissions = createModel<RootModel>()({
  name: 'permissions',
  state: {
    loading: false,
    modules: [],
    features: [],
    rolePermissions: [],
    roles: [],
    permissions: [],
    userPermissions: [],
    userPermissionLoading: false,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setPermissionData(state, payload: IPermissionConfig) {
      state.features = payload.features;
      state.modules = payload.modules;
      state.permissions = payload.permissions;
      state.rolePermissions = payload.rolePermissions;
      state.roles = payload.roles;
    },
    setUserPermissions(state, payload: IUserPermissions[]) {
      state.userPermissions = payload;
    },
    setUserPermissionsLoading(state, payload: boolean) {
      state.userPermissionLoading = payload;
    },
  },
  effects: dispatch => ({
    async handleGetAllPermissionsConfig() {
      try {
        dispatch.permissions.setLoading(true);
        const { data } = await getPermissionConfig();
        dispatch.permissions.setPermissionData(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.permissions.setLoading(false);
      }
    },
    async handleUpdatePermission(payload: IUpdatePermission) {
      try {
        dispatch.permissions.setLoading(true);
        await updatePermission(payload);
        dispatch.permissions.handleGetAllPermissionsConfig();
        dispatch.permissions.handleGetUserPermissions();
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.permissions.setLoading(false);
      }
    },
    async handleGetUserPermissions() {
      try {
        dispatch.permissions.setUserPermissionsLoading(true);
        const { data } = await getUserPermissions();
        dispatch.permissions.setUserPermissions(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.permissions.setUserPermissionsLoading(false);
      }
    },
  }),
});
