import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import CrossIcon from '../../assets/icons/CrossIcon';
import DialogLayout from '../../layouts/DialogLayout/DialogLayout';
import { IPayoutUser } from '../../types/interface';
import ActionButton from '../../components/ActionButton/ActionButton';
import Select from '../../components/Select/Select';
import { rolesEnum } from '../../types/enums';
import { IRole } from '../../types/api';
import { getPayoutUsers, massPayout } from '../../api';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../hooks/useTheme';
import { toast } from 'react-toastify';
import axios from 'axios';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  contractId: string;
  gameId: string;
}

const MassPayout: FC<IProps> = ({ open, setOpen, contractId, gameId }) => {
  const { theme } = useTheme();
  const guild = useSelector((state: RootState) => state.guilds.currentGuild);
  const roles = useSelector((state: RootState) => state.roles.roles);
  const handleClose = () => {
    setOpen(false);
  };

  const [users, setUsers] = useState<IPayoutUser[]>([]);

  const [rolesOptions, setRolesOptions] = useState<IRole[]>([]);
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [payoutLoading, setPayoutLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRole(e.currentTarget.value);
  };

  const handleMassPayout = async () => {
    try {
      setPayoutLoading(true);
      const apiData = {
        contractId,
        guildId: guild!.id,
        roleId: role,
        accounts: users.map(user => user.account_id),
      };

      const { data } = await massPayout(apiData);
      toast.success(`Payout in process queueId = ${data?.queueId}`);
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data?.message;
        toast.error(errorMessage);
      }
    } finally {
      setPayoutLoading(false);
    }
  };

  const handleGetUsers = async () => {
    try {
      setLoading(true);
      let query = `guildId=${guild?.id}&gameId=${gameId}`;
      if (role) {
        query += `&roleId=${role}`;
      }
      const { data } = await getPayoutUsers(query);
      setUsers(data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const newRoles = roles.filter(role => {
      if (
        role.name !== rolesEnum.Admin &&
        role.name !== rolesEnum.GuildManager
      ) {
        return true;
      } else {
        return false;
      }
    });
    setRolesOptions(newRoles);
    setRole(newRoles[0].id);
    // eslint-disable-next-line
  }, [roles]);

  useEffect(() => {
    if (role) {
      handleGetUsers();
    }
    // eslint-disable-next-line
  }, [role]);

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>Mass Payout</p>
          <div className='icon clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <div className='row flex-end filters'>
          <Select
            inputClassName='capitalize'
            label='Role'
            options={
              rolesOptions.map(role => ({
                label: role.name,
                value: role.id,
              })) || []
            }
            value={role}
            onChange={handleChange}
          />
        </div>
        {loading ? (
          <div className='row justify-center'>
            <ClipLoader size={50} color={theme.color.text.primary} />
          </div>
        ) : (
          <TableContainer>
            <table>
              <thead>
                <tr className='header-row'>
                  <th className='font-size-12 text-primary text-semibold'>
                    Name
                  </th>
                  <th className='font-size-12 text-primary text-semibold'>
                    Email
                  </th>
                  <th className='font-size-12 text-primary text-semibold'>
                    Role
                  </th>
                  <th className='font-size-12 text-primary text-semibold'>
                    Payout wallet address
                  </th>
                  <th className='font-size-12 text-primary text-semibold'>
                    Starz wallet address
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map(user => (
                  <React.Fragment key={user.id}>
                    <tr style={{ height: '5px' }} />
                    <tr>
                      <td className='text-primary font-size-12 capitalize'>
                        {user.username}
                      </td>
                      <td className='text-primary font-size-12'>
                        {user.email}
                      </td>
                      <td className='text-primary font-size-12 capitalize'>
                        {roles.find(role => role.id === user.role_id)?.name}
                      </td>
                      <td className='text-primary font-size-12'>
                        {user.payout_wallet_address}
                      </td>
                      <td className='text-primary font-size-12'>
                        {user.starz_wallet_address}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </TableContainer>
        )}

        {!loading && (
          <ButtonContainer>
            <ActionButton
              onClick={handleClose}
              variant='primary'
              className='btn btn-primary'
              disabled={payoutLoading}
            >
              Cancel
            </ActionButton>
            <ActionButton
              variant='primary'
              onClick={handleMassPayout}
              className='btn btn-secondary'
              disabled={payoutLoading}
              loading={payoutLoading}
            >
              Confirm
            </ActionButton>
          </ButtonContainer>
        )}
      </Container>
    </DialogLayout>
  );
};

export default MassPayout;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  padding: 30px;
  width: 1080px;

  & .filters {
    margin-top: 20px;
  }
`;

const TableContainer = styled.div`
  margin-top: 20px;
  overflow: auto;

  & table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    min-width: 700px;

    & tr {
      height: 50px;
      &.header-row {
        height: 40px;
        border: none;
        & th {
          padding-inline: 10px;
          text-align: left;
          background-color: ${({ theme }) => theme.color.background.headerRow};
          &:first-of-type {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding-left: 12px;
          }
          &:last-of-type {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-right: 12px;
          }
        }
      }
      & td {
        background-color: ${({ theme }) => theme.color.background.row};
        padding-inline: 10px;
        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          padding-left: 12px;
        }
        &:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          padding-right: 12px;
        }
        & .btn {
          background-color: ${({ theme }) =>
            theme.color.background.button.primary.bg};
          color: ${({ theme }) => theme.color.background.button.primary.color};
          height: 35px;
        }
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;

  & .btn {
    height: 44px;
    width: 150px;
    border-radius: 4px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
`;
