import React, { FC, useState, useEffect } from 'react';
import ClickAwayListener from 'react-click-away-listener';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../../../../store';

import { getNftMetadata } from '../../../../../api';
import MoreIcon from '../../../../../assets/icons/MoreIcon';
import NftImage from '../../../../../components/NftImage/NftImage';
import { INft } from '../../../../../types/interface';
import {
  features,
  modulesEnum,
  permissionEnum,
} from '../../../../../types/enums';
import WithPermissions from '../../../../../components/WithPermissions/WithPermissions';
import useModulePermissions from '../../../../../hooks/useModulePermissions';

interface IProps {
  setTransferAssetDialog: React.Dispatch<React.SetStateAction<boolean>>;
  nft: INft;
}

const Nft: FC<IProps> = ({ setTransferAssetDialog, nft }) => {
  const permissions = useModulePermissions(modulesEnum.Guilds);
  const dispatch = useDispatch<Dispatch>();

  const [open, setOpen] = useState(false);

  const [metaData, setMetaData] = useState<any>(null);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleTransferAssetDialog = () => {
    dispatch.vault.setSelectedNft(nft);
    setTransferAssetDialog(true);
  };

  const handleGetTokenMetadata = async () => {
    if (!nft.metadata) {
      const { data } = await getNftMetadata({
        contract_address: nft.token_address,
        token_id: nft.token_id,
        token_uri: nft.token_uri,
      });
      setMetaData(data);
    } else {
      setMetaData(JSON.parse(nft.metadata));
    }
  };

  useEffect(() => {
    handleGetTokenMetadata();
    // eslint-disable-next-line
  }, []);

  return (
    <NftContainer>
      <div className='image'>
        <NftImage nftData={nft} size={240} container />
      </div>
      <p className='font-size-12 text-secondary text-bold'>{`${metaData?.name
        ?.split(' ')
        .at(-1)} #${nft.token_id}`}</p>
      <div className='row justify-space-between'>
        <p className='font-size-20 text-primary text-bold'>
          {metaData?.name?.split(' ').at(-1)}
        </p>

        {(permissions?.permissions[features.transferAssets] ===
          permissionEnum.fullPermission ||
          permissions?.permissions[features.removeAssets] ===
            permissionEnum.fullPermission) && (
          <div className='icon clickable' onClick={handleClick}>
            <MoreIcon />
            {open && (
              <ClickAwayListener onClickAway={handleClose}>
                <Menu>
                  <WithPermissions
                    moduleName={modulesEnum.Guilds}
                    featureName={features.transferAssets}
                    action
                  >
                    <div
                      className='row clickable'
                      onClick={handleTransferAssetDialog}
                    >
                      <p className='font-size-14 text-primary'>
                        Transfer asset
                      </p>
                    </div>
                  </WithPermissions>
                  <WithPermissions
                    moduleName={modulesEnum.Guilds}
                    featureName={features.removeAssets}
                    action
                  >
                    <div className='row clickable'>
                      <p className='font-size-14 text-primary'>Remove asset</p>
                    </div>
                  </WithPermissions>
                </Menu>
              </ClickAwayListener>
            )}
          </div>
        )}
      </div>
    </NftContainer>
  );
};

export default Nft;

const NftContainer = styled.div`
  background-color: ${({ theme }) => theme.color.background.row};
  padding-inline: 9px;
  padding-block: 9px 14px;
  border-radius: 10px;

  & .image {
    aspect-ratio: 1/1;
    border-radius: 10px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.color.background.headerRow};
    margin-bottom: 9px;
  }

  & .icon {
    margin-top: 4px;
    position: relative;
    padding-inline: 3px;
  }
`;

const Menu = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 163px;
  background-color: ${({ theme }) => theme.color.background.menu};
  padding: 0px 10px;
  border-radius: 10px;
  z-index: 100;
  filter: drop-shadow(2px 2px 4px rgba(53, 57, 64, 0.102));

  & .row {
    padding-block: 7px;
    border-bottom: 1px solid ${({ theme }) => theme.color.text.dim + '66'};
    padding-inline: 4px;

    &:last-of-type {
      border-bottom: none;
    }

    & .wallet-address {
      padding: 6px 9px;
      display: flex;
      align-items: center;
      gap: 4px;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.color.text.dim + '1a'};
      width: 100%;
      border-radius: 15px;

      & .icon {
        cursor: pointer;
      }
    }
  }
`;
