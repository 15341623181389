import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../store';
import classNames from 'classnames';

import ActionButton from '../../../components/ActionButton/ActionButton';

import { lightTheme } from '../../../theme';

type desiredTheme = 'system' | 'light' | 'dark';

const Appearance: FC = () => {
  const theme = localStorage.getItem('preferedTheme');
  const dispatch = useDispatch<Dispatch>();
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const [active, setActive] = useState(theme || 'light');
  const getSystemTheme = () => {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme:dark)').matches
    ) {
      dispatch.theme.setTheme('dark');
      return 'dark';
    } else {
      dispatch.theme.setTheme('light');
      return 'light';
    }
  };
  const handleActiveTheme = (myTheme: desiredTheme): void => {
    localStorage.setItem('preferedTheme', myTheme);
    setActive(myTheme);
    if (myTheme === 'system') {
      getSystemTheme();
    } else {
      dispatch.theme.setTheme(myTheme);
    }
  };

  useEffect(() => {
    const theme = localStorage.getItem('preferedTheme');
    setActive(theme || activeTheme);
    // eslint-disable-next-line
  }, [activeTheme]);

  return (
    <Container>
      <p className='font-size-25 text-primary section-title'>Appearance</p>
      <div className='divider'></div>
      <div className='wrapper'>
        <div className='row theme-row'>
          <div>
            <p className='font-size-18 text-primary text-semibold'>
              Interface theme
            </p>
            <p className='font-size-14 text-semibold text-secondary'>
              Select or customize your UI theme
            </p>
          </div>

          <div className='preference'>
            <div
              className={classNames(
                'theme first',
                active === 'system' && 'active'
              )}
              onClick={() => handleActiveTheme('system')}
            >
              <div className='left'></div>
              <div className='right'></div>
            </div>
            <p className='font-size-18 text-primary text-semibold'>
              System preferences
            </p>
          </div>
          <div className='preference'>
            <div
              className={classNames(
                'theme second',
                active === 'light' && 'active'
              )}
              onClick={() => handleActiveTheme('light')}
            >
              <div className='left'></div>
              <div className='right'></div>
            </div>
            <p className='font-size-18 text-primary text-semibold'>
              Light mode
            </p>
          </div>
          <div className='preference'>
            <div
              className={classNames(
                'theme third',
                active === 'dark' && 'active'
              )}
              onClick={() => handleActiveTheme('dark')}
            >
              <div className='left'></div>
              <div className='right'></div>
            </div>
            <p className='font-size-18 text-primary text-semibold'>Dark mode</p>
          </div>
        </div>
      </div>
      <div className='divider last'></div>
      <ButtonContainer>
        <ActionButton variant='outlined' style={{ border: 'none' }}>
          Discard Changes
        </ActionButton>
        <ActionButton variant='primary' className='btn'>
          Save Changes
        </ActionButton>
      </ButtonContainer>
    </Container>
  );
};

export default Appearance;

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  & .divider {
    border-top: 1px solid ${({ theme }) => theme.color.background.main};
    margin-block: 31px 32px;
    &.last {
      margin-block: 0px 20px;
    }
  }

  & .wrapper {
    flex-grow: 1;
    overflow-y: auto;
    height: 0;
  }

  & .theme-color {
    gap: 170px;
    margin-top: 70px;

    & .selector {
      height: 50px;
      width: 360px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.color.background.row};
    }
  }

  & .theme-row {
    gap: 30px;
    align-items: flex-start;

    & .preference {
      & .theme {
        height: 120px;
        width: 150px;
        border-radius: 8px;
        margin-bottom: 12px;
        cursor: pointer;
        &.active {
          border: 2px solid #15a3f3;
        }

        &.first {
          display: grid;
          grid-template-columns: 1fr 1fr;
          overflow: hidden;
          & .left {
            background-color: ${({ theme }) => theme.color.background.row};
          }
          & .right {
            background-color: ${({ theme }) => theme.color.text.primary};
          }
        }

        &.second {
          background-color: ${({ theme }) => theme.color.background.row};
        }
        &.third {
          background-color: ${({ theme }) => theme.color.text.primary};
        }
      }
    }
  }

  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    & .theme-row {
      gap: 60px;
      & .preference {
        & .theme {
          height: 150px;
          width: 200px;
        }
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;

  & .btn {
    height: 50px;
    border-radius: 30px;
    background-color: ${({ theme }) => theme.color.background.headerRow};
  }
`;
