import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../../store';

import Select from '../../../../components/Select/Select';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import TransferAsset from '../../../../Dialogs/TransferAsset/TransferAsset';
import Nft from './Nft/Nft';

import { IGameContract, IGameWithContracts } from '../../../../types/interface';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../../../hooks/useTheme';
import TransferMultipleAssets from '../../../../Dialogs/TransferMultipleAssets/TransferMultipleAssets';

interface IProps {
  transferMultiple: boolean;
  setTransferMultiple: React.Dispatch<React.SetStateAction<boolean>>;
}

const GuildVault: FC<IProps> = ({ transferMultiple, setTransferMultiple }) => {
  const { theme } = useTheme();
  const dispatch = useDispatch<Dispatch>();
  const [openDialog, setOpenDialog] = useState(false);
  const [filters, setFilters] = useState({
    game: '',
    contract: '',
    search: '',
  });

  const [contractOptions, setContractOptions] = useState<IGameContract[]>([]);
  const [contract, setContract] = useState('');
  const [contractName, setContractName] = useState('');
  const [gameId, setGameId] = useState('');
  const [selectedGame, setSelectedGame] = useState<IGameWithContracts | null>(
    null
  );

  const nfts = useSelector((state: RootState) => state.vault.nfts);

  const loading = useSelector((state: RootState) => state.vault.loading);

  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  const gamesWithContracts = useSelector(
    (state: RootState) => state.guilds.currentGuildGameNFTContracts
  );

  const handleContract = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setContract(e.currentTarget.value);
  };

  const handleGameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const gameId = e.currentTarget.value;
    const game = gamesWithContracts.find(game => game.game_id === gameId);
    setGameId(gameId);
    if (game) {
      setSelectedGame(game);
      if (game.contracts.length) {
        setContractOptions(game.contracts);
        setContract(game.contracts[0]?.contract_id);
        setContractName(game.contracts[0]?.contract_name);
      }
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleGetNfts = () => {
    if (selectedGame && contract && currentGuild) {
      dispatch.vault.handleGetNFTs({
        contractId: contract,
        user_id: currentGuild.user_id,
      });
    }
  };

  useEffect(() => {
    if (gamesWithContracts.length) {
      const firstGame = gamesWithContracts[0];
      setGameId(firstGame.game_id);
      setSelectedGame(firstGame);
      setContractOptions(firstGame.contracts);
      setContract(firstGame.contracts[0]?.contract_id || '');
    }
  }, [gamesWithContracts]);

  useEffect(() => {
    if (selectedGame && contract) {
      handleGetNfts();
    }
    // eslint-disable-next-line
  }, [selectedGame, contract]);

  return (
    <Container>
      <div className='row justify-space-between'>
        <p className='font-size-30 text-primary text-bold'>Vault</p>
        <div className='row' style={{ gap: '10px' }}>
          <Select
            options={gamesWithContracts.map(game => ({
              label: game.name,
              value: game.game_id,
            }))}
            label='Games'
            value={gameId}
            onChange={handleGameChange}
            name='game'
          />
          {contractOptions.length > 1 && (
            <Select
              options={contractOptions.map(contract => ({
                label: contract.contract_name,
                value: contract.contract_id,
              }))}
              label='Contract'
              value={contract}
              onChange={handleContract}
            />
          )}
          <SearchBar
            value={filters.search}
            onChange={handleChange}
            name='search'
          />
        </div>
      </div>
      {loading ? (
        <div className='row justify-center' style={{ height: '300px' }}>
          <ClipLoader size={50} color={theme.color.text.primary} />
        </div>
      ) : (
        <div className='grid'>
          {nfts.map((nft, i) => (
            <Nft nft={nft} key={i} setTransferAssetDialog={setOpenDialog} />
          ))}
        </div>
      )}

      {openDialog && (
        <TransferAsset
          gameId={gameId}
          contract_id={contract}
          open={openDialog}
          setOpen={setOpenDialog}
          handleGetNfts={handleGetNfts}
          name={contractName}
        />
      )}

      {transferMultiple && (
        <TransferMultipleAssets
          gamesOptions={gamesWithContracts.map(game => ({
            label: game.name,
            value: game.game_id,
          }))}
          contractOptions={contractOptions.map(contract => ({
            label: contract.contract_name,
            value: contract.contract_id,
          }))}
          handleGameChange={handleGameChange}
          handleContract={handleContract}
          contract={contract}
          gameId={gameId}
          nfts={nfts}
          setOpenDialog={setOpenDialog}
          open={transferMultiple}
          setOpen={setTransferMultiple}
          loading={loading}
        />
      )}
    </Container>
  );
};

export default GuildVault;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 10px;
  padding: 20px;
  margin-top: 31px;

  & .grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 15px;
  }
`;
