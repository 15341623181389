import React, { useState } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { object, string, ref, InferType } from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import InputField from '../../components/InputField/InputField';
import Button from '../../components/Button/Button';
import CloseIcon from '../../assets/icons/CloseIcon';

import { lightTheme as theme } from '../../theme';
import { FormErrors, validateData } from '../../utils/validations';

const schema = object({
  code: string().min(6).max(6).required().label('Code'),
  password: string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    )
    .required()
    .label('New password'),
  confirm_password: string()
    .oneOf(
      [ref('password'), null],
      'Confirm password must be same as new password'
    )
    .required()
    .label('Confirm password'),
});

interface IFormData extends InferType<typeof schema> {}

const ResetPassword = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<Dispatch>();

  const loading = useSelector((state: RootState) => state.auth.loading);

  const [formData, setFormData] = useState<IFormData>({
    code: '',
    password: '',
    confirm_password: '',
  });

  const [errors, setErrors] = useState<FormErrors<IFormData | null>>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: null }));
  };

  const handleResetPassword = async () => {
    const { errors: validationErrors } = await validateData(schema, formData);

    if (validationErrors) {
      return setErrors(validationErrors);
    }
    dispatch.auth.handleResetPassword({ data: formData, navigate, setErrors });
  };

  return (
    <Container>
      <h1 className='text-primary'>Reset Password</h1>
      <p className='font-size-18 text-primary'>Set your new password</p>
      <InputContainer>
        <InputField
          label='Code'
          placeholder='Code sent to your email'
          type='text'
          onChange={handleChange}
          value={formData.code}
          name='code'
          error={errors?.code}
        />
      </InputContainer>
      <InputContainer>
        <InputField
          label='New password'
          placeholder='Enter new password'
          type='password'
          onChange={handleChange}
          value={formData.password}
          name='password'
          error={errors?.password}
        />
      </InputContainer>
      <InputContainer>
        <InputField
          label='Confirm new password'
          placeholder='Confirm new password'
          type='password'
          onChange={handleChange}
          value={formData.confirm_password}
          name='confirm_password'
          error={errors?.confirm_password}
        />
      </InputContainer>

      <Button
        disabled={loading}
        variant='primary'
        label='Submit'
        onClick={handleResetPassword}
        loading={loading}
      />
      <CloseIcon className='close-icon' onClick={() => navigate(-1)} />
    </Container>
  );
};

export default ResetPassword;

const Container = styled.div`
  width: 350px;
  padding: 30px 20px;
  background: ${({ theme }) => theme.color.gradient.card};
  border-radius: 12px;
  position: relative;
  & h1 {
    margin-bottom: 12px;
  }

  & .close-icon {
    position: absolute;
    top: 15px;
    right: 16px;
    width: 20px;
    cursor: pointer;
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    width: 499px;
    padding: 50px 30px;
    margin-right: 180px;
    & h1 {
      margin-bottom: 17px;
    }

    & .close-icon {
      top: 35px;
      right: 16px;
      width: unset;
    }
  }
`;

const InputContainer = styled.div`
  &:first-of-type {
    margin-top: 30px;
  }

  margin-block: 20px;

  &:last-of-type {
    margin-block: 0px 30px;
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    &:last-of-type {
      margin-top: 50px;
    }
    margin-block: 30px;
    &:last-of-type {
      margin-block: 0px 30px;
    }
  }
`;
