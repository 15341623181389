import React, { FC, useMemo } from 'react';

import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import classNames from 'classnames';

import UploadIcon from '../../assets/icons/UploadIcon';
import CloseIcon from '../../assets/icons/CloseIcon';

import { lightTheme, darkTheme } from '../../theme';

interface IProps {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  placeholder: string;
  error?: string;
}

const ImagePicker: FC<IProps> = ({ file, setFile, placeholder, error }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const theme = activeTheme === 'light' ? lightTheme : darkTheme;
  const {
    getInputProps,
    getRootProps,
    isDragAccept,
    isDragReject,
    isDragActive,
  } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
    },
    maxFiles: 1,
    maxSize: 2097152,
    onDrop: acceptedFile => {
      const _file = acceptedFile[0];
      let extension = _file.name.split('.');
      const exten = extension[extension.length - 1];
      if (exten === 'png' || exten === 'jpg' || exten === 'jpeg') {
        console.log(_file);
        setFile(_file);
      }
    },
  });
  const baseStyle = {
    height: '100%',
    width: '100%',
    paddingInline: '24px',
    borderRadius: '50%',
    display: 'flex',
    gap: '9px',
    justifyContent: 'center',
    alignItems: 'center',
    // ...customBaseStyles,
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
    // customRejectStyles,
  };

  const style = useMemo(
    () => ({
      ...baseStyle,

      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragActive, isDragReject, isDragAccept]
  );
  return (
    <div>
      <Container className={classNames(error && 'error')}>
        <div className='preview'>
          {file && <img src={URL.createObjectURL(file)} alt='' />}
        </div>
        <Picker>
          {file ? (
            <div className='root'>
              <div className='file-name'>
                <p
                  className='font-size-10 text-semibold'
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '16ch',
                  }}
                >
                  {file.name}
                </p>
                <div
                  className='close-icon clickable'
                  onClick={() => setFile(null)}
                >
                  <CloseIcon fill={theme.color.background.row} />
                </div>
              </div>
            </div>
          ) : (
            <div
              className='root'
              {...getRootProps({ style: file ? {} : style })}
            >
              <input {...getInputProps()} />
              <div className='icon'>
                <UploadIcon />
              </div>
              <p className='font-size-8 text-center'>
                <b>Click to upload</b> {placeholder}
              </p>
            </div>
          )}
        </Picker>
      </Container>
      {error && (
        <p
          style={{ marginTop: '4px', paddingInline: '4px' }}
          className='font-size-12 text-danger'
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default ImagePicker;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  & .preview {
    height: 78px;
    width: 78px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.background.row};
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    & .preview {
      height: 78px;
      width: 78px;
    }
  }
`;

const Picker = styled.div`
  height: 80px;
  width: 140px;
  background-color: ${({ theme }) => theme.color.background.row};
  border-radius: 8px;
  border: 1px dashed ${({ theme }) => theme.color.text.primary};

  & .root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    & .file-name {
      padding: 8px 10px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      gap: 15px;
      max-width: 80%;
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.row};

      & .close-icon {
        & svg {
          width: 9px;
          height: 9px;
        }
      }
    }

    & .icon {
      height: 28px;
      width: 28px;
      background-color: ${({ theme }) => theme.color.text.primary};
      border-radius: 50%;
      display: grid;
      place-items: center;
      & svg {
        width: 15px;
      }
    }
  }
  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    height: 80px;
    width: 178px;
  }
`;
