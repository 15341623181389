import { createModel } from '@rematch/core';
import axios from 'axios';
import { RootModel } from '.';
import { NavigateFunction } from 'react-router-dom';
import {
  allRoles,
  connectWallet,
  forgotPassword,
  getCurrentuser,
  guildById,
  login,
  resetPassword,
  signup,
  userGuild,
  verifyEmail,
  walletAccounts,
} from '../../api';
import { ISignUpData, IWalletAccounts } from '../../types/api';
import { IUser } from '../../types/interface';

import {
  ILoginData,
  IForgotPassword,
  IResetPasswordPayload,
  IEmailVerification,
} from '../../types/storeModels';

interface IState {
  loading: boolean;
  verificationToken: string;
  user: IUser | null;
  isLoggedIn: boolean;
  walletAddress: string;
  starzWalletAddress: string;
  walletAccounts: IWalletAccounts[];
  userLoading: boolean;
}

export const auth = createModel<RootModel>()({
  name: 'auth',
  state: {
    isLoggedIn: !!localStorage.getItem('token') || false,
    loading: false,
    verificationToken: '',
    user: null,
    walletAddress: '',
    walletAccounts: [],
    userLoading: false,
    starzWalletAddress: '',
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setVerificationToken(state, payload: string) {
      state.verificationToken = payload;
    },
    setUser(state, payload: IUser) {
      state.user = payload;
    },
    setIsloggedIn(state, payload: boolean) {
      state.isLoggedIn = payload;
    },
    logOut(state) {
      state.isLoggedIn = false;
      state.user = null;
      state.verificationToken = '';
      state.walletAccounts = [];
      state.walletAddress = '';
      localStorage.clear();
      window.location.reload();
    },
    setWalletAddress(state, payload: string) {
      state.walletAddress = payload;
    },
    setWalletAccounts(state, payload: IWalletAccounts[]) {
      state.walletAccounts = payload;
    },
    setUserLoading(state, payload: boolean) {
      state.userLoading = payload;
    },
    setStarzWalletAddress(state, payload: string) {
      state.starzWalletAddress = payload;
    },
  },
  effects: dispatch => ({
    async handleSignUp(payload: {
      data: ISignUpData;
      navigate: NavigateFunction;
      setErrors: React.Dispatch<React.SetStateAction<any>>;
    }) {
      try {
        dispatch.auth.setLoading(true);
        const { data } = await signup(payload.data);
        dispatch.auth.setVerificationToken(data?.token);
        payload.navigate('/verify-email');
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data.message;
          if (errorMessage.toLowerCase().includes('email')) {
            payload.setErrors((prev: any) => ({
              ...prev,
              email: errorMessage,
            }));
          }
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },

    async handleLogin(payload: ILoginData) {
      try {
        dispatch.auth.setLoading(true);
        const { data } = await login(payload.data);
        const { accessToken, ...user } = data;
        localStorage.setItem('token', accessToken);
        dispatch.auth.setUser(user);
        dispatch.auth.setIsloggedIn(true);
        dispatch.auth.getCurrentuser();
        dispatch.roles.handleGetAllRoles();
        dispatch.config.handleGetsocialLinks();
        dispatch.permissions.handleGetAllPermissionsConfig();
        dispatch.permissions.handleGetUserPermissions();
        payload.navigate('/dashboard');
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          payload.setErrors({
            email: errorMessage,
            password: errorMessage,
          });
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleEmailVerification(payload: IEmailVerification, state) {
      try {
        dispatch.auth.setLoading(true);
        await verifyEmail(payload.data, state.auth.verificationToken);
        payload.navigate('/');
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          payload.setError(errorMessage);
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleForgotPassword(payload: IForgotPassword) {
      try {
        dispatch.auth.setLoading(true);
        const { data } = await forgotPassword(payload.data);
        dispatch.auth.setVerificationToken(data?.token);
        payload.navigate('/reset-password');
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          payload.setErrors({ email: errorMessage });
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleResetPassword(payload: IResetPasswordPayload, state) {
      try {
        dispatch.auth.setLoading(true);
        await resetPassword(payload.data, state.auth.verificationToken);
        payload.navigate('/login');
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          payload.setErrors({ code: errorMessage });
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async getCurrentuser() {
      try {
        dispatch.auth.setUserLoading(true);
        const res = await Promise.all([getCurrentuser(), allRoles()]);
        dispatch.auth.setUser(res[0].data);
        const data = res[0].data;
        dispatch.permissions.handleGetUserPermissions();
        dispatch.permissions.handleGetAllPermissionsConfig();
        const adminRoleId = res[1].data.find(
          role => role.name.toLowerCase() === 'admin'
        )!.id;
        const { data: accounts } = await walletAccounts();
        dispatch.auth.setWalletAccounts(accounts);
        dispatch.auth.setWalletAddress(accounts[0].wallet_address);
        dispatch.auth.setStarzWalletAddress(accounts[0].starz_wallet_address);
        if (data.role_id !== adminRoleId) {
          const { data: guild } = await userGuild(data.id, data.role_id);
          if (guild) {
            const { data: response } = await guildById(guild.id);
            dispatch.guilds.setCurrenGuild(response);
          }
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          console.log(errorMessage);
        }
      } finally {
        dispatch.auth.setUserLoading(false);
      }
    },
    async handleConnectWallet(payload: string) {
      try {
        dispatch.auth.setLoading(true);
        await connectWallet(payload);
        dispatch.auth.handleGetWalletAccounts();
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleGetWalletAccounts() {
      try {
        dispatch.auth.setLoading(true);
        const { data } = await walletAccounts();
        dispatch.auth.setWalletAccounts(data);
        dispatch.auth.setWalletAddress(data[0].wallet_address);
        dispatch.auth.setStarzWalletAddress(data[0].starz_wallet_address);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
  }),
});
