import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const CloseIcon: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M14.864 0.816412C12.0315 0.816214 9.26261 1.65596 6.90741 3.22945C4.5522 4.80294 2.71648 7.03951 1.63241 9.65631C0.548331 12.2731 0.264585 15.1526 0.817051 17.9307C1.36952 20.7088 2.73338 23.2606 4.73617 25.2635C6.73896 27.2665 9.29071 28.6305 12.0687 29.1832C14.8468 29.7358 17.7263 29.4523 20.3432 28.3684C22.9601 27.2845 25.1968 25.4489 26.7704 23.0938C28.3441 20.7387 29.184 17.9699 29.184 15.1374C29.1858 13.2563 28.8167 11.3933 28.0977 9.65506C27.3787 7.91679 26.324 6.33736 24.9939 5.00718C23.6638 3.677 22.0845 2.62217 20.3463 1.90307C18.6081 1.18397 16.7451 0.814702 14.864 0.816412ZM22.024 20.2784L20.005 22.2974L14.864 17.1564L9.723 22.2974L7.703 20.2784L12.843 15.1374L7.703 9.99541L9.722 7.97641L14.864 13.1164L20.005 7.97541L22.024 9.99441L16.883 15.1374L22.024 20.2784Z'
        fill={fill || theme.color.text.primary}
      />
    </svg>
  );
};

export default CloseIcon;
