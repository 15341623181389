import React, { useState } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { InferType, object, string } from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import InputField from '../../components/InputField/InputField';
import Button from '../../components/Button/Button';
import CloseIcon from '../../assets/icons/CloseIcon';

import { lightTheme as theme } from '../../theme';
import { FormErrors, validateData } from '../../utils/validations';

const schema = object({
  email: string().email().required().label('Email'),
});

interface IFormData extends InferType<typeof schema> {}

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const loading = useSelector((state: RootState) => state.auth.loading);

  const [formData, setFormData] = useState<IFormData>({
    email: '',
  });

  const [errors, setErrors] = useState<FormErrors<IFormData | null>>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ email: e.currentTarget.value });
    setErrors({ email: '' });
  };

  const handleSendCode = async () => {
    const { errors: validationErrors } = await validateData(schema, formData);
    if (validationErrors) {
      return setErrors(validationErrors);
    }
    dispatch.auth.handleForgotPassword({ data: formData, navigate, setErrors });
  };

  return (
    <Container>
      <h1 className='text-primary'>Forgot Password?</h1>
      <p className='font-size-18 text-primary'>Recover your password</p>
      <InputContainer>
        <InputField
          label='Email ID'
          placeholder='Enter associated email ID'
          type='email'
          value={formData.email}
          onChange={handleChange}
          error={errors?.email}
        />
      </InputContainer>
      <Button
        variant='primary'
        label='Send code'
        disabled={loading}
        onClick={handleSendCode}
        loading={loading}
      />
      <CloseIcon className='close-icon' onClick={() => navigate(-1)} />
    </Container>
  );
};

export default ForgotPassword;

const Container = styled.div`
  width: 350px;
  padding: 30px 20px;
  background: ${({ theme }) => theme.color.gradient.card};
  border-radius: 12px;
  position: relative;
  & h1 {
    margin-bottom: 12px;
  }

  & .close-icon {
    position: absolute;
    top: 15px;
    right: 16px;
    width: 20px;
    cursor: pointer;
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    width: 499px;
    padding: 50px 30px;
    margin-right: 180px;
    & h1 {
      margin-bottom: 17px;
    }

    & .close-icon {
      top: 35px;
      right: 16px;
      width: unset;
    }
  }
`;

const InputContainer = styled.div`
  margin-top: 30px;
  &:last-of-type {
    margin-block: 20px 30px;
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    &:last-of-type {
      margin-block: 30px 40px;
    }
    margin-top: 50px;
  }
`;
