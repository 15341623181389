import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { string, object, mixed, array, InferType } from 'yup';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';
import ActionButton from '../../components/ActionButton/ActionButton';
import CrossIcon from '../../assets/icons/CrossIcon';

import InputField from '../../components/InputField/InputField';
import Select from '../../components/Select/Select';
import ImagePicker from '../../components/ImagePicker/ImagePicker';

import { lightTheme as theme } from '../../theme';

import assets from '../../assets';
import { IGame } from '../../types/api';
import { FormErrors, validateData } from '../../utils/validations';
import { getUserById } from '../../api';
import { ISingleUser } from '../../types/interface';

interface IOptions {
  label: string;
  value: string | number;
}

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}

const schema = object({
  name: string().required().label('Name'),
  guildManager: string().required().label('Guild Manager'),
  is99StarzGuild: string().optional().label('Is mine'),
  image: mixed().required().label('Image'),
  selectedGames: array().min(1).label('Games'),
});

interface IFormData extends InferType<typeof schema> {}

const AddNewGuild: FC<IProps> = ({ open, setOpen, onClose }) => {
  const dispatch = useDispatch<Dispatch>();

  const users = useSelector((state: RootState) => state.users.guildManagers);
  const games = useSelector((state: RootState) => state.games.games);

  const loading = useSelector((state: RootState) => state.guilds.loading);

  const [manager, setManager] = useState<ISingleUser | null>(null);

  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  const [formData, setFormData] = useState({
    name: currentGuild?.name || '',
    guildManager: currentGuild?.user_id || '',
    is99StarzGuild: currentGuild?.is_mine_guild ? 'yes' : 'no' || 'no',
  });

  const [errors, setErrors] = useState<FormErrors<IFormData | null>>(null);

  const [gameOptions, setGameOptions] = useState<IGame[]>(games);

  const [selectedGames, setSelectedGames] = useState<IGame[]>([]);
  const [image, setImage] = useState<File | null | string>(
    currentGuild?.logo || null
  );
  const handleGameSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line
    const { value } = e.currentTarget;
    const game = games.find(game => game.id === value)!;
    setSelectedGames(prev => [...prev, game]);
    setGameOptions(gameOptions.filter(g => g.id !== game.id));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { value, name } = e.currentTarget;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleRemoveGame = (id: string) => {
    let allSelectedGames = [...selectedGames];
    const game = allSelectedGames.find(g => g.id === id)!;
    setSelectedGames(allSelectedGames.filter(g => g.id !== id));
    setGameOptions([...gameOptions, game]);
  };

  const handleAddGuild = async () => {
    setErrors(null);
    const { errors: validationErrors } = await validateData(schema, {
      ...formData,
      image,
      selectedGames,
    });

    if (currentGuild && validationErrors?.selectedGames) {
      validationErrors.selectedGames = '';
    }

    if (validationErrors) {
      return setErrors(validationErrors);
    }

    if (currentGuild) {
      if (image) {
        dispatch.guilds.handleUpdateGuild({
          data: {
            name: formData.name,
            user_id: formData.guildManager,
            is_mine_guild: formData.is99StarzGuild === 'yes' ? true : false,
          },
          games: selectedGames.map(game => game.id),
          image: image,
          setOpen,
        });
      }
    } else {
      if (image) {
        dispatch.guilds.addNewGuild({
          data: {
            name: formData.name,
            user_id: formData.guildManager,
            is_mine_guild: formData.is99StarzGuild === 'yes' ? true : false,
          },
          games: selectedGames.map(game => game.id),
          image: image,
          setOpen,
        });
      }
    }
  };

  const handleGetGuildManager = async () => {
    try {
      if (currentGuild) {
        const { data } = await getUserById(currentGuild?.user_id);
        setManager(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGuildMangerOptions = (): IOptions[] => {
    if (manager) {
      return [{ label: manager.username, value: manager.id }];
    } else {
      const options = users.map(user => ({
        label: user.username,
        value: user.id,
      }));
      return options;
    }
  };

  useEffect(() => {
    dispatch.users.getGuildMangers();
    dispatch.games.handleGetAllGames('');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentGuild) {
      handleGetGuildManager();
    }
    // eslint-disable-next-line
  }, [currentGuild]);

  useEffect(() => {
    if (currentGuild && currentGuild.Games.length) {
      const filteredGames = games.filter(el => {
        return !currentGuild.Games.some(f => {
          return f.id === el.id;
        });
      });
      setGameOptions(filteredGames);
    }
    // eslint-disable-next-line
  }, [games]);

  return (
    <DialogLayout open={open} onClose={onClose}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>
            {currentGuild ? 'Update Guild' : 'Add New Guild'}
          </p>
          <div className='icon clickable' onClick={() => setOpen(false)}>
            <CrossIcon />
          </div>
        </div>
        <div className='row justify-center'>
          <ImagePicker error={errors?.image} file={image} setFile={setImage} />
        </div>
        <InputContainer>
          <InputField
            label='Name'
            placeholder='99Starz'
            type='text'
            name='name'
            value={formData.name}
            onChange={handleChange}
            error={errors?.name}
          />
        </InputContainer>
        <InputContainer>
          <Select
            title='Guild Manager'
            label='Add User'
            options={handleGuildMangerOptions()}
            name='guildManager'
            value={
              currentGuild?.user_id
                ? currentGuild.user_id
                : formData.guildManager
            }
            onChange={handleChange}
            error={errors?.guildManager}
            disabled={currentGuild ? true : false}
          />
        </InputContainer>

        <InputContainer>
          <Select
            title='Games'
            label='Select games'
            options={gameOptions.map(game => ({
              value: game.id,
              label: game.name,
            }))}
            value={''}
            onChange={handleGameSelection}
            error={errors?.selectedGames}
          />
        </InputContainer>

        <GamesContainer>
          {currentGuild?.Games?.map(game => (
            <Game key={game.id}>
              <img src={game.mini_icon} alt='' />
            </Game>
          ))}
          {selectedGames.map(game => (
            <Game key={game.id}>
              <img src={game.mini_icon} alt='' />
              <img
                src={assets.crossRes}
                onClick={() => handleRemoveGame(game.id)}
                className='remove-icon'
                alt=''
              />
            </Game>
          ))}
        </GamesContainer>

        {!currentGuild && (
          <>
            <InputContainer>
              <Select
                title='Is this 99Starz Guild?'
                label='yes/no'
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                name='is99StarzGuild'
                value={formData.is99StarzGuild}
                onChange={handleChange}
                error={errors?.is99StarzGuild}
              />
            </InputContainer>
          </>
        )}

        {currentGuild && (
          <div className='row justify-space-between disable-guild-row'>
            <ActionButton variant='primary' className='btn'>
              Disable Guild
            </ActionButton>
            <ActionButton className='btn' variant='primary'>
              Remove Guild
            </ActionButton>
          </div>
        )}

        <div className='divider'></div>
        <ButtonContainer>
          <ActionButton
            onClick={() => setOpen(false)}
            variant='primary'
            className='btn btn-primary'
            disabled={loading}
          >
            Cancel
          </ActionButton>
          <ActionButton
            variant='primary'
            onClick={handleAddGuild}
            className='btn btn-secondary'
            disabled={loading}
            loading={loading}
          >
            {currentGuild ? 'Update Guild' : 'Add  Guild'}
          </ActionButton>
        </ButtonContainer>
      </Container>
    </DialogLayout>
  );
};

export default AddNewGuild;

const Container = styled.div`
  width: 420px;
  padding: 20px;
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 15px;

  & .justify-center {
    margin-top: 20px;
  }

  & .divider {
    height: 1px;
    background-color: ${({ theme }) => theme.color.background.headerRow};
    margin-block: 25px 20px;
  }

  & .disable-guild-row {
    gap: 15px;

    & .btn {
      height: 44px;
      width: 100%;
    }
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    width: 560px;
    padding: 30px;
    & .justify-center {
      margin-top: 31px;
    }
    & .divider {
      margin-block: 35px 30px;
    }
    & .disable-guild-row {
      gap: 20px;
      & .btn {
        height: 50px;
        width: 100%;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  & .btn {
    height: 44px;
    width: 150px;
    border-radius: 20px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .btn {
      height: 55px;
      width: 198px;
      border-radius: 30px;
    }
  }
`;

const InputContainer = styled.div`
  margin-block: 12px;

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    margin-block: 19px;
  }
`;

const GamesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const Game = styled.div`
  height: 42px;
  width: 42px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.background.socialLink};
  display: grid;
  place-items: center;
  position: relative;
  margin-bottom: 14px;

  & img:first-of-type {
    width: 100%;
    object-fit: cover;
  }

  & .remove-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
  }
`;
