import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import FacebookIcon from '../../assets/icons/FacebookIcon';
import GlobeIcon from '../../assets/icons/GlobeIcon';
import InstagramIcon from '../../assets/icons/InstagramIcon';
import SelectIcon from '../../assets/icons/SelectIcon';
import TelegramIcon from '../../assets/icons/TelegramIcon';
import TwichIcon from '../../assets/icons/TwichIcon';
import TwitterIcon from '../../assets/icons/TwitterIcon';

import assets from '../../assets';
import { ISocialLinkConfig } from '../../types/api';
import { deleteUserSocialLink } from '../../api';

interface IProps {
  handleChange: (index: number, url?: string, socialMedia_id?: string) => void;
  index: number;
  socialMediaConfig: Array<{ social_id: string; url: string; id: string }>;
  error?: string;
  setSocialMediaConfig: React.Dispatch<
    React.SetStateAction<Array<{ social_id: string; url: string; id: string }>>
  >;
}

const socialConfig: { [x: string]: any } = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  web: GlobeIcon,
  telegram: TelegramIcon,
  instagram: InstagramIcon,
  chat: TwichIcon,
};

const RenderIcon: FC<{ name: string }> = ({ name }) => {
  const Icon = socialConfig[name];
  return <Icon />;
};

const SocialLink: FC<IProps> = ({
  index,
  handleChange,
  socialMediaConfig,
  error,
  setSocialMediaConfig,
}) => {
  const [open, setOpen] = useState(false);

  const socialLinks = useSelector(
    (state: RootState) => state.config.socialLinks
  );

  const [selected, setSelected] = useState<ISocialLinkConfig | null>(
    socialLinks.find(s => s.id === socialMediaConfig[index]?.social_id) || null
  );
  const handleOpen = (): void => {
    setOpen(!open);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  const handleSelectOption = (option: ISocialLinkConfig): void => {
    handleClose();
    setSelected(option);
    handleChange(index, '', option.id);
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(index, e.currentTarget.value);
  };

  const handleDelete = async () => {
    const id = socialMediaConfig[index].id;
    if (id) {
      await deleteUserSocialLink(id);
    }
    const socialList = [...socialMediaConfig];
    socialList.splice(index, 1);
    setSocialMediaConfig(socialList);
  };

  useEffect(() => {
    setSelected(
      socialLinks.find(s => s.id === socialMediaConfig[index]?.social_id) ||
        null
    );
    // eslint-disable-next-line
  }, [socialMediaConfig]);

  return (
    <div>
      <Container>
        <div className='selector'>
          <div className='row clickable' onClick={handleOpen}>
            {/* selected icon will go here */}
            {selected && (
              <>
                <RenderIcon name={selected.logo} />
                <SelectIcon />
              </>
            )}
          </div>
          {open && (
            <ClickAwayListener onClickAway={handleClose}>
              <Menu>
                {socialLinks.map(social => {
                  return (
                    <div
                      className='social clickable'
                      key={social.id}
                      onClick={() => {
                        handleSelectOption(social);
                      }}
                    >
                      <RenderIcon name={social.logo} />
                    </div>
                  );
                })}
              </Menu>
            </ClickAwayListener>
          )}
        </div>
        <div className='row url'>
          {selected && (
            <input
              type='url'
              name='url'
              value={socialMediaConfig[index].url}
              onChange={handleUrlChange}
              id=''
              placeholder='Add url'
            />
          )}
        </div>
        <div className='delete-icon'>
          <img
            src={assets.delete}
            alt=''
            onClick={handleDelete}
            className='clickable'
          />
        </div>
      </Container>
      {error !== 'undefined' && (
        <p className='font-size-14 text-danger'>{error}</p>
      )}
    </div>
  );
};

export default SocialLink;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  background-color: ${({ theme }) => theme.color.background.row};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.text.primary + '33'};
  /* overflow: hidden; */

  & div {
    &.row {
      height: 100%;
      flex-grow: 1;
      padding: 10px;
      border-left: 1px solid ${({ theme }) => theme.color.text.primary + '33'};
      border-right: 1px solid ${({ theme }) => theme.color.text.primary + '33'};

      & input {
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        background-color: transparent;
      }
    }

    &.selector {
      height: 100%;
      width: 60px;
      position: relative;

      & .row {
        padding: 7px;
        justify-content: space-between;
      }
    }
    &.delete-icon {
      height: 100%;
      width: 50px;
      display: grid;
      place-items: center;
    }
  }
`;

const Menu = styled.div`
  height: auto;
  position: absolute;
  padding: 12px;
  background-color: ${({ theme }) => theme.color.background.row};
  bottom: -1px;
  left: 0px;
  transform: translateY(100%);
  border-radius: 4px;
  z-index: 5;
  filter: drop-shadow(0px 2px 6px rgba(53, 57, 64, 0.259));
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 20px;
`;
