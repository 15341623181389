import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  fill?: string;
  [x: string]: any;
}

const EditIcon: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? darkTheme : lightTheme;
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M7.26201 2.47656H2.39156C2.02249 2.47656 1.66855 2.62317 1.40758 2.88414C1.14661 3.14511 1 3.49906 1 3.86812V13.609C1 13.9781 1.14661 14.332 1.40758 14.593C1.66855 14.854 2.02249 15.0006 2.39156 15.0006H12.1325C12.5015 15.0006 12.8555 14.854 13.1165 14.593C13.3774 14.332 13.524 13.9781 13.524 13.609V8.73858'
        stroke={fill || theme.color.text.primary}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.4805 1.4323C12.7573 1.1555 13.1327 1 13.5242 1C13.9156 1 14.291 1.1555 14.5678 1.4323C14.8446 1.7091 15.0001 2.08452 15.0001 2.47597C15.0001 2.86742 14.8446 3.24284 14.5678 3.51964L7.95792 10.1295L5.1748 10.8253L5.87058 8.04221L12.4805 1.4323Z'
        stroke={fill || theme.color.text.primary}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export default EditIcon;
