import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { IUserPermissions } from '../types/interface';

const useModulePermissions = (moduleName: string) => {
  const userPermissions = useSelector(
    (state: RootState) => state.permissions.userPermissions
  );
  const [permissions, setPermissions] = useState<IUserPermissions | null>(null);

  useEffect(() => {
    const modulePermission = userPermissions.find(
      p => p.module_slug === moduleName
    )!;
    setPermissions(modulePermission);
    // eslint-disable-next-line
  }, [userPermissions]);
  return permissions;
};

export default useModulePermissions;
