import React, { FC } from 'react';

import styled from 'styled-components';
import MoreIcon from '../../assets/icons/MoreIcon';

const SimilarGame: FC = () => {
  return (
    <Container>
      <div className='game-image'></div>
      <div className='row justify-space-between'>
        <p className='font-size-20 text-primary text-semibold'>Axie Infinity</p>
        <div className='icon clickable'>
          <MoreIcon />
        </div>
      </div>
    </Container>
  );
};

export default SimilarGame;

const Container = styled.div`
  width: 100%;

  & .game-image {
    width: 100%;
    aspect-ratio: 1.58/1;
    background-color: ${({ theme }) => theme.color.background.headerRow};
    border-radius: 8px;
  }

  & .row {
    padding-top: 20px;
    padding-inline: 10px;
  }
`;
