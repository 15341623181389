import React, { FC, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../store';

import useRole from '../../../hooks/useRole';
import { rolesEnum } from '../../../types/enums';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../../hooks/useTheme';
import UserRow from './UserRow';

interface IProps {
  search?: string;
}

const UsersListView: FC<IProps> = ({ search = '' }) => {
  const { role } = useRole();
  const { theme } = useTheme();

  const dispatch = useDispatch<Dispatch>();

  const user = useSelector((state: RootState) => state.auth.user);

  const loading = useSelector((state: RootState) => state.users.loading);

  const users = useSelector((state: RootState) => state.users.dashboardUsers);

  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  useEffect(() => {
    if (role?.name === rolesEnum.Admin) {
      dispatch.users.getDashboardUsers();
    } else {
      if (currentGuild) {
        dispatch.users.getDashboardUsers(currentGuild?.id);
      }
    }
    // eslint-disable-next-line
  }, [currentGuild, user, role]);
  return (
    <TableContainer>
      {loading && !users.length ? (
        <div className='row justify-center'>
          <ClipLoader size={50} color={theme.color.text.primary} />
        </div>
      ) : (
        <table>
          <thead>
            <tr className='header-row'>
              <th className='font-size-12 text-primary text-semibold'>Name</th>
              <th className='font-size-12 text-primary text-semibold'>Email</th>

              <th className='font-size-12 text-primary text-semibold'>Role</th>
              <th className='font-size-12 text-primary text-semibold'>Games</th>
              <th className='font-size-12 text-primary text-semibold'>
                Total assets
              </th>
              <th className='font-size-12 text-primary text-semibold'>
                Revenue Split
              </th>
              <th className='font-size-12 text-primary text-semibold'>
                Next payout
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <UserRow search={search} user={user} key={user.id} />
            ))}
          </tbody>
        </table>
      )}
    </TableContainer>
  );
};

export default UsersListView;

const TableContainer = styled.div`
  margin-top: 13px;

  & table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    & tr {
      height: 50px;
      &.header-row {
        height: 40px;
        border: none;
        & th {
          text-align: left;
          background-color: ${({ theme }) => theme.color.background.headerRow};
          &:first-of-type {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding-left: 12px;
          }
          &:last-of-type {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-right: 12px;
          }
        }
      }
      & td {
        background-color: ${({ theme }) => theme.color.background.row};
        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          padding-left: 12px;
        }
        &:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          padding-right: 12px;
        }
      }
    }
  }
`;
