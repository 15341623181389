import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { object, array, InferType } from 'yup';

import CrossIcon from '../../assets/icons/CrossIcon';
import ActionButton from '../../components/ActionButton/ActionButton';
import Select from '../../components/Select/Select';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';

import assets from '../../assets';
import { lightTheme as theme } from '../../theme';
import { guildUsers } from '../../api';
import { FormErrors, validateData } from '../../utils/validations';
import { IGame, IGuildUser } from '../../types/api';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}

const schema = object({
  user: array().min(1).required().label('User'),
  games: array().min(1).required().label('Games'),
});

interface IFormData extends InferType<typeof schema> {}

const AddGameCaptain: FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();
  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  const [users, setUsers] = useState<IGuildUser[]>([]);
  const [selected, setSelected] = useState<IGuildUser[]>([]);
  const [selectedGames, setSelectedGames] = useState<IGame[]>([]);
  const [gameOptions, setGameOptions] = useState(currentGuild?.Games || []);

  const [errors, setErrors] = useState<FormErrors<IFormData | null>>(null);

  const handleSelectUser = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.currentTarget.value;
    const userFound = users.find(user => user.id === id)!;
    setSelected(prev => [...prev, userFound]);
    setUsers(users.filter(user => user.id !== id));
  };

  const handleGameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.currentTarget.value;
    const gameFound = gameOptions.find(game => game.id === id);
    if (gameFound) {
      setSelectedGames(prev => [...prev, gameFound]);
      setGameOptions(gameOptions.filter(game => game.id !== id));
    }
  };

  const handleRemoveGame = (game: IGame) => {
    setSelectedGames(selectedGames.filter(g => g.id !== game.id));
    setGameOptions([...gameOptions, game]);
  };

  const handleRemoveUser = (user: IGuildUser) => {
    setUsers(prev => [...prev, user]);
    setSelected(selected.filter(u => u.id !== user.id));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAssignRole = async () => {
    setErrors(null);
    const { errors: validationErrors } = await validateData<IFormData>(schema, {
      games: selectedGames,
      user: selected,
    });

    if (validationErrors) {
      return setErrors(validationErrors);
    }
    if (currentGuild?.id) {
      dispatch.guilds.addGameCaptain({
        data: {
          users: selected.map(user => user.id),
          games: selectedGames.map(game => game.id),
          guild_id: currentGuild.id,
        },
        setOpen,
      });
    }
  };

  const handleGetAvailableUsers = async () => {
    try {
      const query = 'isAvailableUsers=true';
      const { data } = await guildUsers(currentGuild!.id, query);
      setUsers(data);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    handleGetAvailableUsers();
    // eslint-disable-next-line
  }, []);
  return (
    <DialogLayout open={open}>
      <Content>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>
            Add Game Captain
          </p>
          <div className='icon clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <p className='font-size-14 text-secondary subtitle'>
          Assign game master role to some one from your guild
        </p>
        <InputContainer>
          <Select
            title='User'
            label='Search user'
            value=''
            onChange={handleSelectUser}
            options={users.map(user => ({
              label: user.username,
              value: user.id,
            }))}
            error={errors?.user}
          />
        </InputContainer>

        <UserChipsContainer>
          {selected.map(user => (
            <div key={user.id} className='chip'>
              <p className='font-size-12'>{user.username}</p>
              <img
                src={assets.crossRes}
                onClick={() => handleRemoveUser(user)}
                className='remove-icon'
                alt=''
              />
            </div>
          ))}
        </UserChipsContainer>

        <InputContainer>
          <Select
            title='Games'
            label='Select Games'
            value=''
            onChange={handleGameChange}
            options={gameOptions.map(game => ({
              label: game.name,
              value: game.id,
            }))}
            error={errors?.games}
          />
        </InputContainer>
        <GamesContainer>
          {selectedGames.map(game => (
            <Game key={game.id}>
              <img src={game.mini_icon} alt='' />
              <img
                src={assets.crossRes}
                onClick={() => handleRemoveGame(game)}
                className='remove-icon'
                alt=''
              />
            </Game>
          ))}
        </GamesContainer>
        <Divider />
        <ButtonContainer>
          <ActionButton
            onClick={handleClose}
            variant='primary'
            className='btn btn-primary'
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleAssignRole}
            variant='primary'
            className='btn btn-secondary'
          >
            Assign Role
          </ActionButton>
        </ButtonContainer>
      </Content>
    </DialogLayout>
  );
};

export default AddGameCaptain;

const Content = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 30px;
  border-radius: 12px;
  width: 530px;

  & .subtitle {
    margin-top: 12px;
  }
`;

const InputContainer = styled.div`
  margin-top: 32px;
`;

const GamesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 24px;
`;

const Game = styled.div`
  height: 42px;
  width: 42px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.background.socialLink};
  display: grid;
  place-items: center;
  position: relative;
  margin-bottom: 14px;

  & img:first-of-type {
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }

  & .remove-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.text.dim + '33'};
  margin-block: 30px 33px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  & .btn {
    height: 44px;
    width: 150px;
    border-radius: 20px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .btn {
      height: 55px;
      width: 198px;
      border-radius: 30px;
    }
  }
`;

const UserChipsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 12px;

  & .chip {
    padding: 4px 8px;
    border-radius: 4px;
    position: relative;
    background-color: ${({ theme }) =>
      theme.color.background.button.primary.bg};
    color: ${({ theme }) => theme.color.background.button.primary.color};

    & .remove-icon {
      position: absolute;
      top: -5px;
      right: -5px;
      cursor: pointer;
    }
  }
`;
