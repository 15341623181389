import React, { FC } from 'react';

import styled from 'styled-components';

import Loader from '../../../Dialogs/Loader/Loader';

import usePermissions from '../../../hooks/usePermissions';
import RolesRow from './RolesRow';

const RoleManagement: FC = () => {
  const { roles, moduleFeatures } = usePermissions();

  return (
    <Container>
      <Sections>
        <TableContainer>
          <table>
            <thead>
              <tr className='header-row'>
                <th className='font-size-12 text-primary text-semibold'>
                  Section
                </th>
                <th className='spacing-header'></th>
                {roles.map(role => (
                  <th
                    key={role.id}
                    className='font-size-12 text-primary text-semibold capitalize'
                  >
                    {role.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {moduleFeatures &&
                Object.keys(moduleFeatures).map((moduleName, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr style={{ height: '10px' }}></tr>
                      <tr className='round-start'>
                        <td className='font-size-20'>
                          <div className='inner capitalize'>{moduleName}</div>
                        </td>
                        <td className='spacing-data'></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {moduleFeatures[moduleName].map(feature => (
                        <RolesRow feature={feature} key={feature.id} />
                      ))}
                    </React.Fragment>
                  );
                })}

              {/* <tr style={{ height: '10px' }}></tr>
              <tr className='round-start'>
                <td className='font-size-20'>
                  <div className='inner'>Guild</div>
                </td>
                <td className='spacing-data'></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td className='font-size-14'>
                  <div className='inner'>Games list</div>
                </td>
                <td className='spacing-data'></td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
              </tr>
              <tr>
                <td className='font-size-14'>
                  <div className='inner'>Teams list</div>
                </td>
                <td className='spacing-data'></td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
              </tr>
              <tr>
                <td className='font-size-14'>
                  <div className='inner'>Statistics</div>
                </td>
                <td className='spacing-data'></td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
              </tr>
              <tr>
                <td className='font-size-14'>
                  <div className='inner'>Performance chart</div>
                </td>
                <td className='spacing-data'></td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
              </tr>
              <tr className='round-end'>
                <td className='font-size-14'>
                  <div className='inner'>User list</div>
                </td>
                <td className='spacing-data'></td>

                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
        </TableContainer>
      </Sections>
      <Loader />
      {/* <Permissions>
        <TableContainer>
          <table>
            <thead>
              <tr className='header-row'>
                <th className='font-size-12 text-primary text-semibold'>
                  Admin
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Guild Manager
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Game Master
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Captain
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Scholar
                </th>
                <th className='font-size-12 text-primary text-semibold text-right'>
                  <ActionButton variant='primary' className='btn font-size-12'>
                    Add New Role
                  </ActionButton>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ height: '10px' }}></tr>

              <tr className='round-start'>
                <td>
                  <div className='inner'></div>
                </td>
                <td>
                  <div className='inner'></div>
                </td>
                <td>
                  <div className='inner'></div>
                </td>
                <td>
                  <div className='inner'></div>
                </td>
                <td>
                  <div className='inner'></div>
                </td>
                <td className='text-right'>
                  <div className='inner '></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td className='text-right'>
                  <div className='inner '></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td className='text-right'>
                  <div className='inner '></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td className='text-right'>
                  <div className='inner '></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td className='text-right'>
                  <div className='inner '></div>
                </td>
              </tr>

              <tr className='round-end'>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td className='text-right'>
                  <div className='inner '></div>
                </td>
              </tr>
              <tr style={{ height: '10px' }}></tr>

              <tr className='round-start'>
                <td>
                  <div className='inner'></div>
                </td>
                <td>
                  <div className='inner'></div>
                </td>
                <td>
                  <div className='inner'></div>
                </td>
                <td>
                  <div className='inner'></div>
                </td>
                <td>
                  <div className='inner'></div>
                </td>
                <td className='text-right'>
                  <div className='inner '></div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td className='text-right'>
                  <div className='inner '></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td className='text-right'>
                  <div className='inner '></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td className='text-right'>
                  <div className='inner '></div>
                </td>
              </tr>
              <tr className='round-end'>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td>
                  <div className='inner'>
                    <PermissionGroup />
                  </div>
                </td>
                <td className='text-right'>
                  <div className='inner '></div>
                </td>
              </tr>
            </tbody>
          </table>
        </TableContainer>
      </Permissions> */}
    </Container>
  );
};

export default RoleManagement;

const Container = styled.div`
  display: flex;
  gap: 10px;
`;

const Sections = styled.div`
  flex: 1;
`;

const TableContainer = styled.div`
  margin-top: 13px;

  & table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    /* table-layout: fixed; */

    & tr {
      height: 50px;
      & .btn {
        height: 30px;
        background-color: ${({ theme }) => theme.color.background.card};
      }
      &.header-row {
        height: 40px;
        border: none;
        & th {
          text-align: left;
          background-color: ${({ theme }) => theme.color.background.headerRow};

          &.spacing-header {
            background-color: transparent;
            width: 10px;
          }

          &:nth-child(1) {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-right: 12px;
          }

          &:nth-child(3) {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding-left: 12px;
          }
          &:first-of-type {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding-left: 12px;
          }
          &:last-of-type {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-right: 12px;
          }
        }
      }
      &.round-start {
        & td {
          &:first-of-type {
            border-top-left-radius: 4px;
            /* border-bottom-left-radius: 4px; */
            padding-left: 12px;
          }
          &:last-of-type {
            border-top-right-radius: 4px;
            /* border-bottom-right-radius: 4px; */
            padding-right: 12px;
          }
          &:nth-child(3) {
            border-top-left-radius: 4px;
            padding-left: 12px;
          }
          &:nth-child(1) {
            border-bottom-right-radius: 4px;
            padding-right: 12px;
          }
        }
      }
      &.round-end {
        & td {
          &:first-of-type {
            border-bottom-left-radius: 4px;
            padding-left: 12px;
          }
          &:nth-child(3) {
            border-bottom-left-radius: 4px;
            padding-left: 12px;
          }
          &:nth-child(1) {
            border-bottom-right-radius: 4px;
            padding-right: 12px;
          }

          &:last-of-type {
            border-bottom-right-radius: 4px;
            padding-right: 12px;
          }
          & .inner {
            border: none;
          }
        }
      }
      & td {
        &.spacing-data {
          width: 10px;
          background-color: transparent;
        }
        height: 100%;
        color: ${({ theme }) => theme.color.text.primary};
        & .inner {
          border-bottom: 1px solid
            ${({ theme }) => theme.color.background.headerRow};
          height: 50px;
          display: flex;
          align-items: center;
        }
        background-color: ${({ theme }) => theme.color.background.row};
        &:first-of-type {
          padding-left: 12px;
        }
        &:nth-child(3) {
          padding-left: 12px;
        }
        &:last-of-type {
          padding-right: 12px;
        }
      }
    }
  }
`;
