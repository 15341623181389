import React from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import CrossIcon from '../../assets/icons/CrossIcon';
import ActionButton from '../../components/ActionButton/ActionButton';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';

const ErrorDialog = () => {
  const dispatch = useDispatch<Dispatch>();
  const { open, title, description } = useSelector(
    (state: RootState) => state.errorModal
  );
  const handleClose = () => {
    dispatch.errorModal.setClose();
  };
  return (
    <DialogLayout open={open}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>{title}</p>
          <div className='icon clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <p className='font-size-16 text-primary'>{description}</p>
        <div className='row justify-center'>
          <ActionButton variant='secondary' onClick={handleClose}>
            Continue
          </ActionButton>
        </div>
      </Container>
    </DialogLayout>
  );
};

export default ErrorDialog;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  width: 400px;
  padding: 30px;

  & .font-size-16 {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;
