import React, { FC, useState, useEffect, useRef } from 'react';

import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import { InView } from 'react-intersection-observer';

import { getNftImage } from '../../utils/nts';
import { resizerURL } from '../../app.config';

interface IProps {
  nftData: any;
  size: number;
  container?: boolean;
  noCache?: boolean;
}

const NftImage: FC<IProps> = ({ nftData, size, container, noCache }) => {
  const inViewRef = useRef(false);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setImage('');
      setLoading(true);
      const nftImage = await getNftImage(nftData);

      if (!nftImage) return;

      if (noCache) {
        const randomString = Math.floor(Math.random() * 1000000000).toString();
        setImage(
          `${resizerURL}/image?url=${nftImage}&size=${
            2 * size
          }?dummy=${randomString}`
        );
      } else {
        setImage(`${resizerURL}/image?url=${nftImage}&size=${2 * size}`);
      }
    })();
    // eslint-disable-next-line
  }, []);
  return (
    <InView>
      {({ inView, ref }) => {
        if (inView) inViewRef.current = true;

        return (
          <div
            ref={ref}
            style={{
              height: container ? '100%' : size,
              width: container ? '100%' : size,
            }}
          >
            <ImageWrapper
              data-tip={`${nftData?.name} #${nftData?.token_id}`}
              style={{
                height: container ? '100%' : size,
                width: container ? '100%' : size,
              }}
            >
              <ClipLoader
                color='white'
                size={20}
                speedMultiplier={0.5}
                loading={loading}
              />
              {inViewRef.current && (
                <img
                  style={{
                    display: loading ? 'none' : 'block',
                    height: container ? '100%' : '',
                    objectFit: container ? 'cover' : 'initial',
                  }}
                  src={image ? image : ''}
                  alt={`${nftData?.name} #${nftData?.token_id}`}
                  onLoad={() => setLoading(false)}
                />
              )}
            </ImageWrapper>
            {/* <ReactTooltip effect='float' type='dark' place='top' /> */}
          </div>
        );
      }}
    </InView>
  );
};

export default NftImage;

const ImageWrapper = styled.div`
  margin-top: 1px;
  border-radius: 6px;
  display: grid;
  place-items: center;
  & img {
    width: 100%;
  }
`;
