import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { object, string, mixed, array, InferType, number } from 'yup';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';
import ActionButton from '../../components/ActionButton/ActionButton';
import CrossIcon from '../../assets/icons/CrossIcon';

import InputField from '../../components/InputField/InputField';
import MultiLineInput from '../../components/MultiLineInput/MultilineInput';
import Select from '../../components/Select/Select';
import ImagePicker from '../../components/ImagePicker/ImagePicker';
import TokenAmountPicker from '../../components/TokenAmountPicker/TokenAmountPicker';

import assets from '../../assets';
import { lightTheme as theme } from '../../theme';
import { IGame } from '../../types/api';
import { FormErrors, validateData } from '../../utils/validations';

const schema = object({
  image: mixed().required().label('Badge Image'),
  name: string().required().label('Name'),
  description: string().required().label('Description'),
  stz: number().min(0).required().label('STZ'),
  selectedGames: array().optional().label('Games'),
});

interface IFormData extends InferType<typeof schema> {}

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}

const AddNewBadge: FC<IProps> = ({ open, setOpen, onClose }) => {
  const dispatch = useDispatch<Dispatch>();
  const games = useSelector((state: RootState) => state.games.games);

  const loading = useSelector((state: RootState) => state.badges.loading);

  const selectedBadge = useSelector(
    (state: RootState) => state.badges.selected
  );

  const [gameOptions, setGameOptions] = useState<IGame[]>([]);
  const [selectedGames, setSelectedGames] = useState<IGame[]>([]);

  const [formData, setFormData] = useState({
    name: selectedBadge?.name || '',
    description: selectedBadge?.description || '',
    stz: selectedBadge?.stz || '',
  });

  const [image, setImage] = useState<File | null | string>(
    selectedBadge?.logo || null
  );

  const [errors, setErrors] = useState<FormErrors<IFormData | null>>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: null }));
  };

  const handleGameSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const game = gameOptions.find(game => game.id === value)!;
    setSelectedGames(prev => [...prev, game]);
    setGameOptions(gameOptions.filter(game => game.id !== value));
  };

  const handleRemoveGame = (game: IGame) => {
    setGameOptions(prev => [...prev, game]);
    setSelectedGames(selectedGames.filter(_game => _game.id !== game.id));
  };

  const handleAddBadge = async () => {
    let hasError = false;
    const { errors: validationErrors } = await validateData(schema, {
      ...formData,
      selectedGames,
      image,
      stz: +formData.stz,
    });

    if (validationErrors) {
      hasError = true;
      setErrors(validationErrors);
    }

    // TODO: add update functionality for games later

    if (!selectedBadge) {
      // if (!selectedGames.length) {
      //   hasError = true;
      //   setErrors(prev => ({
      //     ...prev,
      //     selectedGames: 'Please select atleast one game',
      //   }));
      // }
    }

    if (hasError) {
      return;
    }

    if (image) {
      if (selectedBadge) {
        dispatch.badges.handleUpdateBadge({
          data: { ...formData, selectedGames, image },
          setOpen,
        });
      } else {
        dispatch.badges.handleAddBadge({
          data: { ...formData, selectedGames, image },
          setOpen,
        });
      }
    }
  };

  useEffect(() => {
    if (games.length) {
      setGameOptions(games);
    }
  }, [games]);

  useEffect(() => {
    if (selectedGames.length) {
      setErrors(prev => ({ ...prev, selectedGames: '' }));
    }
  }, [selectedGames]);

  useEffect(() => {
    dispatch.games.handleGetAllGames('');
    // eslint-disable-next-line
  }, []);
  return (
    <DialogLayout open={open} onClose={onClose}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>
            {selectedBadge ? 'Update Badge' : 'Add New Badge'}
          </p>
          <div className='icon clickable' onClick={() => setOpen(false)}>
            <CrossIcon />
          </div>
        </div>
        <div className='row justify-center'>
          <ImagePicker file={image} setFile={setImage} error={errors?.image} />
        </div>
        <InputContainer>
          <InputField
            label='Name'
            placeholder='Rise to the Ocassion'
            type='text'
            value={formData.name}
            name='name'
            error={errors?.name}
            onChange={handleChange}
          />
        </InputContainer>
        <InputContainer>
          <MultiLineInput
            rows={3}
            label='Description'
            placeholder='The Bronze badge provides starter gaming assets helping
you achieve victory.'
            value={formData.description}
            name='description'
            error={errors?.description}
            onChange={handleChange}
          />
        </InputContainer>

        <InputContainer>
          <Select
            title='Games'
            label='Select games'
            options={gameOptions.map(game => ({
              label: game.name,
              value: game.id,
            }))}
            value={''}
            onChange={handleGameSelection}
            error={errors?.selectedGames}
          />
        </InputContainer>

        <GamesContainer>
          {selectedGames.map(game => (
            <Game key={game.id}>
              <img src={assets.gameIcon1} alt='' />
              <img
                src={assets.crossRes}
                className='remove-icon'
                onClick={() => handleRemoveGame(game)}
                alt=''
              />
            </Game>
          ))}
        </GamesContainer>

        <TokenAmountPicker
          value={formData.stz}
          name='stz'
          onChange={handleChange}
          error={errors?.stz}
        />

        <div className='divider'></div>
        <ButtonContainer>
          <ActionButton
            onClick={() => setOpen(false)}
            variant='primary'
            className='btn btn-primary'
            disabled={loading}
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleAddBadge}
            variant='primary'
            className='btn btn-secondary'
            disabled={loading}
            loading={loading}
          >
            {selectedBadge ? 'Update badge' : 'Add Badge'}
          </ActionButton>
        </ButtonContainer>
      </Container>
    </DialogLayout>
  );
};

export default AddNewBadge;

const Container = styled.div`
  width: 420px;
  padding: 20px;
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 15px;

  & .justify-center {
    margin-top: 20px;
  }

  & .divider {
    height: 1px;
    background-color: ${({ theme }) => theme.color.background.headerRow};
    margin-block: 25px 20px;
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    width: 560px;
    padding: 30px;
    & .justify-center {
      margin-top: 31px;
    }
    & .divider {
      margin-block: 35px 30px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  & .btn {
    height: 44px;
    width: 150px;
    border-radius: 20px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .btn {
      height: 55px;
      width: 198px;
      border-radius: 30px;
    }
  }
`;

const InputContainer = styled.div`
  margin-block: 12px;

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    margin-block: 19px;
  }
`;

const GamesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const Game = styled.div`
  height: 42px;
  width: 42px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.background.socialLink};
  display: grid;
  place-items: center;
  position: relative;
  margin-bottom: 14px;

  & .remove-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
  }
`;
