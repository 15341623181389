import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface IProps {
  active: boolean;
  [x: string]: any;
}

const Lock: FC<IProps> = ({ active, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  return (
    <svg
      width='16'
      height='20'
      viewBox='0 0 16 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M13.4151 7.11086H12.3291V5.00386C12.3408 4.43001 12.2383 3.85955 12.0277 3.32562C11.8171 2.79169 11.5025 2.30491 11.1022 1.89357C10.7019 1.48224 10.2239 1.15453 9.69588 0.929478C9.16787 0.70443 8.60041 0.586528 8.02646 0.58262C7.4525 0.578712 6.88349 0.688875 6.35246 0.906712C5.82144 1.12455 5.33898 1.44572 4.93312 1.85157C4.52725 2.25742 4.20606 2.73986 3.98821 3.27088C3.77035 3.8019 3.66017 4.3709 3.66405 4.94486V7.11086H2.58405C2.10557 7.11218 1.64708 7.30291 1.30883 7.64135C0.970585 7.97978 0.780108 8.43838 0.779053 8.91686V17.5819C0.780372 18.0602 0.970965 18.5185 1.30918 18.8567C1.6474 19.1949 2.10574 19.3855 2.58405 19.3869H13.4151C13.8934 19.3855 14.3517 19.1949 14.6899 18.8567C15.0281 18.5185 15.2187 18.0602 15.2201 17.5819V8.91686C15.219 8.43838 15.0285 7.97978 14.6903 7.64135C14.352 7.30291 13.8935 7.11218 13.4151 7.11086ZM8.62905 12.7439V15.8439C8.63025 16.0081 8.56891 16.1667 8.45749 16.2874C8.34606 16.4081 8.19289 16.4819 8.02905 16.4939C7.94352 16.4979 7.85805 16.4846 7.77783 16.4547C7.6976 16.4247 7.62427 16.3788 7.56229 16.3198C7.50031 16.2607 7.45097 16.1896 7.41723 16.1109C7.3835 16.0322 7.36609 15.9475 7.36605 15.8619V12.7439C7.07239 12.6009 6.83586 12.3626 6.69508 12.0679C6.5543 11.7732 6.51759 11.4395 6.59094 11.1212C6.66428 10.803 6.84336 10.519 7.09892 10.3156C7.35449 10.1122 7.67145 10.0015 7.99805 10.0015C8.32466 10.0015 8.64162 10.1122 8.89718 10.3156C9.15275 10.519 9.33182 10.803 9.40517 11.1212C9.47852 11.4395 9.4418 11.7732 9.30102 12.0679C9.16024 12.3626 8.92372 12.6009 8.63005 12.7439H8.62905ZM11.0691 7.11086H4.92905V4.94486C4.92905 4.13091 5.25239 3.3503 5.82794 2.77475C6.40349 2.1992 7.1841 1.87586 7.99805 1.87586C8.812 1.87586 9.59261 2.1992 10.1682 2.77475C10.7437 3.3503 11.0671 4.13091 11.0671 4.94486L11.0691 7.11086Z'
        fill={
          active ? (activeTheme === 'light' ? '#ffffff' : '#C3CBD2') : '#23272A'
        }
      />
    </svg>
  );
};

export default Lock;
