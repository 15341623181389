import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';
import CrossIcon from '../../assets/icons/CrossIcon';
import InputField from '../../components/InputField/InputField';
import { lightTheme } from '../../theme';
import ActionButton from '../../components/ActionButton/ActionButton';
import { getDefaultRevenueSplit, updateDefaultGuildRevenue } from '../../api';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IUpdataGuildDefaultRevenue } from '../../types/api';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  gameId: string;
  guildId: string;
}

const DefaultRevenueSplit: FC<IProps> = ({
  open,
  setOpen,
  gameId,
  guildId,
}) => {
  const [splits, setSplits] = useState({
    admin: 0,
    guildManager: 0,
    gameMaster: 0,
    captain: 0,
    scholar: 0,
  });

  const [sum, setSum] = useState(0);

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setSplits(prev => ({ ...prev, [name]: value }));
  };

  const handleGetDefaultSplit = async () => {
    try {
      const { data } = await getDefaultRevenueSplit({
        gameId: gameId,
        guildId: guildId,
      });
      setSplits({
        admin: data?.admin_revenue_split,
        guildManager: data?.guild_revenue_split,
        gameMaster: data?.game_master,
        captain: data?.captain,
        scholar: data?.scholar,
      });
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const handleUpdateSplit = async () => {
    try {
      setLoading(true);
      const apiData: IUpdataGuildDefaultRevenue = {
        captain: String(splits.captain),
        game_master: String(splits.gameMaster),
        scholar: String(splits.scholar),
        game_id: gameId,
        guild_id: guildId,
      };
      await updateDefaultGuildRevenue(apiData);
      setOpen(false);
      toast.success('Updated successfully!');
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data.message;
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetDefaultSplit();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSum(
      +splits.admin +
        +splits.gameMaster +
        +splits.guildManager +
        +splits.captain +
        +splits.scholar
    );
  }, [splits]);

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>
            Default Revenue Split
          </p>
          <div className='icon clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <p className='font-size-14 text-secondary subtitle'>
          Set Default Revenue Split for your Guild members. Percentage for admin
          is {splits.admin}% and guild manager is {splits.guildManager}%.
        </p>
        <Content autoComplete='off'>
          <div className='fields-container'>
            <div className=''>
              <InputField
                label='Game Master'
                type='number'
                placeholder='Game Master'
                value={splits.gameMaster}
                onChange={handleChange}
                name='gameMaster'
              />
            </div>
            <div className=''>
              <InputField
                label='Captain'
                type='number'
                placeholder='Captain'
                value={splits.captain}
                onChange={handleChange}
                name='captain'
              />
            </div>
            <div className=''>
              <InputField
                label='Scholar'
                type='number'
                placeholder='Scholar'
                value={splits.scholar}
                onChange={handleChange}
                name='scholar'
              />
            </div>
            {sum > 100 && (
              <p className='font-size-14 text-danger'>
                Overall revenue split must be 100% which is now {sum}%.
              </p>
            )}
          </div>

          <div className='divider'></div>

          <ButtonContainer>
            <ActionButton
              onClick={handleClose}
              variant='primary'
              className='btn btn-primary'
              disabled={loading}
            >
              Cancel
            </ActionButton>
            <ActionButton
              onClick={handleUpdateSplit}
              variant='primary'
              className='btn btn-secondary'
              disabled={sum > 100 || loading}
              loading={loading}
            >
              Continue
            </ActionButton>
          </ButtonContainer>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default DefaultRevenueSplit;

const Container = styled.div`
  width: 480px;
  padding: 25px 20px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.background.card};

  & .subtitle {
    margin-top: 9px;
  }
`;

const Content = styled.form`
  margin-top: 30px;
  & .fields-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  & .divider {
    height: 0.5px;
    background-color: ${({ theme }) => theme.color.text.dim};
    margin-block: 40px 30px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  & .btn {
    height: 44px;
    width: 150px;
    border-radius: 20px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    & .btn {
      height: 55px;
      width: 198px;
      border-radius: 30px;
    }
  }
`;
