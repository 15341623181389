import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const ArrowRight: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='21'
      height='9'
      viewBox='0 0 21 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M17.0169 1.1399C16.9253 1.23336 16.874 1.35902 16.874 1.4899C16.874 1.62078 16.9253 1.74644 17.0169 1.8399L19.3389 4.1659H0.497883C0.366491 4.16774 0.241105 4.22123 0.148846 4.3148C0.0565863 4.40837 0.00486994 4.5345 0.00488281 4.6659C0.00486994 4.7973 0.0565863 4.92343 0.148846 5.017C0.241105 5.11057 0.366491 5.16406 0.497883 5.1659H19.3299L17.0079 7.4919C16.9163 7.58536 16.865 7.71102 16.865 7.8419C16.865 7.97278 16.9163 8.09844 17.0079 8.1919C17.0538 8.23798 17.1083 8.27454 17.1684 8.29949C17.2284 8.32444 17.2928 8.33728 17.3579 8.33728C17.4229 8.33728 17.4873 8.32444 17.5474 8.29949C17.6074 8.27454 17.662 8.23798 17.7079 8.1919L20.8549 5.0219C20.8968 4.97553 20.9306 4.92246 20.9549 4.8649C20.9806 4.80455 20.9936 4.73951 20.9929 4.6739C20.9929 4.54399 20.9423 4.41918 20.8519 4.3259L17.7119 1.1479C17.6671 1.1012 17.6134 1.06391 17.5541 1.03818C17.4947 1.01246 17.4308 0.998824 17.3661 0.998079C17.3014 0.997334 17.2372 1.00949 17.1772 1.03384C17.1172 1.05819 17.0627 1.09425 17.0169 1.1399Z'
        fill={fill || theme.color.text.secondary}
      />
    </svg>
  );
};

export default ArrowRight;
