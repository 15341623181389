import React, { FC } from 'react';
import useModulePermissions from '../../hooks/useModulePermissions';
import { permissionEnum } from '../../types/enums';

interface IProps {
  moduleName: string;
  featureName: string;
  children: React.ReactNode;
  action?: boolean;
}

const WithPermissions: FC<IProps> = ({
  moduleName,
  featureName,
  children,
  action,
}) => {
  const permissions = useModulePermissions(moduleName);
  if (action) {
    if (
      permissions?.permissions[featureName] === permissionEnum.fullPermission
    ) {
      return <>{children}</>;
    } else {
      return <></>;
    }
  } else if (
    permissions?.permissions[featureName] === permissionEnum.fullPermission ||
    permissions?.permissions[featureName] === permissionEnum.view
  ) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};

export default WithPermissions;
