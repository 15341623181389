import React, { FC } from 'react';

import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

import assets from '../../../assets';

dayjs.extend(utc);

const LatestTournaments: FC = () => {
  const tournaments = useSelector(
    (state: RootState) => state.tournaments.tournaments
  );

  const badges = useSelector((state: RootState) => state.badges.badges);

  return (
    <Container>
      <p className='font-size-25 text-primary text-semibold section-title'>
        Latest Tournaments
      </p>
      <Grid>
        {tournaments.map(tournament => (
          <GridItem key={tournament.id} className='row justify-space-between'>
            <div className='row left-row'>
              <div className='img'>
                <img src={tournament.cover_photo || assets.tournament} alt='' />
              </div>
              <div className='info'>
                <p className='font-size-20 text-primary text-bold'>
                  {tournament.name}
                </p>
                <div className='row info-row'>
                  <p className='chip font-size-10 text-semibold'>
                    In {dayjs(tournament.start_date).diff(new Date(), 'd')} Days
                  </p>
                  <p className='font-size-10 text-semibold text-dim'>
                    {dayjs(tournament.end_date).format('DD MMM YYYY')}
                  </p>
                </div>
              </div>
            </div>
            <div className='row requirements'>
              <div className='col'>
                <p className='font-size-10 text-dim text-bold text-center'>
                  DURATION
                </p>
                <p className='font-size-16 text-primary text-bold'>
                  {dayjs(tournament.end_date).diff(tournament.start_date, 'd')}{' '}
                  Days
                </p>
              </div>
              <div className='col'>
                <p className='font-size-10 text-dim text-bold text-center'>
                  BADGE
                </p>
                <p className='font-size-16 text-primary text-bold capitalize'>
                  {
                    badges
                      .find(badge => badge.id === tournament.badge_id)
                      ?.name.split(' ')[0]
                  }
                </p>
              </div>
              <div className='col'>
                <p className='font-size-10 text-dim text-bold text-center'>
                  PRICE
                </p>
                <p className='font-size-16 text-primary text-bold'>
                  {tournament.prize}$
                </p>
              </div>
            </div>
          </GridItem>
        ))}
      </Grid>
    </Container>
  );
};

export default LatestTournaments;

const Container = styled.div`
  padding: 24px 20px;
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;

  & .section-title {
    margin-bottom: 20px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const GridItem = styled.div`
  padding: 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.background.row};

  & .left-row {
    gap: 11px;
    & .img {
      height: 55px;
      width: 58px;
      border-radius: 8px;
      overflow: hidden;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .info {
      & .info-row {
        margin-top: 6px;
        gap: 10px;

        & .text-dim {
          opacity: 0.4;
        }

        & .chip {
          border: 1px solid #707070;
          background-color: ${({ theme }) =>
            theme.color.background.button.primary.bg};
          color: ${({ theme }) => theme.color.background.button.primary.color};
          padding: 2px 10px;
          border-radius: 15px;
        }
      }
    }
  }

  & .requirements {
    gap: 25px;

    & .text-dim {
      opacity: 0.4;
    }
  }
`;
