import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

import VaultIcon from '../assets/icons/VaultIcon';
import DashbaordIcon from '../assets/icons/DashbaordIcon';

import {
  features,
  modulesEnum,
  permissionEnum,
  rolesEnum,
} from '../types/enums';
import { INavItem } from '../types/interface';
import GuildIcon from '../assets/icons/GuildIcon';
import PerformanceIcon from '../assets/icons/PerformanceIcon';
import PayoutsIcon from '../assets/icons/PayoutsIcon';
import RolesIcon from '../assets/icons/RolesIcon';
import TournamentIcon from '../assets/icons/TournamentIcon';
import useModulePermissions from './useModulePermissions';
import useRole from './useRole';

const allTabs: INavItem[] = [
  {
    to: '/dashboard',
    icon: DashbaordIcon,
    label: 'Dashboard',
    end: true,
    index: 1,
  },
  {
    to: '/dashboard/tournaments',
    icon: TournamentIcon,
    label: 'Tournaments',
    end: false,
    index: 2,
  },
  {
    to: '/dashboard/games-vault',
    icon: VaultIcon,
    label: 'Game Vault',
    end: false,
    index: 3,
  },
  {
    to: '/dashboard/guilds',
    icon: GuildIcon,
    label: 'Guilds',
    end: false,
    index: 4,
  },
  {
    to: '/dashboard/performance',
    icon: PerformanceIcon,
    label: 'Performance',
    end: true,
    index: 5,
  },
  {
    to: '/dashboard/payouts',
    icon: PayoutsIcon,
    label: 'Payout',
    end: true,
    index: 6,
  },
  {
    to: '/dashboard/roles',
    icon: RolesIcon,
    label: 'Roles',
    end: true,
    index: 7,
  },
];

const useTabs = () => {
  const { role } = useRole();
  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );
  const guildPermissions = useModulePermissions(modulesEnum.Guilds);
  const userPermissions = useSelector(
    (state: RootState) => state.permissions.userPermissions
  );

  const [tabs, setTabs] = useState<INavItem[]>([]);

  const handleCalculateRenderAbleTabs = () => {
    const _tabs: INavItem[] = [];
    userPermissions.forEach(module => {
      const shouldRender = Object.keys(module.permissions).some(
        e => module.permissions[e] !== permissionEnum.restricted
      );
      if (shouldRender) {
        const tab = allTabs.find(
          tab => tab.label.toLowerCase() === module.module_name.toLowerCase()
        );
        if (tab) {
          let skip = false;
          if (tab.label.toLowerCase() === modulesEnum.Guilds) {
            if (role?.name === rolesEnum.Scholar && !currentGuild) {
              skip = true;
            }
            if (
              guildPermissions?.permissions[features.allGuilds] ===
              permissionEnum.restricted
            ) {
              tab.to = '/dashboard/my-guild';
              tab.label = 'My guild';
            }
          }
          if (!skip) {
            _tabs.push(tab);
          }
        }
      }
    });
    setTabs(
      _tabs.sort((a, b) => {
        if (a.index < b.index) {
          return -1;
        }
        if (a.index > b.index) {
          return 1;
        }
        return 0;
      })
    );
  };

  useEffect(() => {
    if (userPermissions) {
      handleCalculateRenderAbleTabs();
    }
    // eslint-disable-next-line
  }, [userPermissions, guildPermissions]);
  return { tabs };
};

export default useTabs;
