import React, { FC, useState } from 'react';

import styled from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';

import MoreIcon from '../../../../../assets/icons/MoreIcon';
import { IGameMaster } from '../../../../../types/interface';
import assets from '../../../../../assets';
import {
  features,
  modulesEnum,
  permissionEnum,
} from '../../../../../types/enums';
import WithPermissions from '../../../../../components/WithPermissions/WithPermissions';
import useModulePermissions from '../../../../../hooks/useModulePermissions';

interface IProps {
  setMangeTeam: React.Dispatch<React.SetStateAction<boolean>>;
  master: IGameMaster;
}

const GameMasterRow: FC<IProps> = ({ setMangeTeam, master }) => {
  const permissions = useModulePermissions(modulesEnum.Guilds);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleMangeTeam = () => {
    setMangeTeam(true);
  };
  return (
    <Container>
      <div className='row justify-space-between'>
        <div className='row'>
          <div className='image'>
            <img
              src={assets.userPreview}
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              alt=''
            />
          </div>
          <p className='font-size-16 text-primary text-semibold userName'>
            {master.username}
          </p>
        </div>
        <GamesRow>
          {master.games.map((game, index) => (
            <div className='game' key={index}>
              {game.mini_icon && <img src={game.mini_icon} alt='' />}
            </div>
          ))}
        </GamesRow>

        <div className='row secondary'>
          <div className='row count'>
            <div className='count-key'>
              <p className='font-size-12'>C</p>
            </div>
            <p className='font-size-14 text-primary'>0</p>
          </div>
          <div className='row count'>
            <div className='count-key'>
              <p className='font-size-12'>S</p>
            </div>
            <p className='font-size-14 text-primary'>0</p>
          </div>

          {(permissions?.permissions[features.manageTeam] ===
            permissionEnum.fullPermission ||
            permissions?.permissions[features.editUser] ===
              permissionEnum.fullPermission ||
            permissions?.permissions[features.deleteUser] ===
              permissionEnum.fullPermission) && (
            <div className='more-icon clickable' onClick={handleClick}>
              <MoreIcon />
              {open && (
                <ClickAwayListener onClickAway={handleClose}>
                  <Menu>
                    <WithPermissions
                      moduleName={modulesEnum.Guilds}
                      featureName={features.manageTeam}
                      action
                    >
                      <div className='row clickable' onClick={handleMangeTeam}>
                        <p className='font-size-14 text-primary'>Manage team</p>
                      </div>
                    </WithPermissions>
                    <WithPermissions
                      moduleName={modulesEnum.Guilds}
                      featureName={features.editUser}
                      action
                    >
                      <div className='row clickable'>
                        <p className='font-size-14 text-primary'>Edit user</p>
                      </div>
                    </WithPermissions>
                    <WithPermissions
                      moduleName={modulesEnum.Guilds}
                      featureName={features.editUser}
                      action
                    >
                      <div className='row clickable'>
                        <p className='font-size-14 text-primary'>Remove user</p>
                      </div>
                    </WithPermissions>
                  </Menu>
                </ClickAwayListener>
              )}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default GameMasterRow;

const Container = styled.div`
  height: 50px;
  background-color: ${({ theme }) => theme.color.background.row};
  margin-top: 10px;
  border-radius: 4px;
  padding-inline: 10px;
  display: flex;
  align-items: center;
  & .justify-space-between {
    width: 100%;

    & .image {
      height: 36px;
      width: 36px;
      flex-shrink: 0;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.background.headerRow};
      margin-right: 13px;
    }
  }

  & .secondary {
    gap: 41px;
    & .more-icon {
      display: flex;
      align-items: center;
      position: relative;
    }
    & .row {
      &.count {
        gap: 8px;
        & .count-key {
          background-color: ${({ theme }) => theme.color.text.primary};
          height: 20px;
          width: 20px;
          border-radius: 4px;
          display: grid;
          place-items: center;
          color: ${({ theme }) => theme.color.background.button.primary.color};
        }
      }
    }
  }

  & .userName {
    width: 10ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const Menu = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  width: 163px;
  background-color: ${({ theme }) => theme.color.background.menu};
  padding: 0px 10px;
  transform: translateY(100%);
  border-radius: 10px;
  z-index: 100;
  filter: drop-shadow(2px 2px 4px rgba(53, 57, 64, 0.102));

  & .row {
    padding-block: 7px;
    border-bottom: 1px solid ${({ theme }) => theme.color.text.dim + '66'};
    padding-inline: 4px;

    &:last-of-type {
      border-bottom: none;
    }

    & .wallet-address {
      padding: 6px 9px;
      display: flex;
      align-items: center;
      gap: 4px;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.color.text.dim + '1a'};
      width: 100%;
      border-radius: 15px;

      & .icon {
        cursor: pointer;
      }
    }
  }
`;

const GamesRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  & .game {
    height: 24px;
    width: 24px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.text.primary};
    padding: 2px;
    overflow: hidden;

    & img {
      height: 100%;
      width: 100%;
    }
  }
`;
