import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { allChains, socialLinks as socialLinksApi } from '../../api';
import {
  IChains,
  IErcContract,
  INFTType,
  ISocialLinkConfig,
} from '../../types/api';

interface IState {
  loading: boolean;
  ercContracts: IErcContract[];
  nftContracts: IErcContract[];
  nftTypes: INFTType[];
  socialLinks: ISocialLinkConfig[];
}

export const config = createModel<RootModel>()({
  name: 'config',
  state: {
    loading: false,
    ercContracts: [],
    nftContracts: [],
    nftTypes: [],
    socialLinks: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setSupportedChains(state, payload: IChains) {
      state.ercContracts = payload.erc_contract;
      state.nftContracts = payload.nft_contract;
      state.nftTypes = payload.nft_type;
    },
    setSocialLinks(state, payload: ISocialLinkConfig[]) {
      state.socialLinks = payload;
    },
  },
  effects: dispatch => ({
    async handleGetAllSupportedChains() {
      try {
        dispatch.config.setLoading(true);
        const { data } = await allChains();
        dispatch.config.setSupportedChains(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.config.setLoading(false);
      }
    },
    async handleGetsocialLinks() {
      try {
        dispatch.config.setLoading(true);
        const { data } = await socialLinksApi();
        dispatch.config.setSocialLinks(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.config.setLoading(false);
      }
    },
  }),
});
