import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

import SearchBar from '../../../components/SearchBar/SearchBar';
import GuildRow from './GuildRow';

interface IProps {
  filtersHidden?: boolean;
  search?: string;
}

const GuildsTableView: FC<IProps> = ({ filtersHidden = true, search = '' }) => {
  const guilds = useSelector((state: RootState) => state.guilds.guilds);
  const [filters, setFilters] = useState({
    game: '',
    search: search,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setFilters(prev => ({ ...prev, search: search }));
  }, [search]);

  return (
    <Container style={{ padding: filtersHidden ? '20px' : '0px' }}>
      {filtersHidden && (
        <div className='row justify-space-between'>
          <p className='font-size-18 text-primary text-semibold'>Guilds</p>
          <div className='row' style={{ gap: '10px' }}>
            {/* <Select
              options={[
                { label: 'Sorare', value: 'sorare' },
                { label: 'Axie infinity', value: 'Axie infinity' },
              ]}
              label='Games'
              value={filters.game}
              onChange={handleChange}
              name='game'
            /> */}
            <SearchBar
              value={filters.search}
              onChange={handleChange}
              name='search'
            />
          </div>
        </div>
      )}

      <TableContainer>
        <table>
          <thead>
            <tr className='header-row'>
              <th className='font-size-12 text-primary text-semibold'>Name</th>
              <th className='font-size-12 text-primary text-semibold'>Games</th>
              <th className='font-size-12 text-primary text-semibold'>
                Game master
              </th>
              <th className='font-size-12 text-primary text-semibold'>
                captains
              </th>
              <th className='font-size-12 text-primary text-semibold'>
                Scholars
              </th>
              <th className='font-size-12 text-primary text-semibold'>
                Tournaments held
              </th>
              <th className='font-size-12 text-primary text-semibold'>
                Next payout
              </th>
              <th className='font-size-12 text-primary text-semibold'>
                Socials
              </th>
            </tr>
          </thead>
          <tbody>
            {guilds.map(guild => {
              if (
                guild.name.toLowerCase().indexOf(filters.search.toLowerCase()) >
                -1
              ) {
                return <GuildRow guildDetails={guild} key={guild.id} />;
              } else {
                return <></>;
              }
            })}
          </tbody>
        </table>
      </TableContainer>
    </Container>
  );
};

export default GuildsTableView;

const Container = styled.div`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 20px;
`;

const TableContainer = styled.div`
  margin-top: 13px;

  & table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    & tr {
      height: 50px;
      &.header-row {
        height: 40px;
        border: none;
        & th {
          text-align: left;
          background-color: ${({ theme }) => theme.color.background.headerRow};
          &:first-of-type {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding-left: 12px;
          }
          &:last-of-type {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-right: 12px;
          }
        }
      }
      & td {
        background-color: ${({ theme }) => theme.color.background.row};
        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          padding-left: 12px;
        }
        &:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          padding-right: 12px;
        }
      }
    }
  }
`;
