import React, { useState } from 'react';

import styled from 'styled-components';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { object, string, ref } from 'yup';
import { ISignUpData } from '../../types/api';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';

import InputField from '../../components/InputField/InputField';
import Button from '../../components/Button/Button';
import Checkbox from '../../components/Checkbox/Checkbox';

import { lightTheme as theme } from '../../theme';
import { ethers } from 'ethers';
import { FormErrors, validateData } from '../../utils/validations';
import Select from '../../components/Select/Select';
import useCountries from '../../hooks/useCountries';

const schema = object({
  // first_name: string().required().label('First name'),
  // last_name: string().required().label('Last name'),
  username: string().required().label('Username'),
  email: string().email().required().label('Email'),
  password: string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    )
    .required()
    .label('Password'),
  confirm_password: string()
    .oneOf(
      [ref('password'), null],
      'Password and confirm password must be same'
    )
    .required()
    .label('Confirm password'),
  discord: string().required().label('Discord'),
  country: string().required().label('Country'),
  wallet_address: string().required().label('Wallet address'),
});

const Register = () => {
  const countries = useCountries();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const [params] = useSearchParams();
  // @ts-ignore
  const inviteId = params.get('inviteId');

  const loading = useSelector((state: RootState) => state.auth.loading);

  const [acceptTerms, setAcceptTerms] = useState(false);

  const [formData, setFormData] = useState<ISignUpData>({
    // first_name: '',
    // last_name: '',
    username: '',
    email: '',
    password: '',
    confirm_password: '',
    discord: '',
    country: '',
    wallet_address: '',
  });

  const [errors, setErrors] = useState<FormErrors<ISignUpData | null>>(null);

  const handleTerms = () => {
    setAcceptTerms(!acceptTerms);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: null }));
  };

  const handleContinue = async () => {
    setErrors(null);
    const { errors: validationErrors } = await validateData(schema, formData);
    if (validationErrors) {
      return setErrors(validationErrors);
    }
    if (!ethers.utils.isAddress(formData.wallet_address)) {
      return setErrors(prev => ({
        ...prev,
        wallet_address: 'Wallet address must be a valid ethereum address',
      }));
    }
    if (inviteId) {
      dispatch.auth.handleSignUp({
        data: { ...formData, guild_id: inviteId },
        navigate,
        setErrors,
      });
    } else {
      dispatch.auth.handleSignUp({ data: formData, navigate, setErrors });
    }
  };

  return (
    <FormContainer>
      <h1 className='text-primary'>Register</h1>
      <p className='font-size-18 subtitle text-primary'>
        Fill in the fields below
      </p>
      <Grid autoComplete='off'>
        {/* <InputField
          type='text'
          label='First Name'
          placeholder='Enter first name'
          value={formData.first_name}
          onChange={handleChange}
          name='first_name'
          error={errors?.first_name}
        />
        <InputField
          type='text'
          label='Last Name'
          placeholder='Enter last name'
          value={formData.last_name}
          onChange={handleChange}
          name='last_name'
          error={errors?.last_name}
        /> */}
        <InputField
          type='text'
          label='Username'
          placeholder='Enter last name'
          value={formData.username}
          onChange={handleChange}
          name='username'
          error={errors?.username}
        />
        <InputField
          type='email'
          label='Email Address'
          placeholder='Enter email address'
          value={formData.email}
          onChange={handleChange}
          name='email'
          error={errors?.email}
        />
        <InputField
          type='text'
          label='Wallet address'
          placeholder='Enter wallet address'
          value={formData.wallet_address}
          onChange={handleChange}
          name='wallet_address'
          error={errors?.wallet_address}
        />
        <InputField
          type='password'
          label='Password'
          placeholder='Enter password'
          value={formData.password}
          onChange={handleChange}
          name='password'
          error={errors?.password}
        />
        <InputField
          type='password'
          label='Verify Password'
          placeholder='Enter password'
          value={formData.confirm_password}
          onChange={handleChange}
          name='confirm_password'
          error={errors?.confirm_password}
        />
        <Select
          title='Country'
          label='select country'
          options={countries.map(country => ({
            label: country,
            value: country,
          }))}
          value={formData.country}
          onChange={handleChange}
          name='country'
          error={errors?.country}
        />
        <InputField
          type='text'
          label='Discord username'
          placeholder='QueenBee'
          value={formData.discord}
          onChange={handleChange}
          name='discord'
          error={errors?.discord}
        />
      </Grid>
      <TermsConditions>
        <Checkbox
          checked={acceptTerms}
          className='clickable'
          onClick={handleTerms}
        />
        <p className='font-size-14 text-dimmer'>
          Agree to our{' '}
          <Link to='#' className='anchor text-dim'>
            Terms and conditions
          </Link>
        </p>
      </TermsConditions>
      <ButtonContainer>
        <Button
          variant='primary'
          label='Register'
          disabled={!acceptTerms || loading}
          loading={loading}
          onClick={handleContinue}
        />
      </ButtonContainer>
      <p className='font-size-14 text-dimmer text-center'>
        Have an account?{' '}
        <Link to='/login' className='anchor text-dim'>
          Sign in
        </Link>
      </p>
    </FormContainer>
  );
};

export default Register;

const FormContainer = styled.div`
  width: 600px;
  padding: 25px 20px;
  background: ${({ theme }) => theme.color.gradient.card};
  border-radius: 12px;
  & h1 {
    margin-bottom: 12px;
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    width: 810px;
    padding: 30px;
    & h1 {
      margin-bottom: 17px;
    }
  }
`;

const Grid = styled.form`
  margin-block: 30px 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 20px;
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    margin-block: 50px 21px;
    gap: 29px 21px;
  }
`;

const TermsConditions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-block: 25px 15px;

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    margin-block: 31px 19px;
  }
`;
