import React, { FC } from 'react';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../../store';

import { IRevenueGuild } from '../../../types/api';

interface IProps {
  guild: IRevenueGuild;
  setOpenSplitPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdminRevenueRow: FC<IProps> = ({ guild, setOpenSplitPopup }) => {
  const dispatch = useDispatch<Dispatch>();
  const handleOpenPopup = () => {
    dispatch.guilds.setSelectedRevenueGuild(guild);
    setOpenSplitPopup(true);
  };
  return (
    <GuildRevenue>
      <div className='guild'>
        <div className='wrapper'>
          <p className='font-size-18 text-primary text-semibold capitalize'>
            {guild.name}
          </p>
        </div>
        <div className='divider'></div>
      </div>
      <div className='revenue'>
        <div className='revenue-box clickable' onClick={handleOpenPopup}>
          <p className='font-size-18 text-primary text-semibold'>
            {guild.admin_revenue_split} / {guild.guild_revenue_split}
          </p>
        </div>
      </div>
    </GuildRevenue>
  );
};

export default AdminRevenueRow;

const GuildRevenue = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;

  &:first-of-type {
    & .guild {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    & .revenue {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  &:nth-of-type(2) {
    & .guild {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    & .revenue {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
  &:nth-of-type(3) {
    & .guild {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    & .revenue {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
  &:nth-of-type(4) {
    & .guild {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    & .revenue {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  &:nth-last-of-type(1) {
    & .guild {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      & .divider {
        margin-bottom: 10px;
      }
    }
    & .revenue {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  &:nth-last-of-type(2) {
    & .guild {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      & .divider {
        margin-bottom: 10px;
      }
    }
    & .revenue {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  &:nth-last-of-type(3) {
    & .guild {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      & .divider {
        margin-bottom: 10px;
      }
    }
    & .revenue {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  &:nth-last-of-type(4) {
    & .guild {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      & .divider {
        margin-bottom: 10px;
      }
    }
    & .revenue {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  & .guild {
    background-color: ${({ theme }) => theme.color.background.row};

    overflow: hidden;

    & .wrapper {
      padding: 15px 10px;
    }

    & .divider {
      height: 1px;
      background-color: ${({ theme }) => theme.color.background.headerRow};
      width: calc(100% - 20px);
      margin-left: 10px;
    }
  }

  & .revenue {
    background-color: ${({ theme }) => theme.color.background.row};
    padding: 3px;

    & .revenue-box {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      padding: 13px 14px;
      border-radius: 4px;
    }
  }
`;
