import classNames from 'classnames';
import React, { FC } from 'react';

import styled from 'styled-components';

import { lightTheme as theme } from '../../theme';

interface IProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  error?: string;
}

const TokenAmountPicker: FC<IProps> = ({ value, onChange, name, error }) => {
  return (
    <Container>
      <p
        className={classNames(
          'font-size-14 text-primary',
          error && 'text-danger'
        )}
      >
        Required STZ to hold
      </p>

      <div className={classNames('picker', error && 'error')}>
        <div className='left'>
          <input
            type='number'
            className='font-size-16 text-bold text-primary'
            placeholder='Enter amount'
            value={value}
            onChange={onChange}
            name={name}
            min={0}
          />
          <span className='font-size-10 text-primary '> = 302,540 USD</span>
        </div>
        <div className='dropdown'>
          <div id='' className='select font-size-14'>
            STZ
          </div>
        </div>
      </div>

      {error && <p className='font-size-14 text-danger'>{error}</p>}
    </Container>
  );
};

export default TokenAmountPicker;

const Container = styled.div`
  & .picker {
    width: 100%;
    height: 44px;
    background-color: ${({ theme }) => theme.color.background.row};
    margin-top: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.error {
      background-color: ${({ theme }) => theme.color.text.danger + '1a'};
      border: 1px solid ${({ theme }) => theme.color.text.danger};
    }

    & .left {
      display: flex;
      flex-direction: column;
      padding-left: 30px;
      justify-content: center;

      & input {
        background-color: none;
        outline: none;
        border: none;
        background-color: transparent;
        &::placeholder {
          font-weight: 400 !important;
        }
      }
      & .font-size-10 {
        opacity: 0.4;
        margin-top: 4px;
      }
    }

    & .dropdown {
      position: relative;
      & .select {
        width: 130px;
        padding-inline: 22px 22px;
        height: 44px;
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.color.text.primary};
        border-radius: 0 8px 8px 0;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      & .icon {
        position: absolute;
        right: 1px;
        width: 40px;
        height: 40px;
        top: 50%;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        transform: translateY(-50%);
        background-color: ${({ theme }) => theme.color.background.row};
        display: grid;
        place-items: center;
        pointer-events: none;
      }
    }
  }
  & .text-danger {
    margin-top: 4px;
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .text-danger {
      margin-top: 8px;
    }
    & .picker {
      height: 55px;
      & .dropdown {
        & .select {
          height: 55px;
          width: 150px;
        }
        & .icon {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
`;
