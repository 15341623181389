import React, { FC } from 'react';

import styled from 'styled-components';

const ProgressBar: FC = () => {
  return (
    <Container>
      <p className='font-size-8 text-primary'>
        250/<span className='text-dimmer'> 300 XP</span>
      </p>
      <Progress>
        <div className='progress' style={{ width: '80%' }}></div>
      </Progress>
      <div className='row justify-space-between'>
        <div className='level'></div>
        <div className='level'></div>
      </div>
    </Container>
  );
};

export default ProgressBar;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & .level {
    height: 23px;
    width: 23px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.text.primary};
  }
`;

const Progress = styled.div`
  width: 100%;
  height: 5px;
  border-radius: 3px;
  position: relative;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  margin-block: 3px 10px;
  & .progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.color.text.primary};
  }
`;
