import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme';

const SearchIcon: FC<{ [x: string]: any }> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.38161 0.0292969C6.68161 0.0772969 6.99161 0.104297 7.28761 0.176297C8.16585 0.360541 8.98905 0.746947 9.69189 1.30485C10.3947 1.86275 10.9579 2.57679 11.3366 3.3903C11.8172 4.33645 12.0286 5.39647 11.9476 6.4546C11.8667 7.51272 11.4965 8.52826 10.8776 9.3903C10.7836 9.5343 10.6696 9.6663 10.5566 9.8163C10.5906 9.8533 10.6246 9.8913 10.6566 9.9283L14.0016 13.4063C14.1429 13.5285 14.2362 13.6968 14.2652 13.8814C14.2941 14.0659 14.2567 14.2547 14.1596 14.4143C14.1019 14.5088 14.0244 14.5896 13.9326 14.6515C13.8408 14.7133 13.7367 14.7546 13.6274 14.7725C13.5182 14.7904 13.4063 14.7846 13.2996 14.7554C13.1928 14.7262 13.0935 14.6743 13.0086 14.6033C12.9541 14.556 12.902 14.5059 12.8526 14.4533C11.7373 13.294 10.6223 12.1343 9.50761 10.9743C9.48193 10.945 9.45885 10.9136 9.43861 10.8803C9.05761 11.0803 8.71761 11.2913 8.35361 11.4453C7.58714 11.7911 6.75408 11.9645 5.91328 11.9531C5.07247 11.9417 4.24442 11.7458 3.48761 11.3793C2.68623 11.0212 1.97707 10.4851 1.41407 9.81173C0.851068 9.13835 0.449055 8.34543 0.238612 7.4933C0.0254274 6.73727 -0.0286548 5.94524 0.0797819 5.16725C0.188219 4.38926 0.456817 3.64222 0.868612 2.9733C1.28278 2.25538 1.84441 1.6334 2.51648 1.14836C3.18854 0.663315 3.95578 0.32622 4.76761 0.159297C5.04161 0.0972969 5.32761 0.0722969 5.60361 0.0292969H6.38161ZM5.99161 1.5853C5.12116 1.5853 4.27027 1.84348 3.54658 2.32718C2.8229 2.81088 2.25894 3.49836 1.92607 4.30265C1.59319 5.10693 1.50635 5.99188 1.67653 6.84553C1.84671 7.69919 2.26626 8.48318 2.88211 9.09834C3.49797 9.71349 4.28244 10.1322 5.13628 10.3014C5.99013 10.4706 6.87498 10.3827 7.67889 10.0489C8.48279 9.71514 9.16963 9.1504 9.65251 8.42617C10.1354 7.70193 10.3926 6.85075 10.3916 5.9803C10.3877 4.81502 9.92265 3.69868 9.0982 2.87517C8.27376 2.05166 7.15689 1.58793 5.99161 1.5853Z'
        fill={fill ? fill : theme.color.text.primary}
      />
    </svg>
  );
};

export default SearchIcon;
