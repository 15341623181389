import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { useParams } from 'react-router-dom';

import Select from '../../components/Select/Select';
import TabPanel from '../../components/TabPanel/TabPanel';
import GuildDetails from './Components/GuildDetails/GuildDetails';
import GuildGamesAndTeams from './Components/GuildGamesAndTeams/GuildGamesAndTeams';
import ActionButton from '../../components/ActionButton/ActionButton';
import AddNewGuild from '../../Dialogs/AddNewGuild/AddNewGuild';
import GuildInvite from '../../Dialogs/GuildInvite/GuildInvite';

import GuildVault from './Components/GuildVault/GuildVault';
import AddSocialMediaLinks from '../../Dialogs/AddSocialMediaLinks/AddSocialMediaLinks';
import FacebookIcon from '../../assets/icons/FacebookIcon';
import InstagramIcon from '../../assets/icons/InstagramIcon';
import TwitterIcon from '../../assets/icons/TwitterIcon';
import TelegramIcon from '../../assets/icons/TelegramIcon';
import TwichIcon from '../../assets/icons/TwichIcon';

import assets from '../../assets';
import { lightTheme } from '../../theme';
import useRole from '../../hooks/useRole';
import {
  features,
  modulesEnum,
  permissionEnum,
  rolesEnum,
} from '../../types/enums';
import { IGuildStats } from '../../types/api';
import { getGuildStats } from '../../api';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../hooks/useTheme';
import WithPermissions from '../../components/WithPermissions/WithPermissions';
import useModulePermissions from '../../hooks/useModulePermissions';
import GlobeIcon from '../../assets/icons/GlobeIcon';
import MangeAssetsAll from '../../Dialogs/ManageAssetsAll/MangeAssetsAll';

const socialConfig: { [x: string]: any } = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  web: GlobeIcon,
  telegram: TelegramIcon,
  instagram: InstagramIcon,
  chat: TwichIcon,
};

const RenderIcon: FC<{ name: string; fill?: string }> = ({ name, fill }) => {
  const Icon = socialConfig[name];
  return <Icon fill={fill} />;
};

const GuildPage: FC = () => {
  const { role } = useRole();
  const { theme } = useTheme();
  const permissions = useModulePermissions(modulesEnum.Guilds);
  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  const guildSocialLinks = useSelector(
    (state: RootState) => state.guilds.currentGuildSocialLinks
  );

  const guildId = useParams().id || currentGuild?.id;

  const dispatch = useDispatch<Dispatch>();

  const [openDialog, setOpenDialog] = useState(false);
  const [invite, setInvite] = useState(false);
  const [openLinksDialog, setOpenLinksDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<IGuildStats | null>(null);
  const [pullback, setPullback] = useState(false);
  const [transferMultiple, setTransferMultiple] = useState(false);

  const [filters, setFilters] = useState({
    type: '',
    view: 'Guild Details',
    search: '',
  });
  const handlePullBack = () => {
    setPullback(true);
  };

  const handleUpdateGuild = () => {
    setOpenDialog(true);
  };

  const handleGetStats = async () => {
    try {
      setLoading(true);
      const { data } = await getGuildStats(guildId);
      setStats(data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddSocialLink = () => {
    setOpenLinksDialog(true);
  };

  const handleFilters = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleCreateInvite = () => {
    setInvite(true);
  };

  const handleTransferMultiple = () => {
    setTransferMultiple(true);
  };

  useEffect(() => {
    if (guildId) {
      dispatch.guilds.handleGetGuildById(guildId);
      dispatch.scholarships.handleGetGuildScholarships(guildId);
      dispatch.guilds.handleGetGameNftContracts(guildId);
      dispatch.guilds.handleGetGuildSocialLinks(null);
      handleGetStats();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <GuildDetailsView style={{ overflow: 'hidden' }}>
        <div className='cover-image'>
          <img src={assets.guildCover} alt='' />
        </div>
        <div className='row justify-space-between guild-stats'>
          <div className='logo-container'>
            <div className='logo'>
              <img
                src={currentGuild?.logo}
                style={{ borderRadius: '50%' }}
                alt=''
              />
            </div>
            <div className='icon'>
              <p className='font-size-30 text-primary text-bold capitalize guild-name'>
                {currentGuild?.name}
              </p>
            </div>
          </div>
          <Stats>
            <div className='first'>
              <p className='font-size-40 text-primary text-bold text-center'>
                {loading ? (
                  <ClipLoader size={15} color={theme.color.text.primary} />
                ) : (
                  stats?.totalScholars.total
                )}
              </p>
              <p className='font-size-12 text-secondary'>Scholars</p>
            </div>
            <div className='divider'></div>
            <div className='second'>
              <p className='font-size-40 text-primary text-bold text-center'>
                {loading ? (
                  <ClipLoader size={15} color={theme.color.text.primary} />
                ) : (
                  stats?.totalCaptains.total
                )}
              </p>
              <p className='font-size-12 text-secondary'>Captains</p>
            </div>
            <div className='divider'></div>
            <div className='third'>
              <p className='font-size-40 text-primary text-bold text-center'>
                {' '}
                {loading ? (
                  <ClipLoader size={15} color={theme.color.text.primary} />
                ) : (
                  stats?.totalMasters.total
                )}
              </p>
              <p className='font-size-12 text-secondary'>Game Masters</p>
            </div>
          </Stats>

          {permissions?.permissions[features.addSocialMediaLinks] ===
          permissionEnum.fullPermission ? (
            <WithPermissions
              moduleName={modulesEnum.Guilds}
              featureName={features.addSocialMediaLinks}
            >
              <AddSocialMediaButton onClick={handleAddSocialLink}>
                <p className='font-size-18 text-primary text-semibold clickable'>
                  + Add Social media Links
                </p>
              </AddSocialMediaButton>
            </WithPermissions>
          ) : (
            <>
              {guildSocialLinks.length > 0 ? (
                <SocialLinks>
                  {guildSocialLinks.map(social => (
                    <a
                      href={social.url}
                      target='_blank'
                      rel='noreferrer'
                      key={social.id}
                    >
                      <div className='link'>
                        <RenderIcon
                          name={social.logo}
                          fill={theme.color.background.button.primary.color}
                        />
                      </div>
                    </a>
                  ))}
                </SocialLinks>
              ) : (
                <div />
              )}
            </>
          )}
        </div>

        {!(role?.name === rolesEnum.Scholar) && (
          <DropdownContainer className='row flex-end'>
            <Select
              containerClassName='dropdown'
              label='Select an option'
              options={[
                { label: 'Guild Details', value: 'Guild Details' },
                { label: 'Guild Vault', value: 'Guild Vault' },
              ]}
              value={filters.view}
              onChange={handleFilters}
              name='view'
            />
            <WithPermissions
              moduleName={modulesEnum.Guilds}
              featureName={features.updateGuild}
              action
            >
              <ActionButton
                variant='primary'
                className='btn'
                onClick={handleUpdateGuild}
              >
                Update details
              </ActionButton>
            </WithPermissions>
            {filters.view === 'Guild Vault' && (
              <WithPermissions
                moduleName={modulesEnum.Guilds}
                featureName={features.transferAssets}
                action
              >
                <ActionButton
                  variant='primary'
                  className='btn btn-primary'
                  onClick={handleTransferMultiple}
                >
                  Transfer multiple assets
                </ActionButton>
              </WithPermissions>
            )}

            <WithPermissions
              moduleName={modulesEnum.Guilds}
              featureName={features.pullbackAssets}
              action
            >
              <ActionButton
                variant='primary'
                className='btn btn-primary'
                onClick={handlePullBack}
              >
                Pull back assets
              </ActionButton>
            </WithPermissions>

            <WithPermissions
              moduleName={modulesEnum.Guilds}
              featureName={features.createInviteLink}
              action
            >
              <ActionButton
                variant='primary'
                onClick={handleCreateInvite}
                className='btn'
              >
                Create Invite link
              </ActionButton>
            </WithPermissions>
          </DropdownContainer>
        )}

        <TabPanel
          index={0}
          activeIndex={filters.view === 'Guild Details' ? 0 : 1}
        >
          <GuildDetails />
        </TabPanel>
        <TabPanel
          index={1}
          activeIndex={filters.view === 'Guild Vault' ? 1 : 2}
        >
          <GuildVault
            transferMultiple={transferMultiple}
            setTransferMultiple={setTransferMultiple}
          />
        </TabPanel>
      </GuildDetailsView>
      <GuildGamesAndTeams />
      {openLinksDialog && (
        <AddSocialMediaLinks
          open={openLinksDialog}
          setOpen={setOpenLinksDialog}
        />
      )}
      {openDialog && <AddNewGuild open={openDialog} setOpen={setOpenDialog} />}
      {invite && <GuildInvite open={invite} setOpen={setInvite} />}
      {pullback && <MangeAssetsAll open={pullback} setOpen={setPullback} />}
    </Container>
  );
};

export default GuildPage;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;

  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    gap: 30px;
  }
`;

const GuildDetailsView = styled.div`
  flex-grow: 1;
  transition: width 0.6s ease;
  & .cover-image {
    height: 180px;
    border-radius: 10px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  & .guild-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 15ch;
  }

  & .logo-container {
    display: flex;
    align-items: center;
    gap: 20px;

    & .logo {
      background-color: ${({ theme }) => theme.color.background.row};
      height: 180px;
      width: 180px;
      border-radius: 50%;
      padding: 16px;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .icon {
      height: 40px;
      padding-inline: 18px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${({ theme }) => theme.color.background.card};
    }
  }

  & .guild-stats {
    margin-top: -90px;
    padding-inline: 20px;
  }
  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    & .cover-image {
      height: 200px;
    }

    & .logo-container {
      gap: 40px;

      & .logo {
        height: 211px;
        width: 211px;
        padding: 16px;
      }
      & .icon {
        height: 51px;
        padding-inline: 18px;
      }
    }

    & .guild-stats {
      margin-top: -108px;
      padding-inline: 40px;
    }
  }
`;

const Stats = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 10px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  width: 320px;
  justify-content: space-between;

  & .divider {
    width: 1px;
    height: 47px;
    background-color: ${({ theme }) => theme.color.text.secondary};
  }
  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    width: 379px;
    padding: 12px 24px;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 10px;

  & .link {
    height: 28px;
    width: 28px;
    background-color: ${({ theme }) =>
      theme.color.background.button.primary.bg};
    border-radius: 4px;
    display: grid;
    place-items: center;
  }

  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    gap: 16px;
    padding: 15px;
    & .link {
      height: 37px;
      width: 37px;
    }
  }
`;

const DropdownContainer = styled.div`
  margin-top: -50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 19px;
  & .dropdown {
    width: 143px;
    height: 40px;
    & .icon {
      background-color: ${({ theme }) => theme.color.background.card};
      border-radius: 8px;
    }
    & select {
      background-color: ${({ theme }) => theme.color.background.card};
      border-radius: 8px;
    }
  }

  & .btn {
    background-color: ${({ theme }) =>
      theme.color.background.button.primary.bg};
    color: ${({ theme }) => theme.color.background.button.primary.color};

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.card};
      color: ${({ theme }) => theme.color.text.primary};
    }
  }
`;

const AddSocialMediaButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 10px;
  padding: 22px 40px;
  cursor: pointer;
`;
