import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  active: boolean;
  [x: string]: any;
}

const TournamentIcon: FC<IProps> = ({ active, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M6.59571 0.0127422H21.0167C21.0427 0.0707422 21.0697 0.102742 21.0687 0.134742C21.1485 2.34553 21.0907 4.55913 20.8957 6.76274C20.6453 8.63587 19.8831 10.4036 18.6929 11.8715C17.5027 13.3394 15.9306 14.4506 14.1497 15.0827C13.9195 15.1526 13.6721 15.1406 13.4497 15.0487C11.429 14.2652 9.69281 12.8884 8.46945 11.0994C7.24608 9.31041 6.59287 7.19302 6.59571 5.02574C6.59571 3.36674 6.59571 1.70874 6.59571 0.0107422'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
      <path
        d='M12.2358 19.4243V16.2583C12.6942 16.6143 13.2591 16.8058 13.8395 16.802C14.4198 16.7981 14.9821 16.5993 15.4358 16.2373V19.3873H19.9838V25.8223H21.5758V27.4223H6.09082V25.8373H7.65682V19.4263L12.2358 19.4243ZM11.4748 23.6663H16.2068V22.1083H11.4738L11.4748 23.6663Z'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
      <path
        d='M0.197089 0H4.94809V1.587H1.75509C1.8461 3.33229 2.31164 5.03756 3.12009 6.587C3.83104 7.9745 4.79764 9.21525 5.96909 10.244C6.0944 10.3443 6.20632 10.4602 6.30209 10.589C6.99209 11.625 7.67309 12.667 8.30209 13.617C3.99709 12.616 -1.06691 4.242 0.197089 0Z'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
      <path
        d='M25.939 1.60063H22.734V0.015625H27.496C27.6804 2.87087 26.9848 5.71399 25.503 8.16157C24.0211 10.6092 21.8244 12.5435 19.209 13.7036C19.6588 13.1917 20.0772 12.6531 20.462 12.0906C21.0932 11.0814 21.8307 10.1427 22.662 9.29062C24.6264 7.19478 25.7883 4.47299 25.943 1.60462'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
    </svg>
  );
};

export default TournamentIcon;
