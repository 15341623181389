import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../store';

const useNotifications = () => {
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector((state: RootState) => state.auth.user);
  const isNotificationPanelOpen = useSelector(
    (state: RootState) => state.notifications.isOpen
  );

  useEffect(() => {
    if (user) {
      dispatch.notifications.handleGetAllNotifications(null);
    }
    // eslint-disable-next-line
  }, [user, isNotificationPanelOpen]);
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch.notifications.handleGetAllNotifications(null);
    }, 300000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);
};

export default useNotifications;
