import React, { FC, useState } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

import ActionButton from '../../../components/ActionButton/ActionButton';
import SearchBar from '../../../components/SearchBar/SearchBar';
import ListIcon from '../../../assets/icons/ListIcon';
import GridIcon from '../../../assets/icons/GridIcon';

import UsersListView from './UsersListView';
import UsersGridView from './UsersGridView';

import { lightTheme, darkTheme } from '../../../theme';

const Users: FC = () => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const theme = activeTheme === 'light' ? lightTheme : darkTheme;
  const [isListView, setIsListView] = useState(true);
  const [search, setSearch] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  return (
    <Container>
      <div className='row justify-space-between'>
        <p className='font-size-18 text-primary text-semibold'>Users</p>
        <div className='row' style={{ gap: '10px' }}>
          <ActionButton
            variant='primary'
            onClick={() => setIsListView(true)}
            style={{
              background: isListView
                ? theme.color.background.row
                : theme.color.background.row + '66',
              padding: '0px',
              width: '40px',
              height: '35px',
              borderRadius: '4px',
            }}
          >
            <ListIcon active={isListView} style={{ marginTop: '5px' }} />
          </ActionButton>
          <ActionButton
            variant='primary'
            onClick={() => setIsListView(false)}
            style={{
              background: isListView
                ? theme.color.background.row + '66'
                : theme.color.background.row,
              padding: '0px',
              width: '40px',
              height: '35px',
              borderRadius: '4px',
            }}
          >
            <GridIcon active={!isListView} style={{ marginTop: '5px' }} />
          </ActionButton>

          <SearchBar value={search} onChange={handleSearch} name='search' />
        </div>
      </div>
      {isListView ? (
        <UsersListView search={search} />
      ) : (
        <UsersGridView search={search} />
      )}
    </Container>
  );
};

export default Users;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  padding: 20px;
`;
