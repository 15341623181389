import React, { FC, useState, useEffect } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

import styled from 'styled-components';
import { getPayoutUsers } from '../../../api';
import SearchBar from '../../../components/SearchBar/SearchBar';
import Select from '../../../components/Select/Select';
import { IPayoutUser, ITournament } from '../../../types/interface';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../../hooks/useTheme';

interface IProps {
  tournament: ITournament;
}

const TeamsTable: FC<IProps> = ({ tournament }) => {
  const { theme } = useTheme();

  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  const [filters, setFilters] = useState({
    game: '',
    badge: '',
    role: '',
    search: '',
  });

  const [users, setUsers] = useState<IPayoutUser[]>([]);
  const [loading, setLoading] = useState(false);

  const handleFilters = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  // TODO: change these users with tournament participants
  const handleGetGuildUsers = async () => {
    try {
      setLoading(true);
      const query = `guildId=${currentGuild?.id}&gameId=${tournament.game_id}`;
      const { data } = await getPayoutUsers(query);
      setUsers(data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetGuildUsers();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <FiltersRow>
        <p className='font-size-20 text-primary text-bold'>Team</p>
        <div className='row filters'>
          <Select
            label='Game'
            value={filters.game}
            name='game'
            onChange={handleFilters}
            options={[{ label: '', value: '' }]}
          />
          <Select
            label='Badge'
            value={filters.badge}
            name='badge'
            onChange={handleFilters}
            options={[{ label: '', value: '' }]}
          />
          <Select
            label='Role'
            value={filters.role}
            name='role'
            onChange={handleFilters}
            options={[{ label: '', value: '' }]}
          />
          <SearchBar
            value={filters.search}
            onChange={handleFilters}
            name='search'
          />
        </div>
      </FiltersRow>
      <Divider />
      {loading ? (
        <div className='row justify-center' style={{ height: '300px' }}>
          <ClipLoader size={50} color={theme.color.text.primary} />
        </div>
      ) : (
        <TableContainer>
          {!users.length ? (
            <p className='font-size-14 text-primary'>No user found</p>
          ) : (
            <table>
              <thead>
                <tr className='header-row'>
                  <th className='font-size-12 text-primary text-semibold'>
                    Names
                  </th>
                  <th className='font-size-12 text-primary text-semibold'>
                    Badge
                  </th>
                  <th className='font-size-12 text-primary text-semibold'>
                    Matches played
                  </th>
                  <th className='font-size-12 text-primary text-semibold'>
                    Earningns
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, i) => (
                  <React.Fragment key={user.id}>
                    <tr style={{ height: '5px' }} />
                    <tr className={classNames([0, 1, 2].includes(i) && 'top')}>
                      <td className='font-size-12 text-primary text-semibold'>
                        {user.username}
                      </td>
                      <td className='font-size-12 text-primary text-semibold'>
                        Alfa Junkie
                      </td>
                      <td className='font-size-12 text-primary text-semibold'>
                        Alfa Junkie
                      </td>
                      <td className='font-size-12 text-primary text-semibold'>
                        Alfa Junkie
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          )}
        </TableContainer>
      )}
    </Container>
  );
};

export default TeamsTable;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 15px 20px;
  border-radius: 15px;
  margin-top: 16px;
`;

const FiltersRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .filters {
    gap: 10px;
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.background.row};
  margin-block: 15px;
`;

const TableContainer = styled.div`
  margin-top: 13px;

  & table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    & tr {
      height: 50px;
      &.header-row {
        height: 40px;
        border: none;
        & th {
          text-align: left;
          background-color: ${({ theme }) => theme.color.background.headerRow};
          &:first-of-type {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding-left: 21px;
          }
          &:last-of-type {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-right: 12px;
          }
        }
      }
      & td {
        background-color: ${({ theme }) => theme.color.background.row};
        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          padding-left: 21px;
        }
        &:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          padding-right: 12px;
        }
      }

      &.top {
        & td {
          position: relative;
          &:first-of-type {
            &::before {
              content: '';
              left: 0;
              top: 0;
              height: 100%;
              width: 7px;
              background-color: ${({ theme }) => theme.color.text.primary};
              position: absolute;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
          }
        }
      }
    }
  }
`;
