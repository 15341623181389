import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const LinkIcon: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M17.0575 4.74157L14.2585 1.94261C13.9494 1.63344 13.4481 1.63344 13.1389 1.94261L9.22039 5.86116C8.91122 6.17032 8.91122 6.67158 9.22039 6.98074L12.0194 9.77971C12.3285 10.0889 12.8298 10.0889 13.1389 9.77971L17.0575 5.86116C17.3667 5.55199 17.3667 5.05073 17.0575 4.74157Z'
        stroke={fill || theme.color.background.button.primary.color}
        stroke-width='2'
        stroke-linejoin='round'
      />
      <path
        d='M9.78014 12.0189L6.98118 9.21995C6.67201 8.91078 6.17076 8.91078 5.86159 9.21995L1.94304 13.1385C1.63388 13.4477 1.63388 13.9489 1.94304 14.2581L4.74201 17.057C5.05117 17.3662 5.55243 17.3662 5.86159 17.057L9.78014 13.1385C10.0893 12.8293 10.0893 12.3281 9.78014 12.0189Z'
        stroke={fill || theme.color.background.button.primary.color}
        stroke-width='2'
        stroke-linejoin='round'
      />
      <path
        d='M12.2996 6.70117L6.70166 12.2991'
        stroke={fill || theme.color.background.button.primary.color}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export default LinkIcon;
