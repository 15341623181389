import React from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import GameCard from '../GameCard/GameCard';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const GameSlider = () => {
  const games = useSelector((state: RootState) => state.games.games);
  return (
    <Container>
      <Splide
        options={{
          width: '100%',
          perMove: 1,
          perPage: 4,
          gap: '30px',
          breakpoints: {
            1280: {
              gap: '20px',
            },
          },
        }}
      >
        {games.map(game => (
          <SplideSlide key={game.id}>
            <GameCard game={game} />
          </SplideSlide>
        ))}
      </Splide>
    </Container>
  );
};

export default GameSlider;

const Container = styled.div``;
