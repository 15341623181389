import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  active: boolean;
  [x: string]: any;
}

const GridIcon: FC<IProps> = ({ active, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='22'
      height='21'
      viewBox='0 0 22 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M1.83587 0.238281H8.96187C9.23186 0.238281 9.49079 0.345535 9.68171 0.536447C9.87262 0.727359 9.97987 0.986291 9.97987 1.25628V8.38228C9.97987 8.65227 9.87262 8.9112 9.68171 9.10212C9.49079 9.29303 9.23186 9.40028 8.96187 9.40028H1.83587C1.56605 9.40028 1.30727 9.29317 1.11639 9.10247C0.925506 8.91177 0.818136 8.6531 0.817871 8.38328V1.25628C0.817871 0.986291 0.925124 0.727359 1.11604 0.536447C1.30695 0.345535 1.56588 0.238281 1.83587 0.238281ZM7.94387 2.27428H2.85387V7.36428H7.94387V2.27428Z'
        fill={
          active ? theme.color.text.primary : theme.color.text.primary + '99'
        }
      />
      <path
        d='M13.0351 0.238281H20.1611C20.4311 0.238281 20.69 0.345535 20.8809 0.536447C21.0718 0.727359 21.1791 0.986291 21.1791 1.25628V8.38228C21.1791 8.65227 21.0718 8.9112 20.8809 9.10212C20.69 9.29303 20.4311 9.40028 20.1611 9.40028H13.0351C12.7653 9.40028 12.5065 9.29317 12.3156 9.10247C12.1247 8.91177 12.0174 8.6531 12.0171 8.38328V1.25628C12.0171 0.986291 12.1243 0.727359 12.3153 0.536447C12.5062 0.345535 12.7651 0.238281 13.0351 0.238281ZM19.1431 2.27428H14.0531V7.36428H19.1431V2.27428Z'
        fill={
          active ? theme.color.text.primary : theme.color.text.primary + '99'
        }
      />
      <path
        d='M13.0351 11.4375H20.1611C20.4311 11.4375 20.69 11.5448 20.8809 11.7357C21.0718 11.9266 21.1791 12.1855 21.1791 12.4555V19.5815C21.1791 19.8515 21.0718 20.1104 20.8809 20.3013C20.69 20.4922 20.4311 20.5995 20.1611 20.5995H13.0351C12.7653 20.5995 12.5065 20.4924 12.3156 20.3017C12.1247 20.111 12.0174 19.8523 12.0171 19.5825V12.4555C12.0171 12.1855 12.1243 11.9266 12.3153 11.7357C12.5062 11.5448 12.7651 11.4375 13.0351 11.4375ZM19.1431 13.4735H14.0531V18.5635H19.1431V13.4735Z'
        fill={
          active ? theme.color.text.primary : theme.color.text.primary + '99'
        }
      />
      <path
        d='M1.83587 11.4375H8.96187C9.23186 11.4375 9.49079 11.5448 9.68171 11.7357C9.87262 11.9266 9.97987 12.1855 9.97987 12.4555V19.5815C9.97987 19.8515 9.87262 20.1104 9.68171 20.3013C9.49079 20.4922 9.23186 20.5995 8.96187 20.5995H1.83587C1.56605 20.5995 1.30727 20.4924 1.11639 20.3017C0.925506 20.111 0.818136 19.8523 0.817871 19.5825V12.4555C0.817871 12.1855 0.925124 11.9266 1.11604 11.7357C1.30695 11.5448 1.56588 11.4375 1.83587 11.4375ZM7.94387 13.4735H2.85387V18.5635H7.94387V13.4735Z'
        fill={
          active ? theme.color.text.primary : theme.color.text.primary + '99'
        }
      />
    </svg>
  );
};

export default GridIcon;
