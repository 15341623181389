import React, { FC } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import CrossIcon from '../../assets/icons/CrossIcon';
import LinkIcon from '../../assets/icons/LinkIcon';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';
import { copyAddress } from '../../utils';
import { url } from '../../app.config';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const GuildInvite: FC<IProps> = ({ open, setOpen }) => {
  const guild = useSelector((state: RootState) => state.guilds.currentGuild);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-bole text-primary'>
            Create invite link
          </p>
          <div className='icon clicable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <p className='font-size-16 text-primary subtitle'>
          Create the invite link to onboard users to your Guild.
        </p>
        <CopyLinkBar>
          <input
            type='text'
            value={`${url}/register?inviteId=${guild!.id}`}
            readOnly
            name=''
            id=''
          />
          <div
            className='copy-btn clickable'
            onClick={() => copyAddress(`${url}/register?inviteId=${guild!.id}`)}
          >
            <LinkIcon />
            <p className='font-size-16 text primary text-semibold'>Copy</p>
          </div>
        </CopyLinkBar>

        <div className='border'></div>

        <EmailInput>
          <label htmlFor='' className='font-size-16 text-primary text-bold'>
            Email address
          </label>
          <div className='input-contianer'>
            <input
              placeholder='Type email address'
              type='email'
              name=''
              id=''
            />
            <button className='font-size-16 text-semibold'>Invite</button>
          </div>
        </EmailInput>
      </Container>
    </DialogLayout>
  );
};

export default GuildInvite;

const Container = styled.div`
  width: 560px;
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  padding: 30px;

  & .subtitle {
    margin-top: 12px;
  }

  & .border {
    border-top: 1px solid ${({ theme }) => theme.color.text.primary};
    margin-block: 30px 20px;
  }
`;

const CopyLinkBar = styled.div`
  display: flex;
  height: 50px;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  margin-top: 29px;
  border-radius: 8px;
  overflow: hidden;

  & input {
    flex-grow: 1;
    height: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    padding-inline: 15px;
  }

  & .copy-btn {
    display: flex;
    justify-content: center;
    gap: 7px;
    align-items: center;
    padding-inline: 24px;
    background-color: ${({ theme }) =>
      theme.color.background.button.primary.bg};
    color: ${({ theme }) => theme.color.background.button.primary.color};
  }
`;

const EmailInput = styled.div`
  & .input-contianer {
    margin-top: 12px;
    background-color: ${({ theme }) => theme.color.background.headerRow};
    height: 50px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    & input {
      flex-grow: 1;
      height: 100%;
      padding: 0px 12px;
      outline: none;
      background-color: transparent;
      border: none;
    }

    & button {
      background-color: #0960b2;
      height: 100%;
      padding-inline: 35px;
      border: none;
      color: #ffffff;
    }
  }
`;
