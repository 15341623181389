import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { disconnectRoninWallet, getUserNfts } from '../../api';
import CrossIcon from '../../assets/icons/CrossIcon';
import Info from '../../assets/icons/Info';
import ActionButton from '../../components/ActionButton/ActionButton';
import NftImage from '../../components/NftImage/NftImage';
import useTheme from '../../hooks/useTheme';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';
import { lightTheme as theme } from '../../theme';
import { INft, ISingleUser } from '../../types/interface';

interface IProps {
  user: ISingleUser;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  game: string;
}

const AxieDetails: FC<IProps> = ({ open, setOpen, game, user }) => {
  const { theme } = useTheme();
  const dispatch = useDispatch<Dispatch>();
  const [nfts, setNfts] = useState<INft[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [dLoading, setDLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDisconnect = async () => {
    try {
      setDLoading(true);
      await disconnectRoninWallet({
        userId: user.id,
      });
      toast.success('Ronin wallet disconnected successfully!');
      setOpen(false);
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data.message;
        dispatch.errorModal.setOpen({
          title: 'Error!',
          description: errorMessage,
        });
      }
    } finally {
      setDLoading(false);
    }
  };

  const handleGetNfts = async () => {
    try {
      setLoading(true);
      let nftList: INft[] = [];
      const { data } = await getUserNfts({
        public_address: user.account.starz_wallet_address,
        game_id: game,
        userId: user.id,
      });
      data.forEach(item => {
        nftList = [...nftList, ...item.Nfts];
      });
      setNfts(nftList);
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data.message;
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (game) {
      handleGetNfts();
    }
    // eslint-disable-next-line
  }, [game]);

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>
            Axie Infinity Details
          </p>
          <div className='icon clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <p className='font-size-16 text-primary text-bold subtitle'>Assets</p>
        <div className='assets-container'>
          <div className='assets-container-inner'>
            {loading ? (
              <div
                className='row justify-center'
                style={{ width: '100%', height: '20vh' }}
              >
                <ClipLoader size={20} color={theme.color.text.primary} />
              </div>
            ) : (
              <>
                {nfts?.map(nft => (
                  <Asset>
                    <NftImage nftData={nft} size={120} />
                  </Asset>
                ))}
              </>
            )}
          </div>
        </div>
        {nfts && nfts?.length > 0 && (
          <Warning>
            <div className='row'>
              <Info />
              <p className='font-size-14 text-primary'>
                Please first pull back all the assets from ronin wallet.
              </p>
            </div>
          </Warning>
        )}

        <ButtonContainer>
          <ActionButton
            onClick={handleClose}
            variant='primary'
            className='action-btn btn-primary'
            disabled={dLoading}
          >
            Cancel
          </ActionButton>

          <ActionButton
            onClick={handleDisconnect}
            variant='primary'
            className='action-btn btn-secondary'
            disabled={dLoading || !!nfts?.length}
          >
            Disconnect account
          </ActionButton>
        </ButtonContainer>
      </Container>
    </DialogLayout>
  );
};

export default AxieDetails;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  height: 60vh;
  width: 456px;
  max-height: 648px;

  & .subtitle {
    margin-block: 27px 23px;
  }

  & .assets-container {
    & .assets-container-inner {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
      gap: 17px;
    }
    flex-grow: 1;
    height: 0px;
    overflow: auto;
  }
`;

const Asset = styled.div`
  aspect-ratio: 1/1;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 18px;
  margin-top: 20px;

  & .action-btn {
    border-radius: 8px;
    height: 40px;
    width: 100%;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .action-btn {
      border-radius: 8px;
      height: 55px;
      width: 100%;
    }
  }
`;

const Warning = styled.div`
  border: 2px solid ${({ theme }) => theme.color.text.secondary};
  padding: 5px 10px;
  border-radius: 4px;

  & .row {
    gap: 10px;
  }

  & svg {
    width: 30px;
  }
`;
