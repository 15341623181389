import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

import Select from '../../../../../components/Select/Select';
import ActionButton from '../../../../../components/ActionButton/ActionButton';
import DefaultRevenueSplit from '../../../../../Dialogs/DefaultRevenueSplit/DefaultRevenueSplit';
import { getDefaultRevenueSplit } from '../../../../../api';
import { ClipLoader } from 'react-spinners';

interface IProps {
  isPanelOpen: boolean;
}

const RevenueSplit: FC<IProps> = ({ isPanelOpen }) => {
  const guild = useSelector((state: RootState) => state.guilds.currentGuild);

  const [game, setGame] = useState(guild?.Games[0].id || '');
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [splits, setSplits] = useState({
    admin: 0,
    guildManager: 0,
    gameMaster: 0,
    captain: 0,
    scholar: 0,
  });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setGame(e.currentTarget.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleGetDefaultSplit = async () => {
    try {
      setLoading(true);
      const { data } = await getDefaultRevenueSplit({
        gameId: game,
        guildId: guild!.id,
      });
      setSplits({
        admin: data?.admin_revenue_split,
        guildManager: data?.guild_revenue_split,
        gameMaster: data?.game_master,
        captain: data?.captain,
        scholar: data?.scholar,
      });
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (game) {
      handleGetDefaultSplit();
    }
    // eslint-disable-next-line
  }, [game]);

  return (
    <>
      {isPanelOpen ? (
        <RevenueSplitBox>
          <div className='row justify-space-between'>
            <p className='font-size-20 text-primary text-semibold'>
              Revenue Split
            </p>
            <div className='row actions'>
              <Select
                containerClassName='select'
                label='Game'
                value={game}
                onChange={handleChange}
                disabled
                options={
                  guild?.Games.map(game => ({
                    label: game.name,
                    value: game.id,
                  })) || []
                }
              />
              <ActionButton
                onClick={handleOpen}
                variant='primary'
                className='btn'
              >
                Edit
              </ActionButton>
            </div>
          </div>
          <div className='row stats justify-space-between'>
            <p className='font-size-18 text-primary text-semibold'>
              Game Master
            </p>
            <p className='font-size-18 text-primary text-semibold'>
              {loading ? <ClipLoader size={10} /> : `${splits.gameMaster}%`}
            </p>
          </div>
          <div className='row stats justify-space-between'>
            <p className='font-size-18 text-primary text-semibold'>Captain</p>
            <p className='font-size-18 text-primary text-semibold'>
              {loading ? <ClipLoader size={10} /> : `${splits.captain}%`}
            </p>
          </div>
          <div className='row stats justify-space-between'>
            <p className='font-size-18 text-primary text-semibold'>Scholar</p>
            <p className='font-size-18 text-primary text-semibold'>
              {loading ? <ClipLoader size={10} /> : `${splits.scholar}%`}
            </p>
          </div>
        </RevenueSplitBox>
      ) : (
        <RevenueBoxSmall>
          <p className='font-size-12 text-primary text-center'>Revenue Split</p>
          <div className='divider'></div>

          <div className='col'>
            <div className='col-inner'>
              <div className='key'>
                <p className='font-size-10 text-semibold'>GM</p>
              </div>
              <p className='font-size-14 text-semibold text-primary'>30%</p>
            </div>
            <div className='col-inner'>
              <div className='key'>
                <p className='font-size-10 text-semibold'>C</p>
              </div>
              <p className='font-size-14 text-semibold text-primary'>30%</p>
            </div>
            <div className='col-inner'>
              <div className='key'>
                <p className='font-size-10 text-semibold'>S</p>
              </div>
              <p className='font-size-14 text-semibold text-primary'>40%</p>
            </div>
          </div>
        </RevenueBoxSmall>
      )}
      {open && guild && (
        <DefaultRevenueSplit
          guildId={guild.id}
          gameId={game}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
};

export default RevenueSplit;

const RevenueSplitBox = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  padding: 14px 10px;
  margin-bottom: 20px;

  & .row.actions {
    gap: 6px;

    & .btn {
      height: 35px;
      background-color: ${({ theme }) =>
        theme.color.background.button.primary.bg};
      color: ${({ theme }) => theme.color.background.button.primary.color};
    }
    & .select {
      width: 156px;
    }
  }

  & .row.stats {
    height: 50px;
    background-color: ${({ theme }) => theme.color.background.row};
    border-radius: 4px;
    margin-top: 10px;
    padding-inline: 14px;
  }
`;

const RevenueBoxSmall = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  padding: 14px 7px;
  margin-bottom: 20px;

  & .divider {
    height: 0.5px;
    background-color: ${({ theme }) => theme.color.text.primary};
    margin-block: 18px;
  }

  & .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    & .col-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      & .key {
        height: 20px;
        width: 20px;
        border-radius: 4px;
        background-color: ${({ theme }) =>
          theme.color.background.button.primary.bg};
        color: ${({ theme }) => theme.color.background.button.primary.color};
        display: grid;
        place-items: center;
      }
    }
  }
`;
