import React, { FC } from 'react';

import styled from 'styled-components';
import { IScholarship } from '../../../../../types/interface';

interface IProps {
  scholarship: IScholarship;
}

const ScholarshipRow: FC<IProps> = ({ scholarship }) => {
  return (
    <Container className='row'>
      <div className='row justify-space-between' style={{ width: '100%' }}>
        <div className='row game-name'>
          <img src={scholarship.scholarship_logo} alt='' />
          <p className='font-size-18 text-primary text-semibold'>
            {scholarship.game_name}
          </p>
        </div>
        <div className='row spots'>
          <p className='font-size-16 text-primary text-semibold'>
            {scholarship.spots} Spots left
          </p>
          {/* <div className='icon clickable'>
            <MoreIcon fill={theme.color.text.primary} />
          </div> */}
        </div>
      </div>
    </Container>
  );
};

export default ScholarshipRow;

const Container = styled.div`
  height: 50px;
  background-color: ${({ theme }) => theme.color.background.row};
  padding-inline: 10px;
  border-radius: 4px;
  margin-top: 10px;

  & .game-name {
    gap: 12px;

    & img {
      height: 36px;
      width: 36px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  & .spots {
    gap: 10px;
  }
`;
