import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { string, object, InferType } from 'yup';

import ActionButton from '../../../components/ActionButton/ActionButton';
import InputField from '../../../components/InputField/InputField';
import Select from '../../../components/Select/Select';
import ImagePicker from './ImagePicker';

import { lightTheme as theme } from '../../../theme';
import { FormErrors, validateData } from '../../../utils/validations';
import useRole from '../../../hooks/useRole';
import { updateUser, updateUserImage } from '../../../api';
import { useNavigate } from 'react-router-dom';
import useCountries from '../../../hooks/useCountries';

const schema = object({
  username: string().required().label('First name'),
  last_name: string().optional().label('Last name'),
  email: string().email().required().label('Email'),
  wallet_address: string().required().label('Wallet address'),
  country: string().optional().label('Country'),
});

interface IFormData extends InferType<typeof schema> {}

const Profile: FC = () => {
  const countries = useCountries();
  const { role } = useRole();
  const user = useSelector((state: RootState) => state.auth.user);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: user?.username || '',
    last_name: user?.last_name || '',
    email: user?.email || '',
    wallet_address: user?.wallet_address || '',
    country: user?.country || '',
  });
  const [image, setImage] = useState<File | null | string>(null);
  const [errors, setErrors] = useState<FormErrors<IFormData | null>>(null);

  const [loading, setLoading] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;

    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleDiscard = () => {
    navigate(-1);
  };

  const handleUpdateUser = async () => {
    const { errors: validationErrors } = await validateData<IFormData>(
      schema,
      formData
    );
    if (validationErrors) {
      return setErrors(validationErrors);
    }
    try {
      setLoading(true);
      const { email, ...rest } = formData;
      await updateUser(user!.id, rest);
      if (typeof image !== 'string' && image) {
        const imageData = new FormData();
        // @ts-ignore
        imageData.append('logo', image);
        await updateUserImage(user!.id, imageData);
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setFormData({
        username: user?.username || '',
        last_name: user?.last_name || '',
        email: user?.email || '',
        wallet_address: user?.wallet_address || '',
        country: user?.country || '',
      });
      setImage(user.image_url);
    }
  }, [user]);

  return (
    <ProfileSettings>
      <p className='font-size-20 text-primary text-light'>Account Settings</p>
      <div className='divider'></div>
      <div className='wrapper'>
        <div className='form-container'>
          <div>
            <p className='font-size-22 text-primary text-semibold'>
              Personal Information
            </p>
            <p
              className='font-size-16 text-secondary'
              style={{ marginTop: '6px' }}
            >
              Update your photo and personal details here.
            </p>
          </div>

          <div className='row justify-space-between'>
            <p className='font-size-18 text-secondary text-semibold'>Name</p>
            <div className='input-container grid'>
              <InputField
                placeholder='Jack'
                type='text'
                onChange={handleChange}
                value={formData.username}
                name='username'
                error={errors?.username}
              />
              <InputField
                placeholder='Jack'
                type='text'
                onChange={handleChange}
                value={formData.last_name}
                name='last_name'
                error={errors?.last_name}
              />
            </div>
          </div>
          <div className='row justify-space-between'>
            <p className='font-size-18 text-secondary text-semibold'>
              Email address
            </p>
            <div className='input-container'>
              <InputField
                placeholder='Jack@gmail.com'
                type='email'
                readOnly
                onChange={handleChange}
                value={formData.email}
                name='email'
                error={errors?.email}
              />
            </div>
          </div>
          <div className='row justify-space-between'>
            <p className='font-size-18 text-secondary text-semibold'>
              Wallet address
            </p>
            <div className='input-container'>
              <InputField
                placeholder='0xe19ca478a0aa0670ee4c80d856ac10adf8caf449'
                type='text'
                onChange={handleChange}
                value={formData.wallet_address}
                name='wallet_address'
                error={errors?.wallet_address}
              />
            </div>
          </div>
          <div className='row justify-space-between'>
            <div className='left'>
              <p className='font-size-18 text-secondary text-semibold'>Role</p>
            </div>
            <div className='input-container'>
              <p className='font-size-16 text-primary capitalize'>
                {role?.name}
              </p>
            </div>
          </div>
          <div className='row justify-space-between'>
            <p className='font-size-18 text-secondary text-semibold'>Role</p>
            <div className='input-container'>
              <ImagePicker file={image} setFile={setImage} />
            </div>
          </div>
          <div className='row justify-space-between'>
            <p className='font-size-18 text-secondary text-semibold'>Country</p>
            <div className='input-container'>
              <Select
                containerClassName='select'
                value={formData.country}
                name='country'
                onChange={handleChange}
                options={countries.map(country => ({
                  label: country,
                  value: country,
                }))}
                label='Select country'
                error={errors?.country}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='divider last'></div>
      <ButtonContainer>
        <ActionButton
          disabled={loading}
          variant='outlined'
          style={{ border: 'none' }}
          onClick={handleDiscard}
        >
          Discard Changes
        </ActionButton>
        <ActionButton
          onClick={handleUpdateUser}
          variant='primary'
          className='btn'
          loading={loading}
          disabled={loading}
        >
          Save Changes
        </ActionButton>
      </ButtonContainer>
    </ProfileSettings>
  );
};

export default Profile;

const ProfileSettings = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & .wrapper {
    flex-grow: 1;
    height: 0px;
    overflow-y: auto;
    padding-bottom: 20px;
  }
  & .divider {
    border-top: 1px solid ${({ theme }) => theme.color.background.main};
    margin-block: 31px 19px;

    &.last {
      margin-block: 0px 20px;
    }
  }

  & .form-container {
    width: 650px;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 36px;

    & .row {
      & .input-container {
        width: 421px;

        & .select {
          width: 100%;
          height: 55px;
          & select {
            border-radius: 8px;
          }
          & .icon {
            border-radius: 8px;
          }
        }
      }
    }

    & .grid {
      display: grid;
      gap: 16px;
      grid-template-columns: 1fr 1fr;
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .form-container {
      width: 736px;
      padding-left: 30px;
      display: flex;
      flex-direction: column;
      gap: 46px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;

  & .btn {
    height: 50px;
    border-radius: 30px;
    background-color: ${({ theme }) => theme.color.background.headerRow};
  }
`;
