import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../../store';
import { useLocation } from 'react-router-dom';

import CopyIcon from '../../../../assets/icons/CopyIcon';
import { darkTheme, lightTheme } from '../../../../theme';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import {
  IScholarshipNotificationRedirectState,
  IScholarshipUser,
} from '../../../../types/interface';
import WithPermissions from '../../../../components/WithPermissions/WithPermissions';
import { features, modulesEnum } from '../../../../types/enums';
import classNames from 'classnames';
import axios from 'axios';
import { toast } from 'react-toastify';
import { sendAxieCredentials } from '../../../../api';

const ScholarshipsTable: FC = () => {
  const state: IScholarshipNotificationRedirectState = useLocation().state;
  const dispatch = useDispatch<Dispatch>();
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const scholarshipUsers = useSelector(
    (state: RootState) => state.scholarships.scholarshipUsers
  );

  const loading = useSelector((state: RootState) => state.scholarships.loading);

  const selectedUser = useSelector(
    (state: RootState) => state.scholarships.selectedScholarshipUser
  );

  const scholarship = useSelector(
    (state: RootState) => state.scholarships.selectedScholarshipUser
  );

  const activeScholarShip = useSelector(
    (state: RootState) => state.scholarships.selected
  );

  const invertedTheme = activeTheme === 'light' ? darkTheme : lightTheme;

  const [rLoading, setRLoading] = useState(false);

  const handleRowClick = (user: IScholarshipUser) => {
    dispatch.scholarships.setSelectedScholarshipUser(user);
  };

  const handleUpdateStatus = (status: 1 | 2) => {
    dispatch.scholarships.handleUpdateScholarshipStatus({
      data: { status },
      scholarshipId: scholarship!.id,
    });
  };

  const renderScholarshipStatus = (status: number) => {
    if (status === 0) {
      return 'Pending';
    } else if (status === 1) {
      return 'Accepted';
    } else {
      return 'Rejected';
    }
  };

  const handleSendRoninCredentials = async () => {
    try {
      setRLoading(true);
      if (scholarship) {
        await sendAxieCredentials(scholarship?.user_id);
        toast.success('Ronin wallet credentials send successfully!');
      }
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const message = err.response?.data.message;
        toast.error(message);
      }
    } finally {
      setRLoading(false);
    }
  };

  useEffect(() => {
    if (scholarshipUsers.length && state?.scholarship_id) {
      for (let i = 0; i < scholarshipUsers.length; i++) {
        if (scholarshipUsers[i].id === state.scholarship_id) {
          handleRowClick(scholarshipUsers[i]);
          break;
        }
      }
    }
    // eslint-disable-next-line
  }, [scholarshipUsers]);

  return (
    <Container>
      <div className='table-container'>
        <TableContainer>
          <table>
            <thead>
              <tr className='header-row'>
                <th className='font-size-14 text-primary'>Name</th>
                <th className='font-size-14 text-primary'>Email</th>
                <th className='font-size-14 text-primary'>Status</th>
                <th className='font-size-14 text-primary'>Wallet address</th>
                <th className='font-size-14 text-primary'>Discord handle</th>
              </tr>
            </thead>
            <tbody>
              {scholarshipUsers.map(user => (
                <React.Fragment key={user.id}>
                  <tr style={{ height: '8px' }}></tr>
                  <tr
                    className={classNames(
                      'clickable',
                      selectedUser?.id === user.id && 'active'
                    )}
                    onClick={() => handleRowClick(user)}
                  >
                    <td className='font-size-12 text-primary capitalize'>
                      {user.username}
                    </td>
                    <td className='font-size-12 text-primary'>{user.email}</td>
                    <td className='font-size-12 text-primary'>
                      {renderScholarshipStatus(user.is_accepted)}
                    </td>
                    <td className='font-size-12 text-primary'>
                      {user.wallet_address}
                    </td>
                    <td className='font-size-12 text-primary'>
                      {user.discord}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </div>
      {selectedUser && (
        <Details>
          <div className='header'>
            <p className='font-size-14 text-primary text-semibold'>Details</p>
          </div>
          <div className='wrapper'>
            <IntroBox>
              <p className='font-size-16 text-primary text-bold text-center'>
                {selectedUser.title}
              </p>
              <p className='font-size-12 text-secondary subtitle'>
                {selectedUser.about}
              </p>
            </IntroBox>
            <p className='font-size-14 text-primary text-bold text-center info'>
              Additional Information
            </p>
            <div className='row justify-center'>
              <UserInfo>
                <p className='font-size-25 text-primary text-bold text-center capitalize'>
                  {selectedUser.username} {selectedUser.last_name}
                </p>
                <p className='font-size-12 text-secondary text-center text-semibold email'>
                  {selectedUser.email}
                </p>
                <p className='font-size-12 text-secondary text-center  text-semibold country'>
                  {selectedUser.country}
                </p>
                <div className='row justify-center'>
                  <WalletAddress className='row justify-center'>
                    <p
                      className='font-size-12 text-semibold address'
                      style={{ color: invertedTheme.color.text.primary }}
                    >
                      {selectedUser.wallet_address}
                    </p>
                    <div className='icon'>
                      <CopyIcon fill='#ffffff' />
                    </div>
                  </WalletAddress>
                </div>

                <div className='row justify-center badge-row'>
                  <div className='img'></div>
                  <p className='font-size-12 text-primary text-bold'>
                    Bronze Badge
                  </p>
                </div>
              </UserInfo>
            </div>
          </div>
          <WithPermissions
            moduleName={modulesEnum.Guilds}
            featureName={features.acceptOrRejectScholarship}
            action
          >
            <ButtonContainer>
              {activeScholarShip?.isAxie && scholarship?.is_accepted === 1 && (
                <ActionButton
                  onClick={handleSendRoninCredentials}
                  style={{ width: '100%' }}
                  variant='secondary'
                  disabled={rLoading}
                  loading={rLoading}
                >
                  Send login details
                </ActionButton>
              )}
              {scholarship && scholarship?.is_accepted === 0 ? (
                <>
                  <ActionButton
                    onClick={() => handleUpdateStatus(2)}
                    variant='primary'
                    className='btn primary'
                    disabled={loading}
                    loading={loading}
                  >
                    Reject
                  </ActionButton>
                  <ActionButton
                    onClick={() => handleUpdateStatus(1)}
                    variant='primary'
                    className='btn secondary'
                    disabled={loading}
                    loading={loading}
                  >
                    Confirm Scholarship
                  </ActionButton>
                </>
              ) : null}
            </ButtonContainer>
          </WithPermissions>
        </Details>
      )}
    </Container>
  );
};

export default ScholarshipsTable;

const Container = styled.div`
  height: 100%;
  display: flex;
  & .table-container {
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    position: relative;

    &::before {
      content: '';
      width: 10px;
      height: 65.5px;
      position: absolute;
      top: 0px;
      left: 0px;
      background-color: ${({ theme }) => theme.color.background.headerRow};
      border-bottom: 1px solid ${({ theme }) => theme.color.text.primary};
    }
    &::after {
      content: '';
      width: 10px;
      height: 65.5px;
      position: absolute;
      top: 0px;
      right: 0px;
      background-color: ${({ theme }) => theme.color.background.headerRow};
      border-bottom: 1px solid ${({ theme }) => theme.color.text.primary};
    }
  }
`;

const Details = styled.div`
  height: 100%;
  width: 325px;
  border-left: 1px solid ${({ theme }) => theme.color.text.primary};
  border-right: 1px solid ${({ theme }) => theme.color.text.primary};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.background.row};

  & .wrapper {
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    padding: 10px;

    & .info {
      margin-block: 39px 15px;
    }
  }

  & .header {
    padding: 0px 22px;
    height: 65.5px;
    background-color: ${({ theme }) => theme.color.background.headerRow};
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.color.text.primary};
  }
`;

const IntroBox = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 12px;
  border-radius: 8px;
  & .subtitle {
    margin-top: 10px;
  }
`;

const UserInfo = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 8px;
  padding: 20px 30px;

  & .email {
    margin-block: 7px 11px;
  }

  & .badge-row {
    gap: 5px;

    & .img {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.text.primary};
    }
  }
`;

const WalletAddress = styled.div`
  height: 22px;
  width: 119px;
  background-color: ${({ theme }) => theme.color.text.primary};
  border-radius: 12px;
  margin-block: 14px 11px;

  & .address {
    width: 12ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .icon {
    cursor: pointer;
    & svg {
      width: 10px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  & .btn {
    /* width: calc(50% - 10px); */
    min-width: 110px;

    &.primary {
      background-color: ${({ theme }) => theme.color.background.card};
    }
    &.secondary {
      background-color: ${({ theme }) =>
        theme.color.background.button.primary.bg};
      color: ${({ theme }) => theme.color.background.button.primary.color};
    }
  }
`;

const TableContainer = styled.div`
  flex-grow: 1;
  height: 0;
  overflow: auto;
  margin-inline: 10px;
  & table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    position: relative;

    & tr {
      height: 50px;

      &.header-row {
        height: 65px;
        border: none;

        & th {
          position: sticky;
          top: 0;
          text-align: left;
          background-color: ${({ theme }) => theme.color.background.headerRow};
          border-bottom: 1px solid ${({ theme }) => theme.color.text.primary};

          &:first-of-type {
            /* border-top-left-radius: 4px;
            border-bottom-left-radius: 4px; */
            padding-left: 12px;
          }
          &:last-of-type {
            /* border-top-right-radius: 4px;
            border-bottom-right-radius: 4px; */
            padding-right: 12px;
          }
        }
      }
      & td {
        background-color: ${({ theme }) => theme.color.background.row};
        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          padding-left: 12px;
        }
        &:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          padding-right: 12px;
        }
      }
      &.active {
        background-color: ${({ theme }) =>
          theme.color.background.button.primary.bg + '66'};
        td {
          background-color: ${({ theme }) =>
            theme.color.background.button.primary.bg + '66'};
          color: ${({ theme }) => theme.color.background.button.primary.color};
        }
      }
    }
  }
`;
