import React, { useEffect } from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import PrivateOutlet from './PrivateOutlet/PrivateOutlet';
import RedirectOutlet from './RedirectOutlet/RedirectOutlet';

import DashboardConfig from '../pages/DashboardConfig/DashboardConfig';
import DashbaordLayout from '../layouts/DashbaordLayout/DashbaordLayout';
import LoginLayout from '../layouts/LoginLayout/LoginLayout';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import Login from '../pages/Login/Login';
import Register from '../pages/Register/Register';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import Games from '../pages/Games/Games';
import Guilds from '../pages/Guilds/Guilds';
import Performance from '../pages/Performance/Performance';
import Payouts from '../pages/Payouts/Payouts';
import Roles from '../pages/Roles/Roles';
import GameDetails from '../pages/GameDetails/GameDetails';
import UpdateGame from '../pages/UpdateGame/UpdateGame';
import Settings from '../pages/Settings/Settings';
import GuildPage from '../pages/GuildPage/GuildPage';
import PlayerProfile from '../pages/PlayerProfile/PlayerProfile';
import Tournaments from '../pages/Tournaments/Tournaments';
import TournamentDetails from '../pages/TournamentDetails/TournamentDetails';
import EmailVerification from '../pages/EmailVerification/EmailVerification';

const Index = () => {
  useEffect(() => {
    const theme = localStorage.getItem('theme');

    if (!theme) {
      localStorage.setItem('theme', 'light');
    }
  }, []);
  return (
    <Routes>
      {/* public routes */}

      <Route path='/' element={<RedirectOutlet />}>
        <Route path='' element={<LoginLayout />}>
          <Route path='' element={<Navigate to='/login' replace={true} />} />
          <Route path='login' element={<Login />} />
          <Route path='register' element={<Register />} />
          <Route path='verify-email' element={<EmailVerification />} />
          <Route path='forgot-password' element={<ForgotPassword />} />
          <Route path='reset-password' element={<ResetPassword />} />
        </Route>
      </Route>
      {/* private routes */}
      <Route path='/' element={<PrivateOutlet />}>
        <Route path='dashboard' element={<DashbaordLayout />}>
          <Route path='' element={<DashboardConfig />} />
          <Route path='games-vault' element={<Games />} />
          <Route path='games/details/:id' element={<GameDetails />} />
          <Route path='games/update/:id' element={<UpdateGame />} />
          <Route path='guilds' element={<Guilds />} />
          <Route path='my-guild' element={<GuildPage />} />
          <Route path='guilds/page/:id' element={<GuildPage />} />
          <Route path='performance' element={<Performance />} />
          <Route path='payouts' element={<Payouts />} />
          <Route path='roles' element={<Roles />} />
          <Route path='settings' element={<Settings />} />
          <Route path='player/profile/:id' element={<PlayerProfile />} />
          <Route path='tournaments' element={<Tournaments />} />
          <Route
            path='tournaments/details/:id'
            element={<TournamentDetails />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default Index;
