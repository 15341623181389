import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const TrophyIcon: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='18'
      height='16'
      viewBox='0 0 18 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M13.93 2.14498V0.000976562H3.21498V2.14498H0.000976562V4.28698C0.001415 4.79157 0.120544 5.28898 0.348739 5.73903C0.576935 6.18907 0.907796 6.57913 1.31459 6.87768C1.72138 7.17623 2.1927 7.3749 2.69047 7.45764C3.18823 7.54038 3.69847 7.50487 4.17998 7.35398C4.96634 8.48032 6.15514 9.26176 7.50098 9.53698V12.859H6.42998C5.86162 12.859 5.31654 13.0848 4.91465 13.4866C4.51276 13.8885 4.28698 14.4336 4.28698 15.002H12.859C12.859 14.4336 12.6332 13.8885 12.2313 13.4866C11.8294 13.0848 11.2843 12.859 10.716 12.859H9.64498V9.53798C10.9908 9.26276 12.1796 8.48132 12.966 7.35498C13.4475 7.50587 13.9577 7.54138 14.4555 7.45864C14.9532 7.3759 15.4246 7.17723 15.8314 6.87868C16.2382 6.58013 16.569 6.19007 16.7972 5.74003C17.0254 5.28998 17.1445 4.79257 17.145 4.28798V2.14498H13.93ZM3.21498 6.22898C2.70009 6.22845 2.20644 6.02367 1.84236 5.65959C1.47828 5.29551 1.27351 4.80186 1.27298 4.28698V3.21598H3.21498V4.28698C3.21437 4.93974 3.33333 5.58708 3.56598 6.19698C3.45019 6.21824 3.3327 6.22895 3.21498 6.22898ZM15.873 4.28698C15.8733 4.57185 15.8109 4.8533 15.6902 5.11133C15.5695 5.36937 15.3935 5.59766 15.1746 5.77998C14.9557 5.9623 14.6994 6.09418 14.4238 6.16625C14.1482 6.23832 13.8601 6.24881 13.58 6.19698C13.8126 5.58708 13.9316 4.93974 13.931 4.28698V3.21598H15.873V4.28698Z'
        fill={fill || theme.color.text.primary}
      />
    </svg>
  );
};

export default TrophyIcon;
