import React, { FC } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import { lightTheme } from '../../theme';
import usePermissions from '../../hooks/usePermissions';
import { permissionsAlias } from '../../app.config';
import { IRolePermissions } from '../../types/interface';

interface IProps {
  activePermission: IRolePermissions;
}

const PermissionGroup: FC<IProps> = ({ activePermission }) => {
  const dispatch = useDispatch<Dispatch>();
  const { permissions } = usePermissions();
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const handleUpdatePermission = (
    newPermissionId: string,
    prevActivePermissionId: string
  ) => {
    dispatch.permissions.handleUpdatePermission({
      id: prevActivePermissionId,
      permissionId: newPermissionId,
    });
  };

  return (
    <Container
      style={{
        borderColor:
          activeTheme === 'light'
            ? lightTheme.color.text.primary
            : 'transparent',
      }}
    >
      {permissions.map(permission => (
        <Button
          key={permission.id}
          className={classNames(
            'font-size-10 text-semibold',
            permission.id === activePermission.permission_id && 'active'
          )}
          onClick={() =>
            handleUpdatePermission(permission.id, activePermission.id)
          }
        >
          {permissionsAlias[permission.name]}
        </Button>
      ))}
    </Container>
  );
};

export default PermissionGroup;

const Container = styled.div`
  height: 27px;
  border: 1px solid ${({ theme }) => theme.color.text.primary};
  border-radius: 4px;
  overflow: hidden;
  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    height: 26px;
  }
`;

const Button = styled.button`
  height: 100%;
  border: none;
  padding-inline: 5px;
  cursor: pointer;

  &:nth-last-of-type(2) {
    border-left: 1px solid ${({ theme }) => theme.color.text.primary};
    border-right: 1px solid ${({ theme }) => theme.color.text.primary};
  }

  background-color: ${({ theme }) => theme.color.background.headerRow};
  color: ${({ theme }) => theme.color.text.primary};

  &.active {
    background-color: ${({ theme }) => theme.color.text.primary};
    color: ${({ theme }) => theme.color.background.headerRow};
  }

  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    padding-inline: 7px;
  }
`;
