import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  active: boolean;
  [x: string]: any;
}

const Dashbaord: FC<IProps> = ({ active, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='35'
      height='35'
      viewBox='0 0 35 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M19.3913 10.877C19.3913 8.49303 19.3853 6.10803 19.3913 3.72403C19.3527 3.35911 19.3959 2.99017 19.5176 2.64401C19.6394 2.29784 19.8367 1.98311 20.0952 1.72268C20.3537 1.46225 20.667 1.26264 21.0122 1.13833C21.3575 1.01403 21.7261 0.968153 22.0913 1.00403C25.4693 0.999365 28.8469 0.999365 32.2243 1.00403C32.5896 0.971079 32.9577 1.01929 33.3023 1.14521C33.6468 1.27112 33.9593 1.47164 34.2172 1.73241C34.4752 1.99317 34.6724 2.30774 34.7946 2.6536C34.9169 2.99946 34.9611 3.36806 34.9243 3.73303C34.9243 8.5017 34.9243 13.27 34.9243 18.038C34.9667 18.4087 34.9249 18.7842 34.8021 19.1365C34.6792 19.4887 34.4784 19.8088 34.2146 20.0726C33.9509 20.3365 33.6309 20.5374 33.2787 20.6604C32.9264 20.7834 32.551 20.8253 32.1803 20.783C28.825 20.789 25.4693 20.789 22.1133 20.783C21.7423 20.8265 21.3662 20.785 21.0136 20.6616C20.6611 20.5382 20.3412 20.3361 20.0783 20.0707C19.8154 19.8053 19.6164 19.4835 19.4963 19.1298C19.3762 18.7761 19.3383 18.3996 19.3853 18.029C19.3793 15.645 19.3853 13.261 19.3853 10.876'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
      <path
        d='M16.5452 25.114C16.5452 27.476 16.5512 29.838 16.5452 32.2C16.586 32.571 16.5424 32.9465 16.4178 33.2983C16.2931 33.6501 16.0906 33.9693 15.8253 34.2318C15.56 34.4944 15.2388 34.6937 14.8857 34.8148C14.5327 34.9358 14.1568 34.9755 13.7862 34.931C10.4309 34.931 7.0752 34.931 3.7192 34.931C3.35369 34.9653 2.98512 34.9183 2.63985 34.7936C2.29458 34.6688 1.98113 34.4693 1.72193 34.2094C1.46272 33.9494 1.26415 33.6354 1.14043 33.2897C1.0167 32.9441 0.970863 32.5754 1.0062 32.21C1.0062 27.4193 1.0062 22.6287 1.0062 17.838C0.971374 17.4782 1.01631 17.1151 1.13781 16.7746C1.25931 16.4341 1.45439 16.1246 1.70915 15.8682C1.96391 15.6117 2.27209 15.4145 2.61174 15.2907C2.95139 15.167 3.31415 15.1196 3.6742 15.152C7.0742 15.1427 10.4742 15.1427 13.8742 15.152C14.2344 15.1156 14.5982 15.1599 14.9392 15.2817C15.2801 15.4035 15.5897 15.5996 15.8454 15.856C16.101 16.1123 16.2964 16.4224 16.4173 16.7637C16.5382 17.105 16.5815 17.4689 16.5442 17.829C16.5542 20.257 16.5442 22.686 16.5442 25.114'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
      <path
        d='M8.74398 12.299C7.04398 12.299 5.34398 12.309 3.64398 12.299C3.28981 12.3263 2.93393 12.2765 2.60087 12.153C2.26781 12.0295 1.96548 11.8353 1.71473 11.5837C1.46398 11.3321 1.27077 11.0291 1.14841 10.6956C1.02605 10.3621 0.977459 10.0061 1.00598 9.65202C1.00198 7.64336 1.00198 5.63469 1.00598 3.62602C0.983676 3.27698 1.03581 2.92719 1.15894 2.59983C1.28206 2.27246 1.47336 1.97501 1.72015 1.72717C1.96694 1.47934 2.26359 1.2868 2.59043 1.1623C2.91728 1.0378 3.26685 0.984188 3.61598 1.00502C7.05931 0.997023 10.5026 0.997023 13.946 1.00502C14.2895 0.98159 14.6341 1.03137 14.957 1.15107C15.2798 1.27078 15.5736 1.45767 15.8188 1.69939C16.064 1.94112 16.2551 2.23215 16.3794 2.55325C16.5037 2.87435 16.5585 3.21819 16.54 3.56202C16.558 5.63669 16.558 7.71136 16.54 9.78602C16.5556 10.1189 16.5016 10.4514 16.3816 10.7623C16.2617 11.0732 16.0782 11.3558 15.843 11.5919C15.6079 11.828 15.3261 12.0126 15.0156 12.1338C14.7052 12.255 14.3729 12.3103 14.04 12.296C12.275 12.319 10.508 12.301 8.73998 12.302'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
      <path
        d='M27.205 23.6312C28.905 23.6312 30.605 23.6212 32.305 23.6312C32.6596 23.6045 33.0158 23.6552 33.3488 23.7798C33.6819 23.9044 33.9839 24.1 34.2339 24.3529C34.4839 24.6059 34.6759 24.9101 34.7966 25.2446C34.9173 25.5791 34.9638 25.9359 34.933 26.2902C34.9396 28.2768 34.9396 30.2635 34.933 32.2502C34.9622 32.6102 34.9126 32.9722 34.7876 33.3111C34.6626 33.65 34.4653 33.9575 34.2093 34.2123C33.9534 34.4672 33.6449 34.6631 33.3055 34.7866C32.9661 34.91 32.6038 34.958 32.244 34.9272C28.844 34.9319 25.444 34.9319 22.044 34.9272C21.6897 34.9561 21.3334 34.9079 20.9996 34.7858C20.6657 34.6637 20.3624 34.4707 20.1104 34.22C19.8584 33.9693 19.6637 33.667 19.5399 33.3338C19.416 33.0007 19.3659 32.6446 19.393 32.2902C19.381 30.2815 19.381 28.2728 19.393 26.2642C19.3621 25.9096 19.4094 25.5525 19.5316 25.2181C19.6537 24.8838 19.8478 24.5803 20.1 24.3291C20.3522 24.078 20.6565 23.8852 20.9914 23.7644C21.3262 23.6436 21.6835 23.5978 22.038 23.6302C23.76 23.6192 25.481 23.6302 27.203 23.6302'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
    </svg>
  );
};

export default Dashbaord;
