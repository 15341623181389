import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import ProgressBar from './Components/ProgressBar';
import CopyIcon from '../../assets/icons/CopyIcon';
import GamePerformanceCard from './Components/GamePerformanceCard/GamePerformanceCard';
import ManageAssets from '../../Dialogs/ManageAssets/ManageAssets';

import assets from '../../assets';
import Chart from '../../components/Chart/Chart';
import {
  allUserNfts,
  getUserById,
  getUserGames,
  getUserSocialLinks,
  // getUserSocialLinks,
  userGuild,
  userOrGuildStats,
} from '../../api';
import {
  IGuildSocialLink,
  INft,
  ISingleGuild,
  ISingleUser,
  IUserOrGuildStats,
} from '../../types/interface';
import NftImage from '../../components/NftImage/NftImage';
import { toast } from 'react-toastify';
import { features, modulesEnum } from '../../types/enums';
import { IGame } from '../../types/api';
import WithPermissions from '../../components/WithPermissions/WithPermissions';
import UserMiniGameList from '../../components/UserMiniGameList/UserMiniGameList';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../hooks/useTheme';
import AxieDetails from '../../Dialogs/AxieDetails/AxieDetails';
import RenderSocialIcon from '../../components/RenderSocialIcon/RenderSocialIcon';

dayjs.extend(utc);

const PlayerProfile: FC = () => {
  const userId = useParams().id;
  const { theme } = useTheme();
  const dispatch = useDispatch<Dispatch>();

  const roles = useSelector((state: RootState) => state.roles.roles);

  const [openDialog, setOpenDialog] = useState(false);
  const [user, setUser] = useState<ISingleUser | null>(null);
  const [userNfts, setUserNfts] = useState<INft[]>([]);
  const [games, setGames] = useState<IGame[]>([]);
  const [guild, setGuild] = useState<ISingleGuild | null>(null);
  const [userStats, setUserStats] = useState<IUserOrGuildStats | null>(null);
  const [loading, setLoading] = useState(false);
  const [showAxie, setShowAxie] = useState(false);
  const [game, setGame] = useState('');
  const [socials, setSocials] = useState<IGuildSocialLink[]>([]);

  const handleMangeAssets = () => {
    setOpenDialog(true);
  };

  const handleCopyAddress = (address: string) => {
    navigator.clipboard.writeText(address);
    toast.success('Address copied successfully');
  };

  const handleGetInitialData = async () => {
    try {
      setLoading(true);
      let nftList: INft[] = [];
      const [{ data: userData }, { data: userGames }] = await Promise.all([
        getUserById(userId!),
        getUserGames(userId!),
        // getUserSocialLinks(userId!),
      ]);
      setUser(userData);
      setGames(userGames);

      const { data } = await getUserSocialLinks(userId!);
      setSocials(data);

      const { data: stats } = await userOrGuildStats(userId!);
      setUserStats(stats);

      const { data: userGuildData } = await userGuild(
        userId!,
        userData.role_id
      );
      setGuild(userGuildData);

      const { data: nfts } = await allUserNfts({
        public_address: userData.account.starz_wallet_address,
        user_id: userId!,
      });

      nfts.forEach(item => {
        nftList = [...nftList, ...item.Nfts];
      });

      setUserNfts(nftList);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetInitialData();
    dispatch.config.handleGetAllSupportedChains();
    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      <div className='cover'>
        <img src={user?.cover_photo || assets.guildCover} alt='' />
        <div className='profile-image'>
          <img src={user?.image_url || assets.userPreview} alt='' />
        </div>
      </div>
      <div className='row main'>
        <div className=''>
          <ProfileInfoCard>
            <p className='font-size-25 text-primary text-bold text-center'>
              {user?.username} {user?.last_name}
            </p>
            <p
              style={{ marginTop: '5px' }}
              className='font-size-12 text-secondary text-semibold text-center'
            >
              {user?.email}
            </p>
            <p className='font-size-12 country text-secondary text-semibold text-center'>
              {user?.country}
            </p>
            {user?.account.starz_wallet_address && (
              <WalletAddress>
                <p className='font-size-12 address text-semibold'>
                  {user?.account.starz_wallet_address}
                </p>
                <div
                  className='icon clickable'
                  onClick={() =>
                    handleCopyAddress(user?.account.starz_wallet_address)
                  }
                >
                  <CopyIcon fill='#C3CBD2' />
                </div>
              </WalletAddress>
            )}

            <ProgressBar />
            <Divider />
            <p className='font-size-14 text-semibold text-primary'>
              <span className='text-dim'>Name:</span> {user?.username}
            </p>
            {guild && (
              <p className='font-size-14 text-semibold text-primary capitalize'>
                <span className='text-dim'>Guild:</span> {guild?.name}
              </p>
            )}
            <p className='font-size-14 text-semibold text-primary capitalize'>
              <span className='text-dim'>Role:</span>{' '}
              {roles.find(role => role.id === user?.role_id)?.name}
            </p>
            {user?.ronin_wallet && (
              <div className='row' style={{ alignItems: 'center' }}>
                <p className='font-size-14 text-semibold text-primary capitalize'>
                  <span className='text-dim'>Ronin wallet:</span>
                </p>
                <WalletAddress
                  style={{
                    margin: '0',
                    marginLeft: '10px',
                    background: 'transparent',
                  }}
                >
                  <p className='font-size-12 address text-semibold text-primary'>
                    {user.ronin_wallet}
                  </p>
                  <div
                    className='icon clickable'
                    onClick={() => handleCopyAddress(user.ronin_wallet || '')}
                  >
                    <CopyIcon fill={theme.color.text.primary} />
                  </div>
                </WalletAddress>
              </div>
            )}
            {user?.ronin_email && (
              <p className='font-size-14 text-semibold text-primary capitalize'>
                <span className='text-dim'>Ronin email:</span>{' '}
                {user.ronin_email}
              </p>
            )}

            {games.length > 0 && (
              <>
                <Divider />
                <GamesContainer>
                  <UserMiniGameList games={games} />
                </GamesContainer>
              </>
            )}
            {socials.length > 0 && (
              <>
                <Divider />
                <SocialLinks>
                  {socials.map((social: IGuildSocialLink) => (
                    <a
                      href={social.url}
                      target='_blank'
                      key={social.id}
                      className='link'
                      rel='noreferrer'
                    >
                      <RenderSocialIcon name={social.logo} />
                    </a>
                  ))}
                </SocialLinks>
              </>
            )}
          </ProfileInfoCard>

          <LifeTimePerformance>
            <p className='font-size-16 text-primary text-semibold'>
              Lifetime Performance
            </p>
            <div className='divider'></div>
            <div className='row'>
              <div>
                <p className='font-size-14 text-secondary text-semibold'>
                  Games
                </p>
                <p className='font-size-14 text-primary text-bold'>250</p>
              </div>
              <div>
                <p className='font-size-14 text-secondary text-semibold'>
                  Wins
                </p>
                <p className='font-size-14 text-primary text-bold'>123</p>
              </div>
              <div>
                <p className='font-size-14 text-secondary text-semibold'>
                  Level
                </p>
                <p className='font-size-14 text-primary text-bold'>352</p>
              </div>
            </div>
          </LifeTimePerformance>

          {userNfts.length > 0 && (
            <AssetsContainer>
              <div className='row justify-space-between'>
                <p className='font-size-16 text-primary text-semilbold'>
                  Assets
                </p>
                <WithPermissions
                  moduleName={modulesEnum.Guilds}
                  featureName={features.pullbackAssets}
                  action
                >
                  <p
                    className='font-size-16 text-primary text-semilbold clickable'
                    onClick={handleMangeAssets}
                  >
                    Manage
                  </p>
                </WithPermissions>
              </div>
              <div className='assets-grid'>
                {userNfts.map(nft => (
                  <div key={nft.token_hash} className='asset'>
                    <NftImage size={120} nftData={nft} container />
                  </div>
                ))}
              </div>
            </AssetsContainer>
          )}
        </div>

        <Details>
          <StatsRow>
            <div className='row'>
              <p className='font-size-16 text-primary'>Items in vault</p>
              <p className='font-size-22 text-primary text-bold'>
                {userStats?.valutItems}
              </p>
            </div>
            {/* <div className='row'>
              <p className='font-size-16 text-primary'>Revenue split</p>
              <p className='font-size-22 text-primary text-bold'>30/70</p>
            </div>
            <div className='row'>
              <p className='font-size-16 text-primary'>Level</p>
              <p className='font-size-22 text-primary text-bold'>32</p>
            </div> */}
            <div className='row'>
              <p className='font-size-16 text-primary'>Next Payout</p>
              <p className='font-size-22 text-primary text-bold'>
                {userStats?.next_payout &&
                  dayjs(userStats.next_payout).format('DD / MM / YYYY')}
              </p>
            </div>
          </StatsRow>

          {loading && (
            <div className='row justify-center' style={{ height: '300px' }}>
              <ClipLoader size={80} color={theme.color.text.primary} />
            </div>
          )}

          {!loading && games.length > 0 && (
            <>
              <Performance>
                {guild && user && (
                  <Chart games={games} guildId={guild.id} userId={user.id} />
                )}
              </Performance>
              <div className='row justify-space-between'>
                <p className='font-size-25 text-primary text-semibold'>Games</p>
              </div>
              <Grid>
                {games.map(game => (
                  <GamePerformanceCard
                    setShowAxie={setShowAxie}
                    key={game.id}
                    game={game}
                    setGame={setGame}
                  />
                ))}
              </Grid>
            </>
          )}
        </Details>
      </div>
      {openDialog && <ManageAssets open={openDialog} setOpen={setOpenDialog} />}
      {showAxie && user && (
        <AxieDetails
          user={user}
          game={game}
          open={showAxie}
          setOpen={setShowAxie}
        />
      )}
    </Container>
  );
};

export default PlayerProfile;

const Container = styled.div`
  & .cover {
    width: 100%;
    height: 200px;
    border-radius: 12px;

    position: relative;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      border-radius: 12px;
    }
    & .profile-image {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 150px;
      width: 150px;
      border-radius: 50%;
      z-index: 1;
      border: 10px solid ${({ theme }) => theme.color.background.row};

      overflow: hidden;

      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  & .row.main {
    margin-top: 30px;
    gap: 30px;
    align-items: flex-start;
  }
`;

const ProfileInfoCard = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 24px 30px;
  width: 357px;
  border-radius: 10px;

  & .country {
    margin-block: 10px 13px;
  }

  & .text-dim {
    opacity: 0.5;
  }

  & p {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

const WalletAddress = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 15px;
  background-color: #23272a;
  color: #c3cbd2;
  width: 126px;
  margin-inline: auto;
  align-items: center;
  margin-bottom: 25px;

  & .address {
    max-width: 14ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const Divider = styled.div`
  height: 0.5px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.text.dim};
  margin-block: 20px;
`;

const GamesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-block: 10px;

  & .game {
    height: 37px;
    width: 37px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.text.primary};
    display: grid;
    place-items: center;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-top: 10px;

  & .link {
    height: 37px;
    width: 37px;
    background-color: ${({ theme }) => theme.color.background.headerRow};
    border-radius: 50%;
    display: grid;
    place-items: center;
  }
`;

const Details = styled.div`
  flex-grow: 1;
  margin-top: 50px;

  & .join-game-btn {
    background-color: ${({ theme }) => theme.color.background.card};
  }
`;

const StatsRow = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 10px;
  padding: 17px 35px;
  margin-block: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .row {
    gap: 16px;
  }
`;

const Performance = styled.div`
  margin-block: 29px 32px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-block: 29px 31px;
  gap: 20px;
`;

const LifeTimePerformance = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 10px;
  padding: 20px;
  margin-top: 30px;

  & .divider {
    margin-block: 18px 14px;
    border-top: 1px solid ${({ theme }) => theme.color.text.dimmer};
  }

  & .row {
    gap: 47px;

    & div {
      display: flex;
      flex-direction: column;
      gap: 9px;
    }
  }
`;

const AssetsContainer = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 20px 15px;
  margin-top: 20px;

  & .assets-grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 9px;

    & .asset {
      aspect-ratio: 1/1;
      background-color: ${({ theme }) => theme.color.background.row};
      border-radius: 8px;
      overflow: hidden;
    }
  }
`;
