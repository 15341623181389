import React, { FC } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { darkTheme, lightTheme, lightTheme as theme } from '../../theme';
import { ClipLoader } from 'react-spinners';

interface IProps {
  children: any;
  variant: 'primary' | 'secondary' | 'outlined';
  className?: string;
  loading?: boolean;
  [x: string]: any;
}

const ActionButton: FC<IProps> = ({
  children,
  variant,
  className,
  loading,
  ...rest
}) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const theme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <Button className={classNames(variant, className)} {...rest}>
      {loading ? (
        <ClipLoader
          size={20}
          color={theme.color.background.button.primary.color}
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default ActionButton;

const Button = styled.button`
  border: none;
  outline: none;
  padding: 0px 19px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &.primary {
    background-color: ${({ theme }) => theme.color.background.row};
    color: ${({ theme }) => theme.color.text.primary};
  }

  &.secondary {
    background-color: ${({ theme }) =>
      theme.color.background.button.primary.bg};
    color: ${({ theme }) => theme.color.background.button.primary.color};
  }

  &.outlined {
    background-color: transparent;
    color: ${({ theme }) => theme.color.text.primary};
    border: 1px solid ${({ theme }) => theme.color.text.primary};
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    font-size: 16px;
  }
`;
