import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { allTournaments, createTournament } from '../../api';
import { ITournament } from '../../types/interface';
import { ICreateTournamentPayload } from '../../types/storeModels';
import { toast } from 'react-toastify';

interface IState {
  loading: boolean;
  tournaments: ITournament[];
}

export const tournaments = createModel<RootModel>()({
  name: 'tournaments',
  state: {
    loading: false,
    tournaments: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setTournaments(state, payload: ITournament[]) {
      state.tournaments = payload;
    },
  },
  effects: dispatch => ({
    async handleCreateTournament(payload: ICreateTournamentPayload) {
      try {
        dispatch.tournaments.setLoading(true);
        const { data, setOpen } = payload;
        await createTournament(data);
        setOpen(false);
        toast.success('Tournament created successfully');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.tournaments.setLoading(false);
      }
    },
    async handleGetTournaments(payload?: string) {
      try {
        dispatch.tournaments.setLoading(true);
        const { data } = await allTournaments(payload);
        dispatch.tournaments.setTournaments(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.tournaments.setLoading(false);
      }
    },
  }),
});
