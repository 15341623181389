import React, { FC, useEffect } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';

import GuildCard from './GuildCard';

interface IProps {
  search?: string;
}

const GuildsGridView: FC<IProps> = ({ search = '' }) => {
  const dispatch = useDispatch<Dispatch>();

  const guilds = useSelector((state: RootState) => state.guilds.guilds);

  useEffect(() => {
    dispatch.guilds.handleGetAllGuilds();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className='wrapper'>
        {guilds.map(guild => {
          if (guild.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
            return <GuildCard key={guild.id} guildDetails={guild} />;
          } else {
            return <></>;
          }
        })}
      </div>
    </Container>
  );
};

export default GuildsGridView;

const Container = styled.div`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.background.card};
  width: 100%;
  overflow-x: auto;
  margin-top: 15px;

  & .wrapper {
    display: flex;
    gap: 11px;
  }
`;
