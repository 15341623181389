import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const FacebookIcon: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='12'
      height='22'
      viewBox='0 0 12 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M11.011 12.375L11.622 8.394H7.802V5.81C7.77541 5.51263 7.81604 5.21309 7.92088 4.93355C8.02572 4.65401 8.19208 4.40162 8.40766 4.19507C8.62323 3.98852 8.8825 3.8331 9.16627 3.74031C9.45004 3.64751 9.75104 3.61973 10.047 3.659H11.784V0.269C10.7639 0.104226 9.73318 0.0143188 8.7 0C5.554 0 3.5 1.907 3.5 5.359V8.394H0V12.375H3.5V22H7.8V12.375H11.011Z'
        fill={fill || theme.color.text.primary}
      />
    </svg>
  );
};

export default FacebookIcon;
