import React, { FC, useState } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';

import SelectIcon from '../../assets/icons/SelectIcon';
import Heart from '../../assets/icons/Heart';
import Lock from '../../assets/icons/Lock';
import User from '../../assets/icons/User';
// import ActionButton from '../../components/ActionButton/ActionButton';
import TabPanel from '../../components/TabPanel/TabPanel';
import Profile from './components/Profile';
import Appearance from './components/Appearance';

import { lightTheme as theme } from '../../theme';

const Settings: FC = () => {
  const [tab, setTab] = useState(1);

  const handleTab = (activeTab: number): void => {
    setTab(activeTab);
  };

  return (
    <Container>
      <SettingSection>
        <p className='font-size-25 text-primary text-light'>Settings</p>
        <div className='divider'></div>
        {/* <ProfileInformations>
          <div className='row'>
            <div className='profile-status'>
              <p className='font-size-16 text-primary text-semibold'>75%</p>
            </div>
            <div className=''>
              <p className='font-size-18 text-primary text-bold'>
                Profile Informations
              </p>
              <p className='font-size-14 text-secondary text-light'>
                Complete your profile to unlockall features
              </p>
            </div>
          </div>
          <ActionButton className='btn' variant='primary'>
            Complete My Profile
          </ActionButton>
        </ProfileInformations> */}
        <Tabs>
          <Tab
            onClick={() => handleTab(0)}
            className={classNames(tab === 0 && 'active')}
          >
            <div className='row justify-space-between'>
              <div className='row label'>
                <div
                  className='icon-container'
                  style={{
                    backgroundColor: tab === 0 ? '#353B40' : '#C3CBD2',
                  }}
                >
                  <Heart active={tab === 0} />
                </div>
                <div className='info'>
                  <p className='font-size-20 text-primary'>Appearance</p>
                  <p className='font-size-12 text-dim'>
                    Dark and Light Mode, Font size
                  </p>
                </div>
              </div>

              <div className='arrow-icon'>
                <SelectIcon />
              </div>
            </div>
          </Tab>
          <Tab
            onClick={() => handleTab(1)}
            className={classNames(tab === 1 && 'active')}
          >
            <div className='row justify-space-between'>
              <div className='row label'>
                <div
                  className='icon-container'
                  style={{
                    backgroundColor: tab === 1 ? '#353B40' : '#C3CBD2',
                  }}
                >
                  <User active={tab === 1} />
                </div>
                <div className='info'>
                  <p className='font-size-20 text-primary'>Account Settings</p>
                  <p className='font-size-12 text-dim'>
                    Personal Information, Email
                  </p>
                </div>
              </div>

              <div className='arrow-icon'>
                <SelectIcon />
              </div>
            </div>
          </Tab>
          <Tab
            // onClick={() => handleTab(2)}
            className={classNames(tab === 2 && 'active')}
          >
            <div className='row justify-space-between'>
              <div className='row label'>
                <div
                  className='icon-container'
                  style={{
                    backgroundColor: tab === 2 ? '#353B40' : '#C3CBD2',
                  }}
                >
                  <Lock active={tab === 2} />
                </div>
                <div className='info'>
                  <p className='font-size-20 text-primary'>Security</p>
                  <p className='font-size-12 text-dim'>Change Password, 2FA</p>
                </div>
              </div>

              <div className='arrow-icon'>
                <SelectIcon />
              </div>
            </div>
          </Tab>
        </Tabs>
      </SettingSection>
      <ContentSection>
        <TabPanel index={0} activeIndex={tab}>
          <Appearance />
        </TabPanel>
        <TabPanel index={1} activeIndex={tab}>
          <Profile />
        </TabPanel>
      </ContentSection>
    </Container>
  );
};

export default Settings;

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  height: 100%;
  align-items: stretch;
  gap: 20px;
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    gap: 30px;
  }
`;

const ContentSection = styled.div`
  flex: 4;
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 30px 20px;
  border-radius: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const SettingSection = styled.div`
  flex: 1.5;
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 30px 20px;
  border-radius: 10px;

  & .divider {
    border-top: 1px solid ${({ theme }) => theme.color.background.main};
    margin-block: 25px 19px;

    &.last {
      margin-block: 0px 20px;
    }
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    flex: 1;
    padding: 30px 10px;
  }
`;

// const ProfileInformations = styled.div`
//   margin-top: 31px;
//   border-radius: 10px;
//   padding: 10px;
//   padding-top: 20px;
//   margin-inline: 10px;
//   background-color: ${({ theme }) => theme.color.background.row};

//   & .profile-status {
//     height: 50px;
//     min-width: 50px;
//     border-radius: 50%;
//     border: 3px dashed ${({ theme }) => theme.color.text.primary};
//     display: grid;
//     place-items: center;
//     background-color: ${({ theme }) => theme.color.background.headerRow};
//   }

//   & .row {
//     gap: 15px;
//   }

//   & .btn {
//     margin-top: 49px;
//     height: 40px;
//     background-color: ${({ theme }) => theme.color.background.headerRow};
//     width: 100%;
//     border-radius: 20px;
//   }
// `;

const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    margin-top: 30px;
  }
`;

const Tab = styled.div`
  padding: 20px 12px;
  cursor: pointer;

  &.active {
    border-radius: 12px;
    background-color: ${({ theme }) => theme.color.background.main};

    & .icon-container {
      background-color: #353b40;
    }
  }

  & .row {
    &.label {
      gap: 20px;
      align-items: flex-start;
    }

    & .text-primary {
      margin-top: 4px;
    }

    & .text-dim {
      margin-top: 10px;
    }
  }

  & .icon-container {
    height: 36px;
    width: 36px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.color.background.headerRow};
    display: grid;
    place-items: center;
  }
  & .arrow-icon {
    transform: rotate(270deg);
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    padding: 30px 15px;
  }
`;
