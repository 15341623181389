import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import SimilarGame from './SimilarGame';
import Screenshots from './Screenshots';
import ActionButton from '../../components/ActionButton/ActionButton';

import assets from '../../assets';

import { lightTheme as theme } from '../../theme';
import { IGame } from '../../types/api';
import {
  gameBadges as gameBadgesApi,
  gameDetails,
  getGameFtContracts,
} from '../../api';
import { IFTContract, IGameBadge, ISingleGame } from '../../types/interface';
import { features, modulesEnum } from '../../types/enums';
import WithPermissions from '../../components/WithPermissions/WithPermissions';

dayjs.extend(utc);

const GameDetails: FC = () => {
  const navigate = useNavigate();
  const game: IGame = useLocation().state.game;

  const [gameData, setGameData] = useState<ISingleGame | null>(null);
  const [gameBadges, setGameBadges] = useState<IGameBadge[]>([]);
  const [ercContracts, setErcContracts] = useState<IFTContract[]>([]);

  const handleGetGameInfo = async () => {
    try {
      const [{ data: gameDetail }, { data: badges }, { data: contracts }] =
        await Promise.all([
          gameDetails(game.id),
          gameBadgesApi(game.id),
          getGameFtContracts(game.id),
        ]);

      setGameData(gameDetail);
      setGameBadges(badges);
      setErcContracts(contracts);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    handleGetGameInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className='cover-image'>
        <img src={gameData?.cover_photo || assets.cover} alt='' />
      </div>
      <div className='logo-container'>
        <div className='logo'>
          <img src={gameData?.logo} alt='' />
        </div>
        <div className='icon'>
          <p className='font-size-30 text-primary text-bold'>
            {gameData?.name}
          </p>
        </div>
      </div>

      <DetailContent>
        <div className='row justify-space-between info'>
          <div className='row chip-row'>
            {gameData?.categories.map(category => (
              <Chip key={category.id}>
                <p className='font-size-18 capitalize'>{category.name}</p>
              </Chip>
            ))}
          </div>
          <div className='row' style={{ gap: '10px' }}>
            {gameBadges.map(badge => (
              <Badge key={badge.badge_id}>
                <img src={badge.badge_logo} alt='' />
                <p className='font-size-16 text-primary text-bold capitalize'>
                  {badge.badge_name} <br /> Required
                </p>
              </Badge>
            ))}
          </div>
          <TokensRow>
            {ercContracts.map(contract => (
              <div className='row' key={contract.id}>
                <img
                  src={gameData?.mini_icon}
                  style={{ maxWidth: '30px' }}
                  alt=''
                />
                <div>
                  <p className='font-size-18 text-bold text-primary'>
                    {contract.minimum_tokens} {contract.name}
                  </p>
                  <p className='font-size-14 text-primary'> = 302,93500 USD</p>
                </div>
              </div>
            ))}
          </TokensRow>
          <ButtonContainer>
            <ActionButton
              onClick={() => navigate(-1)}
              className='btn secondary'
              variant='primary'
            >
              Cancel
            </ActionButton>
            <WithPermissions
              moduleName={modulesEnum['Game Vault']}
              featureName={features.editGame}
            >
              <ActionButton
                onClick={() =>
                  navigate(`/dashboard/games/update/${game.id}`, {
                    state: {
                      game,
                    },
                  })
                }
                variant='primary'
                className='btn'
              >
                Edit Game
              </ActionButton>
            </WithPermissions>
          </ButtonContainer>
        </div>
        <Divider />
        {gameData?.ScreenShots.length ? (
          <Screenshots screenshots={gameData?.ScreenShots} />
        ) : (
          <></>
        )}
        <AboutSection>
          <div className='left'>
            <p className='font-size-30 text-bold text-primary'>
              About {gameData?.name}
            </p>
            <p className='font-size-20 text-secondary'>
              {gameData?.description}
            </p>
          </div>
          <div className='right'>
            <div className='table'>
              <div className='row'>
                <div className='key'>
                  <p className='font-size-16 text-secondary'>Release date</p>
                </div>
                <div className='value'>
                  <p className='font-size-18 text-primary'>
                    {dayjs(gameData?.release_date).format('MMMM YYYY')}
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='key'>
                  <p className='font-size-16 text-secondary'>Genre(s)</p>
                </div>
                <div className='value'>
                  <p className='font-size-18 text-primary'>
                    {gameData?.genres}
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='key'>
                  <p className='font-size-16 text-secondary'>Mode(s)</p>
                </div>
                <div className='value'>
                  <p className='font-size-18 text-primary'>{gameData?.modes}</p>
                </div>
              </div>
              <div className='row'>
                <div className='key'>
                  <p className='font-size-16 text-secondary'>Creator(s)</p>
                </div>
                <div className='value'>
                  <p className='font-size-18 text-primary'>
                    {gameData?.creators}
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='key'>
                  <p className='font-size-16 text-secondary'>Developer(s)</p>
                </div>
                <div className='value'>
                  <p className='font-size-18 text-primary'>
                    {gameData?.developers}
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='key'>
                  <p className='font-size-16 text-secondary'>Platform(s)</p>
                </div>
                <div className='value'>
                  <p className='font-size-18 text-primary'>
                    {gameData?.platforms}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </AboutSection>
        <p className='font-size-30 text-bold text-primary'>Similar Games</p>
        <SimilarGames>
          <SimilarGame />
          <SimilarGame />
          <SimilarGame />
          <SimilarGame />
        </SimilarGames>
      </DetailContent>
    </Container>
  );
};

export default GameDetails;

const Container = styled.div`
  & .cover-image {
    height: 250px;
    border-radius: 10px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  & .logo-container {
    padding-inline: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: -90px;

    & .logo {
      background-color: ${({ theme }) => theme.color.background.row};
      height: 180px;
      width: 180px;
      border-radius: 50%;
      padding: 10px;
      overflow: hidden;

      & img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    & .icon {
      height: 40px;
      padding-inline: 14px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${({ theme }) => theme.color.background.card};
    }
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .cover-image {
      height: 350px;
    }

    & .logo-container {
      padding-inline: 40px;
      gap: 40px;
      margin-top: -108px;

      & .logo {
        height: 211px;
        width: 211px;
        padding: 16px;
      }
      & .icon {
        height: 51px;
        padding-inline: 18px;
      }
    }
  }
`;

const DetailContent = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 12px 20px;
  border-radius: 12px;
  margin-top: -60px;

  & .info {
    margin-top: 11px;
    padding-left: 175px;
  }

  & .chip-row {
    gap: 15px;
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    padding: 12px 30px;
    & .info {
      padding-left: 260px;
    }
  }
`;

const AboutSection = styled.div`
  display: flex;
  gap: 114px;
  align-items: flex-start;
  margin-block: 90px 50px;

  & .left {
    flex: 1;

    & p.font-size-20 {
      margin-top: 31px;
    }
  }

  & .right {
    flex: 1;

    & .table {
      background-color: ${({ theme }) => theme.color.background.row};
      border-radius: 10px;
      padding: 6px 20px;

      & .row {
        border-bottom: 0.5px solid ${({ theme }) => theme.color.text.secondary};
        padding-block: 20px;

        & .key {
          width: 16ch;
        }
        &:last-of-type {
          border: none;
        }
      }
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .right {
      & .table {
        padding: 6px 30px;

        & .row {
          padding-block: 28px;
        }
        & .key {
          width: 20ch;
        }
      }
    }
  }
`;

const SimilarGames = styled.div`
  margin-top: 21px;
  margin-bottom: 54px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.text.dim};
  margin-top: 15px;
`;

const Chip = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  background-color: ${({ theme }) => theme.color.background.chip + '99'};
  color: ${({ theme }) => theme.color.text.chip};
  border-radius: 8px;

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    height: 40px;
    padding: 0px 15px;
  }
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & img {
    width: 65px;
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & img {
      width: 95px;
    }
  }
`;

const TokensRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  & .row {
    gap: 8px;

    & p.font-size-14 {
      opacity: 0.5;
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    gap: 40px;

    & .row {
      gap: 10px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  & .btn {
    background-color: ${({ theme }) => theme.color.text.primary};
    color: ${({ theme }) => theme.color.background.button.primary.color};

    &.secondary {
      opacity: 0.7;
    }
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    gap: 20px;
  }
`;
