import React, { FC, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../../store';

import Scholarship from './Scholarship';

interface IProps {
  mintBadge: React.Dispatch<React.SetStateAction<boolean>>;
  applyScholarship: React.Dispatch<React.SetStateAction<boolean>>;
}

const Scholarships: FC<IProps> = ({ mintBadge, applyScholarship }) => {
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector((state: RootState) => state.auth.user);
  const scholarships = useSelector(
    (state: RootState) => state.scholarships.activeGuildScholarships
  );
  const badges = useSelector((state: RootState) => state.badges.badges);
  const mintedBadges = useSelector(
    (state: RootState) => state.badges.userMintedBadges
  );

  useEffect(() => {
    console.log('Scholarships', scholarships);
  }, [scholarships]);

  useEffect(() => {
    if (!badges.length) {
      dispatch.badges.handleGetAllBadges();
    }
    if (!mintedBadges.length) {
      if (user) {
        dispatch.badges.handleGetMintedBadges(user.id);
      }
    }
    // eslint-disable-next-line
  }, [user]);
  return (
    <Container>
      <Content>
        {scholarships.map((scholarship, i) => (
          <Scholarship
            mintBadge={mintBadge}
            applyScholarship={applyScholarship}
            scholarship={scholarship}
            key={scholarship.id}
            index={i}
          />
        ))}
      </Content>
    </Container>
  );
};

export default Scholarships;

const Container = styled.div`
  border-radius: 10px;
  margin-block: 20px;

  & .title {
    margin-bottom: 10px;
  }

  & .scholarship {
    padding: 7px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.color.background.row};

    & .row {
      gap: 10px;

      & .btn {
        background-color: ${({ theme }) =>
          theme.color.background.button.primary.bg};
        color: ${({ theme }) => theme.color.background.button.primary.color};
        width: 70px;
        height: 30px;
        padding: 0px;
      }
    }
  }
`;

const Content = styled.div`
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
`;
