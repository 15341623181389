import classNames from 'classnames';
import React, { FC } from 'react';

import styled from 'styled-components';
import SelectIcon from '../../assets/icons/SelectIcon';

import { lightTheme as theme } from '../../theme';

interface IProps {
  title?: string;
  label: string;
  value: string | number;
  options: Array<{ label: string; value: string | number }>;
  onChange: (e: React.ChangeEvent<any>) => void;
  [x: string]: any;
  backgroundColor?: string;
  containerClassName?: string;
  inputClassName?: string;
  error?: string;
  disabled?: boolean;
}

const Select: FC<IProps> = ({
  label,
  value,
  onChange,
  options,
  backgroundColor,
  title,
  containerClassName,
  inputClassName,
  error,
  disabled,
  ...rest
}) => {
  return (
    <Container>
      {title && (
        <label
          htmlFor={title}
          className={classNames(
            'font-size-14 text-dim',
            error && 'text-danger'
          )}
        >
          {title}
        </label>
      )}

      <InputContainer
        className={classNames(title && 'labeled', containerClassName)}
      >
        <select
          className={classNames(
            'font-size-12 text-primary',
            inputClassName,
            error && 'error'
          )}
          onChange={onChange}
          value={value}
          {...rest}
          style={{ backgroundColor: backgroundColor ? backgroundColor : '' }}
        >
          <option value='' disabled={disabled} className='text-primary'>
            {label}
          </option>
          {options.map(option => (
            <option
              className='capitalize'
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
        <div
          className={classNames('icon', error && 'error')}
          style={{ backgroundColor: backgroundColor ? backgroundColor : '' }}
        >
          <SelectIcon />
        </div>
      </InputContainer>
      {error && <p className='font-size-14 text-danger'>{error}</p>}
    </Container>
  );
};

export default Select;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    gap: 10px;
  }
`;

const InputContainer = styled.div`
  height: 35px;
  width: 140px;
  position: relative;
  position: relative;

  &.labeled {
    height: 44px;
    width: 100%;
    & select,
    .icon {
      border-radius: 8px;
    }
  }

  & select {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    outline: none;
    border: none;
    background-color: ${({ theme }) => theme.color.background.row};
    padding-inline: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 12.8px;
    position: relative;
    &.error {
      background-color: ${({ theme }) =>
        theme.color.text.danger + '1a'}!important;
      border: 1px solid ${({ theme }) => theme.color.text.danger};
    }
  }

  & .label {
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
  }

  & .icon {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding-right: 14px;
    pointer-events: none;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.background.row};
    display: grid;
    place-items: center;
    &.error {
      background-color: ${({ theme }) => theme.color.text.danger + '00'};
      opacity: 0;
    }
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    width: 186px;
    &.labeled {
      height: 55px;
    }
    & select {
      padding-inline: 14px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
    }
  }
`;
