import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const GlobeIcon: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='23'
      height='22'
      viewBox='0 0 23 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.4997 1.55513e-05C13.9234 -0.00111094 16.2729 0.836275 18.1495 2.37011C20.0261 3.90394 21.3143 6.03975 21.7955 8.4152C22.2768 10.7906 21.9214 13.2594 20.7897 15.4027C19.658 17.546 17.8197 19.2317 15.5867 20.174C13.021 21.258 10.1298 21.2783 7.54909 20.2305C4.96841 19.1827 2.90964 17.1527 1.8257 14.587C0.74176 12.0213 0.721438 9.1301 1.7692 6.54941C2.81697 3.96872 4.847 1.90996 7.4127 0.826016C8.70559 0.278533 10.0957 -0.00240831 11.4997 1.55513e-05ZM11.4997 19.091C13.1988 19.091 14.8598 18.5872 16.2726 17.6432C17.6854 16.6992 18.7865 15.3574 19.4368 13.7876C20.087 12.2178 20.2571 10.4905 19.9256 8.82399C19.5941 7.1575 18.7759 5.62673 17.5745 4.42526C16.373 3.22379 14.8422 2.40557 13.1757 2.07409C11.5092 1.7426 9.78187 1.91273 8.21207 2.56297C6.64227 3.2132 5.30054 4.31433 4.35654 5.72711C3.41255 7.13989 2.9087 8.80088 2.9087 10.5C2.91108 12.7778 3.81697 14.9615 5.42758 16.5721C7.03819 18.1827 9.22196 19.0886 11.4997 19.091Z'
        fill={theme.color.text.primary}
      />
      <path
        d='M21.0446 11.4544H1.95465C1.8267 11.4586 1.69923 11.4369 1.57981 11.3908C1.46038 11.3447 1.35145 11.2751 1.25948 11.186C1.16751 11.097 1.09437 10.9904 1.04443 10.8725C0.994487 10.7546 0.96875 10.6279 0.96875 10.4999C0.96875 10.3719 0.994487 10.2452 1.04443 10.1273C1.09437 10.0095 1.16751 9.90284 1.25948 9.8138C1.35145 9.72475 1.46038 9.65511 1.57981 9.60901C1.69923 9.5629 1.8267 9.54128 1.95465 9.54542H21.0446C21.1726 9.54128 21.3001 9.5629 21.4195 9.60901C21.5389 9.65511 21.6478 9.72475 21.7398 9.8138C21.8318 9.90284 21.9049 10.0095 21.9549 10.1273C22.0048 10.2452 22.0305 10.3719 22.0305 10.4999C22.0305 10.6279 22.0048 10.7546 21.9549 10.8725C21.9049 10.9904 21.8318 11.097 21.7398 11.186C21.6478 11.2751 21.5389 11.3447 21.4195 11.3908C21.3001 11.4369 21.1726 11.4586 21.0446 11.4544Z'
        fill={theme.color.text.primary}
      />
      <path
        d='M11.4997 0C11.6315 0.000717821 11.7618 0.0287203 11.8822 0.0822444C12.0027 0.135769 12.1108 0.213655 12.1997 0.311C14.7452 3.09476 16.1923 6.70871 16.2717 10.48C16.2717 10.4933 16.2717 10.5067 16.2717 10.52C16.1934 14.2908 14.7477 17.9047 12.2037 20.689C12.1142 20.7869 12.0053 20.8651 11.8839 20.9186C11.7625 20.9722 11.6313 20.9998 11.4987 20.9998C11.366 20.9998 11.2348 20.9722 11.1135 20.9186C10.9921 20.8651 10.8832 20.7869 10.7937 20.689C8.24999 17.9045 6.80466 14.2906 6.72668 10.52C6.72668 10.5067 6.72668 10.4933 6.72668 10.48C6.80493 6.70923 8.25063 3.09535 10.7947 0.311C10.8841 0.213036 10.993 0.134786 11.1144 0.0812401C11.2358 0.027694 11.367 2.60094e-05 11.4997 0ZM14.3627 10.5C14.2978 7.57422 13.2941 4.74679 11.4997 2.435C9.70488 4.74666 8.70081 7.5741 8.63568 10.5C8.70053 13.4258 9.70424 16.2532 11.4987 18.565C13.2935 16.2533 14.2976 13.4259 14.3627 10.5Z'
        fill={theme.color.text.primary}
      />
    </svg>
  );
};

export default GlobeIcon;
