import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { InferType, object, string } from 'yup';

import CrossIcon from '../../assets/icons/CrossIcon';
import DialogLayout from '../../layouts/DialogLayout/DialogLayout';
import InputField from '../../components/InputField/InputField';
import ActionButton from '../../components/ActionButton/ActionButton';

import { lightTheme as theme } from '../../theme';
import Select from '../../components/Select/Select';
import { FormErrors, validateData } from '../../utils/validations';

const schema = object({
  badge_id: string().required().label('Badge'),
  public_address: string().required().label('Wallet address'),
  required_stz: string().required().label('STZ'),
});

interface IFormData extends InferType<typeof schema> {}

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}

const MintBadge: FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();

  const loading = useSelector((state: RootState) => state.badges.loading);

  const badges = useSelector((state: RootState) => state.badges.badges);
  const walletAddress = useSelector(
    (state: RootState) => state.auth.walletAddress
  );

  const [formData, setFormData] = useState<IFormData>({
    badge_id: '',
    public_address: walletAddress || '',
    required_stz: '',
  });

  const [errors, setErrors] = useState<FormErrors<IFormData | null>>(null);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const badge = badges.find(badge => badge.id === value);
    if (badge) {
      setFormData(prev => ({
        ...prev,
        badge_id: badge.id,
        required_stz: badge.stz,
      }));
      setErrors(null);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApply = async () => {
    setErrors(null);
    const { errors: validationErrors } = await validateData<IFormData>(
      schema,
      formData
    );
    if (validationErrors) {
      return setErrors(validationErrors);
    }
    dispatch.badges.handleMintBadge({
      data: {
        badge_id: formData.badge_id,
        public_address: formData.public_address,
      },
      setOpen,
      setErrors,
    });
  };

  useEffect(() => {
    if (!badges.length) {
      dispatch.badges.handleGetAllBadges();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFormData(prev => ({ ...prev, public_address: walletAddress }));
    // eslint-disable-next-line
  }, [walletAddress]);

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>Mint a Badge</p>
          <div className='clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <div className='field'>
          <Select
            label='Select badge'
            title='Badge'
            options={badges.map(badge => ({
              label: badge.name,
              value: badge.id,
            }))}
            value={formData.badge_id}
            onChange={handleChange}
            error={errors?.badge_id}
          />
        </div>
        <div className='field'>
          <InputField
            label='STZ required'
            value={formData.required_stz}
            readOnly
            type='text'
            error={errors?.required_stz}
          />
        </div>
        <div className='field'>
          <InputField
            label='STZ value in USD'
            value={(+formData.required_stz * 0.04959).toFixed(2)}
            readOnly
            type='text'
          />
        </div>
        <div className='field'>
          <InputField
            readOnly
            value={formData.public_address}
            label='Scholar wallet address'
            type='text'
            error={errors?.public_address}
          />
        </div>
        <div className='divider'></div>
        <ButtonContainer>
          <ActionButton
            onClick={handleClose}
            variant='primary'
            className='btn btn-primary'
            disabled={loading}
          >
            Cancel
          </ActionButton>
          <ActionButton
            variant='primary'
            onClick={handleApply}
            className='btn btn-secondary'
            disabled={loading}
            loading={loading}
          >
            Mint
          </ActionButton>
        </ButtonContainer>
      </Container>
    </DialogLayout>
  );
};

export default MintBadge;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  padding: 30px;
  width: 486px;

  & .field {
    margin-block: 30px;

    & label {
      font-weight: 700;
    }

    & select {
      text-transform: capitalize;
    }
  }

  & .divider {
    border-top: 1px solid ${({ theme }) => theme.color.text.dim};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;

  & .btn {
    height: 44px;
    width: 150px;
    border-radius: 20px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .btn {
      height: 50px;
      width: 160px;
      border-radius: 30px;
    }
  }
`;
