import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useLocation } from 'react-router-dom';

import ScholarshipApplications from '../../../../../Dialogs/ScholarshipApplications/ScholarshipApplications';
import ScholarshipRow from './ScholarshipRow';
import useRole from '../../../../../hooks/useRole';
import { rolesEnum } from '../../../../../types/enums';
import { IScholarshipNotificationRedirectState } from '../../../../../types/interface';

interface IProps {
  isPanelOpen: boolean;
}

const Scholarships: FC<IProps> = ({ isPanelOpen }) => {
  const { role } = useRole();
  const state: IScholarshipNotificationRedirectState = useLocation().state;
  const scholarships = useSelector(
    (state: RootState) => state.scholarships.activeGuildScholarships
  );

  const [open, setOpen] = useState(state?.scholarship_id ? true : false);

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (state?.scholarship_id) {
      setOpen(true);
    }
  }, [state]);

  return (
    <>
      {isPanelOpen ? (
        <Container>
          <div className='row justify-space-between header-row'>
            <p className='font-size-20 text-primary text-semibold'>
              Scholarships
            </p>
            {!(role?.name === rolesEnum.Scholar) && (
              <p
                className='font-size-16 text-dimmer text-semibold clickable'
                onClick={handleOpen}
              >
                View Scholarships
              </p>
            )}
          </div>
          {scholarships.map(scholarship => (
            <ScholarshipRow key={scholarship.id} scholarship={scholarship} />
          ))}
        </Container>
      ) : (
        <>
          <ContainerSmall>
            <p className='font-size-12 text-primary text-semibold'>
              Scholarships
            </p>
            <div className='divider'></div>

            {scholarships.map(scholarship => (
              <div className='row' key={scholarship.id}>
                <p className='font-size-12 text-primary text-bold title'>
                  {scholarship.game_name}
                </p>
                <p className='font-size-10 text-primary text-bold'>
                  {scholarship.spots} Spots
                </p>
              </div>
            ))}
          </ContainerSmall>
        </>
      )}

      {open && <ScholarshipApplications open={open} setOpen={setOpen} />}
    </>
  );
};

export default Scholarships;

const Container = styled.div`
  border-radius: 10px;
  padding: 14px 10px;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  margin-bottom: 20px;

  & .header-row {
    padding-bottom: 6px;
  }
`;

const ContainerSmall = styled.div`
  border-radius: 10px;
  padding: 10px 7px;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  margin-bottom: 20px;

  & .divider {
    height: 0.5px;
    background-color: ${({ theme }) => theme.color.text.primary};
    margin-block: 18px;
  }

  & .row {
    height: 50px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.background.row};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 5px;
    margin-top: 10px;

    & .title {
      max-width: 9ch;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
