import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { allNotifications } from '../../api';

import { INotification } from '../../types/interface';

interface IState {
  isOpen: boolean;
  loading: boolean;
  notifications: INotification[];
  count: number;
}

export const notifications = createModel<RootModel>()({
  name: 'notifications',
  state: {
    isOpen: false,
    loading: false,
    notifications: [],
    count: 0,
  } as IState,
  reducers: {
    setNotificationIsOpen(state, payload: boolean) {
      state.isOpen = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setNotifications(state, payload: INotification[]) {
      state.notifications = payload;
    },
    setCount(state, payload: number) {
      state.count = payload;
    },
  },
  effects: dispatch => ({
    async handleGetAllNotifications(_, state) {
      try {
        const id = state.auth.user!.id;
        dispatch.notifications.setLoading(true);
        const { data } = await allNotifications(id);
        dispatch.notifications.setNotifications(data.notifications);
        dispatch.notifications.setCount(+data.count);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.notifications.setLoading(false);
      }
    },
  }),
});
