import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface IProps {
  active: boolean;
  [x: string]: any;
}

const Heart: FC<IProps> = ({ active, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M13.154 0.345215H13.111C12.2928 0.349065 11.4884 0.556807 10.7706 0.949657C10.0528 1.34251 9.44427 1.90807 9 2.59521C8.55573 1.90807 7.94719 1.34251 7.22941 0.949657C6.51163 0.556807 5.70724 0.349065 4.889 0.345215H4.846C3.55689 0.357337 2.32468 0.877815 1.41724 1.7935C0.50979 2.70919 0.000470056 3.94605 0 5.23521C0.107571 7.31136 0.827114 9.30924 2.068 10.9772C4.07164 13.5008 6.40285 15.7459 9 17.6532C11.5971 15.7459 13.9284 13.5008 15.932 10.9772C17.1729 9.30924 17.8924 7.31136 18 5.23521C17.9995 3.94605 17.4902 2.70919 16.5828 1.7935C15.6753 0.877815 14.4431 0.357337 13.154 0.345215Z'
        fill={
          active ? (activeTheme === 'light' ? '#ffffff' : '#C3CBD2') : '#23272A'
        }
      />
    </svg>
  );
};

export default Heart;
