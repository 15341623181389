import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { darkTheme, lightTheme } from '../theme';

const useTheme = () => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const theme = activeTheme === 'light' ? lightTheme : darkTheme;
  const invertedTheme = activeTheme === 'light' ? darkTheme : lightTheme;
  return {
    theme,
    invertedTheme,
  };
};

export default useTheme;
