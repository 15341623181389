import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';

import MyGuildCard from '../../components/MyGuildCard/MyGuildCard';
import ActionButton from '../../components/ActionButton/ActionButton';
import GuildsTableView from './Components/GuildsTableView';
import GuildsGridView from './Components/GuildsGridView';
import AddNewGuild from '../../Dialogs/AddNewGuild/AddNewGuild';

import GridIcon from '../../assets/icons/GridIcon';
import ListIcon from '../../assets/icons/ListIcon';

import { allGuilds } from '../../api';
import useRole from '../../hooks/useRole';
import { features, modulesEnum, rolesEnum } from '../../types/enums';
import { IGuild } from '../../types/api';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../hooks/useTheme';
import WithPermissions from '../../components/WithPermissions/WithPermissions';

const Guilds: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const { role } = useRole();
  const { theme } = useTheme();

  const [openDialog, setOpenDialog] = useState(false);
  const [isListView, setIsListView] = useState(true);
  const [mineGuildLoading, setMineGuildLoading] = useState(false);

  const [mineGuilds, setMineGuilds] = useState<IGuild[]>([]);

  const handleOpenDialog = () => {
    dispatch.guilds.setCurrenGuild(null);
    setOpenDialog(true);
  };

  const handleGetMineGuild = async () => {
    try {
      setMineGuildLoading(true);
      const { data } = await allGuilds('?isMine=true');
      setMineGuilds(data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setMineGuildLoading(false);
    }
  };

  useEffect(() => {
    if (role && role.name === rolesEnum.Admin) {
      handleGetMineGuild();
    }
  }, [role]);

  useEffect(() => {
    dispatch.guilds.handleGetAllGuilds();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <p className='font-size-25 text-primary text-semibold section-title'>
        My Guilds
      </p>
      <div className='my-guilds'>
        {mineGuildLoading ? (
          <div className='row justify-center'>
            <ClipLoader size={50} color={theme.color.text.primary} />
          </div>
        ) : (
          <>
            {mineGuilds.map(guild => (
              <MyGuildCard guild={guild} key={guild.id} />
            ))}
          </>
        )}
      </div>
      <WithPermissions
        moduleName={modulesEnum.Guilds}
        featureName={features.allGuilds}
      >
        <div
          className='row justify-space-between'
          style={{ marginBlock: '26px 12px' }}
        >
          <p className='font-size-25 text-primary text-semibold'>All Guilds</p>
          <div className='row' style={{ gap: '10px' }}>
            <ActionButton
              variant='primary'
              onClick={() => setIsListView(true)}
              style={{
                background: isListView
                  ? theme.color.background.card
                  : theme.color.background.card + '66',
                padding: '0px',
                width: '40px',
              }}
            >
              <ListIcon active={isListView} style={{ marginTop: '5px' }} />
            </ActionButton>
            <ActionButton
              variant='primary'
              onClick={() => setIsListView(false)}
              style={{
                background: isListView
                  ? theme.color.background.card + '66'
                  : theme.color.background.card,
                padding: '0px',
                width: '40px',
              }}
            >
              <GridIcon active={!isListView} style={{ marginTop: '5px' }} />
            </ActionButton>
            <WithPermissions
              moduleName={modulesEnum.Guilds}
              featureName={features.addNewGuild}
            >
              <ActionButton
                variant='primary'
                style={{ background: theme.color.background.card }}
                onClick={handleOpenDialog}
              >
                Add New Guild
              </ActionButton>
            </WithPermissions>
          </div>
        </div>
      </WithPermissions>

      {isListView ? <GuildsTableView /> : <GuildsGridView />}
      {openDialog && <AddNewGuild open={openDialog} setOpen={setOpenDialog} />}
    </Container>
  );
};

export default Guilds;

const Container = styled.div`
  & .section-title {
    margin-bottom: 21px;
  }

  & .my-guilds {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    align-items: stretch;
  }
`;

// const GuildPlacedolder = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 12px;
// `;
