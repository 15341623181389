import React, { FC, useState, useEffect } from 'react';

import { Row } from 'react-table';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { IGame, IGuildUser } from '../../../types/api';
import { getUserGames } from '../../../api';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../../hooks/useTheme';
import UserMiniGameList from '../../../components/UserMiniGameList/UserMiniGameList';

interface IProps {
  row: Row<IGuildUser>;
  search: string;
}

const UserRow: FC<IProps> = ({ row, search }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState<IGame[]>([]);

  const handleGetUserGames = async () => {
    try {
      setLoading(true);
      const { data } = await getUserGames(row.original.id);
      setGames(data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = () => {
    navigate(`player/profile/${row.original.id}`);
  };
  useEffect(() => {
    handleGetUserGames();
    // eslint-disable-next-line
  }, []);

  if (
    row.original.username?.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
    row.original.email.toLowerCase().indexOf(search.toLowerCase()) > -1
  ) {
    return (
      <React.Fragment key={row.original.id}>
        <tr style={{ height: '5px' }} />
        <tr className='clickable' onClick={handleRowClick}>
          <td className='font-size-12 text-primary text-semibold'>
            {row.original.username}
          </td>
          <td className='font-size-12 text-primary text-semibold'>
            {row.original.email}
          </td>
          <td className='font-size-12 text-primary text-semibold capitalize'>
            {row.original.name}
          </td>
          <td className='font-size-12 text-primary text-semibold'>
            <div className='row' style={{ gap: '8px' }}>
              {loading ? (
                <ClipLoader size={15} color={theme.color.text.primary} />
              ) : (
                <UserMiniGameList games={games} />
              )}
            </div>
          </td>
          <td className='font-size-12 text-primary text-semibold'>
            {row.original.total_assets}
          </td>
          <td className='font-size-12 text-primary text-semibold'>70 / 30</td>
          <td className='font-size-12 text-primary text-semibold'>
            {dayjs(row.original.next_payout).format('DD / MM / YYYY')}
          </td>
        </tr>
      </React.Fragment>
    );
  } else {
    return <></>;
  }
};

export default UserRow;
