import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { addGame, addGameToBadge, allGames, updateGameImages } from '../../api';
import { IGame } from '../../types/api';
import { IAddGame } from '../../types/storeModels';
import { toast } from 'react-toastify';

interface IState {
  loading: boolean;
  games: IGame[];
}

export const games = createModel<RootModel>()({
  name: 'games',
  state: {
    loading: false,
    games: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setGames(state, payload: IGame[]) {
      state.games = payload;
    },
  },
  effects: dispatch => ({
    async handleGetAllGames(payload?: string) {
      try {
        dispatch.games.setLoading(true);
        const { data } = await allGames(payload || '');
        dispatch.games.setGames(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.games.setLoading(false);
      }
    },
    async handleAddGame(payload: IAddGame) {
      try {
        dispatch.games.setLoading(true);
        const { data, setOpen } = payload;
        const {
          gameIcon,
          gameLogo,
          description,
          short_description,
          domain,
          name,
          selectedBadges,
        } = data;

        const images = new FormData();

        images.append('icon', gameIcon);
        images.append('logo', gameLogo);

        const { data: res } = await addGame({
          name,
          short_description,
          description,
          domain,
        });

        await updateGameImages(images, res.id);

        await Promise.all(
          selectedBadges.map(badge =>
            addGameToBadge({ badge_id: badge, game_id: res.id })
          )
        );
        setOpen(false);
        toast.success('Game added successfully.');
        dispatch.games.handleGetAllGames('');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.games.setLoading(false);
      }
    },
  }),
});
