import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  [x: string]: any;
}

const CheckboxCheck: FC<IProps> = ({ ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M17 0.5H4C2.067 0.5 0.5 2.067 0.5 4V17C0.5 18.933 2.067 20.5 4 20.5H17C18.933 20.5 20.5 18.933 20.5 17V4C20.5 2.067 18.933 0.5 17 0.5Z'
        stroke={theme.color.text.primary}
      />
      <path
        d='M7.8411 13.7135L4.3821 10.2525L3.2041 11.4225L7.8411 16.0595L17.7951 6.10555L16.6251 4.93555L7.8411 13.7135Z'
        fill={theme.color.text.primary}
      />
    </svg>
  );
};

export default CheckboxCheck;
