import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const Ticks: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  return (
    <svg
      width='18'
      height='8'
      viewBox='0 0 18 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M4.23593 6.96483L1.13593 3.86484C1.0916 3.82062 1.05644 3.76809 1.03244 3.71026C1.00845 3.65244 0.996094 3.59044 0.996094 3.52783C0.996094 3.46523 1.00845 3.40323 1.03244 3.34541C1.05644 3.28758 1.0916 3.23505 1.13593 3.19083L1.81293 2.52283C1.85714 2.47851 1.90967 2.44334 1.9675 2.41935C2.02533 2.39535 2.08732 2.383 2.14993 2.383C2.21254 2.383 2.27453 2.39535 2.33236 2.41935C2.39019 2.44334 2.44271 2.47851 2.48693 2.52283L4.57193 4.60683L9.03993 0.139835C9.08414 0.0955091 9.13667 0.0603416 9.1945 0.0363464C9.25233 0.0123513 9.31432 0 9.37693 0C9.43954 0 9.50153 0.0123513 9.55936 0.0363464C9.61719 0.0603416 9.66971 0.0955091 9.71393 0.139835L10.3879 0.813835C10.4323 0.858051 10.4674 0.910577 10.4914 0.968405C10.5154 1.02623 10.5278 1.08823 10.5278 1.15084C10.5278 1.21344 10.5154 1.27544 10.4914 1.33326C10.4674 1.39109 10.4323 1.44362 10.3879 1.48783L4.90893 6.96483C4.81967 7.05405 4.69863 7.10417 4.57243 7.10417C4.44623 7.10417 4.32519 7.05405 4.23593 6.96483Z'
        fill={activeTheme === 'light' ? '#353940' : '#C3CBD2'}
      />
      <path
        d='M11.4723 6.96483L8.37226 3.86484C8.32793 3.82062 8.29276 3.76809 8.26877 3.71026C8.24477 3.65244 8.23242 3.59044 8.23242 3.52783C8.23242 3.46523 8.24477 3.40323 8.26877 3.34541C8.29276 3.28758 8.32793 3.23505 8.37226 3.19083L9.04926 2.52283C9.09347 2.47851 9.146 2.44334 9.20383 2.41935C9.26165 2.39535 9.32365 2.383 9.38626 2.383C9.44887 2.383 9.51086 2.39535 9.56869 2.41935C9.62651 2.44334 9.67904 2.47851 9.72326 2.52283L11.8083 4.60683L16.2763 0.139835C16.3205 0.0955091 16.373 0.0603416 16.4308 0.0363464C16.4887 0.0123513 16.5506 0 16.6133 0C16.6759 0 16.7379 0.0123513 16.7957 0.0363464C16.8535 0.0603416 16.906 0.0955091 16.9503 0.139835L17.6243 0.813835C17.6686 0.858051 17.7037 0.910577 17.7277 0.968405C17.7517 1.02623 17.7641 1.08823 17.7641 1.15084C17.7641 1.21344 17.7517 1.27544 17.7277 1.33326C17.7037 1.39109 17.6686 1.44362 17.6243 1.48783L12.1453 6.96483C12.056 7.05405 11.935 7.10417 11.8088 7.10417C11.6826 7.10417 11.5615 7.05405 11.4723 6.96483Z'
        fill={activeTheme === 'light' ? '#353940' : '#C3CBD2'}
      />
    </svg>
  );
};

export default Ticks;
