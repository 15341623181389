import React, { FC } from 'react';

import styled from 'styled-components';

import SearchIcon from '../../assets/icons/SearchIcon';

import { lightTheme as theme } from '../../theme';

interface IProps {
  [x: string]: any;
  containerStyles?: { [x: string]: any };
  className?: string;
}

const SearchBar: FC<IProps> = ({ containerStyles, className, ...rest }) => {
  return (
    <Container className={className}>
      <input
        type='text'
        className='text-primary font-size-14'
        placeholder='Search'
        style={containerStyles}
        {...rest}
      />
      <div className='icon'>
        <SearchIcon />
      </div>
    </Container>
  );
};

export default SearchBar;

const Container = styled.div`
  width: 280px;
  height: 35px;
  position: relative;
  & input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.background.row};
    padding: 14px;
  }

  & .icon {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-40%);
    pointer-events: none;
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    width: 381px;
  }
`;
