import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface IProps {
  [x: string]: any;
}

const SliderArrow: FC<IProps> = ({ ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  return (
    <svg
      width='75'
      height='76'
      viewBox='0 0 75 76'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M37.3908 0.949219C44.7188 0.949219 51.8823 3.12222 57.9753 7.19344C64.0683 11.2647 68.8172 17.0512 71.6215 23.8214C74.4258 30.5916 75.1595 38.0413 73.7299 45.2285C72.3003 52.4157 68.7715 59.0176 63.5899 64.1992C58.4082 69.3809 51.8063 72.9097 44.6191 74.3393C37.4319 75.7689 29.9822 75.0352 23.212 72.2309C16.4419 69.4266 10.6553 64.6777 6.58406 58.5847C2.51285 52.4916 0.339844 45.3282 0.339844 38.0002C0.339056 33.1344 1.29687 28.3161 3.15858 23.8205C5.02029 19.3249 7.74941 15.2401 11.1901 11.7994C14.6307 8.35879 18.7155 5.62966 23.2111 3.76795C27.7067 1.90625 32.525 0.948431 37.3908 0.949219ZM29.6598 52.4492C29.018 53.0957 28.6578 53.9697 28.6578 54.8807C28.6578 55.7917 29.018 56.6658 29.6598 57.3122C29.9775 57.6309 30.3552 57.8834 30.7711 58.0551C31.187 58.2268 31.6329 58.3142 32.0828 58.3122C32.9985 58.3106 33.8762 57.9455 34.5228 57.2972L51.2648 40.4942C51.8592 39.842 52.1796 38.986 52.1597 38.1038C52.1398 37.2216 51.781 36.381 51.1578 35.7562L34.1648 18.7082C33.5196 18.0636 32.6447 17.7017 31.7326 17.7022C30.8205 17.7027 29.946 18.0654 29.3013 18.7107C28.6567 19.356 28.2949 20.2309 28.2953 21.143C28.2958 22.0551 28.6586 22.9296 29.3038 23.5742L43.8918 38.0002L29.6598 52.4492Z'
        fill={activeTheme === 'light' ? '#353940' : '#7A7E85'}
      />
    </svg>
  );
};

export default SliderArrow;
