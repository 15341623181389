import React, { FC } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { IGame } from '../../types/api';

interface IProps {
  game: IGame;
}

const GameCard: FC<IProps> = ({ game }) => {
  const navigate = useNavigate();
  return (
    <Container
      onClick={() =>
        navigate(`/dashboard/games/details/${game.id}`, { state: { game } })
      }
    >
      <div className='image-container'>
        <img src={game.logo} alt='' />
      </div>
      <div className='row justify-space-between info'>
        <p className='font-size-25 text-primary'>{game.name}</p>
      </div>
      <div className='row justify-space-between'>
        <div className='row' style={{ gap: '5px' }}>
          <p className='font-size-8 chip'>Strategy</p>
          <p className='font-size-8 chip'>Cards</p>
        </div>
        <div className='avatar'>
          <p className='font-size-8 chip chip-circle'>300+</p>
        </div>
      </div>
    </Container>
  );
};

export default GameCard;

const Container = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.background.card};

  & .more-icon {
    cursor: pointer;
  }

  & .image-container {
    aspect-ratio: 1/0.7;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    background-color: ${({ theme }) => theme.color.background.row};

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .info {
    margin-bottom: 7px;
  }

  & .chip {
    padding: 3px 7px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.color.background.chip};
    color: ${({ theme }) => theme.color.text.chip};

    &.chip-circle {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: grid;
      place-items: center;
      padding: 0;
    }
  }
`;
