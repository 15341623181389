import React, { FC } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

import GuildCard from '../../../components/GuildCard/GuildCard';

import { lightTheme as theme } from '../../../theme';

const GuildsGridView: FC = () => {
  const guilds = useSelector((state: RootState) => state.guilds.guilds);
  return (
    <Grid>
      {guilds.map(guild => (
        <GuildCard guildDetails={guild} key={guild.id} />
      ))}
    </Grid>
  );
};

export default GuildsGridView;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  }
`;
