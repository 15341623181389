import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const MoreIcon: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  return (
    <svg
      width='4'
      height='16'
      viewBox='0 0 4 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M2 4.00098C3.10457 4.00098 4 3.10555 4 2.00098C4 0.896407 3.10457 0.000976562 2 0.000976562C0.89543 0.000976562 0 0.896407 0 2.00098C0 3.10555 0.89543 4.00098 2 4.00098Z'
        fill={fill ? fill : activeTheme === 'light' ? '#707070' : '#707070'}
      />
      <path
        d='M2 10.001C3.10457 10.001 4 9.10555 4 8.00098C4 6.89641 3.10457 6.00098 2 6.00098C0.89543 6.00098 0 6.89641 0 8.00098C0 9.10555 0.89543 10.001 2 10.001Z'
        fill={fill ? fill : activeTheme === 'light' ? '#707070' : '#707070'}
      />
      <path
        d='M2 16.001C3.10457 16.001 4 15.1055 4 14.001C4 12.8964 3.10457 12.001 2 12.001C0.89543 12.001 0 12.8964 0 14.001C0 15.1055 0.89543 16.001 2 16.001Z'
        fill={fill ? fill : activeTheme === 'light' ? '#707070' : '#707070'}
      />
    </svg>
  );
};

export default MoreIcon;
