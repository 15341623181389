import { createGlobalStyle } from 'styled-components';

import { lightTheme as theme } from '.';

export default createGlobalStyle`
    *,*::before,*::after{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Urbanist', sans-serif;
    }
    input[type="number"]{
        padding-right: 0px!important;
    }
    input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button{
        background-color: red;
        opacity: 0;
        margin: 0;
        margin-left: -20px;
        height:25px;
        transform: scale(2);
    }

    h1,.h1{
        font-size: 35px;
        font-weight: 700;
        line-height: 40px;
    }

    .font-size-8{
        font-size: 8px;
        font-weight: 400;
        line-height: 9.5px;
    }

    .font-size-10{
        font-size: 8px;
        font-weight: 400;
        line-height: 10px;
    }

    .font-size-12{
        font-size: 10px;
        font-weight: 400;
        line-height: 12.4px;
    }

    .font-size-14{
        font-size: 12px;
        font-weight: 400;
        line-height: 14.5px;
    }

    .font-size-16{
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
    }


    .font-size-18{
        font-size: 16px;
        font-weight: 400;
        line-height: 18.5px;
    }

    .font-size-20{
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
    }

    .font-size-25{
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
    }

    .font-size-30{
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
    }

    .font-size-40{
        font-size: 35px;
        font-weight: 700;
        line-height: 39px;
    }

    .anchor{
        text-transform: none;
        text-decoration: none;
        color: unset;
    }

    .text-center{
        text-align: center;
    }

    .text-left{
        text-align: left!important;
    }
    .text-right{
        text-align: right!important;
    }

    .row{
        display: flex;
        align-items: center;
    }

    .justify-space-between{
        justify-content: space-between;
    }
    .justify-center{
        justify-content: center;
    }

    .flex-start{
        justify-content: flex-start;
    }

    .flex-end{
        justify-content: flex-end;
    }

    .flex-1{
        flex-grow: 1;
    }

    .text-light{
        font-weight: 400!important;
    }

    .text-bold{
        font-weight: 700!important;
    }
    .text-semibold{
        font-weight: 500!important;
    }

    .clickable{
        cursor: pointer;
    }
    .capitalize{
        text-transform: capitalize !important;
    }
    .flex-1{
        flex-grow: 1;
    }



input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

    @media only screen and (min-width:${theme.breakpoints['2xl']}) {
        h1,.h1{
        font-size: 50px;
        font-weight: 700;
        line-height: 60px;
    }

    .font-size-8{
        font-size: 8px;
        font-weight: 400;
        line-height: 9.5px;
    }
    .font-size-10{
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
    }
    .font-size-12{
        font-size: 12px;
        font-weight: 400;
        line-height: 14.4px;
    }

    .font-size-14{
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
    }

    .font-size-16{
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
    }

    .font-size-18{
        font-size: 18px;
        font-weight: 400;
        line-height: 21.6px;
    }

    .font-size-20{
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
    }
    .font-size-22{
        font-size: 22px;
        font-weight: 400;
        line-height: 26.4px;
    }
    .font-size-25{
        font-size: 25px;
        font-weight: 500;
        line-height: 30px;
    }

    .font-size-30{
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
    }

    .font-size-40{
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
    }
    
    }
`;
