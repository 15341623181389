import React from 'react';

import { Outlet } from 'react-router-dom';

import styled from 'styled-components';

import Navbar from '../../components/Navbar/Navbar';
import ErrorDialog from '../../Dialogs/ErrorDialog/ErrorDialog';
import Loader from '../../Dialogs/Loader/Loader';

import { lightTheme as theme } from '../../theme';
import Notifications from '../Notifications/Notifications';
import GasFeeAlert from '../../Dialogs/GasFeeAlert/GasFeeAlert';

const DashbaordLayout = () => {
  return (
    <Container>
      <Navbar />
      <div className='content'>
        <Outlet />
        <ErrorDialog />
        <Loader />
      </div>
      <Notifications />
      <GasFeeAlert />
    </Container>
  );
};

export default DashbaordLayout;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.row};
  & .content {
    padding: 25px;
    padding-top: 85px;
    min-height: 100vh;
    @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
      padding: 30px;
      padding-top: 120px;
    }
    display: flex;
    flex-direction: column;
  }
`;
