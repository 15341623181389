import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { IRole } from '../types/api';

const useRole = () => {
  const [role, setRole] = useState<IRole | null>(null);

  const user = useSelector((state: RootState) => state.auth.user);

  const roles = useSelector((state: RootState) => state.roles.roles);

  useEffect(() => {
    if (user && roles.length) {
      const _role = roles.find(rol => rol.id === user.role_id)!;
      setRole(_role);
    }
  }, [roles, user]);

  return { role };
};

export default useRole;
