import React from 'react';

import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';

const ThemeToggler = () => {
  const dispatch = useDispatch<Dispatch>();
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const handleChange = () => {
    if (activeTheme === 'light') {
      dispatch.theme.setTheme('dark');
    } else {
      dispatch.theme.setTheme('light');
    }
  };
  return (
    <StyledSwitch
      onChange={handleChange}
      type='checkbox'
      checked={activeTheme === 'dark'}
    />
  );
};

export default ThemeToggler;

const StyledSwitch = styled.input`
  appearance: none;
  -webkit-appearance: none;
  width: 28px;
  height: 16px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.text.primary};
  position: relative;
  transition: 0.4s ease;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.text.secondary};
    top: 50%;
    transform: translateY(-50%);
    left: 3.5px;
    transition: 0.4s ease;
  }

  &:checked::before {
    left: 15.5px;
  }
`;
