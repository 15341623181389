import { FC } from 'react';
import FacebookIcon from '../../assets/icons/FacebookIcon';
import GlobeIcon from '../../assets/icons/GlobeIcon';
import InstagramIcon from '../../assets/icons/InstagramIcon';
import TelegramIcon from '../../assets/icons/TelegramIcon';
import TwichIcon from '../../assets/icons/TwichIcon';
import TwitterIcon from '../../assets/icons/TwitterIcon';

type NAME = 'facebook' | 'twitter' | 'web' | 'telegram' | 'instagram' | 'chat';

const socialConfig: { [x: string]: any } = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  web: GlobeIcon,
  telegram: TelegramIcon,
  instagram: InstagramIcon,
  chat: TwichIcon,
};

const RenderSocialIcon: FC<{ name: NAME }> = ({ name }) => {
  const Icon = socialConfig[name];
  return <Icon />;
};

export default RenderSocialIcon;
