import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import CrossIcon from '../../assets/icons/CrossIcon';
import ActionButton from '../../components/ActionButton/ActionButton';
import InputField from '../../components/InputField/InputField';
import DialogLayout from '../../layouts/DialogLayout/DialogLayout';

import { lightTheme } from '../../theme';
import { updateAdminGuildSplit, updateUserRevenueSplit } from '../../api';
import axios from 'axios';
import useRole from '../../hooks/useRole';
import { rolesEnum } from '../../types/enums';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  gameId: string;
  handleFetchUsers: () => void;
}

const GuildUserRevenueSplit: FC<IProps> = ({
  open,
  setOpen,
  gameId,
  handleFetchUsers,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { role } = useRole();
  const guild = useSelector((state: RootState) => state.guilds.currentGuild);
  const revenueUser = useSelector(
    (state: RootState) => state.users.selectedRevenueUser
  );
  const revenueGuildManager = useSelector(
    (state: RootState) => state.users.selectedRevenueGuildManager
  );
  const revenueGameMaster = useSelector(
    (state: RootState) => state.users.selectedRevenueGameMaster
  );

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    scholar:
      `${revenueUser?.username || ''} ${revenueUser?.last_name || ''}` || '',
    scholarSplit: revenueUser?.revenue_split || 0,
    captainSplit: revenueUser?.captain_revenue_split || 0,
    guildManagerSplit: revenueGuildManager?.guild_revenue_split || 0,
    gameMasterSplit: revenueGameMaster?.revenue_split || 0,
    guildManger: revenueGuildManager?.username || '',
    captain: revenueUser?.captain_username || '',
    gameMaster: revenueGameMaster?.username || '',
  });

  const [error, setError] = useState('');

  const handleSaveChanges = async () => {
    setError('');
    const total =
      +revenueGuildManager!.admin_revenue_split +
      +formData.captainSplit +
      +formData.gameMasterSplit +
      +formData.guildManagerSplit +
      +formData.scholarSplit;
    if (total !== 100) {
      setError(
        `Over all sum of revenue split must not exceed or less than 100 which is now ${total}. Please adjust value of revenue split to make overall sum 100`
      );
    } else {
      try {
        setLoading(true);
        await Promise.all([
          updateAdminGuildSplit({
            guild_percentage: formData.guildManagerSplit,
            admin_percentage: revenueGuildManager!.admin_revenue_split,
            guildId: guild!.id,
            gameId: gameId,
          }),
          updateUserRevenueSplit({
            gameId: gameId,
            userId: revenueUser!.id,
            role: revenueUser!.role_id,
            percentage: formData.scholarSplit,
          }),
          updateUserRevenueSplit({
            gameId: gameId,
            userId: revenueUser!.id,
            role: revenueUser!.captain_role_id,
            percentage: +formData.captainSplit,
          }),
          revenueGameMaster &&
            Object.keys(revenueGameMaster).length &&
            updateUserRevenueSplit({
              gameId: gameId,
              userId: revenueGameMaster!.user_id,
              role: revenueGameMaster!.role_id,
              percentage: formData.gameMasterSplit,
            }),
        ]);
        handleFetchUsers();
        setOpen(false);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          dispatch.errorModal.setOpen({
            title: 'Error!',
            description: errorMessage,
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const total =
      +revenueGuildManager!.admin_revenue_split +
      +formData.captainSplit +
      +formData.gameMasterSplit +
      +formData.guildManagerSplit +
      +formData.scholarSplit;

    if (total === 100) {
      setError('');
    } else {
      setError(
        `Over all sum of revenue split must not exceed or less than 100 which is now ${total}. Please adjust value of revenue split to make overall sum 100`
      );
    }
    // eslint-disable-next-line
  }, [formData]);
  return (
    <DialogLayout open={open}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>Revenue Split</p>
          <div className='clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <p className='font-size-14 text-secondary subtitle'>
          Set Revenue Split for your Guild members. Percentage for Gamified is{' '}
          {revenueGuildManager?.admin_revenue_split}%.
        </p>
        <FieldsGrid>
          {!(role?.name === rolesEnum.GameCaptain) && (
            <>
              <InputField
                label='Guild Manager'
                type='text'
                placeholder='Jack Cooper (Yourself)'
                value={formData.guildManger}
                readOnly
                onChange={handleChange}
                name='guildManger'
              />
              <InputField
                label='Guild Manager’s Split'
                type='number'
                placeholder=''
                value={formData.guildManagerSplit}
                name='guildManagerSplit'
                onChange={handleChange}
              />
              {revenueGameMaster && Object.keys(revenueGameMaster).length && (
                <>
                  <InputField
                    type='text'
                    label='Game Master'
                    value={formData.gameMaster}
                    readOnly
                    onChange={handleChange}
                    name='gameMaster'
                  />
                  <InputField
                    label='Game Master’s Split'
                    type='number'
                    placeholder=''
                    value={formData.gameMasterSplit}
                    name='gameMasterSplit'
                    onChange={handleChange}
                  />
                </>
              )}
            </>
          )}

          <InputField
            type='text'
            label='Captain'
            value={formData.captain}
            onChange={handleChange}
            name='captain'
            readOnly
          />
          <InputField
            label='Captain’s Split'
            type='number'
            placeholder=''
            value={formData.captainSplit}
            name='captainSplit'
            onChange={handleChange}
          />
          <InputField
            type='text'
            label='Scholar'
            onChange={handleChange}
            name='scholar'
            value={formData.scholar}
            readOnly
          />
          <InputField
            label='Scholar’s Split'
            type='number'
            placeholder=''
            value={formData.scholarSplit}
            name='scholarSplit'
            onChange={handleChange}
          />
        </FieldsGrid>
        {error && (
          <p
            className='font-size-18 text-danger'
            style={{ marginBottom: '10px' }}
          >
            {error}
          </p>
        )}
        <div className='divider'></div>
        <ButtonContainer>
          <ActionButton
            onClick={handleClose}
            variant='primary'
            className='btn btn-primary'
            disabled={loading}
          >
            Cancel
          </ActionButton>
          <ActionButton
            variant='primary'
            onClick={handleSaveChanges}
            className='btn btn-secondary'
            disabled={loading}
            loading={loading}
          >
            Save Changes
          </ActionButton>
        </ButtonContainer>
      </Container>
    </DialogLayout>
  );
};

export default GuildUserRevenueSplit;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  padding: 30px;
  width: 740px;

  & .subtitle {
    margin-top: 9px;
  }

  & .divider {
    border-top: 1px solid ${({ theme }) => theme.color.text.secondary + '33'};
  }
`;

const FieldsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 14px;
  margin-block: 30px;

  & label {
    font-weight: 700;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  & .btn {
    height: 44px;
    width: 150px;
    border-radius: 20px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    & .btn {
      height: 55px;
      width: 198px;
      border-radius: 30px;
    }
  }
`;
