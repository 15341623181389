import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';

import MoreIcon from '../../assets/icons/MoreIcon';

import GameSlider from '../../components/GameSlider/GameSlider';
import UsersTable from './Components/UsersTable';
import { lightTheme as theme } from '../../theme';
import useRole from '../../hooks/useRole';
import { features, modulesEnum, rolesEnum } from '../../types/enums';
import { IGuildStats } from '../../types/api';
import { getGuildStats } from '../../api';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../hooks/useTheme';
import WithPermissions from '../../components/WithPermissions/WithPermissions';
// import TransferMultipleAssets from '../../Dialogs/TransferMultipleAssets/TransferMultipleAssets';
// import MangeAssetsAll from '../../Dialogs/ManageAssetsAll/MangeAssetsAll';

const Dashboard = () => {
  const { role } = useRole();
  const { theme } = useTheme();
  const dispatch = useDispatch<Dispatch>();

  const guild = useSelector((state: RootState) => state.guilds.currentGuild);

  const [stats, setStats] = useState<IGuildStats | null>(null);

  const [loading, setLoading] = useState(false);

  const handleGetStats = async () => {
    try {
      setLoading(true);
      if (role?.name === rolesEnum.Admin) {
        const { data } = await getGuildStats();
        setStats(data);
      } else {
        const { data } = await getGuildStats(guild?.id);
        setStats(data);
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch.roles.handleGetAllRoles();
    dispatch.badges.handleGetAllBadges();

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (role) {
      if (role.name === rolesEnum.Admin) {
        dispatch.games.handleGetAllGames();
      } else {
        if (role && guild) {
          dispatch.games.handleGetAllGames(guild.id);
        }
      }
      handleGetStats();
    }
    // eslint-disable-next-line
  }, [role, guild]);
  return (
    <div>
      <StatsContainer
        style={{
          gridTemplateColumns: `repeat(${
            role?.name === rolesEnum.Admin ? 6 : 5
          }, 1fr)`,
        }}
      >
        <WithPermissions
          moduleName={modulesEnum.Dashboard}
          featureName={features.totalGames}
        >
          <div className='stats'>
            <p className='font-size-16 text-dimmer'>Total Games</p>
            <p className='font-size-22 text-primary'>
              {loading ? (
                <ClipLoader size={15} color={theme.color.text.primary} />
              ) : (
                stats?.totalGames.total
              )}
            </p>
          </div>
        </WithPermissions>
        <WithPermissions
          moduleName={modulesEnum.Dashboard}
          featureName={features.totalGuilds}
        >
          <div className='stats'>
            <p className='font-size-16 text-dimmer'>Total Guilds</p>
            <p className='font-size-22 text-primary'>
              {loading ? (
                <ClipLoader size={15} color={theme.color.text.primary} />
              ) : (
                stats?.totalGuilds.total
              )}
            </p>
          </div>
        </WithPermissions>
        <WithPermissions
          moduleName={modulesEnum.Dashboard}
          featureName={features.gameMasters}
        >
          <div className='stats'>
            <p className='font-size-16 text-dimmer'>Total Game masters</p>
            <p className='font-size-22 text-primary'>
              {loading ? (
                <ClipLoader size={15} color={theme.color.text.primary} />
              ) : (
                stats?.totalMasters.total
              )}
            </p>
          </div>
        </WithPermissions>
        <WithPermissions
          moduleName={modulesEnum.Dashboard}
          featureName={features.captains}
        >
          <div className='stats'>
            <p className='font-size-16 text-dimmer'>Total Captains</p>
            <p className='font-size-22 text-primary'>
              {loading ? (
                <ClipLoader size={15} color={theme.color.text.primary} />
              ) : (
                stats?.totalCaptains.total
              )}
            </p>
          </div>
        </WithPermissions>
        <WithPermissions
          moduleName={modulesEnum.Dashboard}
          featureName={features.scholars}
        >
          <div className='stats'>
            <p className='font-size-16 text-dimmer'>Total Scholars</p>
            <p className='font-size-22 text-primary'>
              {loading ? (
                <ClipLoader size={15} color={theme.color.text.primary} />
              ) : (
                stats?.totalScholars.total
              )}
            </p>
          </div>
        </WithPermissions>

        <WithPermissions
          moduleName={modulesEnum.Dashboard}
          featureName={features.tournaments}
        >
          <div className='stats'>
            <p className='font-size-16 text-dimmer'>Ongoing Tournaments</p>
            <p className='font-size-22 text-primary'>
              {loading ? (
                <ClipLoader size={15} color={theme.color.text.primary} />
              ) : (
                stats?.ongoingTournaments.total
              )}
            </p>
          </div>
        </WithPermissions>
      </StatsContainer>
      <FlexLayout>
        <UersAndGames>
          <WithPermissions
            moduleName={modulesEnum.Dashboard}
            featureName={features.gameList}
          >
            <GameSlider />
          </WithPermissions>
          <WithPermissions
            moduleName={modulesEnum.Dashboard}
            featureName={features.usersList}
          >
            <UsersTable />
          </WithPermissions>
        </UersAndGames>
        <TopItems>
          <WithPermissions
            moduleName={modulesEnum.Dashboard}
            featureName={features.topGames}
          >
            <div className='row justify-space-between title-row'>
              <p className='font-size-18 text-primary text-bold'>Top games</p>
              <MoreIcon className='more-icon' />
            </div>
            <TableContainer>
              <table>
                <thead>
                  <tr className='header-row'>
                    <th className='text-primary font-size-12 text-semibold'>
                      Rank
                    </th>
                    <th className='text-primary font-size-12 text-semibold'>
                      Game
                    </th>
                    <th className='text-primary font-size-12 text-semibold'>
                      Earnings
                    </th>
                    <th className='text-primary font-size-12 text-semibold'>
                      Revenue <br /> Split
                    </th>
                    <th className='text-primary font-size-12 text-semibold'>
                      Time spent <br /> this month
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='font-size-14 text-primary text-semibold'>
                      1st
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      123.7 hrs
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      1st
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      30/70
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      1st
                    </td>
                  </tr>
                  <tr>
                    <td className='font-size-14 text-primary text-semibold'>
                      2nd
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      123.7 hrs
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      1st
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      30/70
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      1st
                    </td>
                  </tr>
                  <tr>
                    <td className='font-size-14 text-primary text-semibold'>
                      3rd
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      123.7 hrs
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      1st
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      30/70
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      1st
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
          </WithPermissions>

          <WithPermissions
            moduleName={modulesEnum.Dashboard}
            featureName={features.topEarningGuilds}
          >
            <div className='title-row'>
              <p className='font-size-18 text-primary text-bold'>
                Top earning guilds
              </p>
            </div>
            <TableContainer>
              <table>
                <thead>
                  <tr className='header-row'>
                    <th className='text-primary font-size-12 text-semibold'>
                      Name
                    </th>
                    <th className='text-primary font-size-12 text-semibold'>
                      Scholars
                    </th>
                    <th className='text-primary font-size-12 text-semibold'>
                      Captains
                    </th>
                    <th className='text-primary font-size-12 text-semibold'>
                      Revenue
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='font-size-14 text-primary text-semibold'>
                      99Starz
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      235
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      2
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      4,000 THC
                    </td>
                  </tr>
                  <tr>
                    <td className='font-size-14 text-primary text-semibold'>
                      99Starz
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      235
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      2
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      4,000 THC
                    </td>
                  </tr>
                  <tr>
                    <td className='font-size-14 text-primary text-semibold'>
                      99Starz
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      235
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      2
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      4,000 THC
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
          </WithPermissions>

          <WithPermissions
            moduleName={modulesEnum.Dashboard}
            featureName={features.topPerformingScholars}
          >
            <div className='title-row'>
              <p className='font-size-18 text-primary text-bold'>
                Top performing scholars
              </p>
            </div>
            <TableContainer>
              <table>
                <thead>
                  <tr className='header-row'>
                    <th className='text-primary font-size-12 text-semibold'>
                      Name
                    </th>
                    <th className='text-primary font-size-12 text-semibold'>
                      Games
                    </th>
                    <th className='text-primary font-size-12 text-semibold'>
                      Earnings
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='font-size-14 text-primary text-semibold'>
                      99Starz
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      235
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      2
                    </td>
                  </tr>
                  <tr>
                    <td className='font-size-14 text-primary text-semibold'>
                      99Starz
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      235
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      2
                    </td>
                  </tr>
                  <tr>
                    <td className='font-size-14 text-primary text-semibold'>
                      99Starz
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      235
                    </td>
                    <td className='font-size-14 text-primary text-semibold'>
                      2
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
          </WithPermissions>
        </TopItems>
      </FlexLayout>
      {/* <TransferMultipleAssets /> */}
      {/* <MangeAssetsAll /> */}
    </div>
  );
};

export default Dashboard;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 13px 0px;
  border-radius: 10px;

  & .stats {
    display: flex;
    align-items: center;
    height: 25px;
    gap: 20px;
    justify-content: flex-start;
    padding-inline: 20px;
    border-left: 1px solid ${({ theme }) => theme.color.icon.inActive};

    &:first-of-type {
      border-left: none;
    }
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    padding: 17px 0px;
    & .stats {
      height: 37px;
      gap: 29px;
      padding-inline: 30px;
    }
  }
`;

const FlexLayout = styled.div`
  display: flex;
  align-items: stretch;
  margin-top: 20px;
  gap: 20px;

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    margin-top: 30px;
    gap: 30px;
  }
`;

const UersAndGames = styled.div`
  flex: 3;
  width: 70vw;
`;

const TopItems = styled.div`
  flex: 1;
  background-color: blue;
  padding: 20px;
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 10px;

  & .more-icon {
    cursor: pointer;
  }

  & .title-row {
    margin-bottom: 21px;
  }
`;

const TableContainer = styled.div`
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  & table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));

    & tr {
      height: 63px;

      & td {
        text-align: left;
        background-color: ${({ theme }) => theme.color.background.row};
        &:first-of-type {
          padding-left: 15px;
        }
        &:last-of-type {
          padding-right: 15px;
        }

        border-bottom: 0.5px solid
          ${({ theme }) => theme.color.background.rowSeperator};
      }
      &:last-of-type {
        & td {
          border-bottom: 0px;
          &:first-of-type {
            border-bottom-left-radius: 10px;
          }
          &:last-of-type {
            border-bottom-right-radius: 10px;
          }
        }
      }
      &.header-row {
        height: 50px;
        & th {
          text-align: left;
          background-color: ${({ theme }) => theme.color.background.headerRow};
          &:first-of-type {
            padding-left: 15px;
            border-top-left-radius: 10px;
          }
          &:last-of-type {
            padding-right: 15px;
            border-top-right-radius: 10px;
          }
        }
      }
    }
  }
`;
