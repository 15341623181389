import React, { FC } from 'react';

import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const RedirectOutlet: FC = () => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  if (isLoggedIn) return <Navigate to='/dashboard' replace={true} />;
  return <Outlet />;
};

export default RedirectOutlet;
