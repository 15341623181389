import React, { FC } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import CalenderIcon from '../../../assets/icons/CalenderIcon';
import IDIcon from '../../../assets/icons/IDIcon';
import TrophyIcon from '../../../assets/icons/TrophyIcon';

import assets from '../../../assets';

import { lightTheme, darkTheme } from '../../../theme';
import { ITournament } from '../../../types/interface';

dayjs.extend(utc);

interface IProps {
  tournament: ITournament;
}

const TournamentCard: FC<IProps> = ({ tournament }) => {
  const navigate = useNavigate();
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const theme = activeTheme === 'light' ? lightTheme : darkTheme;

  const badges = useSelector((state: RootState) => state.badges.badges);

  return (
    <Container
      onClick={() =>
        navigate(`/dashboard/tournaments/details/${tournament.id}`, {
          state: { tournament },
        })
      }
    >
      <div className='image'>
        <img
          src={tournament.cover_photo || assets.hero3}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          alt=''
        />
      </div>
      <div className='row time-info justify-space-between'>
        <div className='row'>
          <div className='icon'>
            <CalenderIcon />
          </div>
          <p className='font-size-14 text-dim'>
            {dayjs(tournament.start_date).format('ddd, MMM YYYY ')}
          </p>
        </div>
        {/* <div className='row'>
          <div className='icon'>
            <ClockIcon />
          </div>
          <p className='font-size-14 text-dim'>09:00 AM</p>
        </div> */}
        <p className='font-size-10 text-bold chip'>
          IN {dayjs(tournament.start_date).diff(new Date(), 'd')} DAYS
        </p>
      </div>
      <p className='font-size-15 text-bold text-primary game-title'>
        {tournament.name}
      </p>
      <div className='divider'></div>

      <div className='row justify-space-between duration-prize'>
        <div className='row'>
          <div className='icon'>
            <CalenderIcon
              className='calender'
              fill={theme.color.text.primary}
            />
          </div>
          <div className='info'>
            <p className='font-size-10 text-dim text-center'>DURATION</p>
            <p className='font-size-14 text-primary text-bold text-center'>
              {dayjs(tournament.end_date).diff(tournament.start_date, 'd')} Days
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='icon'>
            <IDIcon />
          </div>
          <div className='info'>
            <p className='font-size-10 text-dim text-center'>BADGE</p>
            <p className='font-size-14 text-primary text-bold text-center capitalize'>
              {
                badges
                  .find(badge => badge.id === tournament.badge_id)
                  ?.name.split(' ')[0]
              }
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='icon'>
            <TrophyIcon />
          </div>
          <div className='info'>
            <p className='font-size-10 text-dim text-center'>PRIZE</p>
            <p className='font-size-14 text-primary text-bold text-center'>
              {tournament.prize}$
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TournamentCard;

const Container = styled.div`
  min-width: 300px;
  max-width: 300px;
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 15px;
  border-radius: 10px;

  & .image {
    width: 100%;
    aspect-ratio: 1/0.53;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  & .time-info {
    gap: 20px;

    & .text-dim {
      opacity: 0.5;
    }

    & .row {
      gap: 10px;
      & .icon {
        opacity: 0.5;
      }
    }

    & .chip {
      padding: 3px 10px;
      border-radius: 15px;
      background-color: ${({ theme }) =>
        theme.color.background.button.primary.bg};
      color: ${({ theme }) => theme.color.background.button.primary.color};
    }
  }

  & .game-title {
    margin-block: 9px 14px;
  }

  & .divider {
    height: 0.5px;
    width: 100%;
    background-color: ${({ theme }) => theme.color.text.dim};
    opacity: 0.8;
    margin-bottom: 10px;
  }

  & .duration-prize {
    & .row {
      gap: 12px;

      & .icon {
        & .calender {
          height: 15px;
          width: 14px;
        }
      }
      & .text-dim {
        opacity: 0.5;
      }
    }
  }

  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    min-width: 350px;
  }
`;
