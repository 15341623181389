import React, { FC } from 'react';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const Camera: FC<IProps> = ({ fill, ...rest }) => {
  return (
    <svg
      width='18'
      height='15'
      viewBox='0 0 18 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M15.5578 14.572H2.44381C1.86421 14.5714 1.3085 14.341 0.898662 13.9311C0.488822 13.5213 0.258342 12.9656 0.257812 12.386L0.257813 4.37198C0.258607 3.79255 0.489204 3.2371 0.899015 2.82747C1.30883 2.41785 1.86438 2.18751 2.44381 2.18698H4.96781L6.20881 0.324976C6.2754 0.225314 6.36554 0.143608 6.47124 0.0870941C6.57694 0.0305799 6.69495 0.00100098 6.81481 0.000976562H11.1858C11.3057 0.00100098 11.4237 0.0305799 11.5294 0.0870941C11.6351 0.143608 11.7252 0.225314 11.7918 0.324976L13.0328 2.18598H15.5578C16.1374 2.18651 16.6931 2.41699 17.103 2.82683C17.5128 3.23667 17.7433 3.79238 17.7438 4.37198V12.386C17.7433 12.9656 17.5128 13.5213 17.103 13.9311C16.6931 14.341 16.1374 14.5714 15.5578 14.572ZM2.44381 3.64398C2.25047 3.64398 2.06505 3.72078 1.92833 3.8575C1.79162 3.99421 1.71481 4.17963 1.71481 4.37298V12.387C1.71481 12.5803 1.79162 12.7657 1.92833 12.9025C2.06505 13.0392 2.25047 13.116 2.44381 13.116H15.5578C15.7512 13.116 15.9366 13.0392 16.0733 12.9025C16.21 12.7657 16.2868 12.5803 16.2868 12.387V4.37198C16.2868 4.17863 16.21 3.99321 16.0733 3.8565C15.9366 3.71978 15.7512 3.64298 15.5578 3.64298H12.6428C12.523 3.64295 12.4049 3.61337 12.2992 3.55686C12.1935 3.50034 12.1034 3.41864 12.0368 3.31898L10.7958 1.45798H7.20481L5.96381 3.31898C5.89722 3.41864 5.80709 3.50034 5.70138 3.55686C5.59568 3.61337 5.47767 3.64295 5.35781 3.64298L2.44381 3.64398Z'
        fill={fill || '#353940'}
      />
      <path
        d='M9.00042 4.37207C9.72094 4.37207 10.4253 4.58573 11.0244 4.98603C11.6235 5.38632 12.0904 5.95528 12.3661 6.62095C12.6418 7.28663 12.714 8.01911 12.5734 8.72578C12.4329 9.43246 12.0859 10.0816 11.5764 10.5911C11.0669 11.1005 10.4178 11.4475 9.71114 11.5881C9.00446 11.7286 8.27198 11.6565 7.60631 11.3808C6.94063 11.105 6.37168 10.6381 5.97138 10.039C5.57108 9.43992 5.35742 8.73559 5.35742 8.01507C5.35848 7.04921 5.74264 6.12322 6.4256 5.44025C7.10857 4.75728 8.03456 4.37313 9.00042 4.37207ZM9.00042 10.2001C9.43277 10.2001 9.85541 10.0719 10.2149 9.83166C10.5744 9.59146 10.8546 9.25006 11.02 8.85062C11.1855 8.45118 11.2288 8.01165 11.1444 7.5876C11.0601 7.16356 10.8519 6.77405 10.5462 6.46834C10.2404 6.16262 9.85093 5.95442 9.42689 5.87007C9.00285 5.78573 8.56331 5.82902 8.16388 5.99447C7.76444 6.15992 7.42303 6.44011 7.18283 6.79959C6.94263 7.15908 6.81442 7.58172 6.81442 8.01407C6.81495 8.59367 7.04543 9.14938 7.45527 9.55922C7.86511 9.96906 8.42082 10.1995 9.00042 10.2001Z'
        fill={fill || '#353940'}
      />
    </svg>
  );
};

export default Camera;
