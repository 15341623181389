import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  wait: number
) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: Parameters<F>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const truncateString = (value: string): string => {
  const start = value.slice(0, 6);
  const end = value.slice(value.length - 4, value.length);
  return `${start}...${end}`;
};

export const copyAddress = (address: string) => {
  navigator.clipboard.writeText(address);
  toast.success('Successfully copied! ');
};

export const getAgoTimeFromNow = (time: string) => {
  let minutes = dayjs().utc().diff(dayjs(time).utc(), 'm');
  let hours, days;

  hours = Math.floor(minutes / 60);
  minutes = minutes % 60;

  days = Math.floor(hours / 24);
  hours = hours % 24;

  if (days) {
    return `${days}d, ${hours}h and ${minutes}m ago`;
  }

  if (hours) {
    return `${hours}h and ${minutes}m ago`;
  }

  return `${minutes}m ago`;
};
