import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  [x: string]: any;
}

const CrossIcon: FC<IProps> = ({ ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M17.8429 14.7557L12.6319 9.54473L17.8429 4.33373C17.9433 4.23291 17.9996 4.09647 17.9996 3.95423C17.9996 3.812 17.9433 3.67555 17.8429 3.57473L15.3809 1.11173C15.2801 1.0114 15.1437 0.955078 15.0014 0.955078C14.8592 0.955078 14.7228 1.0114 14.6219 1.11173L9.40994 6.32673L4.19894 1.11173C4.09813 1.0114 3.96168 0.955078 3.81944 0.955078C3.67721 0.955078 3.54076 1.0114 3.43994 1.11173L0.977944 3.57373C0.877614 3.67455 0.821289 3.811 0.821289 3.95323C0.821289 4.09547 0.877614 4.23191 0.977944 4.33273L6.18894 9.54373L0.977944 14.7557C0.877614 14.8566 0.821289 14.993 0.821289 15.1352C0.821289 15.2775 0.877614 15.4139 0.977944 15.5147L3.43894 17.9767C3.53976 18.0771 3.67621 18.1334 3.81844 18.1334C3.96068 18.1334 4.09713 18.0771 4.19794 17.9767L9.40895 12.7657L14.6199 17.9767C14.7208 18.0771 14.8572 18.1334 14.9994 18.1334C15.1417 18.1334 15.2781 18.0771 15.3789 17.9767L17.8409 15.5147C17.9413 15.4139 17.9976 15.2775 17.9976 15.1352C17.9976 14.993 17.9413 14.8566 17.8409 14.7557H17.8429Z'
        fill={theme.color.text.primary}
      />
    </svg>
  );
};

export default CrossIcon;
