import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { object, string, InferType } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import CrossIcon from '../../assets/icons/CrossIcon';
import InputField from '../../components/InputField/InputField';
import Select from '../../components/Select/Select';
import ActionButton from '../../components/ActionButton/ActionButton';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';

import { lightTheme as theme } from '../../theme';
import { FormErrors, validateData } from '../../utils/validations';
import { Navigate } from 'react-router-dom';

dayjs.extend(utc);

const schema = object({
  name: string().required().label('Name'),
  game_id: string().required().label('Game'),
  guild_id: string().required().label('Guild'),
  start_date: string().required().label('Start date'),
  end_date: string().required().label('End date'),
  badge_id: string().required().label('Badge'),
  prize: string().required().label('Prize'),
  duration: string().required().label('Duration'),
});

interface IFormData extends InferType<typeof schema> {}

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}

const CreateTournament: FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();

  const badges = useSelector((state: RootState) => state.badges.badges);
  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );
  const [formData, setFormData] = useState<IFormData>({
    name: '',
    duration: '',
    start_date: '',
    end_date: '',
    badge_id: '',
    prize: '',
    game_id: '',
    guild_id: currentGuild?.id || '',
  });

  const [errors, setErrors] = useState<FormErrors<IFormData | null>>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { name, value } = e.currentTarget;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleCreateTournament = async () => {
    if (formData.duration !== 'custom') {
      const endDate = new Date(formData.start_date);
      endDate.setDate(endDate.getDate() + +formData.duration);
      setFormData(prev => ({
        ...prev,
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
      }));
    }

    const { errors: validationErrors } = await validateData<IFormData>(
      schema,
      formData
    );
    if (validationErrors) {
      return setErrors(validationErrors);
    }
    const { duration, ...rest } = formData;
    dispatch.tournaments.handleCreateTournament({ data: rest, setOpen });
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch.badges.handleGetAllBadges();
    // eslint-disable-next-line
  }, []);

  if (!currentGuild) {
    return <Navigate to='/dashboard/tournaments' />;
  }

  return (
    <DialogLayout open={open}>
      <Content>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>
            Create Tournament
          </p>
          <div className='icon clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <Grid>
          <InputField
            label='Name'
            valu={formData.name}
            onChange={handleChange}
            name='name'
            error={errors?.name}
            placeholder=''
            type='text'
          />
          <Select
            title='Duration'
            label='Select an option'
            value={formData.duration}
            onChange={handleChange}
            options={[
              { label: '1 Week', value: '7' },
              { label: '2 Week', value: '14' },
              { label: 'Custom', value: 'custom' },
            ]}
            name='duration'
            error={errors?.duration}
          />
          <InputField
            label='Starting Date'
            value={formData.start_date}
            onChange={handleChange}
            name='start_date'
            placeholder=''
            type='date'
            error={errors?.start_date}
          />
          {formData.duration === 'custom' && (
            <InputField
              label='End Date'
              placeholder=''
              value={formData.end_date}
              onChange={handleChange}
              name='end_date'
              type='date'
              error={errors?.end_date}
            />
          )}

          <Select
            title='Minimum Badge Required'
            label='Select Badge'
            value={formData.badge_id}
            inputClassName='capitalize'
            onChange={handleChange}
            options={badges.map(badge => ({
              label: badge.name,
              value: badge.id,
            }))}
            name='badge_id'
            error={errors?.badge_id}
          />
          <InputField
            label='Prize Pool'
            value={formData.prize}
            onChange={handleChange}
            name='prize'
            placeholder=''
            type='text'
            error={errors?.prize}
          />
          <Select
            title='Game'
            label='Select Game'
            value={formData.game_id}
            onChange={handleChange}
            options={
              currentGuild?.Games.map(game => ({
                label: game.name,
                value: game.id,
              })) || []
            }
            name='game_id'
            error={errors?.game_id}
          />
        </Grid>
        <Divider />
        <ButtonContainer>
          <ActionButton
            onClick={handleClose}
            variant='primary'
            className='action-btn btn-primary'
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleCreateTournament}
            variant='primary'
            className='action-btn btn-secondary'
          >
            Create
          </ActionButton>
        </ButtonContainer>
      </Content>
    </DialogLayout>
  );
};

export default CreateTournament;

const Content = styled.div`
  width: 780px;
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 30px;
  border-radius: 12px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 29px 40px;
  margin-block: 45px 30px;
`;

const Divider = styled.div`
  height: 0.5px;
  background-color: ${({ theme }) => theme.color.background.row};
  width: 100%;
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 18px;
  margin-top: 20px;

  & .action-btn {
    border-radius: 20px;
    height: 40px;
    width: 150px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .action-btn {
      border-radius: 30px;
      height: 55px;
      width: 189px;
    }
  }
`;
