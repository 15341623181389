import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  active: boolean;
  [x: string]: any;
}

const VaultIcon: FC<IProps> = ({ active, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='24'
      height='28'
      viewBox='0 0 24 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M7.78598 5.833H15.446V7.72H7.78598V5.833ZM0.000976562 12.543H23.214V0H0.000976562V12.543Z'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
      <path
        d='M7.76562 18.4368H15.4476V20.3038H7.76562V18.4368ZM0.015625 27.0868H23.2286V14.5498H0.015625V27.0868Z'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
    </svg>
  );
};

export default VaultIcon;
