import React, { FC, useState } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import assets from '../../assets';
import CalenderIcon from '../../assets/icons/CalenderIcon';
import TrophyIcon from '../../assets/icons/TrophyIcon';
import IDIcon from '../../assets/icons/IDIcon';
import TeamsTable from './components/TeamsTable';

import { darkTheme, lightTheme } from '../../theme';
import ActionButton from '../../components/ActionButton/ActionButton';
import Camera from '../../assets/icons/Camera';
import { ITournament } from '../../types/interface';
import WithPermissions from '../../components/WithPermissions/WithPermissions';
import { features, modulesEnum } from '../../types/enums';
import { updateTournamentImages } from '../../api';
import { toast } from 'react-toastify';

dayjs.extend(utc);

const TournamentDetails: FC = () => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const [loading, setLoading] = useState(false);

  const badges = useSelector((state: RootState) => state.badges.badges);
  const theme = activeTheme === 'light' ? lightTheme : darkTheme;

  const tournament: ITournament = useLocation().state.tournament;

  const [cover, setCover] = useState<File | string | null>(
    tournament.cover_photo || null
  );
  const [logo, setLogo] = useState<File | string | null>(
    tournament.logo || null
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.currentTarget;
    if (files?.length) {
      const file = files[0];
      let extension = file.name.split('.');
      const exten = extension[extension.length - 1].toLowerCase();
      if (exten === 'png' || exten === 'jpg' || exten === 'jpeg') {
        if (name === 'logo') {
          setLogo(file);
        } else if (name === 'cover') {
          setCover(file);
        }
      }
    }
  };

  const handleUpdateTournament = async () => {
    try {
      setLoading(true);
      const images = new FormData();
      if (logo && typeof logo !== 'string') {
        images.append('logo', logo);
      }
      if (cover && typeof cover !== 'string') {
        images.append('cover_photo', cover);
      }
      await updateTournamentImages(tournament.id, images);
      toast.success('Tournament updated successfully!');
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <div className='cover'>
        <div className='image'>
          <img
            src={
              cover
                ? typeof cover !== 'string'
                  ? URL.createObjectURL(cover)
                  : cover
                : assets.tournamentCover
            }
            alt=''
          />
        </div>
        <Details className='details'>
          <div className='info'>
            <p className='font-size-30 text-primary text-bold'>
              {tournament.name}
            </p>
            <div className='row time-row justify-space-between'>
              <div className='row'>
                <div className='icon'>
                  <CalenderIcon fill={theme.color.text.primary} />
                </div>
                <p className='font-size-14 text-primary'>
                  {dayjs(tournament.start_date).format('ddd, MMM YYYY ')}
                </p>
              </div>
              <p className='chip font-size-12 text-primary text-bold'>
                IN {dayjs(tournament.start_date).diff(new Date(), 'd')} DAYS
              </p>
            </div>
            <div className='divider'></div>
            <div className='row  duration-prize'>
              <div className='row'>
                <div className='icon'>
                  <CalenderIcon
                    className='calender'
                    fill={theme.color.text.primary}
                  />
                </div>
                <div className='info'>
                  <p className='font-size-10 text-dim text-center'>DURATION</p>
                  <p className='font-size-14 text-primary text-bold text-center'>
                    {dayjs(tournament.end_date).diff(
                      tournament.start_date,
                      'd'
                    )}{' '}
                    Days
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='icon'>
                  <IDIcon />
                </div>
                <div className='info'>
                  <p className='font-size-10 text-dim text-center'>BADGE</p>
                  <p className='font-size-14 text-primary text-bold text-center capitalize'>
                    {
                      badges
                        .find(badge => badge.id === tournament.badge_id)
                        ?.name.split(' ')[0]
                    }
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='icon'>
                  <TrophyIcon />
                </div>
                <div className='info'>
                  <p className='font-size-10 text-dim text-center'>PRIZE</p>
                  <p className='font-size-14 text-primary text-bold text-center'>
                    {tournament.prize}$
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='logo-container'>
            <div className='image'>
              <img
                src={
                  logo
                    ? typeof logo !== 'string'
                      ? URL.createObjectURL(logo)
                      : logo
                    : ''
                }
                alt=''
              />
              <WithPermissions
                moduleName={modulesEnum.Tournaments}
                featureName={features.editTournament}
                action
              >
                <label htmlFor='logo-input' className='logo-input'>
                  <div className='icon'>
                    <Camera />
                  </div>
                </label>
                <input
                  type='file'
                  id='logo-input'
                  style={{ display: 'none' }}
                  name='logo'
                  accept='image/jpg, image/jpeg, image/png'
                  onChange={handleChange}
                />
              </WithPermissions>
            </div>
          </div>
        </Details>
        <WithPermissions
          moduleName={modulesEnum.Tournaments}
          featureName={features.editTournament}
          action
        >
          <label htmlFor='cover-input' className='cover-input'>
            <div className='icon'>
              <Camera />
            </div>
          </label>
          <input
            type='file'
            onChange={handleChange}
            name='cover'
            accept='image/jpg, image/jpeg, image/png'
            id='cover-input'
            style={{ display: 'none' }}
          />
        </WithPermissions>
      </div>
      <div className='row btn-row'>
        <ActionButton variant='primary' className='btn-primary'>
          Axie Infinity
        </ActionButton>
        <WithPermissions
          moduleName={modulesEnum.Tournaments}
          featureName={features.editTournament}
          action
        >
          <ActionButton
            variant='primary'
            onClick={handleUpdateTournament}
            className='btn-secondary'
            loading={loading}
            disabled={loading}
          >
            Edit Tournament
          </ActionButton>
        </WithPermissions>
      </div>
      <TeamsTable tournament={tournament} />
    </Container>
  );
};

export default TournamentDetails;

const Container = styled.div`
  & .cover {
    height: 250px;
    border-radius: 15px;
    position: relative;

    & .image {
      height: 100%;
      border-radius: 15px;
      overflow: hidden;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .cover-input {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 40px;
      width: 40px;
      background-color: ${({ theme }) => theme.color.background.row};
      border-radius: 50%;
      display: grid;
      place-items: center;
    }
  }

  & .btn-row {
    justify-content: flex-end;
    gap: 10px;
    margin-top: 17px;

    & .btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      height: 44px;
      cursor: default;
    }
    & .btn-secondary {
      background-color: ${({ theme }) =>
        theme.color.background.button.primary.bg};
      color: ${({ theme }) => theme.color.background.button.primary.color};
      font-weight: 700;
      height: 44px;
    }
  }
`;

const Details = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  padding: 15px 20px;

  & .info {
    display: inline-block;
  }

  & .time-row {
    margin-top: 12px;
    gap: 20px;
    & .row {
      gap: 10px;
    }

    & .chip {
      background-color: ${({ theme }) => theme.color.background.card};
      padding: 3px 10px;
      border-radius: 15px;
    }
  }

  & .divider {
    height: 0.5px;
    background-color: ${({ theme }) => theme.color.text.primary};
    margin-block: 10px;
  }

  & .duration-prize {
    gap: 60px;
    & .row {
      gap: 10px;
      & .icon {
        & .calender {
          height: 15px;
          width: 14px;
        }
      }
    }
  }

  & .logo-container {
    height: 100px;
    margin-top: 35px;
    position: relative;

    & .image {
      height: 100%;
      height: 149px;
      width: 149px;
      border-radius: 50%;
      border: 10px solid ${({ theme }) => theme.color.background.row};
      position: relative;
      & .logo-input {
        position: absolute;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${({ theme }) => theme.color.background.row};
        display: grid;
        place-items: center;
      }
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: ${({ theme }) => theme.color.text.primary};
      }
    }
  }
`;
