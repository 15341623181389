import React, { FC, useState } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';
import Eye from '../../assets/icons/Eye';
import { lightTheme as theme } from '../../theme';
import SelectIcon from '../../assets/icons/SelectIcon';

interface IProps {
  label?: string;
  placeholder?: string;
  type: string;
  error?: string;
  inputClassName?: string;
  [x: string]: any;
}

const InputField: FC<IProps> = ({
  label,
  placeholder = '',
  type,
  error,
  inputClassName,
  ...rest
}) => {
  const [inputType, setInputType] = useState(type);

  const handleToggleVisibility = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  return (
    <Container>
      {label && (
        <label
          htmlFor={label}
          className={classNames('font-size-14', error && 'text-danger')}
        >
          {label}
        </label>
      )}

      <InputContainer>
        <input
          id={label}
          type={inputType}
          {...rest}
          placeholder={placeholder}
          className={classNames(inputClassName, error && 'error')}
        />
        {type === 'password' && (
          <Eye onClick={handleToggleVisibility} className='visibility-icon' />
        )}
        {type === 'number' && (
          <NumberButtons>
            <div className='up-arrow clickable'>
              <div className='icon'>
                <SelectIcon />
              </div>
            </div>
            <div className='down-arrow clickable'>
              <div className='icon'>
                <SelectIcon />
              </div>
            </div>
          </NumberButtons>
        )}
      </InputContainer>
      {error && <p className='font-size-12 error-message'>{error}</p>}
    </Container>
  );
};

export default InputField;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    gap: 10px;
  }

  & label {
    color: ${({ theme }) => theme.color.text.dim};
    &.text-danger {
      color: ${({ theme }) => theme.color.text.danger};
    }
  }

  & .error-message {
    margin-top: 5px;
    color: ${({ theme }) => theme.color.text.danger};
  }
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;

  & input {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    outline: none;
    border: none;
    padding-inline: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: ${({ theme }) => theme.color.text.primary};
    background-color: ${({ theme }) => theme.color.background.row};
    position: relative;

    &.error {
      background-color: ${({ theme }) =>
        theme.color.text.danger + '1a'}!important;
      border: 1px solid ${({ theme }) => theme.color.text.danger};
    }
  }

  & .visibility-icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    cursor: pointer;
    width: 16px;
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & input {
      height: 55px;
      padding-inline: 26px;
    }
    & .visibility-icon {
      width: unset;
      right: 26px;
    }
  }
`;

const NumberButtons = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 25px;
  border-radius: 0px 8px 8px 0px;
  overflow: hidden;
  border-left: 1px solid ${({ theme }) => theme.color.background.headerRow};
  pointer-events: none;

  & .up-arrow {
    height: calc(50% - 0.5px);
    display: grid;
    place-items: center;
    & .icon {
      transform: rotate(-180deg);
      & svg {
        width: 8px;
      }
    }
  }

  & .down-arrow {
    height: calc(50% - 0.5px);
    border-top: 1px solid ${({ theme }) => theme.color.background.headerRow};
    display: grid;
    place-items: center;
    & .icon {
      padding-bottom: 4px;
      & svg {
        width: 8px;
      }
    }
  }
`;
