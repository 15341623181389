import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const TelegramIcon: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='21'
      height='17'
      viewBox='0 0 21 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M20.3654 2.19555L17.4354 16.0165C17.2144 16.9915 16.6354 17.2345 15.8184 16.7755L11.3524 13.4845L9.1994 15.5565C9.09357 15.6943 8.95718 15.8056 8.801 15.8816C8.64481 15.9576 8.47309 15.9963 8.2994 15.9945L8.6204 11.4465L16.8994 3.96855C17.2594 3.64755 16.8214 3.46855 16.3404 3.79055L6.1064 10.2335L1.6994 8.85455C0.741396 8.55455 0.724396 7.89655 1.8994 7.43655L19.1304 0.799548C19.9284 0.500548 20.6264 0.977548 20.3654 2.19555Z'
        fill={fill || theme.color.text.primary}
      />
    </svg>
  );
};

export default TelegramIcon;
