import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import CrossIcon from '../../assets/icons/CrossIcon';
import InputField from '../../components/InputField/InputField';
import Select from '../../components/Select/Select';
import ActionButton from '../../components/ActionButton/ActionButton';
import SearchBar from '../../components/SearchBar/SearchBar';
import DialogLayout from '../../layouts/DialogLayout/DialogLayout';

import { lightTheme as theme } from '../../theme';
import CloseIcon from '../../assets/icons/CloseIcon';
import {
  captainGames,
  getGuildGameCaptains,
  scholarsByGameAndCaptain,
  transferScholars,
} from '../../api';
import { IGame, ITransferScholars } from '../../types/api';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../hooks/useTheme';
import { IGameCaptain, IUser } from '../../types/interface';
import axios from 'axios';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}

const TransferScholars: FC<IProps> = ({ open, setOpen }) => {
  const { theme } = useTheme();
  const dispatch = useDispatch<Dispatch>();
  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );
  const captain = useSelector(
    (state: RootState) => state.users.selectedCaptain
  );

  const [gamesLoading, setGamesLoading] = useState(false);
  const [games, setGames] = useState<IGame[]>([]);
  const [selectedGame, setSelectedGame] = useState('');
  const [captainsLoading, setCaptainsLoading] = useState(false);
  const [captains, setCaptains] = useState<IGameCaptain[]>([]);
  const [selectedCaptain, setSelectedCaptain] = useState('');
  // eslint-disable-next-line
  const [scholarsLoading, setScholoarsLoading] = useState(false);
  const [scholars, setScholars] = useState<IUser[]>([]);
  const [selectedScholars, setSelectedScholars] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChangeGame = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedGame(e.currentTarget.value);
  };

  const handleCaptainChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCaptain(e.currentTarget.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectScholar = (scholar: IUser) => {
    setSelectedScholars(perv => [...perv, scholar]);
    setScholars([...scholars.filter(s => s.id !== scholar.id)]);
  };

  const handleDeselectScholar = (scholar: IUser) => {
    setScholars(perv => [...perv, scholar]);
    setSelectedScholars([...selectedScholars.filter(s => s.id !== scholar.id)]);
  };

  const handleGetCaptainGames = async () => {
    try {
      setGamesLoading(true);
      const { data } = await captainGames(currentGuild!.id, captain!.id);
      setGames(data);
      if (data.length) {
        setSelectedGame(data[0].id);
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setGamesLoading(false);
    }
  };

  const handleGetCaptains = async () => {
    try {
      setError('');
      setCaptainsLoading(true);
      const { data } = await getGuildGameCaptains(
        currentGuild!.id,
        selectedGame
      );
      const allCaptains = data.filter(cap => cap.id !== captain!.id);
      setCaptains(allCaptains);
      if (!allCaptains.length) {
        setError('No other captain available for this game');
      }
      if (allCaptains.length) {
        setSelectedCaptain(allCaptains[0].id);
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setCaptainsLoading(false);
    }
  };

  const handleGetScholars = async () => {
    try {
      setScholoarsLoading(true);
      const { data } = await scholarsByGameAndCaptain(
        captain!.id,
        selectedGame
      );
      setScholars(data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setScholoarsLoading(false);
    }
  };

  const handleSaveChanges = async () => {
    try {
      setLoading(true);
      const apiData: ITransferScholars = {
        fromCaptain: captain!.id,
        toCaptain: selectedCaptain,
        scholars: selectedScholars.map(scholar => scholar.id),
        gameId: selectedGame,
        guildId: currentGuild!.id,
      };
      await transferScholars(apiData);
      setOpen(false);
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const message = err.response?.data?.message;
        dispatch.errorModal.setOpen({ title: 'Error!', description: message });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (captain && currentGuild) {
      handleGetCaptainGames();
    }
    // eslint-disable-next-line
  }, [captain, currentGuild]);

  useEffect(() => {
    if (selectedGame) {
      handleGetCaptains();
      handleGetScholars();
    }
    // eslint-disable-next-line
  }, [selectedGame]);

  useEffect(() => {
    console.log('scholars', scholars);
    console.log('selectedScholars', selectedScholars);
  }, [selectedScholars, scholars]);
  return (
    <DialogLayout open={open}>
      <Content>
        <div className='left'>
          <p className='font-size-25 text-primary text-bold'>
            Transfer Scholars
          </p>
          <p className='font-size-14 text-secondary subtitle'>
            Transfer scholars to any other captain in the guild
          </p>
          <FieldContainer>
            <Grid>
              <InputField
                label='Previous Captain'
                value={`${captain?.username} ${captain?.last_name}`}
                placeholder=''
                type='text'
                readOnly
              />
              {gamesLoading ? (
                <div className='row justify-center'>
                  <ClipLoader size={40} color={theme.color.text.primary} />
                </div>
              ) : (
                <Select
                  options={games.map(game => ({
                    label: game.name,
                    value: game.id,
                  }))}
                  onChange={handleChangeGame}
                  title='Game'
                  label='select game'
                  value={selectedGame}
                />
              )}
            </Grid>
          </FieldContainer>
          <FieldContainer style={{ marginBottom: '150px' }}>
            {captainsLoading ? (
              <div className='row justify-center'>
                <ClipLoader size={40} color={theme.color.text.primary} />
              </div>
            ) : (
              <Select
                label='select captain'
                title='Transfer to'
                options={captains.map(cap => ({
                  label: `${cap.username} ${cap.last_name}`,
                  value: cap.id,
                }))}
                onChange={handleCaptainChange}
                value={selectedCaptain}
                error={error}
              />
            )}
          </FieldContainer>
          <div className='divider-horizontail'></div>
          <ButtonContainer>
            <ActionButton
              onClick={handleClose}
              variant='primary'
              className='action-btn btn-primary'
              disabled={loading}
            >
              Cancel
            </ActionButton>
            <ActionButton
              variant='primary'
              className='action-btn btn-secondary'
              onClick={handleSaveChanges}
              loading={loading}
              disabled={loading}
            >
              Save Changes
            </ActionButton>
          </ButtonContainer>
        </div>
        <div className='divider'></div>
        <div className='right'>
          <div className='row justify-space-between'>
            <p className='font-size-18 text-primary text-bold'>Scholars (15)</p>
            <div className='icon clickable' onClick={handleClose}>
              <CrossIcon />
            </div>
          </div>

          <SelectedScholars>
            {selectedScholars.map(scholar => (
              <div key={scholar.id} className='scholar '>
                <div className='image'>
                  <img src={scholar.image_url || ''} alt='' />
                </div>
                <p className='font-size-8 text-primary'>{`${scholar.username} ${scholar.last_name}`}</p>
                <div
                  className='icon clickable'
                  onClick={() => handleDeselectScholar(scholar)}
                >
                  <CloseIcon className='inner' />
                </div>
              </div>
            ))}
          </SelectedScholars>

          <div className='divider-horizontal'></div>

          <div
            className='row filters justify-space-between'
            style={{ width: '100%' }}
          >
            <p className='font-size-18 text-primary text-bold'>
              Total Members (215)
            </p>
            <div className='row'>
              <SearchBar className='search' />
            </div>
          </div>
          <ScholarsList>
            {scholars.map(scholar => (
              <div
                key={scholar.id}
                className='scholar clickable'
                onClick={() => handleSelectScholar(scholar)}
              >
                <div className='image'>
                  <img src={scholar.image_url || ''} alt='' />
                </div>
                <p className='font-size-8 text-primary'>{`${scholar.username} ${scholar.last_name}`}</p>
              </div>
            ))}
          </ScholarsList>
        </div>
      </Content>
    </DialogLayout>
  );
};

export default TransferScholars;

const Content = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  display: flex;
  align-items: stretch;
  /* height: 620px; */
  width: 997px;
  overflow: hidden;

  & .left {
    width: 470px;
    padding: 30px;

    & .subtitle {
      margin-top: 10px;
    }

    & .divider-horizontail {
      height: 0.5px;
      width: 100%;
      background-color: ${({ theme }) => theme.color.text.secondary};
      margin-block: 50px 30px;
    }
  }

  & .divider {
    width: 0.5px;
    background-color: ${({ theme }) => theme.color.text.secondary};
  }

  & .right {
    flex-grow: 1;
    padding: 24px 30px;
    display: flex;
    flex-direction: column;

    & .divider-horizontal {
      height: 0.5px;
      width: 100%;
      background-color: ${({ theme }) => theme.color.text.secondary};
      margin-bottom: 24px;
    }

    & .filters {
      & .row {
        gap: 10px;
        & .select-bar {
          width: 111px;
        }

        & .search {
          width: 144px;
        }
      }
    }
  }
`;

const FieldContainer = styled.div`
  margin-top: 40px;

  & label {
    font-weight: 700;
    color: ${({ theme }) => theme.color.text.primary}!important;
  }

  &:first-of-type {
    margin-top: 48px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  margin-top: 20px;

  & .action-btn {
    border-radius: 20px;
    height: 40px;
    width: 150px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .action-btn {
      border-radius: 30px;
      height: 55px;
      width: 189px;
    }
  }
`;

const SelectedScholars = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-block: 20px 30px;
  max-height: 172px;
  overflow-y: auto;
  padding-top: 5px;

  & .scholar {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    & .image {
      height: 60px;
      width: 60px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.color.text.primary};
      overflow: hidden;
      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    & .icon {
      position: absolute;
      border: 2px solid ${({ theme }) => theme.color.background.card};
      border-radius: 50%;
      top: -6px;
      right: -6px;
      & .inner {
        width: 8px;
        height: 8px;
      }
    }
  }
`;

const ScholarsList = styled.div`
  margin-top: 21px;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  height: 0px;
  overflow-y: auto;
  align-items: flex-start;

  & .scholar {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    & .image {
      height: 60px;
      width: 60px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.color.text.primary};
      overflow: hidden;
      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;
