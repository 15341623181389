import React, { FC, useEffect, useState, useCallback } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { useNavigate, useLocation } from 'react-router-dom';
import { string, object, array, InferType } from 'yup';
import { ethers } from 'ethers';

import Camera from '../../assets/icons/Camera';
import ActionButton from '../../components/ActionButton/ActionButton';
import Select from '../../components/Select/Select';
import SimilarGame from './SimilarGame';
import Screenshots from './Screenshots';
import CategorySelector from '../../components/CategorySelector/CategorySelector';

import assets from '../../assets';

import { lightTheme, darkTheme } from '../../theme';
import InputField from '../../components/InputField/InputField';
import { IBadge, ICategory, IGame } from '../../types/api';
import classNames from 'classnames';
import { FormErrors, validateData } from '../../utils/validations';
import {
  addFtContract,
  addGameCategory,
  addGameScreenshots,
  addGameToBadge,
  addNFTContract,
  deleteGameFtContract,
  deleteGameNftContract,
  deleteGameScreenshot,
  gameBadges,
  gameDetails,
  getGameFtContracts,
  getGameNftContracts,
  removeBadgeFromGame,
  removeGameCategory,
  updateGame,
  updateGameFtContract,
  updateGameImages,
  updateGameNftContract,
} from '../../api';
import { IGameBadge, IScreenshot } from '../../types/interface';
import { toast } from 'react-toastify';

const initialNftContractData = {
  chain: '',
  contract: '',
  contract_type: '',
  id: '',
};

const initialFtContractData = {
  contract: '',
  chain: '',
  minimum_tokens: '',
  id: '',
};

const nftContractSchema = object({
  contract: string().required().label('NFT Contract address'),
  chain: string().required().label('Contract chain'),
  contract_type: string().required().label('Contract type'),
  id: string(),
});

const ftContractSchema = object({
  contract: string().required().label('FT Contract address'),
  chain: string().required().label('Contract chain'),
  minimum_tokens: string().required().label('Minimum tokens'),
  id: string(),
});

const gameSchema = object({
  name: string().required().label('Name'),
  short_description: string().required().label('Short description'),
  description: string().required().label('Description'),
  creators: string().required().label('Creators'),
  developers: string().required().label('Developers'),
  platforms: string().required().label('Platforms'),
  genres: string().required().label('Genres'),
  domain: string().required().label('Domain'),
  modes: string().required().label('Modes'),
  release_date: string().required().label('Release data'),
});

const nftContractsSchema = array().of(nftContractSchema);

const ftContractsSchema = array().of(ftContractSchema);

interface IGameData extends InferType<typeof gameSchema> {}

interface IGameNFTContractData extends InferType<typeof nftContractSchema> {}
interface IGameFTContractData extends InferType<typeof ftContractSchema> {}

const UpdateGame: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const game: IGame = useLocation().state.game;

  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const theme = activeTheme === 'light' ? lightTheme : darkTheme;

  const badges = useSelector((state: RootState) => state.badges.badges);
  const ercContractsChains = useSelector(
    (state: RootState) => state.config.ercContracts
  );
  const nftContaractsChains = useSelector(
    (state: RootState) => state.config.nftContracts
  );

  const nftContractTypes = useSelector(
    (state: RootState) => state.config.nftTypes
  );
  const [selectedCategories, setSelectedCategories] = useState<ICategory[]>([]);
  const [addedCategories, setAddedCategories] = useState<ICategory[]>([]);

  const [selectedBadges, setSelectedBadges] = useState<IBadge[]>([]);
  const [addedBadges, setAddedBadges] = useState<IGameBadge[]>([]);

  const [screenshots, setScreenshots] = useState<IScreenshot[]>([]);
  const [addedScreenshots, setAddedScreenshots] = useState<File[]>([]);

  const [screenshotError, setScreenshotError] = useState('');

  const [gameData, setGameData] = useState<IGameData>({
    short_description: game.short_description || '',
    description: game.description || '',
    creators: game.creators || '',
    developers: game.developers || '',
    platforms: game.platforms || '',
    genres: game.genres || '',
    domain: game.domain || '',
    modes: game.modes || '',
    release_date: game.release_date || '',
    name: game.name || '',
  });

  const [gameErrors, setGameErrors] =
    useState<FormErrors<IGameData | null>>(null);

  const [nftContracts, setNftContracts] = useState<IGameNFTContractData[]>([
    { ...initialNftContractData },
  ]);

  const [nftContractErrors, setNftContractErrors] =
    useState<FormErrors<IGameNFTContractData[] | null>>(null);

  const [ftContracts, setFtContracts] = useState<IGameFTContractData[]>([
    { ...initialFtContractData },
  ]);

  const [ftContractErrors, setFtContractErrors] =
    useState<FormErrors<IGameFTContractData[] | null>>(null);

  const [uniqueNftContractError, setUniqueNftContractError] = useState('');
  const [uniqueFtContractError, setUniqueFtContractError] = useState('');

  const [gameCover, setGameCover] = useState<File | string>(
    game.cover_photo || ''
  );
  const [gameLogo, setGameLogo] = useState<File | string>(game.logo || '');

  const nftContractOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    setNftContracts(contracts =>
      contracts.map((contract, i) => {
        if (i === index && e.target) {
          return {
            ...contract,
            [e.target.name]: e.target.value,
          };
        }

        return contract;
      })
    );
  };

  const handleRemoveNftContract = async (index: number, id: string) => {
    const _nftContracts = [...nftContracts];
    _nftContracts.splice(index, 1);
    setNftContracts([..._nftContracts]);
    if (id) {
      await deleteGameNftContract(game.id, id);
    }
  };
  const handleRemoveFtContract = async (index: number, id: string) => {
    const _ftContracts = [...ftContracts];
    _ftContracts.splice(index, 1);
    setFtContracts([..._ftContracts]);
    if (id) {
      await deleteGameFtContract(game.id, id);
    }
  };

  const ftContractOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    setFtContracts(contracts =>
      contracts.map((contract, i) => {
        if (i === index && e.target) {
          return {
            ...contract,
            [e.target.name]: e.target.value,
          };
        }

        return contract;
      })
    );
  };

  const handleAddFTContract = () => {
    setFtContracts([...ftContracts, { ...initialFtContractData }]);
  };
  const handleAddNFTContract = () => {
    setNftContracts([...nftContracts, { ...initialNftContractData }]);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.currentTarget;
    setGameData(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectBadge = async (badge: IBadge) => {
    if (isAdded(badge)) {
      await removeBadgeFromGame(game.id, badge.id);
      handleGetGameBadges();
      return;
    }
    let allBadges = [...selectedBadges];
    if (allBadges.indexOf(badge) > -1) {
      allBadges = allBadges.filter(b => b.id !== badge.id);
    } else {
      allBadges = [...allBadges, badge];
    }
    setSelectedBadges(allBadges);
  };

  const handleRemoveCategory = async (category: ICategory) => {
    const found = addedCategories.find(c => c.name === category.name);
    if (found) {
      await removeGameCategory(found.id);
      // handle delete category
    }
    setSelectedCategories(
      selectedCategories.filter(cat => cat.name !== category.name)
    );
  };

  const handleGetGameBadges = async () => {
    try {
      const { data } = await gameBadges(game.id);
      setAddedBadges(data);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const handleGetGameInfo = async () => {
    try {
      const { data } = await gameDetails(game.id);
      const { ScreenShots, categories } = data;
      setScreenshots(ScreenShots);

      setGameCover(data.cover_photo || '');
      setGameLogo(data.logo || '');
      const { data: gameNftContract } = await getGameNftContracts(game.id);
      const { data: gameFtContract } = await getGameFtContracts(game.id);
      if (gameNftContract.length) {
        setNftContracts(gameNftContract);
      }
      if (gameFtContract.length) {
        setFtContracts(gameFtContract);
      }

      // @ts-ignore
      setSelectedCategories(categories);
      // @ts-ignore
      setAddedCategories(categories);
    } catch (err: any) {}
  };

  const isAdded = (badge: IBadge) => {
    const found = addedBadges.find(
      addedBadge => addedBadge.badge_id === badge.id
    );
    if (found) {
      return true;
    } else {
      return false;
    }
  };

  const handleValidateContractAddresses = (addresses: string[]) => {
    let error = '';
    addresses.forEach(address => {
      if (!ethers.utils.isAddress(address)) {
        error = 'Contract addresses must be valid';
      }
    });

    if (error) {
      return error;
    }

    // @ts-ignore
    const _addresses = [...new Set(addresses)];

    if (addresses.length !== _addresses.length) {
      return 'Contract addresses must be unique';
    }
  };

  const handleDeleteScreenshot = useCallback(async (id: string) => {
    try {
      await deleteGameScreenshot(id);
      handleGetGameInfo();
    } catch (err: any) {
      console.log(err.message);
    }
    // eslint-disable-next-line
  }, []);

  const handleCoverImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files?.length) {
      if (files?.length) {
        const _file = files[0];
        let extension = _file.name.split('.');
        const exten = extension[extension.length - 1];
        if (exten === 'png' || exten === 'jpg' || exten === 'jpeg') {
          setGameCover(_file);
        }
      }
    }
  };

  const handleGameLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files?.length) {
      const _file = files[0];
      let extension = _file.name.split('.');
      const exten = extension[extension.length - 1];
      if (exten === 'png' || exten === 'jpg' || exten === 'jpeg') {
        setGameLogo(_file);
      }
    }
  };

  const handleSaveChanges = async () => {
    let hasError = false;
    setGameErrors(null);
    setNftContractErrors(null);
    setFtContractErrors(null);
    setUniqueNftContractError('');
    setUniqueFtContractError('');
    setScreenshotError('');

    const { errors: gameValidataionErrors } = await validateData(
      gameSchema,
      gameData
    );

    if (gameValidataionErrors) {
      hasError = true;
      setGameErrors(gameValidataionErrors);
    }

    const { errors: nftContractValidationErrors } = await validateData<
      IGameNFTContractData[]
    >(nftContractsSchema, nftContracts);

    if (nftContractValidationErrors) {
      hasError = true;
      setNftContractErrors(nftContractValidationErrors);
    }

    const { errors: ftContractsValidationErrors } = await validateData<
      IGameFTContractData[]
    >(ftContractsSchema, ftContracts);

    if (ftContractsValidationErrors) {
      hasError = true;
      setFtContractErrors(ftContractsValidationErrors);
    }

    const notValidNFtContracts = handleValidateContractAddresses(
      nftContracts.map(contract => contract.contract)
    );
    if (notValidNFtContracts) {
      hasError = true;
      setUniqueNftContractError(notValidNFtContracts);
    }

    const notValidFtContracts = handleValidateContractAddresses(
      ftContracts.map(contract => contract.contract)
    );

    if (notValidFtContracts) {
      hasError = true;
      setUniqueFtContractError(notValidFtContracts);
    }

    if (screenshots.length + addedScreenshots.length < 5) {
      hasError = true;
      setScreenshotError('Please select atlease 5 screenshots');
    }

    if (hasError) {
      console.log(gameErrors);
    } else {
      // update game data accordingly

      try {
        dispatch.loadingDialog.setLoading(true);
        await updateGame(gameData, game.id);

        await Promise.all(
          nftContracts.map(async contract => {
            if (contract.id?.length) {
              return updateGameNftContract(
                {
                  contract: contract.contract,
                  chain: contract.chain,
                  contract_type: contract.contract_type,
                },
                game.id,
                contract.id
              );
            } else {
              return addNFTContract(
                {
                  contract: contract.contract,
                  chain: contract.chain,
                  contract_type: contract.contract_type,
                },
                game.id
              );
            }
          })
        );

        await Promise.all(
          ftContracts.map(async contract => {
            if (contract.id?.length) {
              return updateGameFtContract(
                {
                  contract: contract.contract,
                  chain: contract.chain,
                  minimum_tokens: contract.minimum_tokens,
                },
                game.id,
                contract.id
              );
            } else {
              return addFtContract(
                {
                  contract: contract.contract,
                  chain: contract.chain,
                  minimum_tokens: contract.minimum_tokens,
                },
                game.id
              );
            }
          })
        );

        if (selectedBadges.length) {
          await Promise.all(
            selectedBadges.map(async badge => {
              return addGameToBadge({
                game_id: game.id,
                badge_id: badge.id,
              });
            })
          );
        }

        if (addedScreenshots.length) {
          const formData = new FormData();

          addedScreenshots.forEach(screenshot => {
            formData.append('screenshots', screenshot);
          });

          await addGameScreenshots(formData, game.id);
        }
        setAddedScreenshots([]);
        if (selectedCategories.length) {
          await Promise.all(
            selectedCategories.map(async category => {
              if (!addedCategories.find(cat => cat.name === category.name)) {
                return addGameCategory({
                  gameId: game.id,
                  categoryId: category.id,
                });
              }
            })
          );
        }
        const gameImages = new FormData();

        if (typeof gameCover !== 'string') {
          gameImages.append('cover_photo', gameCover);
        }
        if (typeof gameLogo !== 'string') {
          gameImages.append('logo', gameLogo);
        }
        await updateGameImages(gameImages, game.id);
        setAddedCategories([]);
        handleGetGameInfo();
        toast.success('Game updated successfully!');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.loadingDialog.setLoading(false);
      }
    }
  };

  useEffect(() => {
    handleGetGameInfo();
    handleGetGameBadges();
    dispatch.badges.handleGetAllBadges();
    dispatch.config.handleGetAllSupportedChains();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className='cover-image'>
        <img
          src={
            gameCover
              ? typeof gameCover === 'string'
                ? gameCover
                : URL.createObjectURL(gameCover)
              : assets.cover
          }
          className='cover'
          alt=''
        />
        <label htmlFor='cover-image'>
          <div className='camera-icon'>
            <Camera />
          </div>
        </label>
        <input
          type='file'
          accept='image/png , image/jpeg , imgage/jpg'
          onChange={handleCoverImage}
          id='cover-image'
        />
      </div>
      <div className='logo-container'>
        <div className='logo'>
          <img
            src={
              typeof gameLogo === 'string'
                ? gameLogo
                : URL.createObjectURL(gameLogo)
            }
            className='logo-image'
            alt=''
          />
          <label htmlFor='logo-image'>
            <div className='camera-icon black'>
              <Camera fill={theme.color.background.row} />
            </div>
          </label>
          <input
            type='file'
            style={{ display: 'none' }}
            accept='image/png , image/jpeg , imgage/jpg'
            onChange={handleGameLogo}
            id='logo-image'
          />
        </div>
        <div className='icon'>
          <p className='font-size-30 text-primary text-bold'>
            {gameData?.name}
          </p>
        </div>
      </div>
      <DetailContent>
        <div className='row flex-end'>
          <ButtonContainer>
            <ActionButton
              onClick={() => navigate(-1)}
              className='btn secondary'
              variant='primary'
            >
              Cancel
            </ActionButton>
            <ActionButton
              className='btn'
              onClick={handleSaveChanges}
              variant='primary'
            >
              Save Changes
            </ActionButton>
          </ButtonContainer>
        </div>
        <Divider />
        <div
          className='row category-and-badge'
          style={{ alignItems: 'stretch' }}
        >
          <GameCategory>
            <p className='font-size-18 text-primary text-bold'>
              Add Game Categories
            </p>
            <div className='divider'></div>
            <CategorySelector
              selected={selectedCategories}
              setSelected={setSelectedCategories}
            />
            <div className='row chip-container'>
              {selectedCategories.map(category => (
                <div key={category.id} className='chip'>
                  <p className='font-size-12'>{category.name}</p>
                  <img
                    src={assets.crossRes}
                    className='clickable'
                    onClick={() => handleRemoveCategory(category)}
                    alt=''
                  />
                </div>
              ))}
            </div>
          </GameCategory>

          <GameDomain>
            <p className='font-size-18 text-primary text-bold'>
              Add Domain name
            </p>
            <div className='divider'></div>
            <InputField
              placeholder='Domian'
              type='url'
              inputClassName='textfield'
              value={gameData.domain}
              onChange={handleChange}
              name='domain'
              error={gameErrors?.domain}
            />
          </GameDomain>

          <BadgeRequirement>
            <p className='font-size-18 text-primary text-bold'>
              Badge Requirement
            </p>
            <div className='divider'></div>
            <div className='row'>
              {badges.map(badge => (
                <BadgeSelector
                  key={badge.id}
                  className={classNames(
                    'clickable',
                    (selectedBadges.indexOf(badge) > -1 || isAdded(badge)) &&
                      'active'
                  )}
                  onClick={() => handleSelectBadge(badge)}
                >
                  <img src={badge.logo || ''} alt='' />
                  <p
                    className={classNames(
                      'font-size-20 text-primary text-bold capitalize',
                      (selectedBadges.indexOf(badge) > -1 || isAdded(badge)) &&
                        'active'
                    )}
                  >
                    {badge.name}
                  </p>
                  <div />
                </BadgeSelector>
              ))}
            </div>
          </BadgeRequirement>
        </div>
        <Grid>
          {/* FT contract */}
          <NftContracts>
            <div className='row justify-space-between'>
              <p className='font-size-18 text-primary text-bold'>
                Fungible Contracts
              </p>
              <ActionButton
                onClick={handleAddFTContract}
                variant='primary'
                className='btn-primary'
              >
                Add Tokens
              </ActionButton>
            </div>

            <div className='divider'></div>

            <div className='grid'>
              {ftContracts.map((contract, index) => (
                <div className='inner-grid' key={index}>
                  <Select
                    title='Select Chain'
                    containerClassName='select-container'
                    backgroundColor={theme.color.background.headerRow}
                    label='Select category'
                    value={contract.chain || ''}
                    options={ercContractsChains.map(chain => ({
                      label: chain.name,
                      value: chain.chain,
                    }))}
                    onChange={e => ftContractOnChange(e, index)}
                    name='chain'
                    error={
                      ftContractErrors &&
                      // @ts-ignore
                      ftContractErrors[`[${index}].chain`]
                    }
                  />
                  <InputField
                    inputClassName='input'
                    label='Minimum Tokens'
                    placeholder=''
                    type='text'
                    name='minimum_tokens'
                    value={contract.minimum_tokens}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      ftContractOnChange(e, index)
                    }
                    error={
                      ftContractErrors &&
                      // @ts-ignore
                      ftContractErrors[`[${index}].minimum_tokens`]
                    }
                  />
                  <InputField
                    inputClassName='input'
                    label='Fungible Token Address'
                    placeholder='0xb794f5ea0ba39494ce839613fffba742795792'
                    type='text'
                    name='contract'
                    value={contract.contract}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      ftContractOnChange(e, index)
                    }
                    error={
                      ftContractErrors &&
                      // @ts-ignore
                      ftContractErrors[`[${index}].contract`]
                    }
                  />
                  {index !== 0 && (
                    <div
                      className='delete-icon clickable'
                      onClick={() =>
                        handleRemoveFtContract(index, contract.id || '')
                      }
                    >
                      <img src={assets.bin} alt='' />
                    </div>
                  )}
                </div>
              ))}
              {uniqueFtContractError && (
                <p className='font-size-14 text-danger'>
                  {uniqueFtContractError}
                </p>
              )}
            </div>
          </NftContracts>
          {/* Nft contract */}
          <NftContracts>
            <div className='row justify-space-between'>
              <p className='font-size-18 text-primary text-bold'>
                NFT Contracts
              </p>
              <ActionButton
                variant='primary'
                onClick={handleAddNFTContract}
                className='btn-primary'
              >
                Add NFT contracts
              </ActionButton>
            </div>

            <div className='divider'></div>
            <div className='grid'>
              {nftContracts.map((contract, index) => (
                <div className='inner-grid' key={index}>
                  <Select
                    title='Select Chain'
                    containerClassName='select-container'
                    backgroundColor={theme.color.background.headerRow}
                    label='Select category'
                    value={contract.chain || ''}
                    options={nftContaractsChains.map(chain => ({
                      label: chain.name,
                      value: chain.chain,
                    }))}
                    name='chain'
                    onChange={e => nftContractOnChange(e, index)}
                    error={
                      nftContractErrors &&
                      // @ts-ignore
                      nftContractErrors[`[${index}].chain`]
                    }
                  />
                  <Select
                    title='Select Contract type'
                    containerClassName='select-container'
                    backgroundColor={theme.color.background.headerRow}
                    label='Contract type'
                    value={contract.contract_type || ''}
                    options={nftContractTypes.map(contractType => ({
                      label: contractType.name,
                      value: contractType.contract_type,
                    }))}
                    name='contract_type'
                    onChange={e => nftContractOnChange(e, index)}
                    error={
                      nftContractErrors &&
                      // @ts-ignore
                      nftContractErrors[`[${index}].contract_type`]
                    }
                  />
                  <InputField
                    inputClassName='input'
                    label='NFT Contract Address'
                    placeholder='0xb794f5ea0ba39494ce839613fffba742795792'
                    type='text'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      nftContractOnChange(e, index)
                    }
                    value={contract.contract}
                    name='contract'
                    error={
                      nftContractErrors &&
                      // @ts-ignore
                      nftContractErrors[`[${index}].contract`]
                    }
                  />
                  {index !== 0 && (
                    <div
                      className='delete-icon clickable'
                      onClick={() =>
                        handleRemoveNftContract(index, contract.id || '')
                      }
                    >
                      <img src={assets.bin} alt='' />
                    </div>
                  )}
                </div>
              ))}
              {uniqueNftContractError && (
                <p className='font-size-14 text-danger'>
                  {uniqueNftContractError}
                </p>
              )}
            </div>
          </NftContracts>
        </Grid>

        <Screenshots
          screenshots={screenshots}
          addedScreenshots={addedScreenshots}
          setAddedScreenshots={setAddedScreenshots}
          onDelete={handleDeleteScreenshot}
          screenshotError={screenshotError}
        />
        <GameInfo>
          <div className='left'>
            <p className='font-size-16 text-primary '>Short Game Description</p>
            <textarea
              rows={4}
              className='font-size-20 text-primary'
              value={gameData.short_description}
              onChange={handleChange}
              name='short_description'
              spellCheck={false}
            ></textarea>
            {gameErrors?.short_description && (
              <span className='font-size-14 text-danger'>
                {gameErrors?.short_description}
              </span>
            )}
            <p className='font-size-16 text-primary '>Full Game Description</p>
            <div>
              <textarea
                rows={9}
                className='font-size-20 text-primary'
                value={gameData.description}
                onChange={handleChange}
                name='description'
                spellCheck={false}
              ></textarea>
              {gameErrors?.description && (
                <span className='font-size-14 text-danger'>
                  {gameErrors?.description}
                </span>
              )}
            </div>
          </div>
          <div className='right'>
            <div className='row'>
              <div className='key'>
                <p className='font-size-16 text-dimmer'>Release date</p>
              </div>
              <div className='value'>
                <input
                  style={{ position: 'relative' }}
                  type='date'
                  className='font-size-16 text-primary text-semibold'
                  value={gameData.release_date}
                  onChange={handleChange}
                  name='release_date'
                />
                {gameErrors?.release_date && (
                  <p className='font-size-14 text-danger'>
                    {gameErrors.release_date}
                  </p>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='key'>
                <p className='font-size-16 text-dimmer'>Genre(s)</p>
              </div>
              <div className='value'>
                <input
                  type='text'
                  className='font-size-16 text-primary text-semibold'
                  value={gameData.genres}
                  onChange={handleChange}
                  name='genres'
                />
                {gameErrors?.genres && (
                  <p className='font-size-14 text-danger'>
                    {gameErrors.genres}
                  </p>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='key'>
                <p className='font-size-16 text-dimmer'>Mode(s)</p>
              </div>
              <div className='value'>
                <input
                  type='text'
                  className='font-size-16 text-primary text-semibold'
                  value={gameData.modes}
                  onChange={handleChange}
                  name='modes'
                />
                {gameErrors?.modes && (
                  <p className='font-size-14 text-danger'>{gameErrors.modes}</p>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='key'>
                <p className='font-size-16 text-dimmer'>Creator(s)</p>
              </div>
              <div className='value'>
                <input
                  type='text'
                  className='font-size-16 text-primary text-semibold'
                  value={gameData.creators}
                  onChange={handleChange}
                  name='creators'
                />
                {gameErrors?.creators && (
                  <p className='font-size-14 text-danger'>
                    {gameErrors.creators}
                  </p>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='key'>
                <p className='font-size-16 text-dimmer'>Developer(s)</p>
              </div>
              <div className='value'>
                <input
                  type='text'
                  className='font-size-16 text-primary text-semibold'
                  value={gameData.developers}
                  onChange={handleChange}
                  name='developers'
                />
                {gameErrors?.developers && (
                  <p className='font-size-14 text-danger'>
                    {gameErrors.developers}
                  </p>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='key'>
                <p className='font-size-16 text-dimmer'>Platform(s)</p>
              </div>
              <div className='value'>
                <input
                  type='text'
                  className='font-size-16 text-primary text-semibold'
                  value={gameData.platforms}
                  onChange={handleChange}
                  name='platforms'
                />
                {gameErrors?.platforms && (
                  <p className='font-size-14 text-danger'>
                    {gameErrors.platforms}
                  </p>
                )}
              </div>
            </div>
          </div>
        </GameInfo>
        <p className='font-size-30 text-primary text-bold similar-game'>
          Similar Games
        </p>
        <SimilarGames>
          <SimilarGame />
          <SimilarGame />
          <SimilarGame />
          <SimilarGame />
        </SimilarGames>
      </DetailContent>
    </Container>
  );
};

export default UpdateGame;

const Container = styled.div`
  position: relative;
  & .cover-image {
    height: 250px;
    border-radius: 10px;

    & img.cover {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }

    & label {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      & .camera-icon {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #ffffff99;
        display: grid;
        place-items: center;
      }
    }

    & input[type='file'] {
      display: none;
    }
  }

  & .logo-container {
    padding-inline: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: -90px;
    & .logo {
      background-color: ${({ theme }) => theme.color.background.row};
      height: 180px;
      width: 180px;
      border-radius: 50%;
      padding: 10px;
      position: relative;
      overflow: hidden;
      & label {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        & .camera-icon {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.color.text.primary};
          display: grid;
          place-items: center;
        }
      }

      & input[type='file'] {
        display: none;
      }

      & img.logo-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    & .icon {
      height: 40px;
      padding-inline: 14px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${({ theme }) => theme.color.background.card};
    }
  }

  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    & .cover-image {
      height: 350px;
    }

    & .logo-container {
      padding-inline: 40px;
      gap: 40px;
      margin-top: -108px;

      & .logo {
        height: 211px;
        width: 211px;
        padding: 16px;
      }
      & .icon {
        height: 51px;
        padding-inline: 18px;
      }
    }
  }
`;

const DetailContent = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 12px 20px;
  border-radius: 12px;
  margin-top: -60px;

  & .info {
    margin-top: 11px;
    padding-left: 260px;
  }

  & .chip-row {
    gap: 15px;
  }

  & .category-and-badge {
    gap: 15px;
    margin-block: 17px 15px;
    align-items: flex-start;
  }

  & .similar-game {
    margin-block: 49px 36px;
  }
  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    padding: 12px 30px;
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.text.dim};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-block: 8px 20px;
  & .btn {
    background-color: ${({ theme }) => theme.color.text.primary};
    color: ${({ theme }) => theme.color.background.button.primary.color};

    &.secondary {
      opacity: 0.7;
    }
  }
`;

const GameCategory = styled.div`
  flex: 2;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.color.background.row};
  border-radius: 10px;
  padding-block: 27px 17px;
  padding-inline: 15px;
  & .divider {
    border-top: 1px solid ${({ theme }) => theme.color.background.headerRow};
    margin-block: 21px 25px;
  }

  & .select-container {
    width: 100%;
    height: 44px;
  }

  & .chip-container {
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 21px;

    & .chip {
      position: relative;
      padding: 4px 10px;
      background-color: ${({ theme }) => theme.color.background.headerRow};
      border-radius: 15px;
      color: ${({ theme }) => theme.color.text.chip};
      & img {
        position: absolute;
        right: 0px;
        top: -5px;
        cursor: pointer;
      }
    }
  }

  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    & .select-container {
      width: 100%;
      height: 55px;
    }
  }
`;

const BadgeRequirement = styled.div`
  flex: 3;
  background-color: ${({ theme }) => theme.color.background.row};
  border-radius: 10px;
  padding-block: 27px 11px;
  padding-inline: 15px;
  & .divider {
    border-top: 1px solid ${({ theme }) => theme.color.background.headerRow};
    margin-block: 21px 11px;
  }

  & .row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 11px;
  }
`;

const BadgeSelector = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  padding: 15px 10px;
  border-radius: 15px;
  gap: 15px;
  position: relative;

  &.active {
    background-color: ${({ theme }) =>
      theme.color.background.button.primary.bg};
  }

  & p.active {
    color: ${({ theme }) => theme.color.background.button.primary.color};
  }
  & img {
    height: 77px;
  }

  & .checkbox {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    padding: 15px;

    & img {
      width: 120px;
    }
  }
`;

const NftContracts = styled.div`
  background-color: ${({ theme }) => theme.color.background.row};
  border-radius: 10px;
  padding-block: 27px 15px;
  padding-inline: 15px;

  & .btn-primary {
    background-color: ${({ theme }) => theme.color.text.primary};
    color: ${({ theme }) => theme.color.background.button.primary.color};
  }

  & .divider {
    border-top: 1px solid ${({ theme }) => theme.color.background.headerRow};
    margin-block: 21px 19px;
  }

  & .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px 30px;

    & .inner-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr;
      gap: 10px;
      position: relative;

      & .delete-icon {
        position: absolute;
        top: 0;
        right: 10px;
      }

      & .select-container {
        width: 100%;
        height: 44px;
      }

      & .input {
        background-color: ${({ theme }) => theme.color.background.headerRow};
      }
    }
  }
  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    & .grid {
      & .inner-grid {
        gap: 14px;
        & .select-container {
          width: 100%;
          height: 55px;
        }
      }
    }
  }
`;

const GameInfo = styled.div`
  margin-top: 71px;
  gap: 105px;
  display: flex;
  align-items: flex-start;
  & .left {
    flex: 1;
    & p {
      &:last-of-type {
        margin-top: 30px;
      }
    }
    & textarea {
      outline: none;
      border: none;
      background-color: ${({ theme }) => theme.color.background.row};
      padding: 20px;
      border-radius: 10px;
      width: 100%;
      margin-top: 16px;
      resize: none;
    }
  }
  & .right {
    flex: 1.15;
    background-color: ${({ theme }) => theme.color.background.row};
    border-radius: 10px;
    padding: 7px 20px;

    & .row {
      gap: 10px;
      padding-block: 12px;
      border-bottom: 1px solid
        ${({ theme }) => theme.color.background.headerRow};

      &:last-of-type {
        border: none;
      }
      & .key {
        width: 15ch;
      }
      & .value {
        flex-grow: 1;
        & input {
          height: 44px;
          width: 100%;
          background-color: ${({ theme }) => theme.color.background.headerRow};
          outline: none;
          border: none;
          border-radius: 8px;
          padding-inline: 12px;
        }
      }
    }
  }
  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    & .right {
      padding: 7px 30px;
      & .row {
        & .value {
          & input {
            height: 55px;
            padding-inline: 15px;
          }
        }
      }
    }
  }
`;

const SimilarGames = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-bottom: 51px;
`;

const GameDomain = styled.div`
  flex: 2;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.color.background.row};
  border-radius: 10px;
  padding-block: 27px 17px;
  padding-inline: 15px;
  & .divider {
    border-top: 1px solid ${({ theme }) => theme.color.background.headerRow};
    margin-block: 21px 25px;
  }

  & .textfield {
    background-color: ${({ theme }) => theme.color.background.headerRow};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;
