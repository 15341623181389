import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import TabPanel from '../../components/TabPanel/TabPanel';
import UserList from './Components/UserList';
// import GuildManagerRevenueList from './Components/GuildManagerRevenueList';
import RevenueList from './Components/RevenueList';
import useRole from '../../hooks/useRole';
import {
  features,
  modulesEnum,
  permissionEnum,
  rolesEnum,
} from '../../types/enums';
import useModulePermissions from '../../hooks/useModulePermissions';
import GMRevenueSplit from './Components/GMRevenueSplit';

const Payouts: FC = () => {
  const permissions = useModulePermissions(modulesEnum.Payout);
  const { role } = useRole();

  const [tab, setTab] = useState(0);

  const renderRevenueTab =
    permissions?.permissions[features.payoutRevenueSplit] ===
      permissionEnum.fullPermission ||
    permissions?.permissions[features.payoutRevenueSplit] ===
      permissionEnum.view;

  const renderEarningsTab =
    permissions?.permissions[features.payoutEarnings] ===
      permissionEnum.fullPermission ||
    permissions?.permissions[features.payoutEarnings] === permissionEnum.view;

  const renderTabs = renderEarningsTab && renderRevenueTab;

  useEffect(() => {
    setTab(renderRevenueTab ? 0 : 1);
  }, [renderRevenueTab]);

  return (
    <Container>
      {renderTabs && (
        <Tabs>
          <Tab
            onClick={() => setTab(0)}
            className={classNames(tab === 0 && 'active')}
          >
            <p className='font-size-22 text-primary text-bold'>Revenue Split</p>
          </Tab>
          <Tab
            onClick={() => setTab(1)}
            className={classNames(tab === 1 && 'active')}
          >
            <p className='font-size-22 text-primary text-bold'>Earnings</p>
          </Tab>
        </Tabs>
      )}

      <ContentContainer>
        <TabPanel index={0} activeIndex={tab}>
          {role?.name === rolesEnum.Admin ? (
            <RevenueList />
          ) : (
            // <GuildManagerRevenueList />
            <GMRevenueSplit />
          )}
        </TabPanel>
        <TabPanel index={1} activeIndex={tab}>
          <UserList />
        </TabPanel>
      </ContentContainer>
    </Container>
  );
};

export default Payouts;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
`;

const Tabs = styled.div`
  padding: 10px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.text.secondary + '80'};
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const Tab = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  height: 60px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.background.card};
  opacity: 0.6;
  &.active {
    opacity: 1;
  }
`;

const ContentContainer = styled.div`
  padding: 15px;
`;
