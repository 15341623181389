import React, { FC } from 'react';

import styled from 'styled-components';
import WithPermissions from '../../components/WithPermissions/WithPermissions';
import useModulePermissions from '../../hooks/useModulePermissions';
import { features, modulesEnum, permissionEnum } from '../../types/enums';

import GamesAndGuilds from './components/GamesAndGuilds';
import Users from './components/Users';

const Performance: FC = () => {
  const permissions = useModulePermissions(modulesEnum.Performance);
  const renderGamesAndGuilds =
    permissions?.permissions[features.performanceGuilds] ===
      permissionEnum.fullPermission ||
    permissions?.permissions[features.performanceGuilds] ===
      permissionEnum.view;
  return (
    <Container>
      {renderGamesAndGuilds && <GamesAndGuilds />}
      <WithPermissions
        moduleName={modulesEnum.Performance}
        featureName={features.performanceUsers}
      >
        <Users />
      </WithPermissions>
    </Container>
  );
};

export default Performance;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
