import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  getAllNFTs,
  massTransferNfts,
  scholarsWithAcceptedScholarship,
  transferNft,
} from '../../api';
import { IGetNft } from '../../types/api';
import { IAcceptedScholarshipUsers, INft } from '../../types/interface';
import {
  ITransferMassNftsPayload,
  ITransferNftPayload,
} from '../../types/storeModels';
import { toast } from 'react-toastify';

interface IState {
  loading: boolean;
  nfts: INft[];
  selectedNft: INft | null;
  acceptedScholarshipUsers: IAcceptedScholarshipUsers[];
  selectedNfts: INft[];
}

export const vault = createModel<RootModel>()({
  name: 'vault',
  state: {
    loading: false,
    nfts: [],
    selectedNft: null,
    acceptedScholarshipUsers: [],
    selectedNfts: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setNfts(state, payload: INft[]) {
      state.nfts = payload;
    },
    setSelectedNft(state, payload: INft | null) {
      state.selectedNft = payload;
    },
    setAcceptedScholarshipUsers(state, payload: IAcceptedScholarshipUsers[]) {
      state.acceptedScholarshipUsers = payload;
    },
    setSelectedNfts(state, payload: INft[]) {
      state.selectedNfts = payload;
    },
  },
  effects: dispatch => ({
    async handleGetNFTs(payload: IGetNft) {
      try {
        dispatch.vault.setNfts([]);
        dispatch.vault.setLoading(true);
        const { data } = await getAllNFTs(payload);
        dispatch.vault.setNfts(data.result);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.vault.setLoading(false);
      }
    },
    async handleGetAcceptedScholarshipUsers(
      payload: { gameId: string },
      state
    ) {
      try {
        dispatch.vault.setLoading(true);
        const guildId = state.guilds.currentGuild?.id;
        if (guildId) {
          const { data } = await scholarsWithAcceptedScholarship(
            guildId,
            payload.gameId || ''
          );
          dispatch.vault.setAcceptedScholarshipUsers(data);
        }
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.vault.setLoading(false);
      }
    },
    async handleTransferNft(payload: ITransferNftPayload) {
      const { data, setOpen, onSuccess } = payload;
      try {
        dispatch.vault.setLoading(true);
        await transferNft(data);
        setOpen(false);
        onSuccess();
        toast.success('Nft is transferred successfully.');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.vault.setLoading(false);
      }
    },
    async handleTransferMultipleNfts(payload: ITransferMassNftsPayload) {
      try {
        const { data, setOpen, onSuccess } = payload;
        dispatch.vault.setLoading(true);
        const { data: res } = await massTransferNfts(data);
        toast.success(`Assets added to transfer queue with id ${res?.queueId}`);
        setOpen(false);
        onSuccess();
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.vault.setLoading(false);
      }
    },
  }),
});
