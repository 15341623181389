import { createModel } from '@rematch/core';
import { RootModel } from '.';

interface IState {
  open: boolean;
  title: string;
  description: string;
}

interface IPayload {
  title: string;
  description: string;
}

export const errorModal = createModel<RootModel>()({
  name: 'errorModal',
  state: {
    open: false,
    title: '',
    description: '',
  } as IState,
  reducers: {
    setOpen(state, payload: IPayload) {
      state.open = true;
      state.title = payload.title;
      state.description = payload.description;
    },
    setClose(state) {
      state.open = false;
      state.title = '';
      state.description = '';
    },
  },
});
