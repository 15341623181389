import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ArrowRight from '../../../assets/icons/ArrowRight';

import FacebookIcon from '../../../assets/icons/FacebookIcon';
import TwitterIcon from '../../../assets/icons/TwitterIcon';
import GlobeIcon from '../../../assets/icons/GlobeIcon';
import InstagramIcon from '../../../assets/icons/InstagramIcon';
import TelegramIcon from '../../../assets/icons/TelegramIcon';
import TwichIcon from '../../../assets/icons/TwichIcon';
import { IGame, IGuild, IGuildStats } from '../../../types/api';
import {
  getGuildGames,
  getGuildSocialLinks,
  getGuildStats,
} from '../../../api';
import { IGuildSocialLink } from '../../../types/interface';

interface IProps {
  containerStyle?: { [x: string]: any };
  guildDetails: IGuild;
}

const socialConfig: { [x: string]: any } = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  web: GlobeIcon,
  telegram: TelegramIcon,
  instagram: InstagramIcon,
  chat: TwichIcon,
};

const RenderIcon: FC<{ name: string }> = ({ name }) => {
  const Icon = socialConfig[name];
  return <Icon />;
};

const GuildCard: FC<IProps> = ({ containerStyle, guildDetails }) => {
  const [games, setGames] = useState<IGame[]>([]);
  const [socialLinks, setSocialLinks] = useState<IGuildSocialLink[]>([]);
  const [stats, setStats] = useState<IGuildStats | null>(null);

  const handleRenderGames = () => {
    const allGames = [...games];
    let renderGames: IGame[] = [];
    let restGames: IGame[] = [];
    if (allGames.length > 3) {
      renderGames = allGames.slice(0, 3);
      restGames = allGames.slice(3);
    } else {
      renderGames = allGames;
    }

    return (
      <div className='row' style={{ gap: '6px' }}>
        {renderGames.map(game => (
          <Game key={game.id}>
            <img src={game.mini_icon} alt='' />
          </Game>
        ))}
        {restGames.length > 0 && (
          <p className='font-size-8 text-primary'>+{restGames.length} more</p>
        )}
      </div>
    );
  };

  const handleGetInitialData = async () => {
    try {
      const [
        { data: guildGames },
        { data: guildSocial },
        { data: guildStats },
      ] = await Promise.all([
        getGuildGames(guildDetails.id),
        getGuildSocialLinks(guildDetails.id),
        getGuildStats(guildDetails.id),
      ]);
      setGames(guildGames);
      setSocialLinks(guildSocial);
      setStats(guildStats);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    handleGetInitialData();
    // eslint-disable-next-line
  }, []);
  return (
    <Container style={containerStyle}>
      <GuildImage>
        <img src={guildDetails.logo || ''} alt='' />
        <div className='stats-container'>
          <div className='row justify-space-between stats-row'>
            <div className='row'>
              <div className='key'>
                <p className='font-size-10 text-primary text-semibold'>S</p>
              </div>
              <p className='font-size-10 text-primary text-semibold'>
                {stats?.totalScholars.total}
              </p>
            </div>
            <div className='row'>
              <div className='key'>
                <p className='font-size-10 text-primary text-semibold'>C</p>
              </div>
              <p className='font-size-10 text-primary text-semibold'>
                {stats?.totalCaptains.total}
              </p>
            </div>
            <div className='row'>
              <div className='key'>
                <p className='font-size-10 text-primary text-semibold'>GM</p>
              </div>
              <p className='font-size-10 text-primary text-semibold'>
                {stats?.totalMasters.total}
              </p>
            </div>
          </div>
        </div>
      </GuildImage>
      <GuildInfo>
        <div className='row justify-space-between'>
          <p className='font-size-14 text-primary text-bold capitalize'>
            {guildDetails.name}
          </p>
          <div className='row' style={{ gap: '5px' }}>
            {socialLinks.map(social => (
              <a
                href={social.url}
                target='_blank'
                rel='noreferrer'
                key={social.id}
              >
                <SocialLink>
                  <RenderIcon name={social.logo} />
                </SocialLink>
              </a>
            ))}
          </div>
        </div>
        <div className='game-row row justify-space-between'>
          <div className='row' style={{ gap: '6px' }}>
            {handleRenderGames()}
          </div>
          <Link
            to={`/dashboard/guilds/page/${guildDetails.id}`}
            className='anchor'
          >
            <div className='row' style={{ gap: '8px' }}>
              <p className='font-size-10 text-primary'>View Guild</p>
              <ArrowRight />
            </div>
          </Link>
        </div>
      </GuildInfo>
    </Container>
  );
};

export default GuildCard;

const Container = styled.div`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.background.row};
  padding: 13px;
  min-width: 230px;
  max-width: 230px;
`;

const GuildImage = styled.div`
  border-radius: 20px;
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  position: relative;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .stats-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px 15px;
    display: flex;
    align-items: flex-end;

    & .stats-row {
      width: 100%;
      border-radius: 8px;
      background-color: ${({ theme }) => theme.color.background.card};
      padding: 5px;

      & .row {
        gap: 10px;

        & .key {
          width: 25px;
          height: 25px;
          background-color: ${({ theme }) => theme.color.background.row};
          border-radius: 4px;
          display: grid;
          place-items: center;
        }
      }
    }
  }
`;

const GuildInfo = styled.div`
  margin-top: 9px;

  & .game-row {
    margin-top: 13px;
  }
`;

const SocialLink = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  display: grid;
  place-items: center;
  padding: 4px;
  & svg {
    height: 100%;
    width: 100%;
  }
`;

const Game = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.text.primary};
  display: grid;
  place-items: center;

  & img {
    width: 12px;
  }
`;
