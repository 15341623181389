import { Models } from '@rematch/core';

import { theme } from './theme';
import { notifications } from './notiifications';
import { auth } from './auth';
import { users } from './users';
import { permissions } from './permissions';
import { roles } from './roles';
import { badges } from './badges';
import { games } from './games';
import { category } from './category';
import { config } from './config';
import { guilds } from './guilds';
import { scholarships } from './scholarships';
import { vault } from './vault';
import { tournaments } from './tournaments';
import { errorModal } from './errorModal';
import { loadingDialog } from './loadingDialog';
import { gasAlert } from './gasAlert';

export interface RootModel extends Models<RootModel> {
  theme: typeof theme;
  notifications: typeof notifications;
  auth: typeof auth;
  users: typeof users;
  permissions: typeof permissions;
  roles: typeof roles;
  badges: typeof badges;
  games: typeof games;
  category: typeof category;
  config: typeof config;
  guilds: typeof guilds;
  scholarships: typeof scholarships;
  vault: typeof vault;
  tournaments: typeof tournaments;
  errorModal: typeof errorModal;
  loadingDialog: typeof loadingDialog;
  gasAlert: typeof gasAlert;
}

export const models: RootModel = {
  theme,
  notifications,
  auth,
  users,
  permissions,
  roles,
  badges,
  games,
  category,
  config,
  guilds,
  scholarships,
  vault,
  tournaments,
  errorModal,
  loadingDialog,
  gasAlert,
};
