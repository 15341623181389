import React, { FC } from 'react';

import styled from 'styled-components';

interface IProps {
  children: React.ReactNode;
  open: boolean;
  onClose?: () => void; // pass function to close dialog
}

const DialogLayout: FC<IProps> = ({ children, open, onClose }) => {
  return (
    <>
      {open && (
        <Container onClick={onClose}>
          <div className='inner'>
            <div className='cotnet'>{children}</div>
          </div>
        </Container>
      )}
    </>
  );
};

export default DialogLayout;

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  background-color: #1e1e1ee6;
  z-index: 100;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  & .inner {
    max-height: 95vh;
    /* overflow-y: auto; */
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
