import { FC, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import Ticks from '../../assets/icons/Ticks';
import assets from '../../assets';
// import ActionButton from '../../components/ActionButton/ActionButton';

import { lightTheme as theme } from '../../theme';
import { getAgoTimeFromNow } from '../../utils';
import {
  INotification,
  IScholarshipNotificationRedirectState,
} from '../../types/interface';
import { NotificationTypes } from '../../types/enums';
import useNotifications from '../../hooks/useNotifications';
import axios from 'axios';
import { toast } from 'react-toastify';
import { markAllNotificationAsRead } from '../../api';

const Notifications: FC = () => {
  useNotifications();
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch<Dispatch>();
  const isOpen = useSelector((state: RootState) => state.notifications.isOpen);
  const notifcations = useSelector(
    (state: RootState) => state.notifications.notifications
  );

  const handleNotificationRedirect = (notification: INotification) => {
    if (notification.type === NotificationTypes.applyScholarship) {
      const { scholarship_id, user_id, game_id, guild_scholarship_id } =
        notification;
      if (scholarship_id && user_id && game_id) {
        const stateObject: IScholarshipNotificationRedirectState = {
          guild_scholarship_id,
          scholarship_id,
          user_id,
          game_id,
        };
        navigate('/dashboard/my-guild', { state: stateObject });
      }
    }
  };

  const handleMarkAllNotificationsAsRead = async () => {
    try {
      await markAllNotificationAsRead();
      dispatch.notifications.handleGetAllNotifications(null);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data.message;
        dispatch.errorModal.setOpen({
          title: 'Error!',
          description: errorMessage,
        });
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  return (
    <Container>
      <Overly style={{ display: isOpen ? 'block' : 'none' }}></Overly>
      <NotificationPanel
        style={{
          transform: isOpen ? 'translateX(0%)' : 'translateX(105%)',
        }}
      >
        <div className='wrapper'>
          <div className='row justify-space-between title-row'>
            <div className='row' style={{ gap: '20px' }}>
              <img
                src={assets.crossFilled}
                onClick={() =>
                  dispatch.notifications.setNotificationIsOpen(false)
                }
                className='cross-icon'
                alt=''
              />
              <p className='font-size-25 text-bold text-primary'>
                Notifications
              </p>
            </div>
            <div
              role='button'
              onClick={handleMarkAllNotificationsAsRead}
              className='row clickable'
              style={{ gap: '5px' }}
            >
              <Ticks />
              <p className='font-size-12 text-primary text-semibold'>
                Mark as read
              </p>
            </div>
          </div>
          <Tabs
            className='row justify-space-between'
            style={{ display: 'none' }}
          >
            <Tab
              onClick={() => setTab(0)}
              className={classNames(tab === 0 && 'active')}
            >
              <p className='font-size-14 text-semibold'>All</p>
            </Tab>
            <Tab
              onClick={() => setTab(1)}
              className={classNames(tab === 1 && 'active')}
            >
              <p className='font-size-14 text-semibold'>Games</p>
            </Tab>
            <Tab
              onClick={() => setTab(2)}
              className={classNames(tab === 2 && 'active')}
            >
              <p className='font-size-14 text-semibold'>Guilds</p>
            </Tab>
            <Tab
              onClick={() => setTab(3)}
              className={classNames(tab === 3 && 'active')}
            >
              <p className='font-size-14 text-semibold'>Payout</p>
            </Tab>
            <Tab
              onClick={() => setTab(4)}
              className={classNames(tab === 4 && 'active')}
            >
              <p className='font-size-14 text-semibold'>Roles</p>
            </Tab>
          </Tabs>
          <div className='all-notifications'>
            {/* <NotificationRow>
              <div className='row'>
                <div className='profile'></div>
                <div className='info'>
                  <p className='font-size-14 text-primary'>
                    <span className='font-size-16 text-bold'>Dylan</span> has
                    requested to issue a new scholarship for
                    <span className='font-size-16 text-bold'>
                      Axie infinity
                    </span>
                  </p>
                  <p className='font-size-8 text-dim'>20 hours ago - Guilds</p>
                  <div className='row btn-row'>
                    <ActionButton variant='outlined' className='font-size-14'>
                      Deny
                    </ActionButton>
                    <ActionButton variant='secondary' className='font-size-14'>
                      Approve
                    </ActionButton>
                  </div>
                </div>
              </div>

              <div className='box'></div>
            </NotificationRow> */}
            {notifcations.map(notifcation => (
              <NotificationRow
                className='clickable'
                onClick={() => handleNotificationRedirect(notifcation)}
                key={notifcation.id}
              >
                <div className='row'>
                  <div className='profile'></div>
                  <div className='info'>
                    <p className='font-size-14 text-primary'>
                      {notifcation.message}
                      {/* <span className='font-size-16 text-bold'>Dylan</span> has
                      assigned a new role (Guild Manager) to user
                      <span className='font-size-16 text-bold'>
                        Jack Cooper.
                      </span> */}
                    </p>
                    <p className='font-size-8 text-dim'>
                      {getAgoTimeFromNow(notifcation.created_at)}
                    </p>
                  </div>
                </div>
                <div />
              </NotificationRow>
            ))}
          </div>
        </div>
      </NotificationPanel>
    </Container>
  );
};

export default Notifications;

const Container = styled.div``;

const Overly = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  height: calc(100% - 60px);
  width: 100%;
  background-color: #1e1e1ee6;
  z-index: 10;
  & .wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    top: 90px;
    height: calc(100% - 90px);
  }
`;

const NotificationPanel = styled.div`
  position: fixed;
  top: 60px;
  right: 0;
  transition: transform 0.6s ease;
  width: 500px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.background.card};
  z-index: 10;
  padding-top: 19px;
  display: flex;
  flex-direction: column;

  & .wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  & .all-notifications {
    flex: 1 1 auto;
    overflow-y: auto;
    height: 0px;
  }

  & .title-row {
    padding-right: 30px;
    & .cross-icon {
      cursor: pointer;
      transform: translateX(-50%);
    }
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    top: 90px;
  }
`;

const Tabs = styled.div`
  width: 100%;
  padding-inline: 22px 30px;
  border-bottom: 1px solid ${({ theme }) => theme.color.text.dim + '66'};
  margin-block: 25px 9px;
`;

const Tab = styled.div`
  padding-inline: 10px;
  padding-bottom: 8px;
  position: relative;
  color: ${({ theme }) => theme.color.text.primary + '66'};
  display: inline-block;
  transition: 0.4s ease;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -1px;
    background-color: ${({ theme }) => theme.color.text.primary};
    border-radius: 2px;
    height: 2px;
    width: 0px;
    transition: 0.4s ease;
  }

  &.active {
    color: ${({ theme }) => theme.color.text.primary};
    &::before {
      content: '';
      position: absolute;
      left: 0px;
      bottom: -1px;
      background-color: ${({ theme }) => theme.color.text.primary};
      border-radius: 2px;
      height: 2px;
      width: 100%;
    }
  }
`;

const NotificationRow = styled.div`
  padding-block: 20px;
  padding-inline: 20px 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 25px;
  border-bottom: 1px solid ${({ theme }) => theme.color.text.dim + '66'};

  & .row {
    &:first-of-type {
      gap: 25px;
      align-items: flex-start;
    }
  }

  & .profile {
    height: 42px;
    min-width: 42px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.text.primary};
  }

  & .box {
    height: 42px;
    min-width: 42px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.color.text.primary};
  }

  & .text-dim {
    margin-block: 7px 0px;
    opacity: 0.6;
  }

  & .btn-row {
    gap: 20px;
    margin-top: 15px;
    & button {
      width: 106px;
      height: 35px;
    }
  }
`;
