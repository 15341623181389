import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import CrossIcon from '../../assets/icons/CrossIcon';
import InputField from '../../components/InputField/InputField';
import Select from '../../components/Select/Select';
import ActionButton from '../../components/ActionButton/ActionButton';
import SearchBar from '../../components/SearchBar/SearchBar';
import DialogLayout from '../../layouts/DialogLayout/DialogLayout';

import { lightTheme as theme } from '../../theme';
import CloseIcon from '../../assets/icons/CloseIcon';
import { getGuildGameCaptains, guildGameMasters } from '../../api';
import { IGameCaptain, IGameMaster } from '../../types/interface';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../hooks/useTheme';
interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}

const ManageTeam: FC<IProps> = ({ open, setOpen }) => {
  const { theme } = useTheme();
  const [game, setGame] = useState('');
  const [loading, setLoading] = useState(false);

  const [captains, setCaptains] = useState<IGameCaptain[]>([]);
  const [masters, setMasters] = useState<IGameMaster[]>([]);

  const [formData, setFormData] = useState({
    teamName: '',
    gameMaster: '',
    gameCaptain: '',
  });

  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;

    if (name === 'game') {
      setGame(value);
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleGetGameMasterAndCaptains = async () => {
    try {
      setLoading(true);
      if (currentGuild && game) {
        const [{ data: gameCaptains }, { data: gameMasters }] =
          await Promise.all([
            getGuildGameCaptains(currentGuild?.id, game),
            guildGameMasters(currentGuild.id, game),
          ]);

        setCaptains(gameCaptains);
        setMasters(gameMasters);
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (game) {
      handleGetGameMasterAndCaptains();
      setFormData(prev => ({ ...prev, gameMaster: '', gameCaptain: '' }));
    }
    // eslint-disable-next-line
  }, [game]);

  return (
    <DialogLayout open={open}>
      <Content>
        <div className='left'>
          <p className='font-size-25 text-primary text-bold'>Manage Team</p>
          <p className='font-size-14 text-secondary subtitle'>
            Assign captains and scholars to Game master team
          </p>
          <FieldContainer>
            <InputField
              label='Team Name'
              value={formData.teamName}
              name='teamName'
              onChange={handleChange}
              placeholder=''
              type='text'
            />
          </FieldContainer>
          <FieldContainer>
            <Select
              label='Select game'
              title='Game'
              options={
                currentGuild?.Games.map(game => ({
                  label: game.name,
                  value: game.id,
                })) || []
              }
              value={game}
              onChange={handleChange}
              name='game'
            />
          </FieldContainer>
          {game && (
            <>
              <FieldContainer>
                {loading ? (
                  <div className='text-center'>
                    <ClipLoader size={40} color={theme.color.text.primary} />
                  </div>
                ) : (
                  <Select
                    label='Select user'
                    title='Game Master'
                    options={
                      masters.map(master => ({
                        label: `${master.username}`,
                        value: master.id,
                      })) || []
                    }
                    value={formData.gameMaster}
                    onChange={handleChange}
                    name='gameMaster'
                  />
                )}
              </FieldContainer>
              <FieldContainer>
                {loading ? (
                  <div className='text-center'>
                    <ClipLoader size={40} color={theme.color.text.primary} />
                  </div>
                ) : (
                  <Select
                    label='Select user'
                    title='Captain'
                    options={captains.map(cap => ({
                      label: `${cap.username}`,
                      value: cap.id,
                    }))}
                    value={formData.gameCaptain}
                    onChange={handleChange}
                    name='gameCaptain'
                  />
                )}
              </FieldContainer>
            </>
          )}
          <div style={{ marginTop: 'auto' }}>
            <div className='divider-horizontail'></div>
            <ButtonContainer>
              <ActionButton
                onClick={handleClose}
                variant='primary'
                className='action-btn btn-primary'
              >
                Cancel
              </ActionButton>
              <ActionButton
                variant='primary'
                className='action-btn btn-secondary'
              >
                Save Changes
              </ActionButton>
            </ButtonContainer>
          </div>
        </div>
        <div className='divider'></div>
        <div className='right'>
          <div className='row justify-space-between'>
            <p className='font-size-18 text-primary text-bold'>Scholars (15)</p>
            <div className='icon clickable' onClick={handleClose}>
              <CrossIcon />
            </div>
          </div>
          <SelectedScholars>
            {Array.from({ length: 25 }, (_, i) => i).map(i => (
              <div key={i} className='scholar'>
                <div className='image'>{/* <img src='' alt='' /> */}</div>
                <p className='font-size-8 text-primary'>Jake</p>
                <div className='icon clickable'>
                  <CloseIcon className='inner' />
                </div>
              </div>
            ))}
          </SelectedScholars>

          <div className='divider-horizontal'></div>

          <div
            className='row filters justify-space-between'
            style={{ width: '100%' }}
          >
            <p className='font-size-18 text-primary text-bold'>
              Total Members (215)
            </p>
            <div className='row'>
              <Select
                containerClassName='select-bar'
                label='Badge'
                value={''}
                onChange={() => {}}
                options={[]}
              />
              <SearchBar className='search' />
            </div>
          </div>
          <ScholarsList>
            {Array.from({ length: 45 }, (_, i) => i).map(i => (
              <div key={i} className='scholar'>
                <div className='image'>{/* <img src='' alt='' /> */}</div>
                <p className='font-size-8 text-primary'>Jake</p>
              </div>
            ))}
          </ScholarsList>
        </div>
      </Content>
    </DialogLayout>
  );
};

export default ManageTeam;

const Content = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  display: flex;
  align-items: stretch;
  min-height: 620px;
  width: 1252px;
  overflow: hidden;

  & .left {
    display: flex;
    flex-direction: column;
    width: 470px;
    padding: 30px;

    & .subtitle {
      margin-top: 10px;
    }

    & .divider-horizontail {
      height: 0.5px;
      width: 100%;
      background-color: ${({ theme }) => theme.color.text.secondary};
      margin-block: 50px 30px;
    }
  }

  & .divider {
    width: 0.5px;
    background-color: ${({ theme }) => theme.color.text.secondary};
  }

  & .right {
    flex-grow: 1;
    padding: 24px 30px;
    display: flex;
    flex-direction: column;

    & .divider-horizontal {
      height: 0.5px;
      width: 100%;
      background-color: ${({ theme }) => theme.color.text.secondary};
      margin-bottom: 24px;
    }

    & .filters {
      & .row {
        gap: 10px;
        & .select-bar {
          width: 144px;
        }

        & .search {
          width: 250px;
        }
      }
    }
  }
`;

const FieldContainer = styled.div`
  margin-top: 20px;

  & label {
    font-weight: 700;
    color: ${({ theme }) => theme.color.text.primary}!important;
  }

  &:first-of-type {
    margin-top: 48px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  margin-top: 20px;

  & .action-btn {
    border-radius: 20px;
    height: 40px;
    width: 150px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .action-btn {
      border-radius: 30px;
      height: 55px;
      width: 189px;
    }
  }
`;

const SelectedScholars = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-block: 20px 30px;
  max-height: 172px;
  overflow-y: auto;
  padding-top: 5px;

  & .scholar {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    & .image {
      height: 60px;
      width: 60px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.color.text.primary};
      overflow: hidden;
      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    & .icon {
      position: absolute;
      border: 2px solid ${({ theme }) => theme.color.background.card};
      border-radius: 50%;
      top: -6px;
      right: -6px;
      & .inner {
        width: 8px;
        height: 8px;
      }
    }
  }
`;

const ScholarsList = styled.div`
  margin-top: 21px;
  gap: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  height: 0px;
  overflow-y: auto;

  & .scholar {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    & .image {
      height: 60px;
      width: 60px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.color.text.primary};
      overflow: hidden;
      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
`;
