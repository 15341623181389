import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const CalenderIcon: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='11'
      height='13'
      viewBox='0 0 11 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M3.661 5.39405H5.129V6.85805H3.661V5.39405ZM5.861 5.39405H7.322V6.85805H5.858L5.861 5.39405ZM8.061 5.39405H9.519V6.85805H8.054L8.061 5.39405ZM1.471 9.78805H2.929V11.252H1.464L1.471 9.78805ZM3.671 9.78805H5.129V11.252H3.661L3.671 9.78805ZM5.871 9.78805H7.322V11.252H5.858L5.871 9.78805ZM3.671 7.58805H5.129V9.05505H3.661L3.671 7.58805ZM5.871 7.58805H7.322V9.05505H5.858L5.871 7.58805ZM8.071 7.58805H9.519V9.05505H8.054L8.071 7.58805ZM1.481 7.58805H2.929V9.05505H1.464L1.481 7.58805ZM9.536 0.998047V1.73205H8.054V1.00005H2.929V1.73205H1.464V1.00005H0V12.716H10.983V1.00005H9.519L9.536 0.998047ZM10.268 11.982H0.729V3.92905H10.248L10.268 11.982Z'
        fill={fill || theme.color.text.dim}
      />
    </svg>
  );
};

export default CalenderIcon;
