import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { InferType, object, string } from 'yup';

import CrossIcon from '../../assets/icons/CrossIcon';
import ActionButton from '../../components/ActionButton/ActionButton';
import Select from '../../components/Select/Select';
import InputField from '../../components/InputField/InputField';
import MultiLineInput from '../../components/MultiLineInput/MultilineInput';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';

import { lightTheme as theme } from '../../theme';
import {
  applyScholarship,
  gameDetails,
  getGuildGameCaptains,
  getScholarTeamByGameId,
} from '../../api';
import { IGameCaptain, ISingleGame } from '../../types/interface';
import { FormErrors, validateData } from '../../utils/validations';
import axios from 'axios';

const schema = object({
  captain_id: string().required().label('Captain'),
  game_id: string().required(),
  guild_id: string().required(),
  badge_id: string().required(),
  title: string().required().label('Title'),
  about: string().required().label('About'),
});

interface IFormData extends InferType<typeof schema> {}

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}

const ApplyScholarship: FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState(false);
  const [game, setGame] = useState<ISingleGame | null>(null);
  const [captains, setCaptains] = useState<IGameCaptain[]>([]);

  const highestBadge = useSelector(
    (state: RootState) => state.badges.userHighestBadge
  );

  const [formData, setFormData] = useState({
    captain_id: '',
    title: '',
    about: '',
  });

  const [errors, setErrors] = useState<FormErrors<IFormData | null>>(null);

  const scholarsip = useSelector(
    (state: RootState) => state.scholarships.selected
  );

  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  const user = useSelector((state: RootState) => state.auth.user);

  const badges = useSelector((state: RootState) => state.badges.badges);
  const handleClose = () => {
    setOpen(false);
  };

  const handleApply = async () => {
    setErrors(null);
    const { errors: validationErrors, data } = await validateData<IFormData>(
      schema,
      {
        ...formData,
        guild_id: scholarsip!.guild_id,
        badge_id: highestBadge!.id,
        game_id: scholarsip!.game_id,
      }
    );

    if (validationErrors) {
      return setErrors(validationErrors);
    }

    try {
      setLoading(true);
      if (data) {
        await applyScholarship(data);
      }
      setOpen(false);
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data?.message;
        dispatch.errorModal.setOpen({
          title: 'Error',
          description: errorMessage,
        });
      }
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleGetInitData = async () => {
    let caps: IGameCaptain[] = [];
    try {
      if (scholarsip) {
        const [{ data }, { data: cap }] = await Promise.all([
          gameDetails(scholarsip!.game_id),
          getGuildGameCaptains(currentGuild?.id, scholarsip.game_id),
        ]);
        caps = cap;
        setGame(data);
        const res = await getScholarTeamByGameId(user!.id, scholarsip.game_id);

        if (res.status === 200) {
          setCaptains([res.data]);
          setFormData(prev => ({ ...prev, captain_id: res.data.id }));
        }
      }
    } catch (err: any) {
      setCaptains(caps);
      console.log(err.message);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { value, name } = e.currentTarget;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (scholarsip) {
      handleGetInitData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary-text-bold'>
            Apply for Scholarship
          </p>
          <div className='icon clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <div className='game-icon text-center'>
          <img
            src={game?.mini_icon || ''}
            style={{ maxHeight: '100px' }}
            alt=''
          />
          <div className='row'>
            <img
              src={
                badges.find(badge => badge.id === scholarsip?.badge_id)?.logo ||
                ''
              }
              alt=''
            />
            <p className='font-size-10 text-bold text-primary'>required</p>
          </div>
        </div>

        <div className='input-container'>
          <Select
            label='Select a captain'
            title='Select Captain'
            options={captains.map(captain => ({
              label: `${captain.username}`,
              value: captain.id,
            }))}
            value={formData.captain_id}
            onChange={handleChange}
            name='captain_id'
            error={errors?.captain_id}
          />
        </div>

        <div className='input-container'>
          <InputField
            label='Title'
            placeholder='Enter a title for your application'
            type='text'
            value={formData.title}
            onChange={handleChange}
            name='title'
            error={errors?.title}
          />
        </div>
        <MultiLineInput
          label='Description'
          placeholder='Tell us about yourself. What’s your gaming experience and why
are you looking to join the 99starz guild?'
          rows={7}
          value={formData.about}
          onChange={handleChange}
          name='about'
          error={errors?.about}
        />

        <p className='font-size-25 text-primary text-bold text-center title'>
          Have any questions?
        </p>
        <p className='font-size-14 text-primary text-center subtitle'>
          Contact 99starz captains on discord!
        </p>

        <Divider />
        <ButtonContainer>
          <ActionButton
            onClick={handleClose}
            variant='primary'
            className='btn btn-primary'
            disabled={loading}
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleApply}
            variant='primary'
            className='btn btn-secondary'
            disabled={loading}
            loading={loading}
          >
            Apply
          </ActionButton>
        </ButtonContainer>
      </Container>
    </DialogLayout>
  );
};

export default ApplyScholarship;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  padding: 30px;
  width: 550px;

  & .input-container {
    margin-block: 30px;
  }

  & .title {
    margin-block: 20px 6px;
  }

  & .game-icon {
    margin-top: 40px;
    & .row {
      justify-content: center;
      gap: 5px;
      margin-top: 7px;
      & img {
        width: 14px;
      }
    }
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.text.dim};
  margin-top: 30px;
`;

// const CaptainsRow = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 20px;
//   margin-block: 30px;

//   & .row {
//     gap: 12px;
//     & .img {
//       img {
//         height: 42px;
//         width: 42px;
//         object-fit: cover;
//         border-radius: 50%;
//       }
//     }
//   }
// `;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;

  & .btn {
    height: 44px;
    width: 150px;
    border-radius: 20px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .btn {
      height: 55px;
      width: 198px;
      border-radius: 30px;
    }
  }
`;
