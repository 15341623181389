import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import SelectIcon from '../../assets/icons/SelectIcon';
import { ICategory } from '../../types/api';

interface IProps {
  selected: ICategory[];
  setSelected: React.Dispatch<React.SetStateAction<ICategory[]>>;
}

const CategorySelector: FC<IProps> = ({ selected, setSelected }) => {
  const dispatch = useDispatch<Dispatch>();
  const myCategories = useSelector(
    (state: RootState) => state.category.categories
  );
  const [categories, setCategories] = useState<ICategory[]>(myCategories);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const handleSelectCategory = (category: ICategory) => {
    let _categories = [...selected];
    if (_categories.indexOf(category) > -1) {
      _categories = _categories.filter(cat => cat.id !== category.id);
    } else {
      _categories = [..._categories, category];
    }
    setSelected([..._categories]);
    setOpen(false);
  };

  const handleValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddCategory = () => {
    if (!value) {
      setError('Please enter a valid category!');
    } else {
      setError('');
      dispatch.category.handleAddCategory({ data: { name: value }, setOpen });
    }
    setValue('');
    setOpen(false);
  };

  useEffect(() => {
    dispatch.category.handleGetAllCategories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCategories(myCategories);
  }, [myCategories]);

  return (
    <Container>
      <div
        className='selector row justify-space-between'
        onClick={handleToggle}
      >
        <p className='font-size-16 text-dim text-semibold'>Select categories</p>
        <div className='icon'>
          <SelectIcon />
        </div>
      </div>
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <Menu>
            <div className='title justify-space-between'>
              <p className='font-size-14 text-dimmer'>Categories</p>
              <p
                className='font-size-14 text-dimmer clickable'
                onClick={handleAddCategory}
              >
                + Add Category
              </p>
            </div>
            <input
              type='text'
              value={value}
              onChange={handleValue}
              className='font-size-12'
            />
            {error && <p className='font-size-14 text-danger'>{error}</p>}
            {categories.map(category => {
              if (
                category.name.toLowerCase().indexOf(value.toLowerCase()) > -1
              ) {
                if (selected.find(s => s.name === category.name)) return null;
                return (
                  <div
                    key={category.id}
                    className='row clickable'
                    onClick={() => handleSelectCategory(category)}
                  >
                    <p className='font-size-14 text-primary text-semibold capitalize'>
                      {category.name}
                    </p>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </Menu>
        </ClickAwayListener>
      )}
    </Container>
  );
};

export default CategorySelector;

const Container = styled.div`
  position: relative;

  & .selector {
    width: 100%;
    background-color: ${({ theme }) => theme.color.background.headerRow};
    height: 55px;
    padding-inline: 14px;
    border-radius: 8px;
    cursor: pointer;

    & p {
      opacity: 0.4;
    }

    & .icon {
      opacity: 0.4;
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  bottom: -9px;
  right: 0px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  padding: 10px 10px;

  transform: translateY(100%);
  border-radius: 10px;
  z-index: 100;
  filter: drop-shadow(2px 2px 4px rgba(53, 57, 64, 0.102));

  & .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
  }

  & .row {
    padding-block: 7px;
    border-bottom: 1px solid ${({ theme }) => theme.color.text.dim + '66'};
    padding-inline: 4px;

    &:last-of-type {
      border-bottom: none;
    }
  }

  & input {
    height: 30px;
    width: 100%;
    outline: none;
    border: none;
    background-color: ${({ theme }) => theme.color.background.row};
    border: 1px solid ${({ theme }) => theme.color.text.primary + '66'};
    border-radius: 4px;
    margin-block: 8px 5px;
    padding-inline: 10px;
  }
`;
