import { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import Select from '../../components/Select/Select';
import CrossIcon from '../../assets/icons/CrossIcon';
import ActionButton from '../../components/ActionButton/ActionButton';
import DialogLayout from '../../layouts/DialogLayout/DialogLayout';
import SearchBar from '../../components/SearchBar/SearchBar';

import { lightTheme } from '../../theme';
import {
  getGameNftContracts,
  getMassPullBackUsers,
  getUserNfts,
  massPullback,
} from '../../api';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../hooks/useTheme';
import { toast } from 'react-toastify';
import { IMassPullbackNfts, IScholarNfts, IUser } from '../../types/interface';
import { truncateString } from '../../utils';
import NftImage from '../../components/NftImage/NftImage';
import axios from 'axios';
import CloseIcon from '../../assets/icons/CloseIcon';

interface IPullbackUser extends IUser {
  starz_wallet_address: string;
}

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MangeAssetsAll: FC<IProps> = ({ open, setOpen }) => {
  const { theme } = useTheme();
  const dispatch = useDispatch<Dispatch>();
  const guild = useSelector((state: RootState) => state.guilds.currentGuild);
  const supportedChains = useSelector(
    (state: RootState) => state.config.nftContracts
  );

  const [game, setGame] = useState(guild?.Games[0].id || '');
  const [chain, setChain] = useState('');
  const [chainsLoading, setChainsLoading] = useState(false);
  const [chainOptions, setChainOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const [users, setUsers] = useState<IPullbackUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IPullbackUser | null>(null);
  const [nftsLoading, setNftsLoading] = useState(false);

  const [nfts, setNfts] = useState<IScholarNfts[]>([]);

  const [selectedNfts, setSelectedNfts] = useState<IMassPullbackNfts[]>([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectNfts = (nft: IScholarNfts) => {
    const _selectedNfts = [...selectedNfts];
    let userPrevIndex: null | number = null;
    for (let i = 0; i < selectedNfts.length; i++) {
      if (selectedNfts[i].userId === selectedUser!.id) {
        userPrevIndex = i;
        break;
      }
    }
    if (typeof userPrevIndex === 'number' && userPrevIndex >= 0) {
      _selectedNfts[userPrevIndex].nfts.push(nft);
      setSelectedNfts([..._selectedNfts]);
    } else {
      _selectedNfts.push({
        userId: selectedUser!.id,
        username: selectedUser!.username,
        nfts: [nft],
      });
      setSelectedNfts([..._selectedNfts]);
    }

    setNfts(nfts.filter(n => n.token_hash !== nft.token_hash));
  };

  const handleGetGameChainIds = async () => {
    try {
      setChainsLoading(true);
      const options: Array<{ label: string; value: string }> = [];
      const { data } = await getGameNftContracts(game);
      data.forEach(contract => {
        supportedChains.forEach(c => {
          if (c.chain === contract.chain) {
            const exist = options.find(op => op.value === c.chain);
            if (!exist) {
              options.push({ label: c.name, value: c.chain });
            }
          }
        });
      });
      setChainOptions([...options]);
      setChain(options[0].value);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setChainsLoading(false);
    }
  };

  const handleGetUsers = async () => {
    try {
      const { data } = await getMassPullBackUsers({
        gameId: game,
        guildId: guild!.id,
        chain: chain,
      });
      setUsers(data);
      setSelectedUser(data[0]);
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const handleGetNfts = async () => {
    try {
      setNftsLoading(true);
      const sNfts: IScholarNfts[] = [];
      const { data } = await getUserNfts({
        public_address: selectedUser!.starz_wallet_address,
        game_id: game,
        chain_id: chain,
        userId: selectedUser!.id,
      });

      let shouldFilter: boolean = false;
      let userNfts: IScholarNfts[] = [];

      selectedNfts.forEach(nfts => {
        if (nfts.userId === selectedUser!.id) {
          shouldFilter = true;
          userNfts = nfts.nfts;
        }
      });

      data.forEach(e => {
        e.Nfts.forEach(nft => {
          sNfts.push({
            ...nft,
            contract: e.contract,
            game_id: e.game_id,
            contract_id: e.contract_id,
          });
        });
      });

      if (shouldFilter) {
        const filteredNfts: IScholarNfts[] = [];
        sNfts.forEach(nft => {
          const foundNft: IScholarNfts | null | undefined = userNfts.find(
            n =>
              n.token_id === nft.token_id &&
              n.token_address === nft.token_address
          );
          if (!foundNft) {
            filteredNfts.push(nft);
          }
        });
        setNfts(filteredNfts);
      } else {
        setNfts(sNfts);
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setNftsLoading(false);
    }
  };

  const handleDeselect = (nft: IScholarNfts, userId: string) => {
    const _selectedNfts = [...selectedNfts];
    let index: null | number = null;

    for (let i = 0; i < _selectedNfts.length; i++) {
      if (_selectedNfts[i].userId === userId) {
        index = i;
        break;
      }
    }
    if (typeof index === 'number' && index >= 0) {
      const nftList = _selectedNfts[index].nfts.filter(
        n => n.token_hash !== nft.token_hash
      );
      _selectedNfts[index].nfts = nftList;
      setSelectedNfts([..._selectedNfts]);
    }
    if (userId === selectedUser!.id) {
      setNfts(prev => [...prev, nft]);
    }
  };

  const handlePullBack = async () => {
    try {
      setLoading(true);
      const Nfts: any[] = [];
      selectedNfts.forEach(obj => {
        obj.nfts.forEach(nft => {
          Nfts.push({
            userId: obj.userId,
            contract_id: nft.contract_id,
            token_id: nft.token_id,
            amount: nft.amount || '0',
          });
        });
      });
      // TODO: make interface for api data
      const apiData = {
        Nfts,
        game_id: game,
        chain,
        guildId: guild?.id,
      };
      await massPullback(apiData);
      setSelectedNfts([]);
      toast.success('Pullback added to queue successfully.');
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data.message;
        toast.error(errorMessage);
      } else {
        toast.error(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch.config.handleGetAllSupportedChains();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (game && supportedChains) {
      handleGetGameChainIds();
    }
    // eslint-disable-next-line
  }, [game, supportedChains]);

  useEffect(() => {
    if (guild?.Games.length) {
      setGame(guild.Games[0].id);
    }
    // eslint-disable-next-line
  }, [guild]);

  useEffect(() => {
    if (game && chain && guild) {
      setSelectedNfts([]);
      handleGetUsers();
    }
    // eslint-disable-next-line
  }, [game, chain]);

  useEffect(() => {
    if (selectedUser) {
      handleGetNfts();
    }
    // eslint-disable-next-line
  }, [selectedUser]);

  useEffect(() => {
    const filtered = selectedNfts.filter(el => el.nfts.length > 0);
    if (filtered) {
      setSelectedNfts(filtered);
    }
  }, [selectedNfts]);

  return (
    <DialogLayout open={true}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>
            Manage assets for Scholars
          </p>
          <div className='row game-selector'>
            <Select
              label='Games'
              value={game}
              disabled
              options={
                guild?.Games.map(game => ({
                  label: game.name,
                  value: game.id,
                })) || []
              }
              onChange={e => setGame(e.currentTarget.value)}
            />
            {chainsLoading ? (
              <ClipLoader size={15} color={theme.color.text.primary} />
            ) : (
              <Select
                label='Select chain'
                disabled
                value={chain}
                options={chainOptions}
                onChange={e => setChain(e.currentTarget.value)}
              />
            )}

            <div className='icon clickable' onClick={handleClose}>
              <CrossIcon />
            </div>
          </div>
        </div>
        <p className='font-size-12 text-secondary' style={{ marginTop: '6px' }}>
          NOTE: You can only pull multiple assets of same game and chain.
        </p>
        <Content>
          <UserList>
            <div className='search-container'>
              <SearchBar className='search-bar' />
            </div>

            <div className='user-list'>
              {users.map(user => (
                <UserRow
                  className={classNames(
                    'row justify-space-between clickable',
                    user.id === selectedUser?.id && 'active'
                  )}
                  key={user.id}
                  onClick={() => setSelectedUser(user)}
                >
                  <p className='font-size-12 text-primary text-bold'>
                    {user.username}
                  </p>
                  <p className='font-size-12 text-primary'>
                    {truncateString(user.starz_wallet_address)}
                  </p>
                </UserRow>
              ))}
            </div>
          </UserList>
          <NftsSelector>
            <div className='content-box'>
              <p className='font-size-14 text-primary text-semibold'>
                Pull back assets
              </p>
              <UserNfts>
                <div className='container'>
                  {selectedNfts.map(obj => (
                    <UserNftGroup key={obj.userId}>
                      <p className='font-size-14 text-primary text-bold'>
                        {obj.username}
                      </p>
                      <Grid columns='1fr 1fr 1fr'>
                        {obj.nfts.map(nft => (
                          <NFtContainer className='nft' key={nft.token_hash}>
                            <NftImage container nftData={nft} size={60} />
                            <div
                              className='icon clickable'
                              onClick={() => handleDeselect(nft, obj.userId)}
                            >
                              <CloseIcon fill={theme.color.text.primary} />
                            </div>
                          </NFtContainer>
                        ))}
                      </Grid>
                    </UserNftGroup>
                  ))}
                </div>
              </UserNfts>
              <div className='divider'></div>
              <div className='row justify-space-between'>
                <p className='font-size-14 text-primary text-semibold'>
                  All assets
                </p>
                <p className='font-size-14 text-primary text-semibold clickable'>
                  Select all
                </p>
              </div>
              <NFTsContainer
                style={
                  (nftsLoading && {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }) ||
                  {}
                }
              >
                {nftsLoading ? (
                  <div className='row justify-center'>
                    <ClipLoader size={50} color={theme.color.text.primary} />
                  </div>
                ) : (
                  <div className='grid'>
                    {nfts.map(nft => (
                      <NFtContainer
                        onClick={() => handleSelectNfts(nft)}
                        className='clickable'
                        key={nft.token_hash}
                      >
                        <NftImage nftData={nft} size={120} container />
                      </NFtContainer>
                    ))}
                  </div>
                )}
              </NFTsContainer>
            </div>
          </NftsSelector>
        </Content>
        <ButtonContainer className='row flex-end'>
          <ActionButton
            className='btn primary'
            variant='primary'
            onClick={handleClose}
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handlePullBack}
            className='btn secondary'
            variant='secondary'
            disabled={loading || !selectedNfts.length}
          >
            Confirm
          </ActionButton>
        </ButtonContainer>
      </Container>
    </DialogLayout>
  );
};

export default MangeAssetsAll;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 20px;
  border-radius: 12px;
  width: 65vw;
  height: 80vh;
  max-height: 780px;
  display: flex;
  flex-direction: column;

  & .game-selector {
    gap: 15px;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  margin-top: 19px;
  display: flex;
  gap: 15px;
`;

const UserList = styled.div`
  width: 230px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  & .search-container {
    padding: 15px 10px;
    & .search-bar {
      width: 100%;
    }
  }

  & .user-list {
    flex-grow: 1;
    height: 0px;
    overflow: auto;
    padding-inline: 10px;
    padding-bottom: 10px;
  }
`;

const UserRow = styled.div`
  height: 38px;
  border-bottom: 1px solid ${({ theme }) => theme.color.icon.inActive + '33'};
  padding-inline: 8px;

  &.active {
    background-color: ${({ theme }) => theme.color.background.row};
    border-bottom: none;
    border-radius: 8px;
  }
`;

const NftsSelector = styled.div`
  flex-grow: 1;
  height: 100%;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 15px;

  & .filters-row {
    gap: 13px;
    & .search-bar {
      width: 100%;
    }
  }

  & .content-box {
    flex-grow: 1;
    background-color: ${({ theme }) => theme.color.background.row};
    border-radius: 12px;
    padding: 15px;
    display: flex;
    flex-direction: column;

    & .divider {
      height: 0.5px;
      width: 100%;
      background-color: ${({ theme }) => theme.color.text.dim};
      margin-block: 12px 14px;
    }
  }
`;

const NFTsContainer = styled.div`
  flex-grow: 1;
  height: 0px;
  overflow: auto;
  margin-top: 15px;

  & .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    gap: 10px;
  }
`;

const NFtContainer = styled.div`
  aspect-ratio: 1/1;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  border-radius: 6px;
  overflow: hidden;

  &.nft {
    position: relative;
    overflow: visible;

    & img {
      border-radius: 6px;
    }
    & .icon {
      position: absolute;
      top: -3px;
      right: -3px;
      height: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      & svg {
        width: 10px;
        height: 10px;
      }
    }
  }
`;

const UserNftGroup = styled.div``;

const UserNfts = styled.div`
  height: 135px;
  overflow: auto;

  & .container {
    display: flex;
    gap: 30px;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px;
  }
`;

const Grid = styled.div<{ columns: string }>`
  margin-top: 12px;
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  gap: 6px 8px;

  & div.nft {
    width: 28px;
    height: 28px;
    aspect-ratio: 1/1;
    position: relative;
  }
`;

const ButtonContainer = styled.div`
  gap: 16px;
  margin-top: 23px;

  & .btn {
    height: 50px;
    width: 192px;
  }

  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    & .btn {
      height: 40px;
      width: 150px;
    }
  }
`;
