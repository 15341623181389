import { createModel } from '@rematch/core';
import axios from 'axios';
import { RootModel } from '.';
import {
  addBadge,
  addBadgeToMultipleGame,
  allBadges,
  mintBagde,
  mintedBadges,
  updateBadge,
  updateBadgeImage,
} from '../../api';
import { IBadge, IGame } from '../../types/api';
import {
  IAddBadgePayload,
  IMintBadgePayload,
  IUpdateBadgePayload,
} from '../../types/storeModels';
import { toast } from 'react-toastify';

interface IState {
  loading: boolean;
  badges: IBadge[];
  selected: IBadge | null;
  userMintedBadges: IBadge[];
  userHighestBadge: IBadge | null;
}

export const badges = createModel<RootModel>()({
  name: 'badges',
  state: {
    loading: false,
    badges: [],
    selected: null,
    userMintedBadges: [],
    userHighestBadge: null,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setBadges(state, payload: IBadge[]) {
      state.badges = payload;
    },
    setSelected(state, payload: IBadge | null) {
      state.selected = payload;
    },
    setUserMintedBadges(state, payload: IBadge[]) {
      state.userMintedBadges = payload;
    },
    setUserHighestBadge(state, payload: IBadge | null) {
      state.userHighestBadge = payload;
    },
  },
  effects: dispatch => ({
    async handleGetAllBadges() {
      try {
        dispatch.badges.setLoading(true);
        const { data } = await allBadges();
        dispatch.badges.setBadges(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.badges.setLoading(false);
      }
    },
    async handleAddBadge(payload: IAddBadgePayload) {
      try {
        const { data, setOpen } = payload;

        dispatch.badges.setLoading(true);

        const { data: res } = await addBadge({
          description: data.description,
          name: data.name,
          stz: data.stz,
        });

        const formData = new FormData();
        formData.append('logo', data.image);
        await updateBadgeImage(formData, res.id);

        const badgeGames = data.selectedGames.map((game: IGame) => ({
          game_id: game.id,
          badge_id: res.id,
        }));

        if (badgeGames.length) {
          await addBadgeToMultipleGame(badgeGames);
        }

        setOpen(false);
        toast.success('Badge added successfully.');
        dispatch.badges.handleGetAllBadges();
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.badges.setLoading(false);
      }
    },
    async handleUpdateBadge(payload: IUpdateBadgePayload, state) {
      try {
        const { data, setOpen } = payload;

        const { description, name, stz, image } = data;

        const selected = state.badges.selected;
        if (selected) {
          dispatch.badges.setLoading(true);

          await updateBadge({ description, name, stz }, selected.id);

          if (
            typeof image !== 'string' &&
            typeof image !== 'undefined' &&
            image !== null
          ) {
            const formData = new FormData();
            formData.append('logo', image);
            await updateBadgeImage(formData, selected.id);
          }

          dispatch.badges.setSelected(null);
          setOpen(false);
          toast.success('Badge updated successfully.');
          dispatch.badges.handleGetAllBadges();
        }
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.badges.setLoading(false);
      }
    },
    async handleMintBadge(payload: IMintBadgePayload, state) {
      const { data, setOpen, setErrors } = payload;
      try {
        dispatch.badges.setLoading(true);
        await mintBagde(data);
        dispatch.badges.handleGetMintedBadges(state.auth.user!.id);
        setOpen(false);
        toast.success('Badge minted successfully.');
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          if (errorMessage.includes('badge already minited')) {
            setErrors((prev: any) => ({ ...prev, badge_id: errorMessage }));
          }
        }
      } finally {
        dispatch.badges.setLoading(false);
      }
    },
    async handleGetMintedBadges(payload: string) {
      try {
        dispatch.badges.setLoading(true);
        const { data } = await mintedBadges(payload);
        let highest = data[0];
        data.forEach(badge => {
          if (highest.stz < badge.stz) {
            highest = badge;
          }
        });
        dispatch.badges.setUserMintedBadges(data);
        dispatch.badges.setUserHighestBadge(highest);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.badges.setLoading(false);
      }
    },
  }),
});
