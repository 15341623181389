import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ArrowRight from '../../assets/icons/ArrowRight';
import { lightTheme as theme } from '../../theme';
import { IGame, IGuild, IGuildStats } from '../../types/api';
import { IGuildSocialLink } from '../../types/interface';
import { getGuildGames, getGuildSocialLinks, getGuildStats } from '../../api';
import RenderSocialIcon from '../RenderSocialIcon/RenderSocialIcon';
import UserMiniGameList from '../UserMiniGameList/UserMiniGameList';

interface IProps {
  guild: IGuild;
}

const MyGuildCard: FC<IProps> = ({ guild }) => {
  const [games, setGames] = useState<IGame[]>([]);
  const [socialLinks, setSocialLinks] = useState<IGuildSocialLink[]>([]);
  const [stats, setStats] = useState<IGuildStats | null>(null);
  const handleGetInitialData = async () => {
    try {
      const [
        { data: guildGames },
        { data: guildSocial },
        { data: guildStats },
      ] = await Promise.all([
        getGuildGames(guild.id),
        getGuildSocialLinks(guild.id),
        getGuildStats(guild.id),
      ]);

      setGames(guildGames);
      setSocialLinks(guildSocial);
      setStats(guildStats);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    handleGetInitialData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <GuildImage
        style={{ backgroundImage: `url(${guild.logo})` }}
      ></GuildImage>
      <GuildInfo>
        <p className='font-size-30 text-primary'>99Starz</p>

        <div className='divider'></div>

        <div className='row games'>
          <UserMiniGameList games={games} />
        </div>

        <div className='row justify-space-between stats'>
          <GuildUsers>
            <div className='row'>
              <div className='count'>
                <p className='font-size-10 text-bold text-primary'>
                  {stats?.totalMasters.total}
                </p>
              </div>
              <p className='font-size-14 text-primary'>Game Masters</p>
            </div>
            <div className='row mid'>
              <div className='count'>
                <p className='font-size-10 text-bold text-primary'>
                  {stats?.totalCaptains.total}
                </p>
              </div>
              <p className='font-size-14 text-primary'>Captains</p>
            </div>
            <div className='row'>
              <div className='count'>
                <p className='font-size-10 text-bold text-primary'>
                  {stats?.totalScholars.total}
                </p>
              </div>
              <p className='font-size-14 text-primary'>Scholars</p>
            </div>
          </GuildUsers>
          <div className='vault-item-count'>
            {/* <p className='font-size-10 text-primary'>Items in vault</p>
            <p className='font-size-14 text-bold text-primary'>256</p> */}
          </div>
        </div>
        <div className='row justify-space-between'>
          <SocialLinks>
            {socialLinks.map(social => (
              <div className='item' key={social.id}>
                <RenderSocialIcon name={social.logo} />
              </div>
            ))}
          </SocialLinks>
          <Link to={`/dashboard/guilds/page/${guild.id}`} className='anchor'>
            <div className='row' style={{ gap: '7px' }}>
              <p className='font-size-12 text-primary'>View Guild</p>
              <ArrowRight className='arrow' />
            </div>
          </Link>
        </div>
      </GuildInfo>
    </Container>
  );
};

export default MyGuildCard;

const Container = styled.div`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 10px;
  display: flex;
  gap: 10px;

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    padding: 20px;
    gap: 20px;
  }
`;

const GuildImage = styled.div`
  flex: 1;
  aspect-ratio: 1/1;
  border-radius: 12px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const GuildInfo = styled.div`
  flex: 1.1;

  & .divider {
    height: 0.5px;
    background-color: ${({ theme }) => theme.color.text.secondary};
    margin-block: 8px 10px;
  }

  & .games {
    gap: 5px;
  }

  & .stats {
    margin-block: 18px 17px;
    align-items: flex-start;
  }

  & .arrow {
    width: 15px;
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .divider {
      margin-block: 17px 15px;
    }

    & .games {
      gap: 10px;
    }
    & .stats {
      margin-block: 30px 29px;
    }
    & .arrow {
      width: unset;
    }
  }
`;

const GuildUsers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & .row {
    gap: 10px;

    & .count {
      height: 22px;
      width: 22px;
      display: grid;
      place-items: center;
      background-color: ${({ theme }) => theme.color.background.row};
      border-radius: 50%;
    }
  }
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  & .item {
    height: 24px;
    width: 24px;
    padding: 4px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.background.row};
    display: grid;
    place-items: center;
    & svg {
      height: 100%;
      width: 100%;
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    gap: 10px;
  }
`;
