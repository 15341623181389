import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme';

const SelectIcon: FC<{ [x: string]: any; fill?: string }> = ({
  fill,
  ...rest
}) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='11'
      height='7'
      viewBox='0 0 11 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M5.05883 4.99459L8.83983 1.21059C8.90598 1.14416 8.9846 1.09145 9.07118 1.05549C9.15775 1.01952 9.25058 1.001 9.34433 1.001C9.43808 1.001 9.5309 1.01952 9.61748 1.05549C9.70405 1.09145 9.78268 1.14416 9.84883 1.21059C9.98172 1.34548 10.0562 1.52723 10.0562 1.71659C10.0562 1.90594 9.98172 2.0877 9.84883 2.22259L5.56983 6.50959C5.4399 6.63917 5.265 6.71367 5.08154 6.71758C4.89808 6.72149 4.72017 6.65452 4.58483 6.53059L0.269829 2.22559C0.199386 2.16026 0.142831 2.08141 0.103541 1.99374C0.0642517 1.90606 0.0430328 1.81138 0.0411526 1.71532C0.0392723 1.61927 0.0567689 1.52382 0.0925976 1.43468C0.128426 1.34554 0.181852 1.26453 0.249684 1.1965C0.317517 1.12846 0.398364 1.0748 0.487399 1.03871C0.576433 1.00261 0.671828 0.984832 0.767887 0.986427C0.863946 0.988022 0.958698 1.00896 1.04648 1.04799C1.13427 1.08702 1.21329 1.14334 1.27883 1.21359L5.05883 4.99459Z'
        fill={fill || theme.color.text.primary}
      />
    </svg>
  );
};

export default SelectIcon;
