import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import ProgressBar from './Components/ProgressBar';
import CopyIcon from '../../assets/icons/CopyIcon';
import GamePerformanceCard from './Components/GamePerformanceCard/GamePerformanceCard';
import ManageAssets from '../../Dialogs/ManageAssets/ManageAssets';
import Scholarships from './Components/Scholarships/Scholarships';

import assets from '../../assets';
import Chart from '../../components/Chart/Chart';
import MintBadge from '../../Dialogs/MintBadge/MintBadge';
import ApplyScholarship from '../../Dialogs/ApplyScholarship/ApplyScholarship';
import useRole from '../../hooks/useRole';
import { copyAddress } from '../../utils';
import {
  INft,
  IGuildSocialLink,
  IUserOrGuildStats,
} from '../../types/interface';
import {
  allUserNfts,
  getUserById,
  getUserGames,
  updateUserImage,
  userOrGuildStats,
} from '../../api';

import NftImage from '../../components/NftImage/NftImage';
import RenderSocialIcon from '../../components/RenderSocialIcon/RenderSocialIcon';

import { ClipLoader } from 'react-spinners';
import useTheme from '../../hooks/useTheme';
import { IGame } from '../../types/api';

import ActionButton from '../../components/ActionButton/ActionButton';
import EditProfile from '../../Dialogs/EditProfile/EditProfile';
import Camera from '../../assets/icons/Camera';
import UserMiniGameList from '../../components/UserMiniGameList/UserMiniGameList';
import { toast } from 'react-toastify';

dayjs.extend(utc);

const ScholarDashboard: FC = () => {
  const { theme } = useTheme();

  const dispatch = useDispatch<Dispatch>();

  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  const socials: any = useSelector(
    (state: RootState) => state.users.currentUserSocialLinks
  );

  const scholarships = useSelector(
    (state: RootState) => state.scholarships.activeGuildScholarships
  );

  const user = useSelector((state: RootState) => state.auth.user);
  const account = useSelector((state: RootState) => state.auth.walletAccounts);
  const { role } = useRole();
  const [userNfts, setUserNfts] = useState<INft[]>([]);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [mintBadge, setMintBadge] = useState(false);
  const [applyScholarship, setApplyScholarship] = useState(false);
  const [games, setGames] = useState<IGame[]>([]);
  const [userStats, setUserStats] = useState<IUserOrGuildStats | null>(null);
  const [coverImage, setCoverImage] = useState(user?.cover_photo || '');
  const [profileImage, setProfileImage] = useState(user?.image_url || '');

  const handleEditProfile = () => {
    setEdit(true);
  };

  const handleCopyAddress = (address: string) => {
    navigator.clipboard.writeText(address);
    toast.success('Address copied successfully');
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;
    const files = e.currentTarget.files;
    if (files?.length) {
      const file = files[0];
      if (name === 'cover_photo') {
        setCoverImage(URL.createObjectURL(file));
      }
      if (name === 'logo') {
        setProfileImage(URL.createObjectURL(file));
      }
      const image = new FormData();
      image.append(name, file);
      try {
        await updateUserImage(user!.id, image);
        dispatch.auth.getCurrentuser();
      } catch (err: any) {
        console.log(err.message);
      }
    }
  };

  const handleGetInitialData = async () => {
    try {
      setLoading(true);
      if (user) {
        let nftList: INft[] = [];
        const [{ data: gameList }, { data }, { data: stats }] =
          await Promise.all([
            getUserGames(user.id),
            getUserById(user.id),
            userOrGuildStats(user.id),
          ]);
        setGames(gameList);
        setUserStats(stats);
        if (data?.account?.starz_wallet_address) {
          const { data: nfts } = await allUserNfts({
            public_address: data.account.starz_wallet_address,
            user_id: user.id,
          });

          nfts.forEach(item => {
            nftList = [...nftList, ...item.Nfts];
          });

          setUserNfts(nftList);
        }
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch.scholarships.handleGetGuildScholarships(currentGuild?.id);

    // eslint-disable-next-line
  }, [currentGuild]);

  useEffect(() => {
    dispatch.users.handleGetUserSocialLinks(null);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user) {
      handleGetInitialData();
    }
    // eslint-disable-next-line
  }, [user, account]);

  return (
    <Container>
      <StatsRow>
        {/* <div className='row'>
          <p className='font-size-16 text-primary'>Wins</p>
          <p className='font-size-22 text-primary text-bold'>265</p>
        </div>
        <div className='row'>
          <p className='font-size-16 text-primary'>Total Earning</p>
          <p className='font-size-22 text-primary text-bold'>0</p>
        </div> */}
        <div className='row'>
          <p className='font-size-16 text-primary'>Items in vault</p>
          <p className='font-size-22 text-primary text-bold'>
            {userStats?.valutItems}
          </p>
        </div>
        <div className='row'>
          <p className='font-size-16 text-primary'>Next Payout</p>
          <p className='font-size-22 text-primary text-bold'>
            {userStats?.next_payout &&
              dayjs(userStats.next_payout).format('DD / MM / YYYY')}
          </p>
        </div>
      </StatsRow>
      <div className='cover'>
        <div className='cover-inner'>
          <img src={coverImage ? coverImage : assets.guildCover} alt='' />
          <label htmlFor='cover-input' className='cover-input'>
            <div className='icon'>
              <Camera />
            </div>
          </label>
          <input
            type='file'
            onChange={handleChange}
            name='cover_photo'
            accept='image/jpg, image/jpeg, image/png'
            id='cover-input'
            style={{ display: 'none' }}
          />
        </div>
        <div className='profile-image'>
          <div className='profile-inner'>
            <img
              src={profileImage ? profileImage : assets.userPreview}
              alt=''
            />
            <label htmlFor='logo' className='logo'>
              <div className='icon'>
                <Camera />
              </div>
            </label>
            <input
              type='file'
              onChange={handleChange}
              name='logo'
              accept='image/jpg, image/jpeg, image/png'
              id='logo'
              style={{ display: 'none' }}
            />
          </div>
        </div>
      </div>
      <div className='row flex-end edit-profile-btn'>
        <ActionButton variant='secondary' onClick={handleEditProfile}>
          Edit Profile
        </ActionButton>
      </div>

      <div className='row main'>
        <div className='right'>
          <Grid>
            {games.map(game => (
              <GamePerformanceCard game={game} key={game.id} />
            ))}
          </Grid>
          <ProfileInfoCard>
            <p className='font-size-25 text-primary text-bold text-center capitalize'>
              {user?.username} {user?.last_name}
            </p>
            <p
              style={{ marginTop: '5px' }}
              className='font-size-12 text-secondary text-semibold text-center'
            >
              {user?.email}
            </p>
            <p className='font-size-12 country text-secondary text-semibold text-center'>
              {user?.country}
            </p>
            {user?.wallet_address && (
              <WalletAddress>
                <p className='font-size-12 address text-semibold'>
                  {user?.wallet_address}
                </p>
                <div
                  className='icon clickable'
                  onClick={() => copyAddress(user.wallet_address)}
                >
                  <CopyIcon fill='#C3CBD2' />
                </div>
              </WalletAddress>
            )}

            <ProgressBar />
            <Divider />
            <p className='font-size-14 text-semibold text-primary capitalize'>
              <span className='text-dim'>Name:</span> {user?.username}{' '}
              {user?.last_name}
            </p>
            {currentGuild && (
              <p className='font-size-14 text-semibold text-primary capitalize'>
                <span className='text-dim'>Guild:</span> {currentGuild?.name}
              </p>
            )}
            <p className='font-size-14 text-semibold text-primary capitalize'>
              <span className='text-dim'>Role:</span> {role?.name}
            </p>
            {user?.ronin_wallet && (
              <div className='row' style={{ alignItems: 'center' }}>
                <p className='font-size-14 text-semibold text-primary capitalize'>
                  <span className='text-dim'>Ronin wallet:</span>
                </p>
                <WalletAddress
                  style={{
                    margin: '0',
                    marginLeft: '10px',
                    background: 'transparent',
                  }}
                >
                  <p className='font-size-12 address text-semibold text-primary'>
                    {user.ronin_wallet}
                  </p>
                  <div
                    className='icon clickable'
                    onClick={() => handleCopyAddress(user.ronin_wallet || '')}
                  >
                    <CopyIcon fill={theme.color.text.primary} />
                  </div>
                </WalletAddress>
              </div>
            )}
            {user?.ronin_email && (
              <p className='font-size-14 text-semibold text-primary capitalize'>
                <span className='text-dim'>Ronin email:</span>{' '}
                {user.ronin_email}
              </p>
            )}
            {!!games.length && (
              <>
                <Divider />
                <GamesContainer>
                  <UserMiniGameList games={games} />
                </GamesContainer>
              </>
            )}

            {socials.length > 0 && (
              <>
                <Divider />
                <SocialLinks>
                  {socials.map((social: IGuildSocialLink) => (
                    <a
                      href={social.url}
                      target='_blank'
                      key={social.id}
                      className='link'
                      rel='noreferrer'
                    >
                      <RenderSocialIcon name={social.logo} />
                    </a>
                  ))}
                </SocialLinks>
              </>
            )}
          </ProfileInfoCard>
        </div>

        <Details>
          {loading && (
            <div className='row justify-center' style={{ height: '300px' }}>
              <ClipLoader size={80} color={theme.color.text.primary} />
            </div>
          )}
          {!loading && games.length > 0 && (
            <Performance>
              {user?.id && currentGuild?.id && games.length > 0 && (
                <Chart
                  games={games}
                  userId={user?.id}
                  guildId={currentGuild!.id}
                />
              )}
            </Performance>
          )}
          <div className='row justify-space-between'>
            <p className='font-size-25 text-primary text-semibold'>
              Scholarships
            </p>
          </div>
          {!!scholarships.length && (
            <Scholarships
              mintBadge={setMintBadge}
              applyScholarship={setApplyScholarship}
            />
          )}

          {!loading && userNfts.length > 0 && (
            <>
              <div className='row justify-space-between'>
                <p className='font-size-25 text-primary text-semibold'>
                  Assets
                </p>
              </div>

              <AssetsContainer>
                {userNfts.map(nft => (
                  <div key={nft.token_hash} className='asset'>
                    <NftImage size={250} nftData={nft} container />
                  </div>
                ))}
              </AssetsContainer>
            </>
          )}
        </Details>
      </div>
      {openDialog && <ManageAssets open={openDialog} setOpen={setOpenDialog} />}
      {mintBadge && <MintBadge open={mintBadge} setOpen={setMintBadge} />}
      {applyScholarship && (
        <ApplyScholarship
          open={applyScholarship}
          setOpen={setApplyScholarship}
        />
      )}
      {edit && <EditProfile open={edit} setOpen={setEdit} />}
    </Container>
  );
};

export default ScholarDashboard;

const Container = styled.div`
  & .edit-profile-btn {
    margin-top: 16px;
  }
  & .cover {
    width: 100%;
    height: 200px;
    border-radius: 12px;

    position: relative;

    & .cover-inner {
      height: 100%;
      border-radius: 12px;
      overflow: hidden;
      position: relative;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        border-radius: 12px;
      }

      & .cover-input {
        position: absolute;
        right: 8px;
        top: 8px;
        background-color: ${({ theme }) => theme.color.background.headerRow};
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    & .profile-image {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 150px;
      width: 150px;
      border-radius: 50%;
      z-index: 1;
      border: 10px solid ${({ theme }) => theme.color.background.row};

      overflow: hidden;

      & .profile-inner {
        position: relative;
        width: 100%;
        height: 100%;
        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        & .logo {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: ${({ theme }) => theme.color.background.headerRow};
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;
        }
      }
    }
  }
  & .row.main {
    margin-top: 30px;
    gap: 30px;
    align-items: flex-start;
    flex-direction: row-reverse;
    & .right {
      width: 576px;
    }
  }
`;

const ProfileInfoCard = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 24px 30px;
  width: 100%;
  border-radius: 10px;

  & .country {
    margin-block: 10px 13px;
  }

  & .text-dim {
    opacity: 0.5;
  }

  & p {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

const WalletAddress = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 15px;
  background-color: #23272a;
  color: #c3cbd2;
  width: 126px;
  margin-inline: auto;
  align-items: center;
  margin-bottom: 25px;

  & .address {
    max-width: 14ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const Divider = styled.div`
  height: 0.5px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.text.dim};
  margin-block: 20px;
`;

const GamesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-block: 10px;
`;
const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-top: 10px;

  & .link {
    height: 37px;
    width: 37px;
    background-color: ${({ theme }) => theme.color.background.headerRow};
    border-radius: 50%;
    display: grid;
    place-items: center;
  }
`;

const Details = styled.div`
  flex-grow: 1;

  & .join-game-btn {
    background-color: ${({ theme }) => theme.color.background.card};
  }
`;

const StatsRow = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 10px;
  padding: 17px 35px;
  margin-block: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .row {
    gap: 16px;
  }
`;

const Performance = styled.div`
  margin-block: 20px 32px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-block: 29px 31px;
  gap: 20px;
`;

const AssetsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(133px, 1fr));
  gap: 20px;
  margin-top: 20px;

  & .asset {
    aspect-ratio: 1/1;
    background-color: ${({ theme }) => theme.color.background.card};
    border-radius: 8px;
    overflow: hidden;
  }
`;
