import { FC } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';
import CrossIcon from '../../assets/icons/CrossIcon';
import ActionButton from '../../components/ActionButton/ActionButton';

const GasFeeAlert: FC = () => {
  const { open, message } = useSelector((state: RootState) => state.gasAlert);
  const dispatch = useDispatch<Dispatch>();

  const handleClose = () => {
    dispatch.gasAlert.close();
  };
  return (
    <DialogLayout open={open}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>Gas fee alert</p>
          <div className='icon clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <Content>
          <p className='font-size-16 text-primary description'>{message}</p>
          <div className='divider'></div>
          <div className='row justify-center'>
            <ActionButton
              onClick={handleClose}
              variant='secondary'
              style={{ width: '100%', height: '50px' }}
            >
              Continue
            </ActionButton>
          </div>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default GasFeeAlert;

const Container = styled.div`
  width: 480px;
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 30px;
  border-radius: 12px;
`;

const Content = styled.div`
  & .description {
    margin-top: 10px;
  }

  & .divider {
    border-top: 1px solid ${({ theme }) => theme.color.text.primary + 33};
    margin-block: 20px;
  }
`;

// const WalletAddress = styled.div`
//   margin-block: 30px;
//   gap: 10px;
//   background-color: ${({ theme }) => theme.color.background.headerRow};
//   display: inline-flex;
//   padding: 10px 0px;
//   min-width: 270px;
//   margin-inline: auto;
//   border-radius: 30px;

//   & p {
//     max-width: 20ch;
//     text-overflow: ellipsis;
//     overflow: hidden;
//     white-space: nowrap;
//   }
// `;
