import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const TwitterIcon: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='24'
      height='20'
      viewBox='0 0 24 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M20.776 5.6873C20.791 5.8933 20.791 6.0983 20.791 6.3043C20.803 8.08049 20.4619 9.84135 19.7877 11.4846C19.1135 13.1279 18.1196 14.6209 16.8636 15.8769C15.6076 17.1329 14.1146 18.1268 12.4713 18.801C10.828 19.4752 9.06718 19.8163 7.291 19.8043C4.70638 19.8124 2.1745 19.0734 0 17.6763C0.380523 17.7183 0.763176 17.738 1.146 17.7353C3.28215 17.741 5.35786 17.0265 7.038 15.7073C6.04797 15.6897 5.08814 15.3634 4.29252 14.7739C3.4969 14.1845 2.90519 13.3613 2.6 12.4193C2.89773 12.4663 3.19859 12.4907 3.5 12.4923C3.92148 12.491 4.34112 12.4365 4.749 12.3303C3.67326 12.1137 2.70582 11.5309 2.01135 10.6813C1.31688 9.83167 0.938286 8.76764 0.94 7.6703V7.6123C1.59778 7.97842 2.33273 8.184 3.085 8.2123C2.07837 7.54049 1.36593 6.50998 1.09295 5.33095C0.819983 4.15192 1.00705 2.91316 1.616 1.8673C2.80791 3.33431 4.29481 4.53447 5.98031 5.38996C7.6658 6.24546 9.51227 6.7372 11.4 6.8333C11.3237 6.47594 11.2842 6.11171 11.282 5.7463C11.2816 4.79327 11.5678 3.86215 12.1036 3.07395C12.6393 2.28576 13.3998 1.67695 14.2861 1.32665C15.1724 0.97635 16.1436 0.900764 17.0734 1.10971C18.0033 1.31867 18.8488 1.80249 19.5 2.4983C20.5621 2.29341 21.5806 1.90565 22.51 1.3523C22.1561 2.44882 21.4145 3.3786 20.424 3.9673C21.3665 3.85993 22.2877 3.61219 23.157 3.2323C22.5074 4.17837 21.7027 5.00802 20.777 5.6863L20.776 5.6873Z'
        fill={fill || theme.color.text.primary}
      />
    </svg>
  );
};

export default TwitterIcon;
