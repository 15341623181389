import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { addCategory, allCategories } from '../../api';
import { ICategory } from '../../types/api';
import { IAddCategory } from '../../types/storeModels';

interface IState {
  loading: boolean;
  categories: ICategory[];
}

export const category = createModel<RootModel>()({
  name: 'category',
  state: {
    loading: false,
    categories: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setCategories(state, payload) {
      state.categories = payload;
    },
  },
  effects: dispatch => ({
    async handleGetAllCategories() {
      try {
        dispatch.category.setLoading(true);
        const { data } = await allCategories();
        dispatch.category.setCategories(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.category.setLoading(false);
      }
    },
    async handleAddCategory(payload: IAddCategory) {
      try {
        dispatch.category.setLoading(true);
        await addCategory(payload.data);
        payload.setOpen(false);
        dispatch.category.handleGetAllCategories();
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.category.setLoading(false);
      }
    },
  }),
});
