import { DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
  color: {
    background: {
      main: '#EBEAE9',
      card: '#FFFFFF',
      headerRow: '#D4D1D0',
      row: '#EBEBEB',
      gameCard: '#FFFFFF',
      socialLink: '#353940',
      rowSeperator: '#C3CBD2',
      chip: '#41414D',
      menu: '#DFE4E9',
      hover: '#F05231',
      button: {
        primary: {
          bg: '#353B40',
          color: '#ffffff',
        },
      },
    },
    text: {
      primary: '#232221',
      secondary: '#706B69',
      dim: '#8D7D76',
      dimmer: '#828995',
      danger: '#FF0000',
      chip: '#FFFFFF',
    },
    gradient: {
      card: 'linear-gradient(224.48deg, #FFFFFF 0.45%, #FFFFFF 99.55%)',
      login:
        ' linear-gradient(90.37deg, rgba(34, 34, 34, 0) 0.35%, #A6B1BB 93.25%)',
      // 'linear-gradient(90.37deg, rgba(36, 36, 36, 0) 0.35%, #141619 93.25%)',

      // 'linear-gradient(90.37deg, rgba(34, 34, 34, 0) 0.35%, #A6B1BB 93.25%)',
    },
    primary: {},
    secondary: {},
    icon: {
      active: '#FFFFFF',
      inActive: '#443D3C',
    },
  },
  breakpoints: {
    xs: '320px',
    sm: '576px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
    '3xl': '1920px',
  },
};

export const darkTheme: DefaultTheme = {
  color: {
    background: {
      main: '#1A1917',
      card: '#302F2E',
      headerRow: '#262524',
      row: '#383534',
      gameCard: '#52555A',
      socialLink: '#23272A',
      rowSeperator: '#8997A5',
      chip: '#464B50',
      menu: '#2A3034',
      hover: '#F05231',
      button: {
        primary: {
          bg: '#ffffff',
          color: '#353B40',
        },
      },
    },
    gradient: {
      card: 'linear-gradient(224.48deg, #353940 0.45%, #26292E 99.55%)',
      login:
        'linear-gradient(90.37deg, rgba(36, 36, 36, 0) 0.35%, #141619 93.25%)',
      // 'linear-gradient(90.37deg, rgba(36, 36, 36, 0) 0.35%, #141619 93.25%)',
    },
    text: {
      primary: '#EEEAE4',
      secondary: '#C2AFA5',
      dim: '#A79993',
      dimmer: '#6C7781',
      danger: '#FF0000',
      chip: '#8997A5',
    },
    primary: {},
    secondary: {},
    icon: {
      active: '#FFFFFF',
      inActive: '#E8D0CA',
    },
  },
  breakpoints: {
    xs: '320px',
    sm: '576px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
    '3xl': '1920px',
  },
};
