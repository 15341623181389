import { createModel } from '@rematch/core';
import { RootModel } from '.';

interface IState {
  currentTheme: 'dark' | 'light';
}

export const theme = createModel<RootModel>()({
  name: 'theme',
  state: {
    currentTheme: localStorage.getItem('theme') || 'light',
  } as IState,
  reducers: {
    setTheme(state, payload: 'dark' | 'light') {
      state.currentTheme = payload;
      localStorage.setItem('theme', payload);
    },
  },
});
