import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import Select from '../../components/Select/Select';
import ActionButton from '../../components/ActionButton/ActionButton';
import LatestTournaments from './Components/LatestTournaments';
import TournamentCard from './Components/TournamentCard';
import CreateTournament from '../../Dialogs/CreateTournament/CreateTournament';
import { features, modulesEnum } from '../../types/enums';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../hooks/useTheme';
import WithPermissions from '../../components/WithPermissions/WithPermissions';

dayjs.extend(utc);

const Tournaments: FC = () => {
  const { theme } = useTheme();
  const dispatch = useDispatch<Dispatch>();

  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  const badges = useSelector((state: RootState) => state.badges.badges);

  const tournaments = useSelector(
    (state: RootState) => state.tournaments.tournaments
  );

  const loading = useSelector((state: RootState) => state.tournaments.loading);

  const [openDialog, setOpenDialog] = useState(false);
  const [filters, setFilters] = useState({
    game: '',
    duration: '',
  });

  const handleFiltersChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleCreateTournament = (): void => {
    setOpenDialog(true);
  };

  useEffect(() => {
    if (currentGuild) {
      const guildId = currentGuild.id;
      let query = `guild_id=${guildId}`;
      if (filters.game) {
        query = `${query}&game_id=${filters.game}`;
      }
      if (filters.duration) {
        query = `${query}&date=${filters.duration}`;
      }
      dispatch.tournaments.handleGetTournaments(query);
    }
    if (!badges.length) {
      dispatch.badges.handleGetAllBadges();
    }
    // eslint-disable-next-line
  }, [currentGuild, filters]);

  return (
    <Container>
      <WithPermissions
        moduleName={modulesEnum.Tournaments}
        featureName={features.tournamentsList}
      >
        <Filters>
          <div className='row justify-space-between'>
            <p className='font-size-25 text-primary text-semibold'>
              Tournaments
            </p>
            <div className='row filters-section'>
              <Select
                label='Game'
                options={
                  currentGuild?.Games.map(game => ({
                    label: game.name,
                    value: game.id,
                  })) || []
                }
                name='game'
                value={filters.game}
                onChange={handleFiltersChange}
              />
              <Select
                label='This week'
                options={[
                  { label: 'This week', value: 1 },
                  { label: 'Last two weeks', value: 2 },
                  { label: 'Last month', value: 3 },
                ]}
                name='duration'
                value={filters.duration}
                onChange={handleFiltersChange}
              />
              <WithPermissions
                moduleName={modulesEnum.Tournaments}
                featureName={features.createTournament}
                action
              >
                <ActionButton
                  onClick={handleCreateTournament}
                  variant='primary'
                  className='btn'
                >
                  Create Tournament
                </ActionButton>
              </WithPermissions>
            </div>
          </div>
        </Filters>
        <TournamentsRow>
          {loading && !tournaments.length ? (
            <div className='row justify-center' style={{ height: '200px' }}>
              <ClipLoader size={50} color={theme.color.text.primary} />
            </div>
          ) : (
            <div className='row'>
              {tournaments.map(tournament => (
                <TournamentCard tournament={tournament} key={tournament.id} />
              ))}
            </div>
          )}
        </TournamentsRow>
      </WithPermissions>
      <WithPermissions
        moduleName={modulesEnum.Tournaments}
        featureName={features.tournamentsList}
      >
        {tournaments.length > 0 && <LatestTournaments />}
      </WithPermissions>
      {openDialog && (
        <CreateTournament open={openDialog} setOpen={setOpenDialog} />
      )}
    </Container>
  );
};

export default Tournaments;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Filters = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 10px;
  padding: 9px 20px;

  & .filters-section {
    gap: 10px;
  }

  & .btn {
    background-color: ${({ theme }) =>
      theme.color.background.button.primary.bg};
    color: ${({ theme }) => theme.color.background.button.primary.color};
  }
`;

const TournamentsRow = styled.div`
  width: 100%;
  overflow-x: auto;

  & div.row {
    gap: 20px;
    flex-wrap: nowrap;
  }
`;
