import React, { FC } from 'react';

interface IProps {
  children: React.ReactNode;
  index: number; // active tab index
  activeIndex: number; // index of active tab
}

const TabPanel: FC<IProps> = ({ index, children, activeIndex }) => {
  return <>{index === activeIndex && children}</>;
};

export default TabPanel;
