import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const IDIcon: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='12'
      height='16'
      viewBox='0 0 12 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M10.5 0H1.5C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5L0 14.5C0 14.8978 0.158035 15.2794 0.43934 15.5607C0.720644 15.842 1.10218 16 1.5 16H10.5C10.8978 16 11.2794 15.842 11.5607 15.5607C11.842 15.2794 12 14.8978 12 14.5V1.5C12 1.10218 11.842 0.720644 11.5607 0.43934C11.2794 0.158035 10.8978 0 10.5 0V0ZM4.5 1H7.5C7.63261 1 7.75979 1.05268 7.85355 1.14645C7.94732 1.24021 8 1.36739 8 1.5C8 1.63261 7.94732 1.75979 7.85355 1.85355C7.75979 1.94732 7.63261 2 7.5 2H4.5C4.36739 2 4.24021 1.94732 4.14645 1.85355C4.05268 1.75979 4 1.63261 4 1.5C4 1.36739 4.05268 1.24021 4.14645 1.14645C4.24021 1.05268 4.36739 1 4.5 1ZM6 5C6.39556 5 6.78224 5.1173 7.11114 5.33706C7.44004 5.55682 7.69638 5.86918 7.84776 6.23463C7.99913 6.60009 8.03874 7.00222 7.96157 7.39018C7.8844 7.77814 7.69392 8.13451 7.41421 8.41421C7.13451 8.69392 6.77814 8.8844 6.39018 8.96157C6.00222 9.03874 5.60009 8.99913 5.23463 8.84776C4.86918 8.69638 4.55682 8.44004 4.33706 8.11114C4.1173 7.78224 4 7.39556 4 7C4 6.46957 4.21071 5.96086 4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5ZM9.5 12.4C9.48595 12.5721 9.40453 12.7317 9.27344 12.844C9.14235 12.9564 8.9722 13.0124 8.8 13H3.2C3.0278 13.0124 2.85765 12.9564 2.72656 12.844C2.59547 12.7317 2.51405 12.5721 2.5 12.4V11.8C2.54291 11.2841 2.78739 10.8058 3.18047 10.4689C3.57355 10.132 4.08357 9.96349 4.6 10H4.756C5.14983 10.1649 5.57253 10.2499 5.9995 10.2499C6.42647 10.2499 6.84917 10.1649 7.243 10H7.4C7.91643 9.96349 8.42645 10.132 8.81953 10.4689C9.21261 10.8058 9.45709 11.2841 9.5 11.8V12.4Z'
        fill={fill || theme.color.text.primary}
      />
    </svg>
  );
};

export default IDIcon;
