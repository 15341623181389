import { createModel } from '@rematch/core';
import { RootModel } from '.';

interface IState {
  open: boolean;
  scholarWalletAddress: string;
  message: string;
  isPullback: boolean;
}

interface IOpen {
  scholarWalletAddress: string;
  message: string;
  isPullback: boolean;
}

export const gasAlert = createModel<RootModel>()({
  name: 'gasAlert',
  state: {
    open: false,
    scholarWalletAddress: '',
    message: '',
    isPullback: false,
  } as IState,
  reducers: {
    open(state, payload: IOpen) {
      state.open = true;
      state.message = payload.message;
      state.isPullback = payload.isPullback;
      state.scholarWalletAddress = payload.scholarWalletAddress;
    },
    close(state) {
      state.open = false;
      state.message = '';
      state.isPullback = false;
      state.scholarWalletAddress = '';
    },
  },
});
