import React, { FC, useState, useEffect } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import TabPanel from '../../components/TabPanel/TabPanel';
import UserList from './Components/UsersList';
import RoleManagement from './Components/RoleManagement';

import { lightTheme as theme } from '../../theme';
import { getGuildStats } from '../../api';
import { IGuildStats } from '../../types/api';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../hooks/useTheme';

const Roles: FC = () => {
  const { theme } = useTheme();
  const [tab, setTab] = useState(0);

  const [loading, setLoading] = useState(false);

  const [stats, setStats] = useState<IGuildStats | null>(null);

  const handleGetStats = async () => {
    try {
      setLoading(true);
      const { data } = await getGuildStats();
      setStats(data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetStats();
    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      <StatsContainer>
        <div className='stats'>
          <p className='font-size-16 text-dimmer'>Total Users</p>
          <p className='font-size-22 text-primary'>
            {loading ? (
              <ClipLoader size={15} color={theme.color.text.primary} />
            ) : (
              stats &&
              +stats.totalCaptains.total +
                +stats.totalMasters.total +
                +stats.totalScholars.total +
                +stats.totalGuilds.total
            )}
          </p>
        </div>
        <div className='stats'>
          <p className='font-size-16 text-dimmer'>Guild managers</p>
          <p className='font-size-22 text-primary'>
            {loading ? (
              <ClipLoader size={15} color={theme.color.text.primary} />
            ) : (
              stats?.totalGuilds.total
            )}
          </p>
        </div>
        <div className='stats'>
          <p className='font-size-16 text-dimmer'>Game Masters</p>
          <p className='font-size-22 text-primary'>
            {loading ? (
              <ClipLoader size={15} color={theme.color.text.primary} />
            ) : (
              stats?.totalMasters.total
            )}
          </p>
        </div>
        <div className='stats'>
          <p className='font-size-16 text-dimmer'>Captains</p>
          <p className='font-size-22 text-primary'>
            {loading ? (
              <ClipLoader size={15} color={theme.color.text.primary} />
            ) : (
              stats?.totalCaptains.total
            )}
          </p>
        </div>
        <div className='stats'>
          <p className='font-size-16 text-dimmer'>Scholars</p>
          <p className='font-size-22 text-primary'>
            {loading ? (
              <ClipLoader size={15} color={theme.color.text.primary} />
            ) : (
              stats?.totalScholars.total
            )}
          </p>
        </div>
      </StatsContainer>
      <TabsContainer>
        <Tabs>
          <Tab
            onClick={() => setTab(0)}
            className={classNames(tab === 0 && 'active')}
          >
            <p className='font-size-22 text-primary text-bold'>Roles</p>
          </Tab>
          <Tab
            onClick={() => setTab(1)}
            className={classNames(tab === 1 && 'active')}
          >
            <p className='font-size-22 text-primary text-bold'>Users</p>
          </Tab>
        </Tabs>
        <ContentContainer>
          <TabPanel activeIndex={tab} index={0}>
            <RoleManagement />
          </TabPanel>
          <TabPanel activeIndex={tab} index={1}>
            <UserList />
          </TabPanel>
        </ContentContainer>
      </TabsContainer>
    </Container>
  );
};

export default Roles;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    gap: 30px;
  }
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 13px 0px;
  border-radius: 10px;

  & .stats {
    display: flex;
    align-items: center;
    height: 25px;
    gap: 20px;
    justify-content: flex-start;
    padding-inline: 20px;
    border-left: 1px solid ${({ theme }) => theme.color.icon.inActive};

    &:first-of-type {
      border-left: none;
    }
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    padding: 17px 0px;
    & .stats {
      height: 37px;
      gap: 29px;
      padding-inline: 30px;
    }
  }
`;

const TabsContainer = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
`;

const Tabs = styled.div`
  padding: 10px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.text.secondary + '80'};
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const Tab = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  height: 60px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.background.card};
  opacity: 0.6;
  &.active {
    opacity: 1;
  }
`;
const ContentContainer = styled.div`
  padding: 15px;
`;
