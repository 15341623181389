import { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';
import CrossIcon from '../../assets/icons/CrossIcon';
import Select from '../../components/Select/Select';
import ActionButton from '../../components/ActionButton/ActionButton';

import { lightTheme as theme } from '../../theme';
import CloseIcon from '../../assets/icons/CloseIcon';
import { INft } from '../../types/interface';
import NftImage from '../../components/NftImage/NftImage';
import { ClipLoader } from 'react-spinners';

interface IProps {
  gamesOptions: Array<{ label: string; value: string }>;
  contractOptions: Array<{ label: string; value: string }>;
  handleGameChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleContract: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  contract: string;
  gameId: string;
  nfts: INft[];
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
}

const TransferMultipleAssets: FC<IProps> = props => {
  const {
    gamesOptions,
    contractOptions,
    handleGameChange,
    handleContract,
    contract,
    gameId,
    nfts,
    setOpenDialog,
    open,
    setOpen,
    loading,
  } = props;

  const dispatch = useDispatch<Dispatch>();

  const [nftList, setNftList] = useState<INft[]>([]);
  const [selected, setSelected] = useState<INft[]>([]);

  const handleSelect = (nft: INft) => {
    setNftList(nftList.filter(n => n.token_id !== nft.token_id));
    setSelected(prev => [...prev, nft]);
  };

  const handleDeselect = (nft: INft) => {
    setNftList(prev => [...prev, nft]);
    setSelected(selected.filter(sNft => sNft.token_id !== nft.token_id));
  };
  const handleSelectAll = () => {
    setSelected(prev => [...prev, ...nftList]);
    setNftList([]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    dispatch.vault.setSelectedNfts(selected);
    setOpen(false);
    setOpenDialog(true);
  };

  useEffect(() => {
    setNftList(nfts);
    setSelected([]);
  }, [nfts]);
  return (
    <DialogLayout open={open}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>Transfer assets</p>
          <div className='icon clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <Filters className='row flex-end'>
          <Select
            label='Game'
            options={gamesOptions}
            onChange={handleGameChange}
            value={gameId}
            name=''
            disabled
          />
          {contractOptions.length > 1 && (
            <Select
              label='Contract'
              options={contractOptions}
              onChange={handleContract}
              value={contract}
              name=''
              disabled
            />
          )}
          {/* <SearchBar className='search' /> */}
        </Filters>
        <NFTContainer>
          <p className='font-size-14 text-primary'>Pull back assetss</p>
          <SelectedNFTs>
            <div className='container-inner'>
              {selected.map(nft => (
                <SelectedNFT>
                  <NftImage container size={240} nftData={nft} />
                  <div
                    className='close-btn clickable'
                    onClick={() => handleDeselect(nft)}
                  >
                    <CloseIcon />
                  </div>
                </SelectedNFT>
              ))}
            </div>
          </SelectedNFTs>
          <Divider />
          <div className='row justify-space-between actions'>
            <p className='font-size-14 text-semibold text-primary'>
              All assets
            </p>
            <p
              className='font-size-14 text-semibold text-primary clickable'
              onClick={handleSelectAll}
            >
              Select all
            </p>
          </div>
          <div className='container'>
            {loading ? (
              <div className='row justify-center'>
                <ClipLoader size={25} color={theme.color.text.primary} />
              </div>
            ) : (
              <NFTsContainer>
                {nftList.map(nft => (
                  <NFT
                    key={nft.token_hash}
                    className='clickable'
                    onClick={() => handleSelect(nft)}
                  >
                    <NftImage nftData={nft} size={120} container />
                  </NFT>
                ))}
              </NFTsContainer>
            )}
          </div>
        </NFTContainer>
        <ButtonContainer>
          <ActionButton
            onClick={handleClose}
            variant='primary'
            className='btn btn-primary'
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleNext}
            variant='primary'
            className='btn btn-secondary'
          >
            Next
          </ActionButton>
        </ButtonContainer>
      </Container>
    </DialogLayout>
  );
};

export default TransferMultipleAssets;

const Container = styled.div`
  width: 649px;
  height: 642px;
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
`;

const Filters = styled.div`
  gap: 15px;
  margin-block: 16px 17px;

  & .search {
    flex-grow: 1;
  }
`;

const NFTContainer = styled.div`
  background-color: ${({ theme }) => theme.color.background.row};
  border-radius: 12px;
  flex-grow: 1;
  padding: 14px 15px;
  display: flex;
  flex-direction: column;

  & .actions {
    margin-block: 15px 16px;
  }

  & .container {
    flex-grow: 1;
    height: 0px;
    overflow: auto;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: ${({ theme }) => theme.color.text.secondary};
`;

const SelectedNFTs = styled.div`
  height: 110px;
  max-height: 170px;
  overflow: auto;
  padding-top: 11px;

  & .container-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
  }
`;

const NFTsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(63px, 1fr));
  gap: 15px 10px;
`;

const NFT = styled.div`
  aspect-ratio: 1/1;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.background.card};

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const SelectedNFT = styled.div`
  width: 77px;
  aspect-ratio: 1/1;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.background.card};
  position: relative;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 14px;
    width: 14px;
    transform: translateX(50%);
    top: -6px;
    border-radius: 50%;

    & svg {
      width: 14px;
      height: 14px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px;

  & .btn {
    height: 44px;
    width: 150px;
    border-radius: 8px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .btn {
      height: 55px;
      width: 198px;
      border-radius: 8px;
    }
  }
`;
