import React, { FC, useState } from 'react';

import styled from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';

import MoreIcon from '../../assets/icons/MoreIcon';

import assets from '../../assets';

import { lightTheme as theme } from '../../theme';
import { IBadge } from '../../types/api';

interface IProps {
  badge: IBadge;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const BadgeCard: FC<IProps> = ({ badge, setOpenDialog }) => {
  const dispatch = useDispatch<Dispatch>();
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const handleClose = () => {
    setOpenMenu(false);
  };

  const handleUpdatebadge = () => {
    dispatch.badges.setSelected(badge);
    setOpenDialog(true);
  };

  return (
    <Container>
      <div className='image-container'>
        <img
          src={badge.logo || ''}
          style={{ width: '100%', objectFit: 'contain' }}
          alt=''
        />
      </div>
      <p className='font-size-22 text-primary text-bold title capitalize'>
        {badge.name}
      </p>

      <p className='font-size-14 text-blend subtitle'>{badge.description}</p>

      <div className='divider'></div>

      <div className='game-container'>
        <GameIcon>
          <img src={assets.gameIcon1} alt='' />
        </GameIcon>
        <GameIcon>
          <img src={assets.gameIcon2} alt='' />
        </GameIcon>
        <GameIcon>
          <img src={assets.gameIcon3} alt='' />
        </GameIcon>
      </div>
      <div className='more clickable' onClick={toggleMenu}>
        <div className='wrapper'>
          <MoreIcon />
          {openMenu && (
            <ClickAwayListener onClickAway={handleClose}>
              <Menu>
                <div className='row clickable' onClick={handleUpdatebadge}>
                  <p className='font-size-14 text-primary'>Edit Badge</p>
                </div>
                {/* <div className='row clickable'>
                  <p className='font-size-14 text-primary'>Remove Badge</p>
                </div> */}
              </Menu>
            </ClickAwayListener>
          )}
        </div>
      </div>
    </Container>
  );
};

export default BadgeCard;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.row};
  border-radius: 12px;
  width: 100%;
  padding: 0 40px;
  padding-bottom: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  position: relative;

  & .image-container {
    height: 130px;
    width: 130px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.background.headerRow};
    margin-top: -20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 5px;
  }

  & .title {
    margin-block: 19px 16px;
  }

  & .subtitle {
    max-width: 35ch;
  }

  & .divider {
    height: 0.5px;
    background-color: ${({ theme }) => theme.color.icon.inActive};
    width: 100%;
    margin-block: 20px 15px;
  }

  & .game-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & .more {
    position: absolute;
    top: 18px;
    right: 16px;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;

    & .wrapper {
      position: relative;
    }
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    padding: 0 40px;
    padding-bottom: 15px;

    & .image-container {
      height: 166px;
      width: 166px;
    }

    & .divider {
      margin-block: 30px 15px;
    }
  }
`;

const GameIcon = styled.div`
  height: 40px;
  width: 40px;
  padding: 5px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.background.headerRow};

  & img {
    width: 100%;
    height: 100%;
  }
`;

const Menu = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  width: 130px;
  background-color: ${({ theme }) => theme.color.background.menu};
  padding: 0px 10px;
  transform: translateY(100%);
  border-radius: 10px;
  z-index: 100;
  filter: drop-shadow(2px 2px 4px rgba(53, 57, 64, 0.102));

  & .row {
    padding-block: 7px;
    border-bottom: 1px solid ${({ theme }) => theme.color.text.dim + '66'};
    padding-inline: 4px;

    &:last-of-type {
      border-bottom: none;
    }

    & .wallet-address {
      padding: 6px 9px;
      display: flex;
      align-items: center;
      gap: 4px;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.color.text.dim + '1a'};
      width: 100%;
      border-radius: 15px;

      & .icon {
        cursor: pointer;
      }
    }
  }
`;
