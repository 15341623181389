import React, { FC, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

import ActionButton from '../../../components/ActionButton/ActionButton';
import { IPayoutUser } from '../../../types/interface';
import { toast } from 'react-toastify';
import { payout } from '../../../api';
import axios from 'axios';

interface IProps {
  user: IPayoutUser;
  contractId: string;
}

const PayoutUserRow: FC<IProps> = ({ user, contractId }) => {
  const roles = useSelector((state: RootState) => state.roles.roles);
  const guild = useSelector((state: RootState) => state.guilds.currentGuild);
  const [loading, setLoading] = useState(false);

  const handlePayout = async () => {
    try {
      setLoading(true);
      const role = roles.find(r => r.id === user.role_id)!;

      await payout({
        contractId,
        guildId: guild!.id,
        roleId: role?.id,
        accountId: user.account_id,
      });

      toast.success('Payout successfull');
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data?.message;
        toast.error(errorMessage);
      }
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment key={user.id}>
      <tr style={{ height: '5px' }} />
      <tr>
        <td className='font-size-12 text-primary text-semibold'>
          {user.username}
        </td>
        <td className='font-size-12 text-primary text-semibold'>
          {user.email}
        </td>
        <td className='font-size-12 text-primary text-semibold capitalize'>
          {roles.find(role => role.id === user.role_id)?.name}
        </td>
        <td className='font-size-12 text-primary text-semibold'>
          {user.payout_wallet_address}
        </td>
        <td className='font-size-12 text-primary text-semibold'>
          {user.starz_wallet_address}
        </td>
        {/* <td className='font-size-12 text-primary text-semibold'>
    35 / 65
  </td>
  <td className='font-size-12 text-primary text-semibold'>
    0.9 RIOT
  </td> */}
        <td className='font-size-12 text-primary text-semibold'>
          <ActionButton
            loading={loading}
            disabled={loading}
            variant='primary'
            className='btn text-bold'
            onClick={handlePayout}
          >
            Payout
          </ActionButton>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default PayoutUserRow;
