import React, { FC } from 'react';

import styled from 'styled-components';
import { IGame } from '../../types/api';

interface IProps {
  games: IGame[];
}

const UserMiniGameList: FC<IProps> = ({ games }) => {
  const allGames = [...games];
  let renderGames: IGame[] = [];
  let restGames: IGame[] = [];
  if (allGames.length > 3) {
    renderGames = allGames.slice(0, 3);
    restGames = allGames.slice(3);
  } else {
    renderGames = allGames;
  }

  return (
    <div className='row' style={{ gap: '6px' }}>
      {renderGames.map(game => (
        <Game key={game.id}>
          <img src={game.mini_icon} alt='' />
        </Game>
      ))}
      {restGames.length > 0 && (
        <p className='font-size-8 text-primary'>+{restGames.length} more</p>
      )}
    </div>
  );
};

export default React.memo(UserMiniGameList);

const Game = styled.div`
  height: 27px;
  width: 27px;
  border-radius: 4px;
  padding: 4px;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  display: grid;
  place-items: center;
  & img {
    height: 100%;
    width: 100%;
  }
`;
