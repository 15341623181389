import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { IFeature } from '../types/interface';

interface IModuleFeatures {
  [x: string]: IFeature[];
}

const usePermissions = () => {
  const [moduleFeatures, setModuleFeatures] = useState<IModuleFeatures | null>(
    null
  );
  const { features, rolePermissions, roles, modules, permissions } =
    useSelector((state: RootState) => state.permissions);

  const handleSortData = () => {
    const allModules: any = {};
    modules.forEach(module => {
      const moduleFeature: any = [];
      features.forEach(feature => {
        if (module.id === feature.module_id) {
          moduleFeature.push(feature);
        }
      });
      allModules[module.slug] = moduleFeature;
    });

    setModuleFeatures(allModules);
  };

  useEffect(() => {
    handleSortData();
    // eslint-disable-next-line
  }, [features, rolePermissions, roles, modules, permissions]);
  return {
    roles,
    moduleFeatures,
    permissions,
    rolePermissions,
  };
};

export default usePermissions;
