import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface IProps {
  active: boolean;
  [x: string]: any;
}

const User: FC<IProps> = ({ active, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M10.594 11.918C11.7726 11.918 12.9247 11.5685 13.9046 10.9137C14.8846 10.2589 15.6484 9.32828 16.0994 8.23941C16.5504 7.15055 16.6684 5.95239 16.4385 4.79646C16.2086 3.64053 15.641 2.57873 14.8076 1.74535C13.9743 0.911972 12.9125 0.344433 11.7565 0.114503C10.6006 -0.115426 9.40245 0.00258195 8.31359 0.453605C7.22472 0.904627 6.29406 1.66841 5.63927 2.64836C4.98449 3.62831 4.635 4.78042 4.635 5.959C4.63553 7.53927 5.26352 9.05465 6.38094 10.1721C7.49835 11.2895 9.01374 11.9175 10.594 11.918ZM15.894 13.242H13.61C12.6642 13.6784 11.6351 13.9044 10.5935 13.9044C9.55193 13.9044 8.52275 13.6784 7.577 13.242H5.3C3.89435 13.242 2.54628 13.8004 1.55233 14.7943C0.558391 15.7883 0 17.1364 0 18.542L0 19.2C0.000264975 19.7266 0.209589 20.2316 0.581979 20.604C0.954368 20.9764 1.45936 21.1857 1.986 21.186H19.2C19.4609 21.1861 19.7193 21.1349 19.9605 21.0351C20.2016 20.9354 20.4207 20.7891 20.6053 20.6047C20.7899 20.4203 20.9364 20.2013 21.0364 19.9603C21.1363 19.7193 21.1879 19.4609 21.188 19.2V18.538C21.1869 17.1336 20.6285 15.787 19.6353 14.794C18.6421 13.801 17.2954 13.2428 15.891 13.242H15.894Z'
        fill={
          active ? (activeTheme === 'light' ? '#ffffff' : '#C3CBD2') : '#23272A'
        }
      />
    </svg>
  );
};

export default User;
