import React, { FC, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import dayjs from 'dayjs';

import { useNavigate } from 'react-router-dom';

import { IGame, IGuildUser } from '../../../types/api';
import { getUserGames } from '../../../api';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../../hooks/useTheme';
import UserMiniGameList from '../../../components/UserMiniGameList/UserMiniGameList';

interface IProps {
  user: IGuildUser;
  search?: string;
}

const UserRow: FC<IProps> = ({ user, search = '' }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const roles = useSelector((state: RootState) => state.roles.roles);
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState<IGame[]>([]);
  const getRole = (id: string) => {
    return roles.find(role => role.id === id)?.name;
  };

  const handleRowClick = () => {
    navigate(`/dashboard/player/profile/${user.id}`);
  };

  const handleGetUserGames = async () => {
    try {
      setLoading(true);
      const { data } = await getUserGames(user.id);
      setGames(data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetUserGames();
    // eslint-disable-next-line
  }, []);

  if (
    user.username?.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
    user.email.toLowerCase().indexOf(search.toLowerCase()) > -1
  ) {
    return (
      <React.Fragment key={user.id}>
        <tr style={{ height: '5px' }} />
        <tr className='clickable' onClick={handleRowClick}>
          <td className='font-size-12 text-primary text-semibold'>
            {user.username}
          </td>
          <td className='font-size-12 text-primary text-semibold'>
            {user.email}
          </td>
          <td className='font-size-12 text-primary text-semibold capitalize'>
            {getRole(user.role_id)}
          </td>
          <td className='font-size-12 text-primary text-semibold'>
            <div className='row' style={{ gap: '8px' }}>
              {loading ? (
                <ClipLoader size={15} color={theme.color.text.primary} />
              ) : (
                <UserMiniGameList games={games} />
              )}
            </div>
          </td>
          <td className='font-size-12 text-primary text-semibold'>
            {user.total_assets}
          </td>
          <td className='font-size-12 text-primary text-semibold'>70 / 30</td>
          <td className='font-size-12 text-primary text-semibold'>
            {dayjs(user.next_payout).format('DD / MM / YYYY')}
          </td>
        </tr>
      </React.Fragment>
    );
  } else {
    return <></>;
  }
};

export default UserRow;
