import { createModel } from '@rematch/core';
import axios from 'axios';
import { RootModel } from '.';
import {
  addNewUser,
  addUserSocialMediaLink,
  allGuildManagers,
  allUsers,
  dashboardUser,
  getRevenueUsers,
  getUserSocialLinks,
  updateUserSocialLinks,
} from '../../api';
import {
  IGetRevenueUser,
  IGuildUser,
  IRevenueGameMaster,
  IRevenueGuildManger,
  IRevenueUser,
} from '../../types/api';
import { IGameCaptain, IUser } from '../../types/interface';
import {
  IAddUserPayload,
  IAddUserSocialLinkPayload,
  IAddUserSocialData,
} from '../../types/storeModels';
import { toast } from 'react-toastify';

interface IAllUserFilter {
  revenueSplit?: string;
  roleId?: string;
}

interface IState {
  loading: boolean;
  users: IUser[];
  guildManagers: IUser[];
  dashboardUsers: IGuildUser[];
  revenueUsers: IRevenueUser[];
  selectedRevenueUser: IRevenueUser | null;
  selectedRevenueGuildManager: IRevenueGuildManger | null;
  selectedRevenueGameMaster: IRevenueGameMaster | null;
  selectedCaptain: IGameCaptain | null;
  currentUserSocialLinks: IAddUserSocialData[];
}

export const users = createModel<RootModel>()({
  name: 'users',
  state: {
    loading: false,
    users: [],
    guildManagers: [],
    dashboardUsers: [],
    revenueUsers: [],
    selectedRevenueUser: null,
    selectedRevenueGuildManager: null,
    selectedRevenueGameMaster: null,
    selectedCaptain: null,
    currentUserSocialLinks: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setUsers(state, payload: IUser[]) {
      state.users = payload;
    },
    setGuildMangers(state, payload: IUser[]) {
      state.guildManagers = payload;
    },
    setDashboardUsers(state, payload: IGuildUser[]) {
      state.dashboardUsers = payload;
    },
    setRevenueUsers(state, payload: IRevenueUser[]) {
      state.revenueUsers = payload;
    },
    setSelectedRevenueUser(state, payload: IRevenueUser | null) {
      state.selectedRevenueUser = payload;
    },
    setSelectedRevenueGuildManager(state, payload: IRevenueGuildManger | null) {
      state.selectedRevenueGuildManager = payload;
    },
    setSelectedRevenueGameMaster(state, payload: IRevenueGameMaster | null) {
      state.selectedRevenueGameMaster = payload;
    },
    setSelectedCaptain(state, payload: IGameCaptain | null) {
      state.selectedCaptain = payload;
    },
    setCurrentUserSocialLinks(state, payload: IAddUserSocialData[]) {
      state.currentUserSocialLinks = payload;
    },
  },
  effects: dispatch => ({
    async getAllUsers(payload?: IAllUserFilter) {
      try {
        dispatch.users.setLoading(true);
        const { data } = await allUsers(payload?.revenueSplit, payload?.roleId);
        dispatch.users.setUsers(data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          console.log(errorMessage);
        }
      } finally {
        dispatch.users.setLoading(false);
      }
    },
    async getGuildMangers() {
      try {
        dispatch.users.setLoading(true);
        const { data } = await allGuildManagers();
        dispatch.users.setGuildMangers(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.users.setLoading(false);
      }
    },
    async handleAddUser(payload: IAddUserPayload) {
      const { data, setOpen } = payload;
      try {
        dispatch.users.setLoading(true);
        await addNewUser(data);
        dispatch.users.getAllUsers();
        setOpen(false);
        toast.success('User added successfully.');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.users.setLoading(false);
      }
    },
    async getDashboardUsers(payload?: string) {
      try {
        dispatch.users.setLoading(true);
        const { data } = await dashboardUser(payload || '');
        dispatch.users.setDashboardUsers(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.users.setLoading(false);
      }
    },
    async getRevenueUsers(payload: IGetRevenueUser) {
      try {
        dispatch.users.setLoading(true);
        const { data } = await getRevenueUsers(payload);
        dispatch.users.setSelectedRevenueGameMaster(data.gameMaster);
        dispatch.users.setSelectedRevenueGuildManager(data.guildManager);
        dispatch.users.setRevenueUsers(data.users);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.users.setLoading(false);
      }
    },
    async handleAddUserSocialLink(payload: IAddUserSocialLinkPayload) {
      try {
        dispatch.users.setLoading(true);
        await Promise.all(
          payload.data.map(social => {
            if (social.id) {
              return updateUserSocialLinks(social.id, {
                social_id: social.social_id,
                url: social.url,
              });
            } else {
              return addUserSocialMediaLink({
                social_id: social.social_id,
                url: social.url,
              });
            }
          })
        );
        payload.setOpen(false);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.users.setLoading(false);
      }
    },
    async handleGetUserSocialLinks(val = 'null', state) {
      try {
        const id = state?.auth?.user?.id;
        if (id) {
          dispatch.users.setLoading(true);
          const { data } = await getUserSocialLinks(id);
          dispatch.users.setCurrentUserSocialLinks(data);
        }
      } catch (err: any) {
      } finally {
        dispatch.users.setLoading(false);
      }
    },
  }),
});
