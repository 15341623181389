import axios from 'axios';
import {
  IAddBadge,
  IAddCaptain,
  IAddFTContract,
  IAddGame,
  IAddGameMaster,
  IAddGuild,
  IAddNFTContract,
  IAddScholarship,
  IAddSocialMedia,
  IAddUser,
  IAddUserSocialLink,
  IApplyScholarship,
  IBadge,
  ICategory,
  IChains,
  ICreateTournament,
  IGame,
  IGetNft,
  IGetNFTMetaData,
  IGetRevenueUser,
  IGuild,
  IGuildStats,
  IGuildUser,
  IMassPayoutData,
  IMintBadge,
  INftResponse,
  INotificationResponse,
  IPayoutData,
  IPerformancePayload,
  IPermissionConfig,
  IPullbackNfts,
  IResetPassword,
  IRevenueGuild,
  IRevenueUserResponse,
  IRole,
  ISignUpData,
  ITransferMassNfts,
  ITransferNft,
  ITransferScholars,
  IUpdataGuildDefaultRevenue,
  IUpdateGuildRevenue,
  IUpdatePermission,
  IUpdateRevenueSplit,
  IWalletAccounts,
} from '../types/api';
import {
  IAcceptedScholarshipUsers,
  ICheckGasFee,
  IFTContract,
  IGameBadge,
  IGameCaptain,
  IGameMaster,
  IGuildSocialLink,
  INFTContract,
  IPayoutUser,
  IScholarship,
  IScholarshipUser,
  ISingleGame,
  ISingleGuild,
  ISingleUser,
  ITournament,
  IUser,
  IUserNfts,
  IUserOrGuildStats,
  IUserPermissions,
} from '../types/interface';

import { baseURL } from '../app.config';

const api = axios.create({
  baseURL: baseURL,
  headers: {
    'content-type': 'application/json',
  },
  withCredentials: true,
});

const apiPrivate = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    'content-type': 'application/json',
  },
});

let isRefreshing = false;
const requestToRefresh: any[] = [];

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      // @ts-ignore
      config.headers!['Authorization'] = 'Bearer ' + token;
    }
    // getRefreshToken();
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== '/login'
    ) {
      const prevRequest = error?.config;
      try {
        const token = localStorage.getItem('token');

        if (!token) {
          return Promise.reject(error);
        }

        if (!isRefreshing) {
          isRefreshing = true;
          apiPrivate
            .get('/v1/auth/refresh', {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            })
            .then(({ data }) => {
              localStorage.setItem('token', data.accessToken);
              requestToRefresh.forEach((cb: any) => cb(data.accessToken));
            })
            .catch((err: any) => {
              console.log(err.message);
              localStorage.clear();
              window.location.pathname = '/';
              console.error(error.message);
              requestToRefresh.forEach((cb: any) => cb(null));
            })
            .finally(() => {
              requestToRefresh.length = 0;
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          requestToRefresh.push((token: string) => {
            if (token) {
              prevRequest.headers['Authorization'] = 'Bearer ' + token;
              resolve(axios(prevRequest));
            }
            reject(error);
          });
        });
      } catch (error: any) {
        localStorage.clear();
        api.get('/v1/auth/logout');
        window.location.pathname = '/';
        console.error(error.message);
      }
    }
    return Promise.reject(error);
  }
);

/* ====================  Auth apis ==================== */

// Register user

export const signup = (data: ISignUpData) => api.post('/v1/auth/signup', data);

// Login

export const login = (data: { email: string; password: string }) =>
  api.post('/v1/auth/login', data);

// email verification

export const verifyEmail = (data: { code: string }, token: string) =>
  api.post(`/v1/auth/email-verification/${token}`, data);

// forgot password
export const forgotPassword = (data: { email: string }) =>
  api.post('/v1/auth/forgot-password', data);

// Reset password

export const resetPassword = (data: IResetPassword, token: string) =>
  api.post(`/v1/auth/recover-password/${token}`, data);

// get current user

export const getCurrentuser = () => api.get<IUser>('/v1/user/current');

// logout

export const logout = () => api.get('/v1/auth/logout');

/* ====================  wallet apis ==================== */

// connect wallet

export const connectWallet = (public_address: string) =>
  api.post('/v1/wallet/generate', { public_address });

// get wallet accounts

export const walletAccounts = () => api.get<IWalletAccounts[]>('/v1/wallet');

/* ====================  users apis ==================== */

// get current user

export const currentUser = () => api.get('/v1/user/current');

//  update user

export const updateUser = (userId: string, data: any) =>
  api.patch(`/v1/user/${userId}`, data);

// update user image

export const updateUserImage = (userId: string, data: any) =>
  api.patch(`/v1/user/image/${userId}`, data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

// get all users

export const allUsers = (revenueSplit?: string, roleId?: string) => {
  let query = '?';
  if (revenueSplit) {
    query = `${query}revenueSplit=${revenueSplit}&`;
  }
  if (roleId) {
    query = `${query}roleId=${roleId}`;
  }
  return api.get<IUser[]>(`/v1/user${query}`);
};

// get all guild Managers

export const allGuildManagers = () =>
  api.get<IUser[]>('/v1/user?isAvailableGuildManagers=true');

// get user by Id

export const getUserById = (user: string) =>
  api.get<ISingleUser>(`/v1/user/${user}`);

// add new user

export const addNewUser = (data: IAddUser) => api.post('/v1/user', data);

// get dashboard users

export const dashboardUser = (query?: string) =>
  api.get<IGuildUser[]>(`/v1/guild/user${query ? `?${query}` : ''}`);

// get team scholars based on gameid and captainId

export const scholarsByGameAndCaptain = (captainId: string, gameId: string) =>
  api.get(`/v1/team/scholars/${captainId}/${gameId}`);

// add user social media link

export const addUserSocialMediaLink = (data: IAddUserSocialLink) =>
  api.post('/v1/user/social-media', data);

export const updateUserSocialLinks = (
  userId: string,
  data: IAddUserSocialLink
) => api.patch(`/v1/user/social-media/${userId}`, data);

export const getUserSocialLinks = (id: string) =>
  api.get(`/v1/user/${id}/social-media`);

export const deleteUserSocialLink = (id: string) =>
  api.delete(`/v1/user/social-media/${id}`);

/* ====================  roles apis ==================== */

// get all roles

export const allRoles = () => api.get<IRole[]>('/v1/role');

/* ====================  badges apis ==================== */

// get all badges

export const allBadges = () => api.get<IBadge[]>('/v1/badge');

// add badge

export const addBadge = (data: IAddBadge) => api.post('/v1/badge', data);

// update badge image

export const updateBadgeImage = (data: any, id: string) =>
  api.patch(`/v1/badge/image/${id}`, data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

// add games in badge

export const addBadgeToMultipleGame = (
  data: Array<{ game_id: string; badge_id: string }>
) => api.post('/v1/game/badge/multiple', data);

// update badge

export const updateBadge = (data: IAddBadge, badge_id: string) =>
  api.patch(`/v1/badge/${badge_id}`, data);

// mint badge

export const mintBagde = (data: IMintBadge) => api.post('/v1/badge/mint', data);

// get user minted badges

export const mintedBadges = (userId: string) =>
  api.get<IBadge[]>(`/v1/badge/user/${userId}`);

// assign badge to a user

export const assignBadge = (badge_id: string, user_id: string) =>
  api.post('/v1/badge/assign', { badge_id, user_id });

/* ====================  games apis ==================== */

// add game

export const addGame = (data: IAddGame) => api.post('/v1/game', data);

// update game

export const updateGame = (data: any, gameId: string) =>
  api.patch(`/v1/game/${gameId}`, data);

// Update game images

export const updateGameImages = (data: any, gameId: string) =>
  api.patch(`/v1/game/images/${gameId}`, data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

// assocaite badge with games

export const addGameToBadge = (data: { game_id: string; badge_id: string }) =>
  api.post('/v1/game/badge', data);

// get all games

export const allGames = (guildId?: string, isNotGuildGames?: boolean) =>
  api.get<IGame[]>(
    `/v1/game${guildId ? `?guildId=${guildId}` : ''}${
      isNotGuildGames ? '&isNotGuildGames=true' : ''
    }`
  );

// add game screenshots

export const addGameScreenshots = (data: any, gameId: string) =>
  api.post(`/v1/game/screenshoots/${gameId}`, data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

// get game screenshots

export const getGameScreenshots = (gameId: string) =>
  api.get(`/v1/game/screenshoot/${gameId}`);

// add delete screenshot

export const deleteGameScreenshot = (screenshotId: string) =>
  api.delete(`/v1/game/screenshoot/${screenshotId}`);

// get game badges

export const gameBadges = (gameId: string) =>
  api.get<IGameBadge[]>(`/v1/game/badge/${gameId}`);

// remove badge from game

export const removeBadgeFromGame = (gameId: string, badgeId: string) =>
  api.delete(`/v1/game/badge/${badgeId}/${gameId}`);

// get game details

export const gameDetails = (gameID: string) =>
  api.get<ISingleGame>(`/v1/game/single/${gameID}`);

// add game category

export const addGameCategory = (data: { gameId: string; categoryId: string }) =>
  api.post('/v1/game/category', data);

// remove category from  game

export const removeGameCategory = (categoryId: string) =>
  api.delete(`/v1/game/category/${categoryId}`);

// get user games

export const getUserGames = (userId: string) =>
  api.get<IGame[]>(`/v1/user/games/${userId}`);

/* ====================  game category apis ==================== */

// get all categories

export const allCategories = () => api.get<ICategory[]>('/v1/category/');

// add category

export const addCategory = (data: { name: string }) =>
  api.post('/v1/category/', data);

/* ====================  config apis ==================== */

// all supported chains

export const allChains = () => api.get<IChains>('/v1/config');

// all social media accounts

export const socialLinks = () => api.get('/v1/social-media');

/* ====================  game NFT contracts apis ==================== */

// add game nft contract

export const addNFTContract = (data: IAddNFTContract, gameId: string) =>
  api.post(`/v1/game/nft/${gameId}`, data);

// get game nft contracts

export const getGameNftContracts = (gameId: string) =>
  api.get<INFTContract[]>(`/v1/game/nft/${gameId}`);

// update game nft contract

export const updateGameNftContract = (
  data: IAddNFTContract,
  gameId: string,
  contractId: string
) => api.patch(`/v1/game/nft/${gameId}/${contractId}`, data);

// delete game nft contract

export const deleteGameNftContract = (gameId: string, contractId: string) =>
  api.delete(`/v1/game/nft/${gameId}/${contractId}`);

//  get game nft contract

export const getGameNftContractsByGuildId = (guildId: string) =>
  api.get(`/v1/game/nft/contract/name/${guildId}`);

/* ====================  game FT contracts apis ==================== */

// add game FT contract

export const addFtContract = (data: IAddFTContract, gameId: string) =>
  api.post(`/v1/game/erc/${gameId}`, data);

// get game ft contracts

export const getGameFtContracts = (gameId: string) =>
  api.get<IFTContract[]>(`/v1/game/erc/${gameId}`);

// update game ft contract

export const updateGameFtContract = (
  data: IAddFTContract,
  gameId: string,
  contractId: string
) => api.patch(`/v1/game/erc/${gameId}/${contractId}`, data);

// delete game ft contract

export const deleteGameFtContract = (gameId: string, contractId: string) =>
  api.delete(`/v1/game/erc/${gameId}/${contractId}`);

/* ====================  Guild apis ==================== */

// add new guild

export const addGuild = (data: IAddGuild) => api.post('/v1/guild', data);

// update guild

export const updateGuild = (
  data: { name: string; user_id: string; is_active: boolean },
  guildId: string
) => api.patch(`/v1/guild/${guildId}`, data);

// add guild game

export const addGamesInGuild = (data: { guild_id: string; game_id: string }) =>
  api.post('/v1/guild/add/game', data);

// add guild image

export const updateGuildImage = (data: any, guildId: string) =>
  api.patch(`/v1/guild/image/${guildId}`, data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

// get all guilds

export const allGuilds = (query?: string) =>
  api.get<IGuild[]>(`/v1/guild${query ? query : ''}`);

// get guild by id

export const guildById = (guild_is: string) =>
  api.get<ISingleGuild>(`/v1/guild/${guild_is}`);

// get guilds by game id

export const guildsByGameId = (query: string) =>
  api.get<IRevenueGuild[]>(`/v1/guild/game${query}`);

// get guild users

export const guildUsers = (guildId: string, query?: string) =>
  api.get(`/v1/guild/user?guildId=${guildId}&${query ? query : ''}`);

// get guild by user

export const userGuild = (userId: string, role_id: string) =>
  api.get<ISingleGuild>(`/v1/guild/user/${userId}/${role_id}`);

// add socila media link

export const addSocialMediaLink = (data: IAddSocialMedia) =>
  api.post(`/v1/guild/social-media`, data);

// get all social links

export const getGuildSocialLinks = (guildId: string) =>
  api.get<IGuildSocialLink[]>(`/v1/guild/${guildId}/social-media`);

// update social media links

export const updateGuildSocialLinks = (
  data: {
    url: string;
    social_id: string;
  },
  socialLinkId: string
) => api.patch(`/v1/guild/social-media/${socialLinkId}`, data);

export const deleteSocialMediaLink = (linkId: string) =>
  api.delete(`/v1/guild/social-media/${linkId}`);

// all guild games

export const getGuildGames = (guildId: string) =>
  api.get(`/v1/guild/${guildId}/games`);

// guild stats6

export const getGuildStats = (query?: string) =>
  api.get<IGuildStats>(`/v1/guild/stats${query ? `?guildId=${query}` : ''}`);

/* ====================  guild game master apis ==================== */

//add game master in guild

export const addGameMaster = (data: IAddGameMaster) =>
  api.post('/v1/master', data);

// export guild game masters

export const guildGameMasters = (guildId: string, gameId?: string) =>
  api.get<IGameMaster[]>(
    `/v1/master?guildId=${guildId}${gameId ? `&gameId=${gameId}` : ''} `
  );

// check if user is already a gameMaster

export const checkIfAlreadyGameMaster = (guildId: string, gameId: string) =>
  api.get<{ exists: false }>(`/v1/master/${gameId}/${guildId}`);

/* ====================  guild game captain apis ==================== */

// add game captain in guild

export const addGameCaptain = (data: IAddCaptain) =>
  api.post('/v1/captain', data);

// get guild game captains

export const getGuildGameCaptains = (guildId?: string, gameId?: string) => {
  let query = '';
  if (guildId) {
    query = `?guildId=${guildId}`;
  }
  if (gameId) {
    if (query.length) {
      query = `${query}&gameId=${gameId}`;
    } else {
      query = `?gameId=${gameId}`;
    }
  }
  return api.get<IGameCaptain[]>(`/v1/captain${query}`);
};

// check if already game Captain

export const checkIfAlreayGameCaptain = (userId: string, gameId: string) =>
  api.get(`/captain/${userId}/${gameId}`);

// return all games in which user is captain

export const captainGames = (guildId: string, captainId: string) =>
  api.get<IGame[]>(`/v1/captain/games/${guildId}/${captainId}`);

/* ==================== scholarship apis ==================== */

// add new scholarship

export const addScholarship = (data: IAddScholarship) =>
  api.post('/v1/scholarship', data);

// update scholarship image

export const addScholarshipImage = (data: any, scholarshipId: string) =>
  api.patch(`/v1/scholarship/image/${scholarshipId}`, data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

// get all scholarships in a guild

export const getGuildScholarships = (guildId?: string) =>
  api.get<IScholarship[]>(
    `/v1/scholarship/guild${guildId ? `?id=${guildId}` : ''}`
  );

// get scholarship users

export const getScholarshipUsers = (scholarshipId: string) =>
  api.get<IScholarshipUser[]>(`/v1/scholarship/users/${scholarshipId}`);

// apply scholarship

export const applyScholarship = (data: IApplyScholarship) =>
  api.post('/v1/scholarship/apply', data);

// update scholarship status (accept or reject)

export const updateScholarshipStatus = (
  data: { status: 1 | 2 },
  scholarshipId: string
) => api.patch(`/v1/scholarship/status/${scholarshipId}`, data);

/* ==================== assets apis ==================== */

// get all nfts based on contract_id and user_id

export const getAllNFTs = (data: IGetNft) =>
  api.post<INftResponse>('/v1/assets', data);

// get nft metadata if not available

export const getNftMetadata = (data: IGetNFTMetaData) =>
  api.post('/v1/assets/metadata', data);

// scholar with accepted scholarship by guild id and game id

export const scholarsWithAcceptedScholarship = (
  guildId: string,
  gameId?: string
) =>
  api.get<IAcceptedScholarshipUsers[]>(
    `/v1/scholarship/accepted/users?guildId=${guildId}${
      gameId ? `&gameId=${gameId}` : ''
    }`
  );

// transfer nft

export const transferNft = (data: ITransferNft) =>
  api.post('/v1/transfer/nft', data);

// transfer multiple nfts

export const massTransferNfts = (data: ITransferMassNfts) =>
  api.post('/v1/transfer/nft/mass', data);

export const getUserNfts = (data: {
  public_address: string;
  userId: string;
  game_id: string;
  chain_id?: string;
}) =>
  api.get<IUserNfts[]>(
    `/v1/user/games/assets/${data.public_address}/${data.userId}/${
      data.game_id
    }${data.chain_id ? `?chainId=${data.chain_id}` : ''}`
  );

export const pullbackNfts = (data: IPullbackNfts) =>
  api.post('/v1/transfer/nft/back', data);

export const allUserNfts = (data: {
  public_address: string;
  user_id: string;
}) =>
  api.get<IUserNfts[]>(
    `/v1/user/assets/${data.public_address}/${data.user_id}`
  );

export const checkGasFee = (data: ICheckGasFee) =>
  api.post('/v1/transfer/check/gasfee', data);

// mass pullback users

export const getMassPullBackUsers = (data: {
  guildId: string;
  gameId: string;
  chain: string;
}) =>
  api.get(
    `/v1/user/nft/${data.guildId}?gameId=${data.gameId}&chain=${data.chain}`
  );

// pullback mass nfts

export const massPullback = (data: any) =>
  api.post('/v1/transfer/nft/mass/back', data);

/* ==================== tournaments apis ==================== */

// create tournament

export const createTournament = (data: ICreateTournament) =>
  api.post('/v1/tournament', data);

// get all tournaments

export const allTournaments = (query?: string) =>
  api.get<ITournament[]>(`/v1/tournament${query ? `?${query}` : ''}`);

/* ==================== tournaments apis ==================== */

// get scholar by team

export const getScholarTeamByGameId = (scholarId: string, gameId: string) =>
  api.get(`/v1/team/scholar/${scholarId}/${gameId}`);

// update tournament images

export const updateTournamentImages = (tournamentId: string, data: any) =>
  api.patch(`/v1/tournament/images/${tournamentId}`, data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

/* ==================== payout apis ==================== */

// payout users

export const getPayoutUsers = (query: string) =>
  api.get<IPayoutUser[]>(`/v1/user/payout/?${query}`);

// payout

export const payout = (data: IPayoutData) =>
  api.post('/v1/transfer/payout', data);

// mass payout

export const massPayout = (data: IMassPayoutData) =>
  api.post('/v1/transfer/masspayout', data);

/* ==================== notification apis ==================== */

// all notifications

export const allNotifications = (user_id: string) =>
  api.get<INotificationResponse>(`/v1/notification?user_id=${user_id}`);

// mark all read

export const markAllNotificationAsRead = () => api.patch('/v1/notification');

/* ==================== Guild revenue apis ==================== */

// get default revenue split

export const getDefaultRevenueSplit = (data: {
  gameId: string;
  guildId: string;
}) => api.get(`/v1/default/revenue/percentage/${data.gameId}/${data.guildId}`);

export const updateDefaultGuildRevenue = (data: IUpdataGuildDefaultRevenue) =>
  api.patch('/v1/default/revenue/percentage', data);

// update admin guild revenue split

export const updateAdminGuildSplit = (data: IUpdateGuildRevenue) =>
  api.patch(`/v1/guild/revenue/split`, data);

/* ==================== User revenue apis ==================== */

export const getRevenueUsers = (data: IGetRevenueUser) =>
  api.get<IRevenueUserResponse>(
    `/v1/user/revenue/split/${data.guildId}/${data.gameId}`
  );

export const updateUserRevenueSplit = (data: IUpdateRevenueSplit) =>
  api.patch(`/v1/user/${data.userId}/${data.gameId}/percentage`, {
    role: data.role,
    percentage: data.percentage,
  });

/* ==================== Permissions apis ==================== */

// get permission config

export const getPermissionConfig = () =>
  api.get<IPermissionConfig>('/v1/permission');

// update permissions

export const updatePermission = (data: IUpdatePermission) =>
  api.patch('/v1/permission', data);

// user permissions

export const getUserPermissions = () =>
  api.get<IUserPermissions[]>('/v1/user/permissions');

/* ==================== Teams apis ==================== */

// transfer scholars between captains

export const transferScholars = (data: ITransferScholars) =>
  api.patch('/v1/team/transfer', data);

/* ==================== Performance apis ==================== */

// graph performance api

export const performance = (data: IPerformancePayload) => {
  let query = `gameId=${data.gameId}`;
  if (data.userId) {
    query = `${query}&userId=${data.userId}`;
  }
  if (data.guildId) {
    query = `${query}&guildId=${data.guildId}`;
  }
  if (data.year) {
    query = `${query}&year=${data.year}`;
  }

  return api.get(`/v1/performance?${query}`);
};

// stats for guild and scholar

export const userOrGuildStats = (userId: string) =>
  api.get<IUserOrGuildStats>(`/v1/user/stats/${userId}`);

/* ==================== axie game apis ==================== */

// send ronin wallet credentials

export const sendAxieCredentials = (userId: string) =>
  api.post(`/v1/user/axie/account/${userId}`);

// disconnect ronin wallet

export const disconnectRoninWallet = (data: { userId: string }) =>
  api.patch(`/v1/user/${data.userId}/disconnect`);
