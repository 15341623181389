import React, { FC, useState } from 'react';

import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { InferType, object, string } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';

import InputField from '../../components/InputField/InputField';
import Button from '../../components/Button/Button';
import Checkbox from '../../components/Checkbox/Checkbox';

import { lightTheme as theme } from '../../theme';
import assets from '../../assets';
import { FormErrors, validateData } from '../../utils/validations';

const schema = object({
  email: string().email().required().label('Email'),
  password: string().required().label('Password'),
});

interface IFormData extends InferType<typeof schema> {}

const Login: FC = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<Dispatch>();

  const loading = useSelector((state: RootState) => state.auth.loading);

  const [formData, setFormData] = useState<IFormData>({
    email: '',
    password: '',
  });

  const [acceptTerms, setAcceptTerms] = useState(false);

  const [errors, setErrors] = useState<FormErrors<IFormData | null>>(null);

  const handleTerms = () => {
    setAcceptTerms(!acceptTerms);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: null }));
  };

  const handleContinue = async () => {
    const { errors: validationErrors } = await validateData(schema, formData);
    if (validationErrors) {
      return setErrors(validationErrors);
    }
    dispatch.auth.handleLogin({ data: formData, navigate, setErrors });
  };

  return (
    <Card>
      <h1>Sign in</h1>
      <p className='font-size-18'>Enter your login details</p>
      <InputContainer>
        <InputField
          label='Username or email'
          type='text'
          placeholder='Enter username or email'
          value={formData.email}
          onChange={handleChange}
          name='email'
          error={errors?.email}
        />
      </InputContainer>
      <InputContainer>
        <InputField
          label='Password'
          type='password'
          placeholder='Enter password'
          value={formData.password}
          onChange={handleChange}
          name='password'
          error={errors?.password}
        />
      </InputContainer>
      <div className='row'>
        <div className='row-inner'>
          <Checkbox
            checked={acceptTerms}
            onClick={handleTerms}
            className='clickable'
            iconClassName='checkbox'
          />
          <p className='font-size-14'>Remember me</p>
        </div>
        <Link to='/forgot-password' className='anchor'>
          <p className='font-size-14 link'>Forgot password?</p>
        </Link>
      </div>
      <ButtonContainer>
        <Button
          variant='primary'
          label='Continue'
          disabled={loading}
          loading={loading}
          onClick={handleContinue}
        />
      </ButtonContainer>

      <p className='font-size-14 text-center'>
        Don't have an account yet?{' '}
        <Link to='/register' className='anchor'>
          Create account
        </Link>
      </p>

      <p className='font-size-14 text-center follow-link'>Follow us on</p>
      <SocialLinks>
        <a href='/' className='item'>
          <img src={assets.discord} alt='' />
        </a>
        <a href='/' className='item'>
          <img src={assets.facebook} className='fb' alt='' />
        </a>
        <a href='/' className='item'>
          <img src={assets.twitter} alt='' />
        </a>
        <a href='/' className='item'>
          <img src={assets.telegram} alt='' />
        </a>
        <a href='/' className='item'>
          <img src={assets.dots} alt='' />
        </a>
      </SocialLinks>
    </Card>
  );
};

export default Login;

const Card = styled.div`
  padding: 25px 20px;
  background: ${({ theme }) => theme.color.gradient.card};
  width: 350px;
  border-radius: 12px;

  & h1 {
    color: ${({ theme }) => theme.color.text.primary};
  }
  & p.font-size-18 {
    color: ${({ theme }) => theme.color.text.primary};
    margin-top: 8px;
  }

  & .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    & p {
      color: ${({ theme }) => theme.color.text.dim};
    }
    & .row-inner {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  & p.text-center {
    color: ${({ theme }) => theme.color.text.dimmer};
    & .anchor {
      color: ${({ theme }) => theme.color.text.dim};
    }
  }

  & p.follow-link {
    color: ${({ theme }) => theme.color.text.primary};
    margin-block: 25px 15px;
  }

  & .checkbox {
    width: 15px;
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    width: 499px;
    padding: 30px;

    margin-right: 180px;

    & .checkbox {
      width: unset;
    }

    & .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 26px;
      & p {
        color: ${({ theme }) => theme.color.text.dim};
      }
      & .row-inner {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      & p.text-center {
        color: ${({ theme }) => theme.color.text.dimmer};
        & .anchor {
          color: ${({ theme }) => theme.color.text.dim};
        }
      }

      & p.follow-link {
        color: ${({ theme }) => theme.color.text.primary};
        margin-block: 44px 15px;
      }
    }
  }
`;

const InputContainer = styled.div`
  margin-top: 19px;
  &:first-of-type {
    margin-top: 30px;
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    margin-top: 29px;
    &:first-of-type {
      margin-top: 50px;
    }
  }
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;

  & .item {
    height: 35px;
    width: 35px;
    display: grid;
    place-items: center;
    background-color: ${({ theme }) => theme.color.background.socialLink};
    border-radius: 4px;
    padding: 8px;
    & img {
      height: 100%;
      width: 100%;

      &.fb {
        width: 10px;
      }
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    gap: 20px;
    & .item {
      height: 47px;
      width: 47px;

      & img {
        &.fb {
          width: auto;
        }
      }
    }
  }
`;

const ButtonContainer = styled.div`
  margin-block: 25px 12px;
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    margin-block: 47px 17px;
  }
`;
