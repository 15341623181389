import React, { FC, useState } from 'react';

import styled from 'styled-components';
import MoreIcon from '../../assets/icons/MoreIcon';
import Select from '../../components/Select/Select';

const SimilarGame: FC = () => {
  const [game, setGame] = useState('');
  return (
    <Container>
      <Select
        containerClassName='select-container'
        label='Select category'
        value={game}
        options={[
          { label: 'strategy', value: 'strategy' },
          { label: 'cards', value: 'cards' },
          { label: 'fight', value: 'fight' },
        ]}
        onChange={e => setGame(e.currentTarget.value)}
      />
      <div className='image'>
        <img src='' alt='' />
      </div>
      <div className='row justify-space-between'>
        <p className='font-size-20 text-primary text-semibold'>Axie Infinity</p>
        <div className='icon clickable'>
          <MoreIcon />
        </div>
      </div>
    </Container>
  );
};

export default SimilarGame;

const Container = styled.div`
  & .select-container {
    width: 100%;
    height: 55px;
    & select {
      border-radius: 8px;
    }
    & .icon {
      border-radius: 8px;
    }
  }

  & .image {
    width: 100%;
    aspect-ratio: 1.58/1;
    background-color: ${({ theme }) => theme.color.background.headerRow};
    border-radius: 8px;
    margin-block: 20px 29px;
  }
  & .row {
    padding-inline: 10px;
  }
`;
