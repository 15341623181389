import { getNftMetadata } from '../api';

// TODO: make is dynamic for erc721 and erc1155 and chain
export const getNftImage = async (nft: any) => {
  try {
    const metadata = JSON.parse(nft.metadata);

    let image: string = metadata?.image_url || metadata?.image || '';
    // let animation = metadata?.animation_url;

    if (image) return image;

    const token_uri = nft?.token_uri;

    const { data } = await getNftMetadata({
      contract_address: nft.token_address,
      token_id: nft.token_id,
      ...(token_uri && { token_uri }),
    });

    image = data?.image_url || data?.image || '';

    return image;
  } catch (err) {
    console.error(err);
    return '';
  }
};
