import React, { FC, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { InferType, object, string } from 'yup';

import InputField from '../../components/InputField/InputField';
import Select from '../../components/Select/Select';
import ActionButton from '../../components/ActionButton/ActionButton';
import DialogLayout from '../../layouts/DialogLayout/DialogLayout';

import CrossIcon from '../../assets/icons/CrossIcon';

import { lightTheme as theme } from '../../theme';
import { FormErrors, validateData } from '../../utils/validations';
import { ethers } from 'ethers';

const schema = object({
  // first_name: string().required().label('First name'),
  // last_name: string().required().label('Last name'),
  username: string().required().label('Username'),
  email: string().email().required().label('Email'),
  password: string().required().label('Password'),
  discord: string().optional().label('Discord'),
  country: string().optional().label('Country'),
  wallet_address: string().required().label('Wallet address'),
  roleId: string().required().label('Role'),
});

interface IFormData extends InferType<typeof schema> {}
interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}

const AddNewUser: FC<IProps> = ({ open, setOpen, onClose }) => {
  const dispatch = useDispatch<Dispatch>();

  const loading = useSelector((state: RootState) => state.users.loading);

  const roles = useSelector((state: RootState) => state.roles.roles);

  const [formData, setFormData] = useState<IFormData>({
    // first_name: '',
    // last_name: '',
    username: '',
    email: '',
    wallet_address: '',
    password: '',
    roleId: '',
    country: '',
    discord: '',
  });

  const [errors, setErrors] = useState<FormErrors<IFormData | null>>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: null }));
  };

  const handleSave = async () => {
    let hasError = false;
    const { errors: validationErrors } = await validateData<IFormData>(
      schema,
      formData
    );
    if (validationErrors) {
      hasError = true;
      setErrors(validationErrors);
    }

    if (!ethers.utils.isAddress(formData.wallet_address)) {
      hasError = true;
      setErrors(prev => ({
        ...prev,
        wallet_address: 'Wallet address must be a valid wallet address',
      }));
    }

    if (!hasError) {
      dispatch.users.handleAddUser({
        data: { ...formData, confirm_password: formData.password },
        setOpen,
      });
    }
  };

  return (
    <DialogLayout open={open} onClose={onClose}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>Add New User</p>
          <div className='icon' onClick={() => setOpen(false)}>
            <CrossIcon />
          </div>
        </div>
        <Grid>
          {/* <InputField
            label='First Name'
            placeholder='Enter first name'
            type='text'
            value={formData.first_name}
            onChange={handleChange}
            name='username'
            error={errors?.first_name}
          />
          <InputField
            label='Last Name'
            placeholder='Enter last name'
            type='text'
            value={formData.last_name}
            onChange={handleChange}
            name='last_name'
            error={errors?.last_name}
          /> */}
          <InputField
            label='Username'
            placeholder='Enter last name'
            type='text'
            value={formData.username}
            onChange={handleChange}
            name='username'
            error={errors?.username}
          />
          <InputField
            label='Email Address'
            placeholder='Enter email address'
            type='email'
            value={formData.email}
            onChange={handleChange}
            name='email'
            error={errors?.email}
          />
          <InputField
            label='Wallet address'
            placeholder='Enter wallet address'
            type='text'
            value={formData.wallet_address}
            onChange={handleChange}
            name='wallet_address'
            error={errors?.wallet_address}
          />
          <InputField
            label='Generate Password'
            placeholder='Enter password'
            type='password'
            value={formData.password}
            onChange={handleChange}
            name='password'
            error={errors?.password}
          />
          <Select
            inputClassName='capitalize'
            title='User role'
            options={roles.map(role => ({ label: role.name, value: role.id }))}
            onChange={handleChange}
            label='Select role'
            value={formData.roleId}
            name='roleId'
            error={errors?.roleId}
          />
          <InputField
            label='Country (Optional)'
            placeholder='Select country'
            type='text'
            value={formData.country}
            onChange={handleChange}
            name='country'
            error={errors?.country}
          />
          <InputField
            label='Discord username (Optional)'
            placeholder='Enter discord username'
            type='text'
            value={formData.discord}
            onChange={handleChange}
            name='discord'
            error={errors?.discord}
          />
        </Grid>

        <div className='divider'></div>
        <ButtonContainer>
          <ActionButton
            onClick={() => setOpen(false)}
            variant='primary'
            className='action-btn btn-primary'
            disabled={loading}
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleSave}
            variant='primary'
            className='action-btn btn-secondary'
            loading={loading}
            disabled={loading}
          >
            Add User
          </ActionButton>
        </ButtonContainer>
      </Container>
    </DialogLayout>
  );
};

export default AddNewUser;

const Container = styled.div`
  width: 600px;
  padding: 20px;
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 15px;

  & .icon {
    cursor: pointer;
  }

  & .divider {
    height: 1px;
    background-color: ${({ theme }) => theme.color.background.headerRow};
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    width: 812px;
    padding: 30px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-block: 30px;

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    margin-block: 40px;
    gap: 30px 20px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 18px;
  margin-top: 20px;

  & .action-btn {
    border-radius: 20px;
    height: 40px;
    width: 150px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .action-btn {
      border-radius: 30px;
      height: 55px;
      width: 189px;
    }
  }
`;
