import React, { FC, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import CrossIcon from '../../assets/icons/CrossIcon';
import SideBar from './components/SideBar/SideBar';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';
import NewScholarship from './components/NewScholarship/NewScholarship';
import ScholarshipsTable from './components/ScholarshipsTable/ScholarshipsTable';
import ActionButton from '../../components/ActionButton/ActionButton';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScholarshipApplications: FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();
  const newScholarship = useSelector(
    (state: RootState) => state.scholarships.issueNewScholarship
  );

  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  const selectedScholarshipUser = useSelector(
    (state: RootState) => state.scholarships.selectedScholarshipUser
  );

  const handleClose = () => {
    setOpen(false);
    dispatch.scholarships.setIssueScholarship(false);
    dispatch.scholarships.setScholarshipUsers([]);
  };

  const handleCloseClick = () => {
    dispatch.scholarships.setSelectedScholarshipUser(null);
  };

  useEffect(() => {
    if (currentGuild) {
      dispatch.scholarships.handleGetGuildScholarships(currentGuild?.id);
    }
    // eslint-disable-next-line
  }, [currentGuild]);

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='row justify-space-between title-row'>
          <p className='font-size-25 text-primary text-bold'>
            Scholarship applications
          </p>
          <div className='icon clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <Content>
          <div className='left'>
            <SideBar />
          </div>
          <div className='right'>
            {newScholarship ? <NewScholarship /> : <ScholarshipsTable />}
          </div>
        </Content>

        <div className='bottom row'>
          {selectedScholarshipUser && (
            <ActionButton onClick={handleCloseClick} variant='primary'>
              Close
            </ActionButton>
          )}
        </div>
      </Container>
    </DialogLayout>
  );
};

export default ScholarshipApplications;

const Container = styled.div`
  width: 95vw;
  min-height: 80vh;
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  height: 85%;
  max-height: 901px;
  display: flex;
  flex-direction: column;

  & .title-row {
    padding: 30px;
  }

  & .bottom {
    height: 85px;
    justify-content: flex-end;
    padding-inline: 35px;

    & button {
      width: 146px;
      background-color: ${({ theme }) =>
        theme.color.background.button.primary.bg};
      color: ${({ theme }) => theme.color.background.button.primary.color};
    }
  }
`;

const Content = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.text.primary};
  border-bottom: 1px solid ${({ theme }) => theme.color.text.primary};
  display: flex;
  align-items: stretch;
  flex-grow: 1;

  & .left {
    width: 450px;
  }

  & .right {
    flex-grow: 1;
    border-left: 1px solid ${({ theme }) => theme.color.text.primary};
  }
`;
