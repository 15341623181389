import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../store';

import ActionButton from '../../../components/ActionButton/ActionButton';
import SearchBar from '../../../components/SearchBar/SearchBar';
import ListIcon from '../../../assets/icons/ListIcon';
import GridIcon from '../../../assets/icons/GridIcon';

import GuildsGridView from './GuildsGridView';
import GuildsTableView from '../../Guilds/Components/GuildsTableView';

import { lightTheme, darkTheme } from '../../../theme';
import useModulePermissions from '../../../hooks/useModulePermissions';
import { features, modulesEnum, permissionEnum } from '../../../types/enums';

const GamesAndGuilds: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const permissions = useModulePermissions(modulesEnum.Performance);
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const theme = activeTheme === 'light' ? lightTheme : darkTheme;
  const [isListView, setIsListView] = useState(true);
  const [filters, setFilters] = useState({
    game: '',
    search: '',
  });
  const [filterOptions, setFilterOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleGetFilterOptions = () => {
    const options: Array<{ label: string; value: string }> = [];
    if (
      permissions?.permissions[features.performanceGuilds] ===
        permissionEnum.fullPermission ||
      permissions?.permissions[features.performanceGuilds] ===
        permissionEnum.view
    ) {
      options.push({ label: 'Guilds', value: 'guild' });
    }
    if (
      permissions?.permissions[features.performanceGames] ===
        permissionEnum.fullPermission ||
      permissions?.permissions[features.performanceGames] ===
        permissionEnum.view
    ) {
      options.push({ label: 'Games', value: 'games' });
    }
    setFilterOptions(options);
  };

  useEffect(() => {
    handleGetFilterOptions();
    // eslint-disable-next-line
  }, [permissions]);

  useEffect(() => {
    if (filterOptions.length) {
      setFilters(prev => ({ ...prev, game: filterOptions[0].value }));
    }
    // eslint-disable-next-line
  }, [filterOptions]);

  useEffect(() => {
    dispatch.guilds.handleGetAllGuilds();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className='row justify-space-between'>
        <p className='font-size-18 text-primary text-semibold'>Guilds</p>
        <div className='row' style={{ gap: '10px' }}>
          <ActionButton
            variant='primary'
            onClick={() => setIsListView(true)}
            style={{
              background: isListView
                ? theme.color.background.row
                : theme.color.background.row + '66',
              padding: '0px',
              width: '40px',
              height: '35px',
              borderRadius: '4px',
            }}
          >
            <ListIcon active={isListView} style={{ marginTop: '5px' }} />
          </ActionButton>
          <ActionButton
            variant='primary'
            onClick={() => setIsListView(false)}
            style={{
              background: isListView
                ? theme.color.background.row + '66'
                : theme.color.background.row,
              padding: '0px',
              width: '40px',
              height: '35px',
              borderRadius: '4px',
            }}
          >
            <GridIcon active={!isListView} style={{ marginTop: '5px' }} />
          </ActionButton>

          <SearchBar
            value={filters.search}
            onChange={handleChange}
            name='search'
          />
        </div>
      </div>
      {isListView ? (
        <GuildsTableView search={filters.search} filtersHidden={false} />
      ) : (
        <GuildsGridView search={filters.search} />
      )}
    </Container>
  );
};

export default GamesAndGuilds;

const Container = styled.div`
  border-radius: 12px;
  padding: 20px;
  background-color: ${({ theme }) => theme.color.background.card};
`;
