import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { object, string, array, InferType } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';

import CrossIcon from '../../assets/icons/CrossIcon';
import ActionButton from '../../components/ActionButton/ActionButton';
import AddSocialLink from './AddSocialLink';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';

import { lightTheme as theme } from '../../theme';
import { FormErrors, validateData } from '../../utils/validations';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}

const schemaObject = object({
  social_id: string().required('Please select a social media'),
  url: string().url().required().label('URL'),
  id: string(),
});

const schema = array().of(schemaObject);

interface ISocialConfigSchema extends InferType<typeof schemaObject> {}

const AddSocialMediaLinks: FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();
  const handleClose = () => {
    setOpen(false);
  };

  const [errors, setErrors] =
    useState<FormErrors<ISocialConfigSchema | null>>(null);

  const guildSocialLinks = useSelector(
    (state: RootState) => state.guilds.currentGuildSocialLinks
  );

  const loading = useSelector((state: RootState) => state.guilds.loading);

  const socialmediaCount = 5 - guildSocialLinks.length;

  const [socialMediaConfig, setSocialMediaConfig] = useState([
    ...guildSocialLinks.map(social => ({ ...social })),
    ...Array.from({ length: socialmediaCount }, (_, i) => i).map(i => ({
      social_id: '',
      url: '',
      id: '',
    })),
  ]);

  const handleChange = (
    index: number,
    url?: string,
    socialMedia_id?: string
  ) => {
    const socialList = [...socialMediaConfig];
    if (url) {
      if (url === 'null') {
        socialList[index].url = '';
      } else {
        socialList[index].url = url;
      }
    }
    if (socialMedia_id) {
      if (socialMedia_id === 'null') {
        socialList[index].social_id = '';
      } else {
        socialList[index].social_id = socialMedia_id;
      }
    }
    setSocialMediaConfig([...socialList]);
  };

  const handleSave = async () => {
    setErrors(null);
    const socialList: any = [];
    socialMediaConfig.forEach(social => {
      if (social.url || social.social_id) {
        socialList.push(social);
      }
    });

    const { errors: validationErrors } = await validateData<
      ISocialConfigSchema[]
    >(schema, socialList);
    if (validationErrors) {
      // @ts-ignore
      return setErrors(validationErrors);
    }
    dispatch.guilds.handleAddSocialMediaLinks({ data: socialList, setOpen });
  };

  useEffect(() => {
    dispatch.guilds.handleGetGuildSocialLinks(null);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSocialMediaConfig([
      ...guildSocialLinks.map(social => ({ ...social })),
      ...Array.from({ length: socialmediaCount }, (_, i) => i).map(i => ({
        social_id: '',
        url: '',
        id: '',
      })),
    ]);
    // eslint-disable-next-line
  }, [guildSocialLinks]);
  return (
    <DialogLayout open={open}>
      <Content>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>
            Add Social Media Links
          </p>
          <div className='icon clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <p className='font-size-14 text-secondary subtitle'>
          Add social media links of your guild to be shown on guild page
        </p>
        <p className='font-size-18 text-primary text-bold form-title'>
          Add Social Link
        </p>
        <div className='social-links-container'>
          {Array.from({ length: socialMediaConfig.length }, (_, i) => i).map(
            i => (
              <AddSocialLink
                key={i}
                index={i}
                socialMediaConfig={socialMediaConfig}
                handleChange={handleChange}
                // @ts-ignore
                error={
                  errors && // @ts-ignore
                  `${(errors[`[${i}].social_id`], errors[`[${i}].url`])}`
                }
              />
            )
          )}
        </div>
        <Divider />
        <ButtonContainer>
          <ActionButton
            onClick={handleClose}
            variant='primary'
            className='btn btn-primary'
            disabled={loading}
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleSave}
            variant='primary'
            className='btn btn-secondary'
            loading={loading}
            disabled={loading}
          >
            Add Links
          </ActionButton>
        </ButtonContainer>
      </Content>
    </DialogLayout>
  );
};

export default AddSocialMediaLinks;

const Content = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  padding: 30px;
  width: 530px;

  & .subtitle {
    margin-top: 12px;
  }

  & .form-title {
    margin-top: 47px;
  }

  & .social-links-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-block: 21px 100px;
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.text.dim + '33'};
  margin-block: 30px 33px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  & .btn {
    height: 44px;
    width: 150px;
    border-radius: 20px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .btn {
      height: 55px;
      width: 198px;
      border-radius: 30px;
    }
  }
`;
