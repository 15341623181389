import React, { FC, useMemo } from 'react';

import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import UploadIcon from '../../assets/icons/UploadIcon';
import UpdateImg from '../../assets/icons/UpdateImg';
import classNames from 'classnames';

interface IProps {
  file: File | null | string;
  setFile: React.Dispatch<React.SetStateAction<File | null | string>>;
  error?: string;
}

const ImagePicker: FC<IProps> = ({ file, setFile, error }) => {
  const {
    getInputProps,
    isDragAccept,
    getRootProps,
    isDragActive,
    isDragReject,
    open,
  } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
    },
    maxFiles: 1,
    maxSize: 2097152,
    onDrop: acceptedFile => {
      const _file = acceptedFile[0];
      let extension = _file.name.split('.');
      const exten = extension[extension.length - 1];
      if (exten === 'png' || exten === 'jpg' || exten === 'jpeg') {
        setFile(_file);
      }
    },
  });

  const baseStyle = {
    height: '100%',
    width: '100%',
    paddingInline: '24px',
    borderRadius: '50%',
    display: 'flex',
    gap: '9px',
    justifyContent: 'center',
    alignItems: 'center',
    // ...customBaseStyles,
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
    // customRejectStyles,
  };

  const style = useMemo(
    () => ({
      ...baseStyle,

      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragActive, isDragReject, isDragAccept, open]
  );
  return (
    <Container className={classNames(error && 'error')}>
      <div {...getRootProps({ style })} className='root'>
        <input {...getInputProps()} />
        {file ? (
          <>
            <img
              src={typeof file === 'string' ? file : URL.createObjectURL(file)}
              className='image-preview'
              alt=''
            />
            <div className='update-image' onClick={open}>
              <UpdateImg />
            </div>
          </>
        ) : (
          <>
            <div className='image-container'>
              <UploadIcon />
            </div>
            <p className='font-size-10 text-primary'>
              <b>Click to upload </b>or drag and drop badge image
            </p>
          </>
        )}
      </div>
    </Container>
  );
};

export default ImagePicker;

const Container = styled.div`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.background.row};
  position: relative;
  &.error {
    border: 1px solid ${({ theme }) => theme.color.text.danger};
  }
  & .root {
    flex-direction: column;

    & .image-preview {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    & .update-image {
      position: absolute;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.text.primary};
      top: 5px;
      right: 5px;
      display: grid;
      place-items: center;
      cursor: pointer;
    }
  }

  & .image-container {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.text.primary};
    display: grid;
    place-items: center;
  }
`;
