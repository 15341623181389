import React from 'react';
import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  addGameCaptain,
  addGameMaster,
  addGamesInGuild,
  addGuild,
  addSocialMediaLink,
  allGuilds,
  getGameNftContractsByGuildId,
  getGuildSocialLinks,
  guildById,
  guildsByGameId,
  updateAdminGuildSplit,
  updateGuild,
  updateGuildImage,
  updateGuildSocialLinks,
} from '../../api';
import { IAddSocialMedia, IGuild, IRevenueGuild } from '../../types/api';
import {
  IGameWithContracts,
  IGuildSocialLink,
  ISingleGuild,
} from '../../types/interface';
import {
  IAddGameCaptainPayload,
  IAddGameMasterPayload,
  IAddGamesToGuildPayload,
  IAddGuildPayload,
  IUpdateAdminGuildRevenuePayload,
} from '../../types/storeModels';
import axios from 'axios';
import { toast } from 'react-toastify';

interface IProps {
  loading: boolean;
  guilds: IGuild[];
  currentGuild: ISingleGuild | null;
  mineGuild: IGuild[];
  loadData: boolean;
  currentGuildGameNFTContracts: IGameWithContracts[];
  currentGuildSocialLinks: IGuildSocialLink[];
  revenueGuilds: IRevenueGuild[];
  selectedRevenueGuild: null | IRevenueGuild;
}

export const guilds = createModel<RootModel>()({
  name: 'guilds',
  state: {
    loading: false,
    guilds: [],
    currentGuild: null,
    mineGuild: [],
    loadData: false,
    currentGuildGameNFTContracts: [],
    currentGuildSocialLinks: [],
    revenueGuilds: [],
    selectedRevenueGuild: null,
  } as IProps,

  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setGuilds(state, payload: IGuild[]) {
      state.guilds = payload;
    },
    setCurrenGuild(state, payload: ISingleGuild | null) {
      state.currentGuild = payload;
    },
    setMineGuilds(state, payload: IGuild[]) {
      state.mineGuild = payload;
    },
    loadData(state) {
      state.loadData = !state.loadData;
    },
    setCurrentGuildGameNFTContracts(state, payload: IGameWithContracts[]) {
      state.currentGuildGameNFTContracts = payload;
    },
    setCurrentGuildSocialLinks(state, payload: IGuildSocialLink[]) {
      state.currentGuildSocialLinks = payload;
    },
    setRevenueGuilds(state, payload: IRevenueGuild[]) {
      state.revenueGuilds = payload;
    },
    setSelectedRevenueGuild(state, payload: IRevenueGuild | null) {
      state.selectedRevenueGuild = payload;
    },
  },
  effects: dispatch => ({
    async addNewGuild(payload: IAddGuildPayload) {
      try {
        dispatch.guilds.setLoading(true);
        const { data, image, setOpen, games } = payload;
        const { data: res } = await addGuild(data);

        const guildImage = new FormData();
        guildImage.append('logo', image);

        await Promise.all(
          games.map(game => {
            return addGamesInGuild({ game_id: game, guild_id: res.id });
          })
        );

        await updateGuildImage(guildImage, res.id);
        toast.success('Guild added successfully.');
        dispatch.guilds.handleGetAllGuilds();
        setOpen(false);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.guilds.setLoading(false);
      }
    },
    async handleUpdateGuild(payload: IAddGuildPayload, state) {
      try {
        dispatch.guilds.setLoading(true);
        const { data, image, games, setOpen } = payload;
        const id = state.guilds.currentGuild?.id;
        if (id) {
          await updateGuild(
            { name: data.name, user_id: data.user_id, is_active: true },
            id
          );

          if (typeof image !== 'string') {
            const formImage = new FormData();
            formImage.append('logo', image);
            await updateGuildImage(formImage, id);
          }

          await Promise.all(
            games.map(game => {
              return addGamesInGuild({ guild_id: id, game_id: game });
            })
          );
        }
        toast.success('Guild updated successfully.');
        setOpen(false);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.guilds.setLoading(false);
      }
    },
    async handleGetAllGuilds() {
      try {
        dispatch.guilds.setLoading(true);
        const { data } = await allGuilds();
        dispatch.guilds.setGuilds(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.guilds.setLoading(false);
      }
    },
    async handleGetGuildById(payload: string) {
      try {
        dispatch.guilds.setLoading(true);
        const { data } = await guildById(payload);
        dispatch.guilds.setCurrenGuild(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.guilds.setLoading(false);
      }
    },
    async handleGetMineGuils() {
      try {
        dispatch.guilds.setLoading(true);
        const { data } = await allGuilds('?isMine=true');
        dispatch.guilds.setGuilds(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.guilds.setLoading(false);
      }
    },
    async addGameMaster(payload: IAddGameMasterPayload) {
      try {
        const { data, setOpen } = payload;
        dispatch.guilds.setLoading(true);
        await addGameMaster(data);
        dispatch.guilds.loadData();
        toast.success('Game master added successfully.');
        setOpen(false);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.guilds.setLoading(false);
      }
    },
    async addGameCaptain(payload: IAddGameCaptainPayload) {
      try {
        const { data, setOpen } = payload;
        dispatch.guilds.setLoading(true);
        await addGameCaptain(data);
        dispatch.guilds.loadData();
        setOpen(false);
        toast.success('Game captain added successfully.');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.guilds.setLoading(false);
      }
    },
    async handleGetGameNftContracts(payload: string) {
      try {
        dispatch.guilds.setLoading(true);
        const { data } = await getGameNftContractsByGuildId(payload);
        dispatch.guilds.setCurrentGuildGameNFTContracts(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.guilds.setLoading(false);
      }
    },
    async handleAddSocialMediaLinks(
      payload: {
        data: IAddSocialMedia[];
        setOpen: React.Dispatch<React.SetStateAction<boolean>>;
      },
      state
    ) {
      try {
        dispatch.guilds.setLoading(true);
        const guildId = state.guilds.currentGuild?.id;
        if (guildId) {
          await Promise.all(
            payload.data.map(social => {
              if (social.id) {
                return updateGuildSocialLinks(
                  {
                    url: social.url,
                    social_id: social.social_id,
                  },
                  social.id
                );
              } else {
                return addSocialMediaLink({
                  url: social.url,
                  social_id: social.social_id,
                  guild_id: guildId,
                });
              }
            })
          );
        }
        dispatch.guilds.handleGetGuildSocialLinks(null);
        toast.success('Social media added successfully.');
        payload.setOpen(false);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.guilds.setLoading(false);
      }
    },
    async handleGetGuildSocialLinks(_, state) {
      try {
        dispatch.guilds.setLoading(true);
        const guildId = state.guilds.currentGuild?.id;

        if (guildId) {
          const { data } = await getGuildSocialLinks(guildId);
          dispatch.guilds.setCurrentGuildSocialLinks(data);
        }
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.guilds.setLoading(false);
      }
    },
    async addGamesToGuild(payload: IAddGamesToGuildPayload, state) {
      try {
        dispatch.guilds.setLoading(true);
        const guildId = state.guilds.currentGuild!.id;
        const { data, setOpen } = payload;
        await Promise.all(
          data.map(game => {
            return addGamesInGuild({ guild_id: guildId, game_id: game });
          })
        );
        setOpen(false);
        dispatch.guilds.handleGetGuildById(guildId);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.guilds.setLoading(false);
      }
    },
    async handleGetGuildsByGameId(payload: string) {
      try {
        dispatch.guilds.setLoading(true);
        const { data } = await guildsByGameId(payload);
        dispatch.guilds.setRevenueGuilds(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.guilds.setLoading(false);
      }
    },
    async handleUpdateAdminGuildRevenue(
      payload: IUpdateAdminGuildRevenuePayload
    ) {
      try {
        dispatch.guilds.setLoading(true);
        const { data, setOpen, afterUpdate } = payload;

        await updateAdminGuildSplit(data);
        setOpen(false);
        afterUpdate();
        toast.success('Revenue updated successfully.');
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          dispatch.errorModal.setOpen({
            title: 'Error!',
            description: errorMessage,
          });
        }
      } finally {
        dispatch.guilds.setLoading(false);
      }
    },
  }),
});
