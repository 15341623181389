import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme';

const Eye: FC<{ [x: string]: any }> = ({ ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='22'
      height='15'
      viewBox='0 0 22 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M21.87 6.645C20.8795 4.66195 19.3596 2.99161 17.4785 1.81883C15.5975 0.646053 13.4287 0.0165554 11.212 0C8.99535 0.0168746 6.82654 0.646615 4.94553 1.81955C3.06452 2.99249 1.54464 4.66291 0.554033 6.646C0.468428 6.81545 0.423828 7.00265 0.423828 7.1925C0.423828 7.38235 0.468428 7.56955 0.554033 7.739C1.5446 9.72205 3.0645 11.3924 4.94553 12.5652C6.82657 13.7379 8.9954 14.3674 11.212 14.384C13.4287 14.3671 15.5975 13.7374 17.4785 12.5644C19.3595 11.3915 20.8794 9.72109 21.87 7.738C21.9556 7.56855 22.0002 7.38135 22.0002 7.1915C22.0002 7.00165 21.9556 6.81446 21.87 6.645ZM11.212 12.586C10.1452 12.586 9.10233 12.2696 8.21529 11.6769C7.32825 11.0842 6.63689 10.2418 6.22863 9.25619C5.82037 8.27057 5.71355 7.18602 5.92168 6.13968C6.12981 5.09335 6.64353 4.13223 7.3979 3.37787C8.15226 2.6235 9.11338 2.10977 10.1597 1.90164C11.206 1.69352 12.2906 1.80033 13.2762 2.20859C14.2619 2.61685 15.1043 3.30822 15.697 4.19525C16.2897 5.08229 16.606 6.12517 16.606 7.192C16.606 8.62258 16.0377 9.99456 15.0262 11.0061C14.0146 12.0177 12.6426 12.586 11.212 12.586ZM11.212 3.596C10.8911 3.6005 10.5722 3.64826 10.264 3.738C10.5188 4.08316 10.6413 4.50828 10.6093 4.93609C10.5772 5.36391 10.3928 5.76604 10.0894 6.0694C9.78607 6.37275 9.38394 6.55721 8.95613 6.58925C8.52831 6.62129 8.10319 6.49879 7.75803 6.244C7.56053 6.96825 7.59516 7.7362 7.85705 8.43972C8.11894 9.14325 8.5949 9.74692 9.21791 10.1657C9.84092 10.5845 10.5796 10.7974 11.3299 10.7743C12.0803 10.7512 12.8045 10.4934 13.4006 10.0371C13.9967 9.58077 14.4346 8.94899 14.6528 8.2307C14.8709 7.5124 14.8583 6.74378 14.6167 6.03304C14.375 5.3223 13.9165 4.70526 13.3058 4.26879C12.695 3.83232 11.9627 3.59841 11.212 3.6V3.596Z'
        fill={theme.color.text.primary}
      />
    </svg>
  );
};

export default Eye;
