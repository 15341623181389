const assets = {
  logoWhite: require('./icons/logo-white.png'),
  logoBlack: require('./icons/logo-black.png'),
  gamefiedNewLogo: require('./icons/gamefiedNewLogo.svg').default,
  loginBackground: require('./img/loginbgLight.png'),
  hero1: require('./img/hero1.png'),
  hero2: require('./img/hero2.png'),
  hero3: require('./img/hero3.png'),
  add: require('./icons/add.svg').default,
  addLight: require('./icons/addLight.svg').default,
  moon: require('./icons/moon.svg').default,
  moonLight: require('./icons/moonLight.svg').default,
  discord: require('./icons/discord.svg').default,
  facebook: require('./icons/facebook.svg').default,
  twitter: require('./icons/twitter.svg').default,
  telegram: require('./icons/telegram.svg').default,
  dots: require('./icons/dots.svg').default,
  gameIcon1: require('./icons/gameIcon1.svg').default,
  gameIcon2: require('./icons/gameIcon2.svg').default,
  gameIcon3: require('./icons/gameIcon3.svg').default,
  crossRes: require('./icons/cross-red.svg').default,
  goldBadge: require('./icons/gold-badge.svg').default,
  silverBadge: require('./icons/silver-badge.svg').default,
  bronzeBadge: require('./icons/bronze-badge.svg').default,
  cover: require('./img/cover.png'),
  axieLogo: require('./icons/axieLogo.svg').default,
  axieIcon: require('./icons/axieIcon.svg').default,
  riot: require('./icons/riot.svg').default,
  SLP: require('./icons/SLP.svg').default,
  screenshot: require('./img/screenshot.png'),
  crossFilled: require('./icons/crossFilled.svg').default,
  guildCover: require('./img/guild-cover.png'),
  stz: require('./icons/stz.svg').default,
  tournament: require('./img/tournament.png'),
  tournamentCover: require('./img/tournamentCover.png'),
  theaten: require('./icons/theaten.svg').default,
  axie: require('./icons/axie.svg').default,
  delete: require('./icons/delete.svg').default,
  bin: require('./icons/bin.svg').default,
  userPreview: require('./img/userPreview.png'),
  axieIconR: require('./icons/axieIconR.svg').default,
  mainLogo: require('./icons/mainIcon.png'),
  benji: require('./img/benji.png'),
};

export default assets;
