import classNames from 'classnames';
import React, { FC } from 'react';

import styled from 'styled-components';
import { lightTheme as theme } from '../../theme';

interface IProps {
  label: string;
  placeholder?: string;
  rows: number;
  error?: string;
  [x: string]: any;
}

const MultiLineInput: FC<IProps> = ({
  label,
  placeholder = '',
  error,
  rows,
  ...rest
}) => {
  return (
    <Container>
      <label
        htmlFor={label}
        className={classNames('font-size-14', error && 'error')}
      >
        {label}
      </label>
      <InputContainer>
        <textarea
          className={classNames(error && 'error')}
          id={label}
          rows={rows}
          {...rest}
          placeholder={placeholder}
        ></textarea>
      </InputContainer>
      {error && <p className='font-size-14 error-message'>{error}</p>}
    </Container>
  );
};

export default MultiLineInput;

const Container = styled.div`
  width: 100%;

  & label {
    color: ${({ theme }) => theme.color.text.dim};
    &.error {
      color: ${({ theme }) => theme.color.text.danger};
    }
  }

  & .error-message {
    margin-top: 5px;
    color: ${({ theme }) => theme.color.text.danger};
  }
`;

const InputContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  position: relative;

  & textarea {
    width: 100%;
    border-radius: 8px;
    padding-block: 13px;
    outline: none;
    border: none;
    padding-inline: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: ${({ theme }) => theme.color.text.primary};
    background-color: ${({ theme }) => theme.color.background.row};
    resize: none;

    &.error {
      background-color: ${({ theme }) => theme.color.text.danger + '1a'};
      border: 1px solid ${({ theme }) => theme.color.text.danger};
    }
  }

  & .visibility-icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    cursor: pointer;
    width: 16px;
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & textarea {
      padding-inline: 26px;
      padding-block: 15px;
    }
    margin-top: 8px;
    & .visibility-icon {
      width: unset;
      right: 26px;
    }
  }
`;
