import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const CopyIcon: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='13'
      height='14'
      viewBox='0 0 13 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M8.95567 0H1.27901C0.940113 0.00103469 0.615396 0.13542 0.375761 0.373812C0.136126 0.612204 0.00104009 0.935235 0 1.27237V10.1816H1.27901V1.27237H8.95567V0ZM10.8748 2.54605H3.83833C3.49944 2.54708 3.17472 2.68147 2.93509 2.91986C2.69545 3.15825 2.56037 3.48128 2.55933 3.81842V12.7276C2.56037 13.0648 2.69545 13.3878 2.93509 13.6262C3.17472 13.8646 3.49944 13.999 3.83833 14H10.8748C11.2137 13.999 11.5385 13.8646 11.7781 13.6262C12.0177 13.3878 12.1528 13.0648 12.1538 12.7276V3.81842C12.1528 3.48128 12.0177 3.15825 11.7781 2.91986C11.5385 2.68147 11.2137 2.54708 10.8748 2.54605ZM10.8748 12.7276H3.83833V3.81842H10.8748V12.7276Z'
        fill={fill || theme.color.text.primary}
      />
    </svg>
  );
};

export default CopyIcon;
