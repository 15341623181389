import React, { FC } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { IGuildUser } from '../../types/api';
import { IUser } from '../../types/interface';

import assets from '../../assets';
interface IProps {
  user?: IUser | IGuildUser;
  index?: number;
}

const UserCard: FC<IProps> = ({ user, index }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/dashboard/player/profile/${user!.id}`);
  };
  return (
    <Container onClick={handleClick}>
      <div className='image'>
        <img src={user?.image_url || assets.userPreview} alt='' />
      </div>
      <div className='row justify-space-between'>
        <p className='font-size-12 text-primary text-semibold capitalize'>
          {user?.username}
        </p>
        <div className='user-number'>
          {typeof index === 'number' ? (
            <p className='font-size-12 text-secondary text-semibold '>
              {index < 10 ? `0${index + 1}` : `${index + 1}`}
            </p>
          ) : (
            <p className='font-size-12 text-secondary text-semibold '>00</p>
          )}
        </div>
      </div>
    </Container>
  );
};

export default UserCard;

const Container = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.background.row};
  padding: 6px;
  cursor: pointer;

  & .image {
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: 1/1;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .row {
    margin-top: 12px;
    & p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 12ch;
    }
    & .user-number {
      height: 24px;
      width: 24px;
      background-color: #23272a;
      display: grid;
      place-items: center;
      border-radius: 4px;
    }
  }
`;
