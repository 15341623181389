import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

import ClickAwayListener from 'react-click-away-listener';

import SelectIcon from '../../../assets/icons/SelectIcon';
import { IUser } from '../../../types/interface';
import { assignBadge, mintedBadges as userMintedBadges } from '../../../api';
import { IBadge } from '../../../types/api';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../../hooks/useTheme';

interface IProps {
  user: IUser;
}

const AssaignBadge: FC<IProps> = ({ user }) => {
  const { theme } = useTheme();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [highestBadge, setHighestBadge] = useState<IBadge | null>(null);
  const [badgeOptions, setBadgeOptions] = useState<IBadge[]>([]);

  const badges = useSelector((state: RootState) => state.badges.badges);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUserMintedBadges = async () => {
    try {
      setLoading(true);
      const { data } = await userMintedBadges(user.id);
      let highest = data[0];
      data.forEach(badge => {
        if (highest.stz < badge.stz) {
          highest = badge;
        }
      });
      const filteredBadges = badges.filter(el => {
        return !data.some(f => {
          return f.id === el.id;
        });
      });
      setHighestBadge(highest);
      setBadgeOptions(filteredBadges);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAssignBadge = async (badge: IBadge) => {
    try {
      setLoading(true);
      await assignBadge(badge.id, user.id);
      getUserMintedBadges();
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (badges.length) {
      getUserMintedBadges();
    }
    // eslint-disable-next-line
  }, [badges]);

  return (
    <Container onClick={toggleMenu}>
      {loading ? (
        <div className='row justify-center'>
          <ClipLoader size={10} color={theme.color.text.primary} />
        </div>
      ) : (
        <>
          <div className='row selector clickable'>
            <p className='font-size-12 text-primary capitalize'>
              {highestBadge ? highestBadge.name : 'Assign badge'}
            </p>
            <div className='icon'>
              <SelectIcon />
            </div>
          </div>
          {open && (
            <ClickAwayListener onClickAway={handleClose}>
              <Menu>
                {badgeOptions.map(badge => (
                  <div
                    className='row clickable'
                    onClick={() => handleAssignBadge(badge)}
                  >
                    <p className='font-size-14 text-primary capitalize'>
                      {badge.name}
                    </p>
                  </div>
                ))}
              </Menu>
            </ClickAwayListener>
          )}
        </>
      )}
    </Container>
  );
};

export default AssaignBadge;

const Container = styled.div`
  position: relative;
  & .selector {
    gap: 6px;

    & .icon {
      & svg {
        width: 7px;
      }
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  width: 163px;
  background-color: ${({ theme }) => theme.color.background.menu};
  padding: 0px 10px;
  transform: translateY(100%);
  border-radius: 10px;
  z-index: 100;

  & .row {
    padding-block: 7px;
    border-bottom: 1px solid ${({ theme }) => theme.color.text.dim + '66'};
    padding-inline: 4px;

    &:first-of-type {
      padding-inline: 0px;
    }
    &:last-of-type {
      border-bottom: none;
    }

    & .wallet-address {
      padding: 6px 9px;
      display: flex;
      align-items: center;
      gap: 4px;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.color.text.dim + '1a'};
      width: 100%;
      border-radius: 15px;

      & .icon {
        cursor: pointer;
      }
    }
  }
`;
