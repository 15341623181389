import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../store';

import Select from '../../../components/Select/Select';
import SearchBar from '../../../components/SearchBar/SearchBar';
// import ActionButton from '../../../components/ActionButton/ActionButton';

import AddNewUser from '../../../Dialogs/AddNewUser/AddNewUser';
import { ClipLoader } from 'react-spinners';
import { darkTheme, lightTheme } from '../../../theme';
import AssaignBadge from './AssaignBadge';
import { rolesEnum } from '../../../types/enums';

const UserList: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const users = useSelector((state: RootState) => state.users.users);
  const roles = useSelector((state: RootState) => state.roles.roles);
  const loading = useSelector((state: RootState) => state.users.loading);

  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const theme = activeTheme === 'light' ? lightTheme : darkTheme;
  const [openDialog, setOpenDialog] = useState(false);
  const [filters, setFilters] = useState({
    game: '',
    role: '',
    revenue: '',
  });

  const [search, setSearch] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    dispatch.users.getAllUsers();
    dispatch.roles.handleGetAllRoles();
    dispatch.badges.handleGetAllBadges();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch.users.getAllUsers({
      revenueSplit: filters.revenue,
      roleId: filters.role,
    });
    // eslint-disable-next-line
  }, [filters]);

  return (
    <Container>
      <div className='row justify-space-between'>
        <div />
        {/* <ActionButton
          className='btn'
          variant='primary'
          onClick={() => setOpenDialog(true)}
        >
          + Add New User
        </ActionButton> */}
        <div className='row' style={{ gap: '10px' }}>
          {/* <Select
            label='New/Old'
            options={[
              { label: 'Axie infinity', value: 'Axie infinity' },
              { label: 'Sorare', value: 'Sorare' },
            ]}
            value={filters.game}
            name='game'
            onChange={handleChange}
          /> */}
          <Select
            label='Roles'
            options={
              roles
                .filter(role => role.name !== rolesEnum.Admin)
                .map(role => ({ label: role.name, value: role.id })) || []
            }
            value={filters.role}
            inputClassName='capitalize'
            name='role'
            onChange={handleChange}
          />
          {/* <Select
            label='Revenue'
            options={[
              { label: 'Axie infinity', value: 'Axie infinity' },
              { label: 'Sorare', value: 'Sorare' },
            ]}
            value={filters.revenue}
            name='revenue'
            onChange={handleChange}
          /> */}
          <SearchBar value={search} onChange={handleSearch} name='search' />
        </div>
      </div>
      {loading && !users.length ? (
        <div style={{ height: '200px' }} className='row justify-center'>
          <ClipLoader size={50} color={theme.color.text.primary} />
        </div>
      ) : (
        <TableContainer>
          <table>
            <thead>
              <tr className='header-row'>
                <th className='font-size-12 text-primary text-semibold'>
                  Name
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Email
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Role
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Badge
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Wallet address
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Revenue Split
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => {
                if (
                  user.username.toLowerCase().indexOf(search.toLowerCase()) >
                    -1 ||
                  user.email.toLowerCase().indexOf(search.toLowerCase()) > -1
                ) {
                  return (
                    <React.Fragment key={user.id}>
                      <tr style={{ height: '5px' }} />
                      <tr>
                        <td className='font-size-12 text-primary text-semibold capitalize'>
                          {user.username}
                        </td>
                        <td className='font-size-12 text-primary text-semibold'>
                          {user.email}
                        </td>
                        <td className='font-size-12 text-primary text-semibold capitalize'>
                          {roles.find(role => role.id === user.role_id)?.name}
                        </td>
                        <td className='font-size-12 text-primary text-semibold'>
                          <AssaignBadge user={user} />
                        </td>
                        <td className='font-size-12 text-primary text-semibold'>
                          {user.wallet_address}
                        </td>
                        <td className='font-size-12 text-primary text-semibold'>
                          35 / 65
                        </td>
                        <td className='font-size-12 text-primary text-semibold'>
                          Actions
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                } else {
                  return <></>;
                }
              })}
            </tbody>
          </table>
        </TableContainer>
      )}

      <AddNewUser open={openDialog} setOpen={setOpenDialog} />
    </Container>
  );
};

export default UserList;

const Container = styled.div`
  & .btn {
    height: 35px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.background.headerRow};
  }
`;

const TableContainer = styled.div`
  margin-top: 13px;

  & table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    & tr {
      height: 50px;
      &.header-row {
        height: 40px;
        border: none;
        & th {
          text-align: left;
          background-color: ${({ theme }) => theme.color.background.headerRow};
          &:first-of-type {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding-left: 12px;
          }
          &:last-of-type {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-right: 12px;
          }
        }
      }
      & td {
        background-color: ${({ theme }) => theme.color.background.row};
        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          padding-left: 12px;
        }
        &:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          padding-right: 12px;
        }
      }
    }
  }
`;
