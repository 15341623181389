import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme';

const UpdateImg: FC<{ [x: string]: any }> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  // eslint-disable-next-line
  const theme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M0 2.22383C0 1.04364 0.956728 0.0869141 2.13691 0.0869141H6.41074C6.80413 0.0869141 7.12304 0.405824 7.12304 0.799218C7.12304 1.19261 6.80413 1.51152 6.41074 1.51152H2.13691C1.74352 1.51152 1.42461 1.83043 1.42461 2.22383V12.1961C1.42461 12.5895 1.74351 12.9084 2.13691 12.9084H12.1092C12.5026 12.9084 12.8215 12.5895 12.8215 12.1961V7.92226C12.8215 7.52887 13.1404 7.20996 13.5338 7.20996C13.9272 7.20996 14.2461 7.52887 14.2461 7.92226V12.1961C14.2461 13.3763 13.2894 14.333 12.1092 14.333H2.13691C0.956735 14.333 0 13.3763 0 12.1961V2.22383Z'
        fill={theme.color.background.card}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.674 1.65854C12.3621 1.34663 11.8563 1.34663 11.5444 1.65854L5.96488 7.23808L5.4001 8.93243L7.09444 8.36765L12.674 2.78812C12.674 2.78812 12.674 2.78812 12.674 2.78812C12.9859 2.4762 12.9859 1.97046 12.674 1.65854ZM10.5371 0.6512C11.4053 -0.21707 12.8131 -0.217067 13.6813 0.651209C14.5496 1.51947 14.5496 2.92719 13.6813 3.79545L7.98289 9.4939C7.9047 9.57209 7.80937 9.63101 7.70447 9.66597L4.4991 10.7344C4.24314 10.8197 3.96095 10.7531 3.77017 10.5624C3.57939 10.3716 3.51278 10.0894 3.5981 9.83343L4.66655 6.62806C4.70152 6.52315 4.76044 6.42783 4.83863 6.34963L10.5371 0.6512C10.5371 0.651197 10.5371 0.651203 10.5371 0.6512Z'
        fill={theme.color.background.card}
      />
    </svg>
  );
};

export default UpdateImg;
