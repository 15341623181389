import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../store';

import useTheme from '../../../hooks/useTheme';

import Select from '../../../components/Select/Select';
import SearchBar from '../../../components/SearchBar/SearchBar';
import EditIcon from '../../../assets/icons/EditIcon';
import GuildUserRevenueSplit from '../../../Dialogs/GuildUsersRevenueSplit/GuildUserRevenueSplit';
import { ClipLoader } from 'react-spinners';

const GMRevenueSplit: FC = () => {
  const { theme } = useTheme();
  const dispatch = useDispatch<Dispatch>();

  const guild = useSelector((state: RootState) => state.guilds.currentGuild);

  const loading = useSelector((state: RootState) => state.users.loading);
  const revenueUsers = useSelector(
    (state: RootState) => state.users.revenueUsers
  );
  const roles = useSelector((state: RootState) => state.roles.roles);

  const [revenue, setRevenue] = useState(false);
  const [filters, setFilters] = useState({
    game: '',
    search: '',
  });

  const [game, setGame] = useState('');

  const handleGameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const gameId = e.currentTarget.value;
    setGame(gameId);
  };

  const handleRevenue = () => {
    setRevenue(true);
  };

  const handleFilters = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleFetchUsers = async () => {
    if (guild && game) {
      dispatch.users.getRevenueUsers({ guildId: guild.id, gameId: game });
    }
  };

  useEffect(() => {
    if (guild && guild.Games.length) {
      setGame(guild.Games[0].id);
    }
  }, [guild]);

  useEffect(() => {
    if (game) {
      handleFetchUsers();
    }
    // eslint-disable-next-line
  }, [game]);

  useEffect(() => {
    dispatch.roles.handleGetAllRoles();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className='row header justify-space-between'>
        <p className='font-size-18 text-primary text-semibold'>Users</p>
        <div className='row filters'>
          <Select
            label='Game'
            disabled
            value={game}
            onChange={handleGameChange}
            options={
              guild?.Games.map(game => ({
                label: game.name,
                value: game.id,
              })) || []
            }
            name='game'
          />
          <SearchBar
            className='search'
            value={filters.search}
            name='search'
            onChange={handleFilters}
          />
        </div>
      </div>
      {loading ? (
        <div className='row justify-center'>
          <ClipLoader size={50} color={theme.color.text.primary} />
        </div>
      ) : (
        <TableContainer>
          <table>
            <thead>
              <tr className='header-row'>
                <th className='font-size-12 text-primary text-semibold'>
                  Name
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Email
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Role
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Payment wallet address
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Revenue Split
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {revenueUsers.map(user => (
                <React.Fragment key={user.id}>
                  <tr style={{ height: '5px' }} />
                  <tr>
                    <td className='font-size-12 text-primary text-semibold'>
                      {user.username}
                    </td>
                    <td className='font-size-12 text-primary text-semibold'>
                      {user.email}
                    </td>
                    <td className='font-size-12 text-primary text-semibold'>
                      {roles.find(role => role.id === user.role_id)?.name}
                    </td>
                    <td className='font-size-12 text-primary text-semibold'>
                      {user.payout_wallet_address}
                    </td>
                    <td className='font-size-12 text-primary text-semibold'>
                      {user.revenue_split}%
                    </td>
                    <td className='font-size-12 text-primary text-semibold'>
                      <IConButton
                        onClick={() => {
                          dispatch.users.setSelectedRevenueUser(user);
                          handleRevenue();
                        }}
                      >
                        <EditIcon />
                      </IConButton>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </TableContainer>
      )}

      {revenue && (
        <GuildUserRevenueSplit
          handleFetchUsers={handleFetchUsers}
          gameId={game}
          open={revenue}
          setOpen={setRevenue}
        />
      )}
    </Container>
  );
};

export default GMRevenueSplit;

const Container = styled.div`
  padding: 20px 15px;

  & .header {
    margin-bottom: 15px;
    & .filters {
      gap: 10px;

      & .search {
        width: 381px;
      }
    }
  }
`;

const TableContainer = styled.div`
  margin-top: 13px;

  & table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    & tr {
      height: 50px;
      &.header-row {
        height: 40px;
        border: none;
        & th {
          text-align: left;
          background-color: ${({ theme }) => theme.color.background.headerRow};
          &:first-of-type {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding-left: 12px;
          }
          &:last-of-type {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-right: 12px;
          }
        }
      }
      & td {
        background-color: ${({ theme }) => theme.color.background.row};
        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          padding-left: 12px;
        }
        &:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          padding-right: 12px;
        }
      }
    }
  }
`;

const IConButton = styled.button`
  background-color: ${({ theme }) => theme.color.text.primary};
  border-radius: 6px;
  border: none;
  min-width: 27px;
  min-height: 27px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 0px 2px 2px;
`;
