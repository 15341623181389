import React, { FC } from 'react';

import styled from 'styled-components';

import assets from '../../../../assets';
import { IGame } from '../../../../types/api';

interface IProps {
  game: IGame;
}

const GamesRow: FC<IProps> = ({ game }) => {
  return (
    <GameRow>
      <div className='row justify-space-between'>
        <div className='row profile'>
          <div className='image'>
            <img src={game.mini_icon} alt='' />
          </div>
          <div className='name font-size-14 text-primary'>{game.name}</div>
        </div>
        <div className='row info'>
          <div className='row count-row'>
            <div className='count-key'>
              <p className='font-size-12 text-primary text-semibold'>S</p>
            </div>
            <p className='font-size-14 text-primary text-semibold'>25</p>
          </div>
          <div className='row count-row'>
            <div className='count-key'>
              <p className='font-size-12 text-primary text-semibold'>C</p>
            </div>
            <p className='font-size-14 text-primary text-semibold'>02</p>
          </div>
          <div className='row earning'>
            <img src={assets.stz} alt='' />
            <div className=''>
              <p className='font-size-14 text-primary text-bold'>4,000 STZ</p>
              <p className='font-size-8 text-primary text-bold'>≈ 150.69 USD</p>
            </div>
          </div>
        </div>
      </div>
    </GameRow>
  );
};

export default GamesRow;

const GameRow = styled.div`
  background-color: ${({ theme }) => theme.color.background.row};
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding-inline: 7px;

  & .justify-space-between {
    width: 100%;
    & .profile {
      gap: 7px;
      & .image {
        height: 36px;
        width: 36px;
        flex-shrink: 0;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.text.primary};
        overflow: hidden;
        & img {
          height: 100%;
          width: 100%;
        }
      }

      & .name {
        max-width: 12ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  & .row.info {
    gap: 21px;

    & .row.count-row {
      gap: 7px;

      & .count-key {
        height: 20px;
        width: 20px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.color.background.headerRow};
        display: grid;
        place-items: center;
      }
    }
  }

  & .row.earning {
    gap: 7px;
    & img {
      height: 23px;
      width: 23px;
      border-radius: 50%;
    }
  }
`;
