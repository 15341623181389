import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import {
  getGuildGames,
  getGuildSocialLinks,
  getGuildStats,
} from '../../../api';
import RenderSocialIcon from '../../../components/RenderSocialIcon/RenderSocialIcon';
import UserMiniGameList from '../../../components/UserMiniGameList/UserMiniGameList';

import { IGame, IGuild, IGuildStats } from '../../../types/api';
import { IGuildSocialLink } from '../../../types/interface';

interface IProps {
  guildDetails: IGuild;
}

const GuildRow: FC<IProps> = ({ guildDetails }) => {
  const navigate = useNavigate();
  const [games, setGames] = useState<IGame[]>([]);
  const [socialLinks, setSocialLinks] = useState<IGuildSocialLink[]>([]);
  const [stats, setStats] = useState<IGuildStats | null>(null);

  const handleRowClick = () => {
    navigate(`/dashboard/guilds/page/${guildDetails.id}`);
  };

  const handleGetInitialData = async () => {
    try {
      const [
        { data: guildGames },
        { data: guildSocial },
        { data: guildStats },
      ] = await Promise.all([
        getGuildGames(guildDetails.id),
        getGuildSocialLinks(guildDetails.id),
        getGuildStats(guildDetails.id),
      ]);
      setGames(guildGames);
      setSocialLinks(guildSocial);
      setStats(guildStats);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    handleGetInitialData();
    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      <tr style={{ height: '5px' }} />
      <tr className='clickable' onClick={handleRowClick}>
        <td className='font-size-12 text-primary text-semibold capitalize'>
          {guildDetails.name}
        </td>
        <td className='font-size-12 text-primary text-semibold'>
          <div className='row' style={{ gap: '6px' }}>
            <UserMiniGameList games={games} />
          </div>
        </td>
        <td className='font-size-12 text-primary text-semibold '>
          {stats?.totalMasters.total}
        </td>

        <td className='font-size-12 text-primary text-semibold'>
          {stats?.totalCaptains.total}
        </td>
        <td className='font-size-12 text-primary text-semibold'>
          {stats?.totalScholars.total}
        </td>
        <td className='font-size-12 text-primary text-semibold'>
          {stats?.totalMasters.total}
        </td>
        <td className='font-size-12 text-primary text-semibold'>2/04/2023</td>
        <td className='font-size-12 text-primary text-semibold'>
          <div className='row' style={{ gap: '8px' }}>
            {socialLinks.map(social => (
              <a
                href={social.url}
                target='_blank'
                rel='noreferrer'
                key={social.id}
              >
                <SocialLink key={social.id}>
                  <RenderSocialIcon name={social.logo} />
                </SocialLink>
              </a>
            ))}
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default GuildRow;

const SocialLink = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  display: grid;
  place-items: center;
  padding: 4px;
  & svg {
    height: 100%;
    width: 100%;
  }
`;
