import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import CloseIcon from '../../assets/icons/CloseIcon';
import CrossIcon from '../../assets/icons/CrossIcon';
import Select from '../../components/Select/Select';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';
import { allGames } from '../../api';
import { IGame } from '../../types/api';

import { lightTheme as theme } from '../../theme';
import ActionButton from '../../components/ActionButton/ActionButton';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddGameToGuild: FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();
  const guild = useSelector((state: RootState) => state.guilds.currentGuild);
  const loading = useSelector((state: RootState) => state.guilds.loading);

  const [games, setGames] = useState<IGame[]>([]);

  const [selectedGames, setSelectedGames] = useState<IGame[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.currentTarget.value;

    const game = games.find(game => game.id === id)!;
    setSelectedGames(prev => [...prev, game]);
    setGames(games.filter(game => game.id !== id));
  };

  const handleDeSelectGame = (game: IGame) => {
    setSelectedGames(selectedGames.filter(g => g.id !== game.id));
    setGames([...games, game]);
  };

  const handleGetGames = async () => {
    try {
      const { data } = await allGames(guild?.id, true);
      setGames(data);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddGames = () => {
    dispatch.guilds.addGamesToGuild({
      data: selectedGames.map(g => g.id),
      setOpen,
    });
  };

  useEffect(() => {
    handleGetGames();
    // eslint-disable-next-line
  }, []);
  return (
    <DialogLayout open={true}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>Add New Game</p>
          <div className='clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <p className='font-size-14 text-primary subtitle'>
          Assign new game to the {guild?.name} guild
        </p>

        <div className='input-container'>
          <Select
            title='Games'
            label='select game'
            options={games.map(game => ({ label: game.name, value: game.id }))}
            value=''
            onChange={handleChange}
          />
        </div>
        <div className='games-grid'>
          {selectedGames.map(game => (
            <div className='game' key={game.id}>
              <img src={game.mini_icon} alt='' />
              <div
                className='close clickable'
                onClick={() => handleDeSelectGame(game)}
              >
                <CloseIcon />
              </div>
            </div>
          ))}
        </div>
        <div className='divider'></div>
        <ButtonContainer>
          <ActionButton
            onClick={handleClose}
            variant='primary'
            className='btn btn-primary'
            disabled={loading}
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleAddGames}
            variant='primary'
            className='btn btn-secondary'
            disabled={!selectedGames.length || loading}
            loading={loading}
          >
            Add Game
          </ActionButton>
        </ButtonContainer>
      </Container>
    </DialogLayout>
  );
};

export default AddGameToGuild;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 30px;
  border-radius: 12px;
  width: 527px;

  & .subtitle {
    margin-top: 12px;
  }

  & .input-container {
    margin-block: 30px 24px;
  }

  & .games-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    & .game {
      height: 50px;
      width: 50px;
      border-radius: 8px;
      background-color: ${({ theme }) =>
        theme.color.background.button.primary.bg};
      padding: 5px;
      position: relative;

      & img {
        width: 100%;
        height: 100%;
      }

      & .close {
        position: absolute;
        top: -6px;
        right: -6px;
        & svg {
          width: 13px;
          height: 13px;
        }
      }
    }
  }

  & .divider {
    border-top: 1px solid ${({ theme }) => theme.color.text.secondary};
    margin-block: 40px 34px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  & .btn {
    height: 44px;
    width: 150px;
    border-radius: 20px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .btn {
      height: 55px;
      width: 198px;
      border-radius: 30px;
    }
  }
`;
