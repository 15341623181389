import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';

import SearchBar from '../../../components/SearchBar/SearchBar';
import Select from '../../../components/Select/Select';
import AdminRevenueRow from './AdminRevenueRow';
import AdminGuildRevenue from '../../../Dialogs/AdminGuildRevenue/AdminGuildRevenue';

import { lightTheme as theme } from '../../../theme';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../../hooks/useTheme';

const RevenueList: FC = () => {
  const { theme } = useTheme();
  const dispatch = useDispatch<Dispatch>();

  const [revenue, setRevenue] = useState(false);

  const guilds = useSelector((state: RootState) => state.guilds.revenueGuilds);
  const loading = useSelector((state: RootState) => state.guilds.loading);

  const games = useSelector((state: RootState) => state.games.games);
  const [filters, setFilters] = useState({
    search: '',
  });

  const [game, setGame] = useState('');

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.currentTarget;
    if (name === 'game') {
      setGame(value);
    } else {
      setFilters(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleGetGuilds = async () => {
    const query = `?gameId=${game}`;
    dispatch.guilds.handleGetGuildsByGameId(query);
  };

  useEffect(() => {
    dispatch.games.handleGetAllGames();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (games.length) {
      setGame(games[0].id);
    }
    // eslint-disable-next-line
  }, [games]);

  useEffect(() => {
    if (game) {
      handleGetGuilds();
    }
    // eslint-disable-next-line
  }, [game]);
  return (
    <MainContainer>
      <div className='row justify-space-between filters'>
        <p className='font-size-18 text-primary text-bold'>Guilds</p>
        <div className='row filters'>
          <Select
            value={game}
            options={games.map(game => ({ label: game.name, value: game.id }))}
            onChange={handleChange}
            label='game'
            name='game'
          />
          <SearchBar
            value={filters.search}
            onChange={handleChange}
            name='search'
          />
        </div>
      </div>
      <div className='wrapper'>
        {/* Revenue list */}
        {loading && !guilds.length ? (
          <div className='row justify-center' style={{ height: '300px' }}>
            <ClipLoader size={50} color={theme.color.text.primary} />
          </div>
        ) : (
          <>
            <Container>
              <GridItem>
                <div className='guild'>
                  <p className='font-size-16 text-primary text-semibold'>
                    Guilds List
                  </p>
                </div>
                <div className='guild revenue text-center'>
                  <p className='font-size-16 text-primary text-semibold'>
                    Admin/Guild
                  </p>
                </div>
              </GridItem>
              <GridItem>
                <div className='guild'>
                  <p className='font-size-16 text-primary text-semibold'>
                    Guilds List
                  </p>
                </div>
                <div className='guild revenue text-center'>
                  <p className='font-size-16 text-primary text-semibold'>
                    Admin/Guild
                  </p>
                </div>
              </GridItem>
              <GridItem>
                <div className='guild'>
                  <p className='font-size-16 text-primary text-semibold'>
                    Guilds List
                  </p>
                </div>
                <div className='guild revenue text-center'>
                  <p className='font-size-16 text-primary text-semibold'>
                    Admin/Guild
                  </p>
                </div>
              </GridItem>
              <GridItem>
                <div className='guild'>
                  <p className='font-size-16 text-primary text-semibold'>
                    Guilds List
                  </p>
                </div>
                <div className='guild revenue text-center'>
                  <p className='font-size-16 text-primary text-semibold'>
                    Admin/Guild
                  </p>
                </div>
              </GridItem>
            </Container>
            <DetailsContainer>
              {guilds.map(guild => {
                if (
                  guild.name
                    .toLowerCase()
                    .indexOf(filters.search.toLowerCase()) > -1
                ) {
                  return (
                    <AdminRevenueRow
                      setOpenSplitPopup={setRevenue}
                      guild={guild}
                      key={guild.id}
                    />
                  );
                } else {
                  return <></>;
                }
              })}
            </DetailsContainer>
          </>
        )}
      </div>
      {revenue && (
        <AdminGuildRevenue
          afterUpdate={handleGetGuilds}
          game={game}
          open={revenue}
          setOpen={setRevenue}
        />
      )}
    </MainContainer>
  );
};

export default RevenueList;

const MainContainer = styled.div`
  & .filters {
    margin-bottom: 12px;
    gap: 14px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    gap: 20px;
  }
`;

const GridItem = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;

  & .guild {
    padding-inline: 17px;
    height: 40px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.background.row};
    display: flex;
    align-items: center;

    &.revenue {
      padding-inline: 5px;
      justify-content: center;
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .guild {
      &.revenue {
        padding-inline: 10px;
      }
    }
  }
`;

const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0px 10px;
  margin-top: 10px;

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    gap: 0px 20px;
  }
`;
