import React from 'react';
import { ClipLoader } from 'react-spinners';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import useTheme from '../../hooks/useTheme';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';

const Loader = () => {
  const { theme } = useTheme();

  const loading = useSelector(
    (state: RootState) => state.loadingDialog.loading
  );
  return (
    <DialogLayout open={loading}>
      <Container>
        <div className='row justify-center'>
          <ClipLoader size={80} color={theme.color.text.primary} />
        </div>
        <p className='font-size-18 text-primary text-bold text-center'>
          Please wait ...
        </p>
      </Container>
    </DialogLayout>
  );
};

export default Loader;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 30px;
  width: 320px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;
`;
