import React, { FC, useState, useEffect } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../../store';
import { useParams, useLocation } from 'react-router-dom';

import SelectIcon from '../../../../assets/icons/SelectIcon';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import Select from '../../../../components/Select/Select';

import { darkTheme, lightTheme } from '../../../../theme';
import {
  IScholarship,
  IScholarshipNotificationRedirectState,
} from '../../../../types/interface';
import WithPermissions from '../../../../components/WithPermissions/WithPermissions';
import { features, modulesEnum } from '../../../../types/enums';

const SideBar: FC = () => {
  const state: IScholarshipNotificationRedirectState = useLocation().state;
  const dispatch = useDispatch<Dispatch>();

  const guildId = useParams().id;

  const newScholarShip = useSelector(
    (state: RootState) => state.scholarships.issueNewScholarship
  );

  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const scholarships = useSelector(
    (state: RootState) => state.scholarships.activeGuildScholarships
  );

  const invertedTheme = activeTheme === 'light' ? darkTheme : lightTheme;
  const theme = activeTheme === 'light' ? lightTheme : darkTheme;

  const [tab, setTab] = useState(0);
  const handleTab = (currentTab: number) => {
    setTab(currentTab);
    if (currentTab > 0) {
      dispatch.scholarships.setIssueScholarship(false);
    }
  };

  const handleIssueNewScholarship = () => {
    dispatch.scholarships.setIssueScholarship(true);
    setTab(0);
  };

  const handleScholarshipClick = (scholarship: IScholarship) => {
    dispatch.scholarships.setSelected(scholarship);
    dispatch.scholarships.setSelectedScholarshipUser(null);
    dispatch.scholarships.handleGetScholarshipUsers('');
    dispatch.scholarships.setScholarshipUsers([]);
  };

  // TODO: complete this lator (selecting scholarship and scholarship application on notification click)

  useEffect(() => {
    if (guildId) {
      dispatch.scholarships.handleGetGuildScholarships(guildId);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (scholarships.length && state?.guild_scholarship_id) {
      for (let i = 0; i < scholarships.length; i++) {
        // console.log(scholarships[i].game_id, state.game_id );
        if (scholarships[i].id === state.guild_scholarship_id) {
          setTab(i + 1);
          handleScholarshipClick(scholarships[i]);
          break;
        }
      }
    }
    // eslint-disable-next-line
  }, [scholarships]);

  return (
    <Container>
      <div className='wrapper'>
        <Filters>
          <div className='game-selector'>
            <Select
              containerClassName='select'
              label='game'
              options={[]}
              value=''
              onChange={() => {}}
            />
          </div>
          <div className='search'>
            <SearchBar className='search-input' />
          </div>
        </Filters>
        <div className='tabs'>
          {scholarships.map((scholarship, index) => (
            <Tab
              key={scholarship.id}
              className={classNames(tab === index + 1 && 'active')}
              onClick={() => {
                handleTab(index + 1);
                handleScholarshipClick(scholarship);
              }}
              style={{
                borderTopWidth: tab + 1 === index + 1 ? '0px' : '1px',
              }}
            >
              <div className='row profile'>
                <div className='img'>
                  <img src={scholarship.scholarship_logo} alt='' />
                </div>
                <p
                  className='font-size-18 text-primary text-bold capitalize'
                  style={{
                    color:
                      tab === index + 1
                        ? invertedTheme.color.text.primary
                        : theme.color.text.primary,
                  }}
                >
                  {scholarship.scholarship_name}
                </p>
              </div>

              <p
                className='font-size-14 text-secondary font-semibold capitalize'
                style={{
                  color:
                    tab === index + 1
                      ? invertedTheme.color.text.secondary
                      : theme.color.text.secondary,
                }}
              >
                {scholarship.game_name}
              </p>

              <div className='row spots'>
                <p
                  className='font-size-14 text-secondary font-semibold capitalize'
                  style={{
                    color:
                      tab === index + 1
                        ? invertedTheme.color.text.secondary
                        : theme.color.text.secondary,
                  }}
                >
                  {scholarship.badge_name}
                </p>
                <div className='icon'>
                  <SelectIcon
                    fill={
                      tab === 1
                        ? invertedTheme.color.text.primary
                        : theme.color.text.primary
                    }
                  />
                </div>
              </div>
            </Tab>
          ))}

          {newScholarShip && (
            <Tab
              className={classNames(tab === 0 && 'active')}
              onClick={() => handleTab(0)}
            >
              <p
                className='font-size-18 text-primary text-bold'
                style={{
                  color:
                    tab === 0
                      ? invertedTheme.color.text.primary
                      : theme.color.text.primary,
                }}
              >
                New Scholarship
              </p>

              <div className='row spots'>
                <div className='icon'>
                  <SelectIcon
                    fill={
                      tab === 0
                        ? invertedTheme.color.text.primary
                        : theme.color.text.primary
                    }
                  />
                </div>
              </div>
            </Tab>
          )}
        </div>
      </div>
      <WithPermissions
        moduleName={modulesEnum.Guilds}
        featureName={features.issueNewScholarship}
        action
      >
        <ActionButton
          onClick={handleIssueNewScholarship}
          variant='primary'
          className='btn'
        >
          Issue new scholarship
        </ActionButton>
      </WithPermissions>
    </Container>
  );
};

export default SideBar;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .tabs {
    padding-inline: 9px;
    flex-grow: 1;
    overflow: auto;
    height: 0px;
    padding-top: 10px;
  }

  & .btn {
    width: 100%;
    background-color: ${({ theme }) =>
      theme.color.background.button.primary.bg};
    color: ${({ theme }) => theme.color.background.button.primary.color};
    border-radius: 0px;
    height: 54px;
  }

  & .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const Filters = styled.div`
  padding: 0px 12px;
  height: 65.5px;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  display: flex;
  align-items: center;
  gap: 9px;
  border-bottom: 1px solid ${({ theme }) => theme.color.text.primary};

  & .game-selector {
    width: 142px;
    & .select {
      width: 100%;
    }
  }
  & .search {
    flex-grow: 1;

    & .search-input {
      width: 100%;
    }
  }
`;

const Tab = styled.div`
  padding: 13px 9px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #707070;
  border-top: 1px solid #707070;
  cursor: pointer;

  &:first-of-type {
    border-bottom: none;
    border-top: none;
  }

  &:last-of-type {
    border-top: none;
  }

  &.active {
    background-color: ${({ theme }) => theme.color.text.secondary};
    border-radius: 8px;
    border-bottom: none;
    border-top: none;
  }

  &:last-of-type {
    border-bottom: none;
  }

  & .profile {
    gap: 4px;
    & .img {
      height: 36px;
      width: 36px;
      background-color: ${({ theme }) => theme.color.background.row};
      border-radius: 50%;
      overflow: hidden;
      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  & .spots {
    gap: 15px;

    & .icon {
      transform: rotate(-90deg);
    }
  }
`;
