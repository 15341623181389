import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

import Select from '../../../components/Select/Select';
import SearchBar from '../../../components/SearchBar/SearchBar';
import ActionButton from '../../../components/ActionButton/ActionButton';
import { getGameFtContracts, getPayoutUsers } from '../../../api';
import { IFTContract, IPayoutUser } from '../../../types/interface';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../../hooks/useTheme';
import { rolesEnum } from '../../../types/enums';
import { IRole } from '../../../types/api';
import PayoutUserRow from './PayoutUserRow';
import MassPayout from '../../../Dialogs/MassPayout/MassPayout';

const UserList: FC = () => {
  const { theme } = useTheme();
  const guild = useSelector((state: RootState) => state.guilds.currentGuild);
  const [filters, setFilters] = useState({
    role: '',
  });

  const [search, setSearch] = useState('');

  const roles = useSelector((state: RootState) => state.roles.roles);

  const [game, setGame] = useState('');

  const [massPayout, setMassPayout] = useState(false);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const handleMassPayout = () => {
    setMassPayout(true);
  };

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IPayoutUser[]>([]);
  const [contractOptions, setContractOptions] = useState<Array<IFTContract>>(
    []
  );

  const [rolesOptions, setRolesOptions] = useState<IRole[]>([]);
  const [contract, setContract] = useState('');

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleGame = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setGame(e.currentTarget.value);
  };

  const handleGetUsers = async () => {
    try {
      setLoading(true);
      let query = `guildId=${guild?.id}&gameId=${game}`;
      if (filters.role) {
        query += `&roleId=${filters.role}`;
      }

      const { data } = await getPayoutUsers(query);
      setUsers(data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGetContracts = async () => {
    try {
      const { data } = await getGameFtContracts(game);
      setContractOptions(data);
      setContract(data[0].id);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (game) {
      handleGetContracts();
    }
    // eslint-disable-next-line
  }, [game]);

  useEffect(() => {
    if (guild?.Games.length) {
      setGame(guild.Games[0].id);
    }
    // eslint-disable-next-line
  }, [guild]);

  useEffect(() => {
    if (guild && game) {
      handleGetUsers();
    }
    // eslint-disable-next-line
  }, [guild, game, filters]);

  useEffect(() => {
    const newRoles = roles.filter(role => {
      if (
        role.name !== rolesEnum.Admin &&
        role.name !== rolesEnum.GuildManager
      ) {
        return true;
      } else {
        return false;
      }
    });
    setRolesOptions(newRoles);
    // eslint-disable-next-line
  }, [roles]);

  return (
    <Container>
      <div className='row justify-space-between'>
        <p className='font-size-18 text-semibold text-secondary'>User list</p>
        <div className='row' style={{ gap: '10px' }}>
          <Select
            label='Games'
            options={
              guild?.Games.map(game => ({
                label: game.name,
                value: game.id,
              })) || []
            }
            value={game}
            name='game'
            onChange={handleGame}
          />
          {contractOptions.length > 1 && (
            <Select
              label='Token contract'
              options={
                contractOptions.map(contract => ({
                  label: contract.name || '',
                  value: contract.id,
                })) || []
              }
              value={contract}
              onChange={e => setContract(e.currentTarget.value)}
            />
          )}

          <Select
            inputClassName='capitalize'
            label='Roles'
            options={rolesOptions.map(role => ({
              label: role.name,
              value: role.id,
            }))}
            value={filters.role}
            name='role'
            onChange={handleChange}
          />
          <SearchBar value={search} onChange={handleSearch} name='search' />
          <ActionButton
            variant='primary'
            style={{ height: '35px', borderRadius: '4px' }}
            onClick={handleMassPayout}
          >
            Mass Payout
          </ActionButton>
        </div>
      </div>
      {loading ? (
        <div className='row justify-center' style={{ height: '300px' }}>
          <ClipLoader size={50} color={theme.color.text.primary} />
        </div>
      ) : (
        <TableContainer>
          <table>
            <thead>
              <tr className='header-row'>
                <th className='font-size-12 text-primary text-semibold'>
                  Name
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Email
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Role
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Payout wallet address
                </th>
                <th className='font-size-12 text-primary text-semibold'>
                  Starz wallet address
                </th>
                {/* <th className='font-size-12 text-primary text-semibold'>
                Revenue Split
              </th> */}
                {/* <th className='font-size-12 text-primary text-semibold'>
                Total Earning
              </th> */}
                <th className='font-size-12 text-primary text-semibold'>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => {
                if (
                  user.username.toLowerCase().indexOf(search.toLowerCase()) > -1
                ) {
                  return (
                    <PayoutUserRow
                      contractId={contract}
                      user={user}
                      key={user.id}
                    />
                  );
                } else {
                  return <></>;
                }
              })}
            </tbody>
          </table>
        </TableContainer>
      )}
      {massPayout && (
        <MassPayout
          contractId={contract}
          open={massPayout}
          setOpen={setMassPayout}
          gameId={game}
        />
      )}
    </Container>
  );
};

export default UserList;

const Container = styled.div``;

const TableContainer = styled.div`
  margin-top: 13px;

  & table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    & tr {
      height: 50px;
      &.header-row {
        height: 40px;
        border: none;
        & th {
          text-align: left;
          background-color: ${({ theme }) => theme.color.background.headerRow};
          &:first-of-type {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding-left: 12px;
          }
          &:last-of-type {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-right: 12px;
          }
        }
      }
      & td {
        background-color: ${({ theme }) => theme.color.background.row};
        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          padding-left: 12px;
        }
        &:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          padding-right: 12px;
        }
        & .btn {
          background-color: ${({ theme }) =>
            theme.color.background.button.primary.bg};
          color: ${({ theme }) => theme.color.background.button.primary.color};
          height: 35px;
        }
      }
    }
  }
`;
