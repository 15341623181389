import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import Dashbaord from '../Dashbaord/Dashboard';
import ScholarDashboard from '../ScholarDashboard/ScholarDashboard';

const DashboardConfig: FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const roles = useSelector((state: RootState) => state.roles.roles);

  if (roles.find(role => role.id === user?.role_id)?.name === 'scholar') {
    return <ScholarDashboard />;
  } else {
    return <Dashbaord />;
  }
};

export default DashboardConfig;
