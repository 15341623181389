import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../../store';

import ArrowRight from '../../../../assets/icons/ArrowRight';

import { scholarshipColors } from '../../../../app.config';
import { IScholarship, IScholarshipUser } from '../../../../types/interface';

import { getScholarshipUsers } from '../../../../api';

import { lightTheme } from '../../../../theme';

interface IProps {
  index: number;
  scholarship: IScholarship;
  mintBadge: React.Dispatch<React.SetStateAction<boolean>>;
  applyScholarship: React.Dispatch<React.SetStateAction<boolean>>;
}

const Scholarship: FC<IProps> = ({
  index,
  scholarship,
  mintBadge,
  applyScholarship,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const [color, setColor] = useState('');
  const [users, setUsers] = useState<IScholarshipUser[]>([]);

  const highestBadge = useSelector(
    (state: RootState) => state.badges.userHighestBadge
  );

  const badges = useSelector((state: RootState) => state.badges.badges);

  const handleGetUsers = async () => {
    try {
      const { data } = await getScholarshipUsers(scholarship.id);
      setUsers(data);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const handleEnroll = () => {
    if (highestBadge) {
      const scholarshipBadge = badges.find(
        badge => badge.id === scholarship.badge_id
      )!;
      if (highestBadge!.stz >= scholarshipBadge.stz) {
        dispatch.scholarships.setSelected(scholarship);
        applyScholarship(true);
      } else {
        mintBadge(true);
      }
    } else {
      mintBadge(true);
    }
  };

  useEffect(() => {
    handleGetUsers();
    if (index > scholarshipColors.length - 1) {
      setColor(scholarshipColors[index % scholarshipColors.length]);
    } else {
      setColor(scholarshipColors[index]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container
      style={{
        backgroundColor: color,
      }}
    >
      <div className='row' style={{ gap: '14px', alignItems: 'stretch' }}>
        <div className='image-container'>
          <img src={scholarship.scholarship_logo} alt='' />
        </div>
        <div
          className='information'
          style={{
            flexGrow: '1',
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            className='main-row row justify-space-between'
            style={{ height: '100%' }}
          >
            <div className='row'>
              <div className='info'>
                <p className='font-size-25 text-primary text-bold'>
                  {scholarship.scholarship_name}
                </p>
                <div className='row'>
                  <img
                    src={
                      badges.find(badge => badge.id === scholarship.badge_id)
                        ?.logo || ''
                    }
                    alt=''
                  />
                  <p className='font-size-12 text-primary text-semibold'>
                    required
                  </p>
                </div>
                <p className='font-size-14 text-primary'>
                  {scholarship.spots} spots remaining
                </p>
              </div>
            </div>

            <div className='enrolment'>
              <p className='font-size-32 text-bold' style={{ color: color }}>
                {users.length}
              </p>
            </div>
          </div>
          <div className='progress-row row'>
            <ProgressBar>
              <div
                className='progress'
                style={{
                  width: `${
                    (users.length / (users.length + scholarship.spots)) * 100
                  }%`,
                }}
              ></div>
            </ProgressBar>

            <div className='row link clickable' onClick={handleEnroll}>
              <p className='font-size-12'>Enroll</p>
              <ArrowRight fill={'#ffffff'} />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Scholarship;

const Container = styled.div`
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;

  & .main-row {
    align-items: flex-start;

    & .row {
      gap: 10px;
      & .img {
        height: 92px;
        width: 92px;
        background-color: ${({ theme }) => theme.color.background.headerRow};
        border-radius: 10px;
        overflow: hidden;
        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      & .info {
        flex-grow: 1;
        & .row {
          margin-block: 5px 10px;
          gap: 6px;
          & img {
            width: 25px;
          }
        }
      }
    }

    & .enrolment {
      background-color: #ffffff;
      padding: 3px 10px;
      border-radius: 10px;
      & p {
        font-size: 32px;
        line-height: 48px;
      }
    }
  }
  & .progress-row {
    gap: 18px;
    width: 100%;
    margin-top: 20px;
    & .row.link {
      gap: 5px;
      & p {
        color: #ffffff;
      }
    }
  }
  & .image-container {
    height: 150px;
    aspect-ratio: 1/1;
    border-radius: 8px;
    overflow: hidden;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    & .image-container {
      height: 186px;
    }
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 3.5px;
  background-color: #ffffff66;
  position: relative;

  & .progress {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
    height: 100%;
    border-radius: 3.5px;
    transition: 0.4s ease;
  }
`;
