import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  addScholarship,
  addScholarshipImage,
  getGuildScholarships,
  getScholarshipUsers,
  updateScholarshipStatus,
} from '../../api';
import { IScholarship, IScholarshipUser } from '../../types/interface';
import {
  IAddScholarshipPayload,
  IUpdateScholarshipStatus,
} from '../../types/storeModels';
import { toast } from 'react-toastify';

interface IState {
  loading: boolean;
  issueNewScholarship: boolean;
  activeGuildScholarships: IScholarship[];
  selected: IScholarship | null;
  scholarshipUsers: IScholarshipUser[];
  selectedScholarshipUser: IScholarshipUser | null;
}

export const scholarships = createModel<RootModel>()({
  name: 'scholarships',
  state: {
    loading: false,
    issueNewScholarship: false,
    activeGuildScholarships: [],
    selected: null,
    scholarshipUsers: [],
    selectedScholarshipUser: null,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setIssueScholarship(state, payload: boolean) {
      state.issueNewScholarship = payload;
    },
    setActiveGuildScholarships(state, payload: IScholarship[]) {
      state.activeGuildScholarships = payload;
    },
    setSelected(state, payload: IScholarship | null) {
      state.selected = payload;
    },
    setScholarshipUsers(state, payload: IScholarshipUser[]) {
      state.scholarshipUsers = payload;
    },
    setSelectedScholarshipUser(state, payload: IScholarshipUser | null) {
      state.selectedScholarshipUser = payload;
    },
  },
  effects: dispatch => ({
    async handleAddScholarship(payload: IAddScholarshipPayload, state) {
      try {
        const { data, image } = payload;
        dispatch.scholarships.setLoading(true);
        const { data: res } = await addScholarship(data);

        if (image) {
          const formImage = new FormData();
          formImage.append('image', image);
          await addScholarshipImage(formImage, res.id);
        }
        dispatch.scholarships.setIssueScholarship(false);
        dispatch.scholarships.handleGetGuildScholarships(
          state.guilds.currentGuild!.id
        );
        toast.success('New scholarship created successfully.');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.scholarships.setLoading(false);
      }
    },
    async handleGetGuildScholarships(payload?: string) {
      try {
        dispatch.scholarships.setLoading(true);
        const { data } = await getGuildScholarships(payload);
        dispatch.scholarships.setActiveGuildScholarships(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.scholarships.setLoading(false);
      }
    },
    async handleGetScholarshipUsers(_ = '', state) {
      try {
        dispatch.scholarships.setLoading(true);
        const scholarshipId = state.scholarships.selected?.id;
        if (scholarshipId) {
          const { data } = await getScholarshipUsers(scholarshipId);
          dispatch.scholarships.setScholarshipUsers(data);
        }
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.scholarships.setLoading(false);
      }
    },
    async handleUpdateScholarshipStatus(payload: IUpdateScholarshipStatus) {
      const { data, scholarshipId } = payload;
      try {
        dispatch.scholarships.setLoading(true);
        await updateScholarshipStatus(data, scholarshipId);
        dispatch.scholarships.handleGetScholarshipUsers('');
        dispatch.scholarships.setSelectedScholarshipUser(null);

        toast.success(
          `Scholarship ${
            data.status === 1 ? 'accepted' : 'rejected'
          } successfully.`
        );
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.scholarships.setLoading(false);
      }
    },
  }),
});
