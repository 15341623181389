import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { InferType, mixed, object, string } from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import CrossIcon from '../../assets/icons/CrossIcon';
import DialogLayout from '../../layouts/DialogLayout/DialogLayout';
import InputField from '../../components/InputField/InputField';
import MultiLineInput from '../../components/MultiLineInput/MultilineInput';
import ActionButton from '../../components/ActionButton/ActionButton';
import ImagePicker from './ImagePicker';

import { lightTheme as theme } from '../../theme';
import { FormErrors, validateData } from '../../utils/validations';
import classNames from 'classnames';

const schema = object({
  name: string().required().label('Name'),
  short_description: string().required().label('Short Description'),
  description: string().required().label('Long Description'),
  domain: string().url().required().label('Domain'),
});

const imgaeSchema = object({
  gameLogo: mixed().required().label('Game logo'),
  gameIcon: mixed().required().label('Game icon'),
});

interface IFormData extends InferType<typeof schema> {}
interface IImageData extends InferType<typeof imgaeSchema> {}

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}

const AddNewGame: FC<IProps> = ({ open, setOpen, onClose }) => {
  const dispatch = useDispatch<Dispatch>();

  const loading = useSelector((state: RootState) => state.games.loading);
  const badges = useSelector((state: RootState) => state.badges.badges);

  const [gameLogo, setGameLogo] = useState<File | null>(null);
  const [gameIcon, setGameIcon] = useState<File | null>(null);
  const [selectedBadges, setSelectedBadges] = useState<string[]>([]);

  const [formData, setFormData] = useState<IFormData>({
    name: '',
    short_description: '',
    description: '',
    domain: '',
  });

  const [errors, setErrors] = useState<FormErrors<IFormData | null>>(null);
  const [imageErrors, setImageErrors] =
    useState<FormErrors<IImageData | null>>(null);
  const [badgeError, setBadgeError] = useState('');

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: null }));
  };

  const handleSelectBadge = (id: string) => {
    let badgeList = [...selectedBadges];
    if (badgeList.indexOf(id) > -1) {
      badgeList = badgeList.filter(c => c !== id);
    } else {
      badgeList.push(id);
    }
    setSelectedBadges(badgeList);
  };

  const handleAddGame = async () => {
    setErrors(null);
    setImageErrors(null);
    setBadgeError('');
    let hasError = false;
    const { errors: validationErrors } = await validateData(schema, formData);
    if (validationErrors) {
      setErrors(validationErrors);
      hasError = true;
    }

    const { errors: imageValidationErrors } = await validateData(imgaeSchema, {
      gameIcon,
      gameLogo,
    });

    if (imageValidationErrors) {
      setImageErrors(imageValidationErrors);
      hasError = true;
    }

    if (!selectedBadges.length) {
      setBadgeError('Atleast one badge is required');
      hasError = true;
    }

    if (hasError) {
      return;
    } else {
      hasError = false;

      if (gameIcon && gameLogo) {
        dispatch.games.handleAddGame({
          data: { ...formData, gameIcon, gameLogo, selectedBadges },
          setOpen,
        });
      }
    }
  };

  useEffect(() => {
    console.log(gameIcon);
    if (gameIcon) {
      setImageErrors(prev => ({ ...prev, gameIcon: '' }));
    }
    if (gameLogo) {
      setImageErrors(prev => ({ ...prev, gameLogo: '' }));
    }
    if (selectedBadges.length) {
      setBadgeError('');
    }
  }, [gameIcon, gameLogo, selectedBadges]);

  useEffect(() => {
    dispatch.badges.handleGetAllBadges();
    // eslint-disable-next-line
  }, []);

  return (
    <DialogLayout open={open} onClose={onClose}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>Add New Game</p>
          <div className='icon clickable' onClick={() => setOpen(false)}>
            <CrossIcon />
          </div>
        </div>
        <FlexContainer>
          <div className='left'>
            <div className='row' style={{ gap: '20px' }}>
              <InputField
                label='Name'
                placeholder='Riot Racers'
                type='text'
                name='name'
                value={formData.name}
                onChange={handleChange}
                error={errors?.name}
              />
              <InputField
                label='Domain'
                placeholder='www.abc.com'
                type='text'
                name='domain'
                value={formData.domain}
                onChange={handleChange}
                error={errors?.domain}
              />
            </div>

            <div className='badge-req'>
              <p className='font-size-14'>
                Badge requirement
                {badgeError && (
                  <span className='text-danger'>{badgeError}</span>
                )}
              </p>
            </div>
            <BadgeSelectionGrid>
              {badges.map(badge => (
                <Badge
                  key={badge.id}
                  onClick={() => handleSelectBadge(badge.id)}
                  className={classNames(
                    'clickable',
                    selectedBadges.indexOf(badge.id) > -1 && 'active'
                  )}
                >
                  <img src={badge.logo || ''} alt='' />
                  <p
                    className={classNames(
                      'font-size-20 text-primary text-semibold capitalize',
                      selectedBadges.indexOf(badge.id) > -1 && 'active'
                    )}
                  >
                    {badge.name}
                  </p>
                </Badge>
              ))}
            </BadgeSelectionGrid>
          </div>
          <MultiLineInput
            label='Short Game Description'
            placeholder='Sorare allows players to experience football like never before – bringing fantasy'
            rows={5}
            name='short_description'
            value={formData.short_description}
            onChange={handleChange}
            error={errors?.short_description}
          />
          <MultiLineInput
            label='Full Game Description'
            placeholder='Sorare allows players to experience football like never before – bringing fantasy
                to reality. Buy, sell or trade digital player cards to create the perfect roster and 
                use your skill to manage the virtual team. You’re able to build strategies, create 
                lineups and earn points based on your players’ real-life performances. Players 
                that are part of 99Starz dominate the leaderboards as strategic masterminds.'
            rows={7}
            name='description'
            value={formData.description}
            onChange={handleChange}
            error={errors?.description}
          />
          <div className='row justify-space-between'>
            <ImagePicker
              placeholder='or drag
                and drop game logo'
              file={gameLogo}
              setFile={setGameLogo}
              error={imageErrors?.gameLogo}
            />
            <ImagePicker
              placeholder='or drag
                and drop game icon'
              file={gameIcon}
              setFile={setGameIcon}
              error={imageErrors?.gameIcon}
            />
          </div>
        </FlexContainer>
        <div className='dividers'></div>
        <ButtonContainer>
          <ActionButton
            onClick={() => setOpen(false)}
            variant='primary'
            className='action-btn btn-primary'
            disabled={loading}
          >
            Cancel
          </ActionButton>
          <ActionButton
            variant='primary'
            onClick={handleAddGame}
            className='action-btn btn-secondary'
            disabled={loading}
            loading={loading}
          >
            Add Game
          </ActionButton>
        </ButtonContainer>
      </Container>
    </DialogLayout>
  );
};

export default AddNewGame;

const Container = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.color.background.card};
  width: 670px;
  border-radius: 12px;

  & .dividers {
    height: 1px;
    background-color: ${({ theme }) => theme.color.background.row};
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    width: 670px;
    padding: 30px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-block: 30px 20px;

  & .badge-req {
    margin-block: 30px 20px;
  }
  & .left {
    flex: 1;
    flex-shrink: 0;
  }

  & .divider {
    width: 1px;
    background-color: ${({ theme }) => theme.color.background.row};
  }

  & .right {
    flex: 1;
    flex-shrink: 0;
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    margin-block: 50px 30px;
  }
`;
// eslint-disable-next-line
const FieldContainer = styled.div`
  margin-block: 29px;

  &.right {
    margin-block: 15px 15px;
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    margin-block: 29px;
    &.right {
      margin-block: 39px 19px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 18px;
  margin-top: 15px;

  & .action-btn {
    border-radius: 20px;
    height: 44px;
    width: 150px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    margin-top: 20px;
    & .action-btn {
      border-radius: 30px;
      height: 55px;
      width: 189px;
    }
  }
`;

const BadgeSelectionGrid = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    gap: 12px;
  }
`;

const Badge = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.background.row};
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  position: relative;
  padding: 10px;
  overflow: hidden;

  &.active {
    background-color: ${({ theme }) =>
      theme.color.background.button.primary.bg};
  }

  & p.active {
    color: ${({ theme }) => theme.color.background.button.primary.color};
  }

  cursor: pointer;
  & img {
    width: 70px;
  }

  & .icon {
    /* position: absolute;
    top: 10px;
    right: 10px; */
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    gap: 10px;
    & img {
      width: 97px;
    }
  }
`;
