import React, { FC, useMemo } from 'react';

import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import UploadIcon from '../../assets/icons/UploadIcon';

interface IProps {
  screenshots: Array<any>;
  addedScreenshots: File[];
  setAddedScreenshots: React.Dispatch<React.SetStateAction<File[]>>;
  screenshotError?: string;
}

const ScreenshotPicker: FC<IProps> = ({
  screenshots,
  setAddedScreenshots,
  addedScreenshots,
  screenshotError,
}) => {
  const {
    getInputProps,
    getRootProps,
    isDragAccept,
    isDragActive,
    isDragReject,
    open,
  } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
    },
    maxFiles: 8 - (screenshots.length + addedScreenshots.length),
    maxSize: 2097152,
    onDrop: acceptedFiles => {
      const acceptedImages: File[] = [...addedScreenshots];
      if (acceptedImages.length + screenshots.length === 8) return;
      acceptedFiles.forEach(file => {
        let extension = file.name.split('.');
        const exten = extension[extension.length - 1];
        if (exten === 'png' || exten === 'jpg' || exten === 'jpeg') {
          acceptedImages.push(file);
        }
        setAddedScreenshots(acceptedImages);
      });
    },
    disabled: 8 - (screenshots.length + addedScreenshots.length) === 0,
  });

  const baseStyle = {
    height: '100%',
    width: '100%',
    paddingInline: '24px',
    borderRadius: '50%',
    display: 'flex',
    gap: '9px',
    justifyContent: 'center',
    alignItems: 'center',
    // ...customBaseStyles,
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
    // customRejectStyles,
  };

  const style = useMemo(
    () => ({
      ...baseStyle,

      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragActive, isDragReject, isDragAccept, open]
  );
  return (
    <Container>
      <div {...getRootProps({ style })} className='root'>
        <input {...getInputProps()} />
        <div className='icon'>
          <UploadIcon />
        </div>
        <p className='font-size-12 text-primary text-center'>
          <b>Click to upload</b> or drag <br /> and drop game logo <br />
          maximum <b>
            {8 - (screenshots.length + addedScreenshots.length)}
          </b>{' '}
          images
        </p>
        {screenshotError && (
          <p className='font-size-14 text-danger' style={{ marginTop: '10px' }}>
            {screenshotError}
          </p>
        )}
      </div>
    </Container>
  );
};

export default ScreenshotPicker;

const Container = styled.div`
  height: 100%;
  width: 100%;
  min-height: 250px;
  background-color: ${({ theme }) => theme.color.background.headerRow};
  border-radius: 10px;
  border: 1px dashed ${({ theme }) => theme.color.text.primary};

  & .root {
    flex-direction: column;

    & .icon {
      background-color: ${({ theme }) => theme.color.text.primary};
      height: 55px;
      width: 55px;
      border-radius: 50%;
      cursor: pointer;
      display: grid;
      place-items: center;
    }
  }
`;
