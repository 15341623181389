import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  active: boolean;
  [x: string]: any;
}

const GuildIcon: FC<IProps> = ({ active, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='32'
      height='28'
      viewBox='0 0 32 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M15.988 27.0906C13.9513 27.0906 11.9146 27.0906 9.87798 27.0906C8.82098 27.0906 8.55098 26.8156 8.54998 25.7516C8.54998 22.8836 8.54098 20.0156 8.54998 17.1516C8.5069 16.4944 8.60421 15.8356 8.83545 15.2189C9.06668 14.6023 9.42653 14.0419 9.89108 13.5751C10.3556 13.1083 10.9142 12.7457 11.5298 12.5115C12.1453 12.2772 12.8036 12.1767 13.461 12.2166C15.1443 12.2072 16.8276 12.2072 18.511 12.2166C19.1683 12.1766 19.8267 12.277 20.4423 12.5111C21.0578 12.7452 21.6165 13.1076 22.0812 13.5744C22.5458 14.0411 22.9058 14.6014 23.1371 15.218C23.3685 15.8346 23.4659 16.4934 23.423 17.1506C23.433 20.0186 23.423 22.8856 23.423 25.7506C23.423 26.8146 23.152 27.0876 22.095 27.0896C20.0583 27.0936 18.0216 27.0936 15.985 27.0896'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
      <path
        d='M21.516 5.54223C21.5132 6.6372 21.1853 7.70668 20.5739 8.61508C19.9626 9.52348 19.0953 10.2299 18.0819 10.6448C17.0686 11.0597 15.9549 11.1643 14.882 10.9455C13.8091 10.7266 12.8254 10.1942 12.0555 9.41557C11.2856 8.63696 10.7642 7.64729 10.5575 6.57202C10.3507 5.49674 10.4679 4.38429 10.8941 3.37569C11.3204 2.36709 12.0365 1.50777 12.9517 0.90666C13.867 0.305552 14.9401 -0.0102706 16.035 -0.000768272C17.4967 0.00769583 18.8952 0.596215 19.9232 1.63543C20.9512 2.67465 21.5244 4.07951 21.517 5.54123'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
      <path
        d='M6.72127 27.0215C5.26627 27.0215 3.77027 27.0985 2.28727 26.9975C1.67908 26.9242 1.11787 26.6336 0.707125 26.1791C0.296382 25.7246 0.0638023 25.1369 0.0522721 24.5245C-0.00272788 22.6355 -0.0307279 20.7405 0.0522721 18.8535C0.128153 17.7432 0.61176 16.7001 1.41012 15.9249C2.20849 15.1496 3.26525 14.6968 4.37727 14.6535C5.24427 14.6175 6.11427 14.6475 6.93827 14.6475C6.49427 18.7875 6.82627 22.8905 6.71727 27.0255'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
      <path
        d='M25.2464 27.0526C25.1464 22.9116 25.4734 18.8056 25.0234 14.5576C26.2415 14.5612 27.4574 14.6588 28.6604 14.8496C29.6035 15.1018 30.4368 15.6588 31.0304 16.4338C31.624 17.2088 31.9447 18.1584 31.9424 19.1346C31.9674 20.8346 31.9634 22.5426 31.9424 24.2456C31.9599 24.5954 31.9081 24.9452 31.7899 25.2749C31.6718 25.6046 31.4897 25.9077 31.254 26.1668C31.0183 26.4259 30.7338 26.6358 30.4167 26.7846C30.0997 26.9334 29.7563 27.018 29.4064 27.0336C28.0064 27.1216 26.5964 27.0526 25.2454 27.0526'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
      <path
        d='M6.24791 13.4418C5.43198 13.4396 4.63494 13.1959 3.95725 12.7415C3.27956 12.2871 2.75153 11.6423 2.43971 10.8883C2.12788 10.1343 2.04621 9.30486 2.20499 8.50452C2.36376 7.70418 2.75588 6.96875 3.33193 6.39089C3.90798 5.81304 4.64219 5.41861 5.44202 5.25733C6.24186 5.09605 7.07154 5.17512 7.82651 5.48458C8.58149 5.79405 9.22797 6.32005 9.6845 6.99632C10.141 7.67258 10.3872 8.46885 10.3919 9.28477C10.3995 9.83152 10.2977 10.3743 10.0925 10.8811C9.8872 11.3879 9.58263 11.8485 9.19668 12.2359C8.81073 12.6232 8.3512 12.9295 7.84513 13.1366C7.33906 13.3437 6.79668 13.4474 6.24991 13.4418'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
      <path
        d='M29.8516 9.36247C29.8418 10.1812 29.589 10.9785 29.1253 11.6533C28.6616 12.3281 28.008 12.85 27.2473 13.1527C26.4866 13.4555 25.6531 13.5255 24.8525 13.3539C24.052 13.1822 23.3204 12.7767 22.7507 12.1887C22.181 11.6006 21.7987 10.8567 21.6524 10.0511C21.5062 9.24553 21.6024 8.41466 21.9291 7.66389C22.2557 6.91311 22.7979 6.27626 23.487 5.83413C24.1762 5.39199 24.981 5.16449 25.7996 5.18048C26.3407 5.1875 26.8751 5.30141 27.372 5.51567C27.869 5.72993 28.3186 6.0403 28.6952 6.42894C29.0718 6.81758 29.3678 7.27683 29.5662 7.78027C29.7647 8.28371 29.8617 8.82142 29.8516 9.36247Z'
        fill={active ? theme.color.icon.active : theme.color.icon.inActive}
      />
    </svg>
  );
};

export default GuildIcon;
