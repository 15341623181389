import React, { useState, FC, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { string, object, array, InferType } from 'yup';

import CrossIcon from '../../assets/icons/CrossIcon';
import ActionButton from '../../components/ActionButton/ActionButton';
import InputField from '../../components/InputField/InputField';

import DialogLayout from '../../layouts/DialogLayout/DialogLayout';
import SocialLink from './SocialLink';

import { lightTheme as theme } from '../../theme';
import { FormErrors, validateData } from '../../utils/validations';
import { updateUser } from '../../api';

interface ISocial {
  social_id: string;
  url: string;
  id: string;
}

const socialInitData: ISocial = {
  social_id: '',
  url: '',
  id: '',
};

const schemaObject = object({
  social_id: string().required('Please select a social media'),
  url: string().url().required().label('URL'),
  id: string(),
});

const userSchema = object({
  username: string().required().label('Username'),
  email: string().email().required().label('Email'),
  wallet_address: string().required().label('Wallet address'),
  dob: string().required().label('Date of birth'),
});

const schema = array().of(schemaObject);

interface ISocialConfig extends InferType<typeof schemaObject> {}

interface IUserData extends InferType<typeof userSchema> {}

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditProfile: FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();
  const socialLinks = useSelector(
    (state: RootState) => state.users.currentUserSocialLinks
  );
  const user = useSelector((state: RootState) => state.auth.user);
  const loading = useSelector((state: RootState) => state.users.loading);
  const [socialMediaConfig, setSocialMediaConfig] = useState<ISocial[]>([]);
  const [formData, setFormData] = useState<IUserData>({
    username: user?.username || '',
    wallet_address: user?.wallet_address || '',
    email: user?.email || '',
    dob: user?.dob ? new Date(user.dob).toLocaleDateString('sv-SE') : '',
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleAddSocialMediaLink = () => {
    setSocialMediaConfig([...socialMediaConfig, { ...socialInitData }]);
  };

  const [errors, setErrors] = useState<FormErrors<IUserData | null>>(null);

  const [socialErrors, setSocialErrors] =
    useState<FormErrors<ISocialConfig | null>>(null);

  const handleChange = (
    index: number,
    url?: string,
    socialMedia_id?: string
  ) => {
    const socialList = [...socialMediaConfig];
    if (url) {
      if (url === 'null') {
        socialList[index].url = '';
      } else {
        socialList[index].url = url;
      }
    }
    if (socialMedia_id) {
      if (socialMedia_id === 'null') {
        socialList[index].social_id = '';
      } else {
        socialList[index].social_id = socialMedia_id;
      }
    }
    setSocialMediaConfig(socialList);
  };

  const handleSaveChanges = async () => {
    setSocialErrors(null);
    setErrors(null);
    let hasError = false;
    const { errors: userErrors } = await validateData<IUserData>(
      userSchema,
      formData
    );
    if (userErrors) {
      setErrors(userErrors);
    }
    const { errors: validationErrors } = await validateData<ISocialConfig[]>(
      schema,
      socialMediaConfig
    );
    if (validationErrors) {
      hasError = true;
      // @ts-ignore
      setSocialErrors(validationErrors);
    }

    if (!hasError) {
      const { username, email, ...apiData } = formData;
      await updateUser(user!.id, apiData);
      dispatch.users.handleAddUserSocialLink({
        data: socialMediaConfig.map(social => ({
          social_id: social.social_id,
          url: social.url,
          id: social.id,
        })),
        setOpen,
      });
    }
  };

  useEffect(() => {
    dispatch.config.handleGetsocialLinks();
    dispatch.users.handleGetUserSocialLinks('');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSocialMediaConfig([
      ...socialLinks.map(social => ({
        social_id: social.social_id,
        url: social.url,
        id: social.id || '',
      })),
    ]);
    // eslint-disable-next-line
  }, [socialLinks]);

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>Edit Profile</p>
          <div className='icon clickable' onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <Grid>
          <InputField
            label='Username'
            type='text'
            onChange={handleChangeUser}
            value={formData.username}
            name='username'
            error={errors?.username}
          />
          <InputField
            label='Wallet address'
            type='text'
            onChange={handleChangeUser}
            value={formData.wallet_address}
            name='wallet_address'
            error={errors?.wallet_address}
          />
          <InputField
            label='Email'
            type='email'
            readOnly
            onChange={handleChangeUser}
            value={formData.email}
            name='email'
            error={errors?.email}
          />
          <InputField
            label='Date of Birth'
            type='date'
            onChange={handleChangeUser}
            value={formData.dob}
            name='dob'
            error={errors?.dob}
          />
        </Grid>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-bold'>
            Social Media profiles
          </p>
          <div className='btn'>
            {!(socialMediaConfig.length === 5) && (
              <ActionButton
                variant='secondary'
                onClick={handleAddSocialMediaLink}
              >
                + Add new
              </ActionButton>
            )}
          </div>
        </div>
        <Grid>
          {socialMediaConfig.map((_, index) => (
            <SocialLink
              key={index}
              socialMediaConfig={socialMediaConfig}
              index={index}
              handleChange={handleChange}
              setSocialMediaConfig={setSocialMediaConfig}
              // @ts-ignore
              error={
                socialErrors &&
                `${
                  // @ts-ignore
                  (socialErrors[`[${index}].social_id`],
                  // @ts-ignore
                  socialErrors[`[${index}].url`])
                }`
              }
            />
          ))}
        </Grid>
        <div className='divider'></div>
        <ButtonContainer>
          <ActionButton
            onClick={handleClose}
            variant='primary'
            className='btn btn-primary'
            disabled={loading}
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleSaveChanges}
            variant='primary'
            className='btn btn-secondary'
            disabled={loading}
            loading={loading}
          >
            Save
          </ActionButton>
        </ButtonContainer>
      </Container>
    </DialogLayout>
  );
};

export default EditProfile;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 30px;
  border-radius: 12px;
  width: 845px;

  & .divider {
    border-top: 1px solid ${({ theme }) => theme.color.text.secondary + '66'};
    margin-block: 50px 30px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px 20px;
  margin-block: 30px 32px;

  & label {
    font-weight: 700;
    color: ${({ theme }) => theme.color.text.primary}!important;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  & .btn {
    height: 44px;
    width: 150px;
    border-radius: 20px;

    &.btn-primary {
      background-color: ${({ theme }) => theme.color.background.headerRow};
      color: ${({ theme }) => theme.color.background.card};
    }

    &.btn-secondary {
      background-color: ${({ theme }) => theme.color.text.primary};
      color: ${({ theme }) => theme.color.background.card};
    }
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .btn {
      height: 55px;
      width: 198px;
      border-radius: 30px;
    }
  }
`;
