import React, { FC } from 'react';
import PermissionGroup from '../../../components/PermissionGroup/PermissionGroup';
import usePermissions from '../../../hooks/usePermissions';
import { IFeature } from '../../../types/interface';

interface IProps {
  feature: IFeature;
}

const RolesRow: FC<IProps> = ({ feature }) => {
  const { roles, rolePermissions } = usePermissions();
  return (
    <tr>
      <td className='font-size-14'>
        <div className='inner capitalize'>{feature.name}</div>
      </td>
      <td className='spacing-data'></td>
      {roles.map(role => {
        return rolePermissions.map(rolePermission => {
          if (
            rolePermission.role_id === role.id &&
            rolePermission.feature_id === feature.id
          ) {
            return (
              <td key={rolePermission.id}>
                <div className='inner'>
                  <PermissionGroup activePermission={rolePermission} />
                </div>
              </td>
            );
          } else {
            return null;
          }
        });
      })}
    </tr>
  );
};

export default RolesRow;
