import React, { FC, useState } from 'react';

import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import OtpInput from '@unit-finance/react-otp-input';

import Button from '../../components/Button/Button';

import { lightTheme, darkTheme } from '../../theme';

const EmailVerification: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const loading = useSelector((state: RootState) => state.auth.loading);
  const theme = activeTheme === 'light' ? lightTheme : darkTheme;
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  const handleChange = (otp: string) => {
    setOtp(otp);
    if (otp.length < 6) {
      setError('Invalid otp');
    } else {
      setError('');
    }
  };

  const handleVerify = () => {
    setError('');
    if (otp.length < 6) {
      return setError('Invalid otp');
    }
    dispatch.auth.handleEmailVerification({
      data: { code: otp },
      navigate,
      setError,
    });
  };

  return (
    <Card>
      <h1 className='font-size-50 text-primary text-bold'>Verify Email</h1>
      <p className='font-size-18 text-primary subtitle'>
        An Email with a verification code has been sent to your email address
      </p>

      <OTPContainer>
        <OtpInput
          onChange={handleChange}
          shouldAutoFocus
          hasErrored={!!error}
          numInputs={6}
          value={otp}
          isInputNum={true}
          containerStyle={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(6,1fr)',
            gap: '20px',
          }}
          inputStyle={{
            width: '100%',
            aspectRatio: '1/1',
            border: 'none',
            outline: 'none',
            backgroundColor: theme.color.background.row,
            borderRadius: '8px',
            fontSize: '18px',
            color: theme.color.text.primary,
          }}
          errorStyle={{
            border: `1px solid ${theme.color.text.danger}`,
          }}
        ></OtpInput>
        {error && <p className='font-size-16 text-danger'>{error}</p>}
      </OTPContainer>

      <p className='font-size-14 text-center text-dimmer '>
        Didn’t receive a code?{' '}
        <Link to='/' className='anchor text-dim'>
          Request again
        </Link>
      </p>
      <Button
        label='Verify Email'
        onClick={handleVerify}
        className='btn'
        variant='primary'
        disabled={loading}
        loading={loading}
      />
      <p className='font-size-14 text-center text-dimmer '>
        Have an account?{' '}
        <Link to='/login' className='anchor text-dim'>
          Sign In
        </Link>
      </p>
    </Card>
  );
};

export default EmailVerification;

const Card = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  padding: 25px 20px;
  border-radius: 12px;
  width: 400px;

  & .subtitle {
    margin-top: 17px;
  }

  & .btn {
    margin-block: 30px 11px;
    max-width: 100%;
  }

  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    width: 500px;
  }
`;

const OTPContainer = styled.div`
  margin-block: 30px 20px;
`;
