import React, { useEffect } from 'react';

import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from './store';
import { BrowserRouter } from 'react-router-dom';

import GlobalStyles from './theme/GlobalStyles';
import { darkTheme, lightTheme } from './theme';

import Router from './router';
import { ClipLoader } from 'react-spinners';
import useTheme from './hooks/useTheme';

const App = () => {
  const dispatch = useDispatch<Dispatch>();
  const { theme } = useTheme();
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const userPermissionsLoading = useSelector(
    (state: RootState) => state.permissions.userPermissionLoading
  );
  const allPermissionsLoading = useSelector(
    (state: RootState) => state.permissions.loading
  );
  const userLoading = useSelector((state: RootState) => state.auth.userLoading);

  const handleThemeChange = (payload: any) => {
    const theme = localStorage.getItem('preferedTheme');
    if (theme === 'system') {
      if (payload?.matches) {
        dispatch.theme.setTheme('dark');
      } else {
        dispatch.theme.setTheme('light');
      }
    }
  };

  const getSystemTheme = () => {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme:dark)').matches
    ) {
      dispatch.theme.setTheme('dark');
      return 'dark';
    } else {
      dispatch.theme.setTheme('light');
      return 'light';
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const theme = localStorage.getItem('preferedTheme');
    if (token) {
      dispatch.roles.handleGetAllRoles();
      dispatch.auth.getCurrentuser();
      dispatch.roles.handleGetAllRoles();
      dispatch.config.handleGetsocialLinks();
      dispatch.permissions.handleGetAllPermissionsConfig();
      dispatch.permissions.handleGetUserPermissions();
    }

    if (theme === 'system') {
      getSystemTheme();
    }

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', handleThemeChange);

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', handleThemeChange);
    };
    // eslint-disable-next-line
  }, []);

  if (userPermissionsLoading || allPermissionsLoading || userLoading) {
    return (
      <ThemeProvider theme={activeTheme === 'dark' ? darkTheme : lightTheme}>
        <Wrapper>
          <GlobalStyles />
          <Loading>
            <ClipLoader size={100} color={theme?.color?.text?.primary} />
            <p className='font-size-30 text-primary text-bold'>
              Please wait!...
            </p>
          </Loading>
        </Wrapper>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={activeTheme === 'dark' ? darkTheme : lightTheme}>
      <Wrapper>
        <BrowserRouter>
          <GlobalStyles />
          <Router />
        </BrowserRouter>
        <ToastContainer />
      </Wrapper>
    </ThemeProvider>
  );
};

export default App;

const Wrapper = styled.div`
  .text-primary {
    color: ${({ theme }) => theme.color.text.primary};
  }
  .text-secondary {
    color: ${({ theme }) => theme.color.text.secondary};
  }
  .text-dim {
    color: ${({ theme }) => theme.color.text.dim};
  }
  .text-dimmer {
    color: ${({ theme }) => theme.color.text.dimmer};
  }
  .text-blend {
    color: ${({ theme }) => theme.color.icon.inActive};
  }
  .text-danger {
    color: ${({ theme }) => theme.color.text.danger};
  }
`;

const Loading = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.background.card};
`;
