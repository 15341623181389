import React, { FC, useState } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

import SelectIcon from '../../../../assets/icons/SelectIcon';
import ArrowIcon from '../../../../assets/icons/SelectIcon';
import RevenueSplit from './RevenueSplit/RevenueSplit';
import Scholarships from './Scholarships/Scholarships';
import GamesRow from './GamesRow';

import assets from '../../../../assets';
import { lightTheme as theme } from '../../../../theme';
import { features, modulesEnum } from '../../../../types/enums';
import AddGameToGuild from '../../../../Dialogs/AddGameToGuild/AddGameToGuild';
import WithPermissions from '../../../../components/WithPermissions/WithPermissions';

const GuildGamesAndTeams: FC = () => {
  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  const [open, setOpen] = useState(false);
  const [addGame, setAddGame] = useState(false);

  const handleAddGame = () => {
    setAddGame(true);
  };
  return (
    <Container className={classNames(!open && 'close')}>
      <div className='wrapper' style={{ overflow: 'hidden' }}>
        {open && (
          <div className='row justify-space-between'>
            <div className='row'>
              <p className='font-size-25 text-primary text-semibold'>
                Games&nbsp;
              </p>
              <p className='font-size-16 text-primary text-semibold'>(06)</p>
            </div>
            <WithPermissions
              moduleName={modulesEnum.Guilds}
              featureName={features.addGamesInGuild}
            >
              <p
                className='font-size-16 text-primary text-semibold clickable'
                onClick={handleAddGame}
              >
                +Add
              </p>
            </WithPermissions>
          </div>
        )}

        <div className='gameContainer'>
          <GamesContainer className={classNames(!open && 'close')}>
            {open ? (
              <>
                {currentGuild?.Games?.map(game => (
                  <GamesRow game={game} key={game.id} />
                ))}
              </>
            ) : (
              <GameRow className='game-row'>
                <div className='game-name'>
                  <div className='icon'></div>
                  <p className='font-size-12 text-primary text-semibold'>
                    CryptoMotors
                  </p>
                </div>
                <div className='count'>
                  <div className='count-key'>
                    <p className='font-size-15 text-semibold'>S</p>
                  </div>
                  <p className='font-size-18 text-primary text-semibold'>20</p>
                </div>
                <div className='count'>
                  <div className='count-key'>
                    <p className='font-size-15 text-semibold'>C</p>
                  </div>
                  <p className='font-size-18 text-primary text-semibold'>02</p>
                </div>
                <div className='earning-col'>
                  <img src={assets.stz} alt='' />
                  <p className='font-size-14 text-primary text-bold'>
                    4,000 STZ
                  </p>
                  <span className='font-size-8 text-dim'>≈ 150.69 USD</span>
                </div>
              </GameRow>
            )}
          </GamesContainer>
        </div>
        <WithPermissions
          moduleName={modulesEnum.Guilds}
          featureName={features.guildUserRevenueSplit}
        >
          <RevenueSplit isPanelOpen={open} />
        </WithPermissions>
        <WithPermissions
          moduleName={modulesEnum.Guilds}
          featureName={features.issueNewScholarship}
        >
          <Scholarships isPanelOpen={open} />
        </WithPermissions>
        <Team>
          {open ? (
            <>
              <div className='row justify-center'>
                <div className='arrow-icon left-icon'>
                  <ArrowIcon />
                </div>
                <div>
                  <p className='font-size-25 text-primary text-semibold'>
                    Team A
                  </p>
                  <p className='font-size-10 text-primary text-center'>
                    25 members
                  </p>
                </div>
                <div className='arrow-icon right-icon'>
                  <ArrowIcon />
                </div>
              </div>

              <div className='captain-row row justify-space-between'>
                <div className='row'>
                  <div className='image'>
                    <img src='' alt='' />
                  </div>
                  <p className='font-size-14 text-primary text-semibold'>
                    Jack Cooper
                  </p>
                </div>
                <p className='font-size-14 text-primary text-semibold'>
                  Captain
                </p>
              </div>

              <div className='users'>
                {Array.from({ length: 20 }, (_, i) => i).map(i => (
                  <div className='user' key={i}>
                    <div className='image'>
                      {/* <img src='' alt='user-profile' /> */}
                    </div>
                    <p className='font-size-12 text-primary text-semibold text-center'>
                      Jack
                    </p>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <TeamSmallView>
                <p className='font-size-16 text-primary text-semibold text-center'>
                  Team A
                </p>
                <div className='divider'></div>
                <div className='count'>
                  <div className='count-key'>
                    <p className='font-size-15 text-semibold'>C</p>
                  </div>
                  <p className='font-size-12 text-primary text-semibold text-center'>
                    Jack cooper
                  </p>
                </div>
                <div className='grid'>
                  {Array.from({ length: 28 }, (_, i) => i).map(i => (
                    <div key={i} className='user'></div>
                  ))}
                </div>
              </TeamSmallView>
            </>
          )}
        </Team>
        <div
          className={classNames('slide-icon clickable', !open && 'close')}
          onClick={() => setOpen(!open)}
        >
          <SelectIcon fill='#ffffff' />
        </div>
      </div>
      {addGame && <AddGameToGuild open={addGame} setOpen={setAddGame} />}
    </Container>
  );
};

export default GuildGamesAndTeams;

const Container = styled.div`
  width: 250px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 10px;
  padding: 15px;
  position: relative;
  transition: width 0.6s ease;

  &.close {
    width: 102px;
    padding: 10px;
  }

  & .slide-icon {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background-color: #6a737d;
    display: grid;
    place-items: center;
    position: absolute;
    top: 25px;
    left: 0;
    transform: translateX(-50%) rotate(-90deg);
    transition: 0.4s ease;
    & svg {
      width: 10px;
    }

    &.close {
      transform: translateX(-50%) rotate(90deg);
    }
  }

  & .gameContainer {
    width: 100%;
    overflow-x: auto;
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    width: 420px;
    padding: 24px 20px;
  }
  @media only screen and (min-width: ${theme.breakpoints['3xl']}) {
    width: 520px;
    padding: 24px 20px;
  }
`;

const GamesContainer = styled.div`
  min-width: 380px;
  margin-block: 21px 27px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  &.close {
    margin-block: 0px 15px;
    min-width: 0px;

    & .game-row {
      display: flex;
      flex-direction: column;
      gap: 50px;
      height: auto;
      padding-block: 10px;
      border-radius: 12px;
      padding-inline: 5px;

      & .game-name {
        & .icon {
          height: 36px;
          width: 36px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.color.text.primary};
          margin-inline: auto;
        }

        & p {
          max-width: 12ch;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-block: 9px 14px;
        }
        border-bottom: 1px solid
          ${({ theme }) => theme.color.text.secondary + '33'};
      }
    }
    & .count {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 7px;

      & .count-key {
        height: 20px;
        width: 20px;
        border-radius: 4px;
        background-color: ${({ theme }) =>
          theme.color.background.button.primary.bg};
        color: ${({ theme }) => theme.color.background.button.primary.color};
        display: grid;
        place-items: center;
      }
    }

    & .earning-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      & p {
        margin-block: 5px 1px;
      }
    }
  }
`;

const GameRow = styled.div`
  background-color: ${({ theme }) => theme.color.background.row};
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding-inline: 7px;

  & .justify-space-between {
    width: 100%;
    & .profile {
      gap: 7px;
      & .image {
        height: 36px;
        width: 36px;
        flex-shrink: 0;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.text.primary};
      }

      & .name {
        max-width: 12ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  & .row.info {
    gap: 21px;

    & .row.count-row {
      gap: 7px;

      & .count-key {
        height: 20px;
        width: 20px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.color.background.headerRow};
        display: grid;
        place-items: center;
      }
    }
  }

  & .row.earning {
    gap: 7px;
    & img {
      height: 23px;
      width: 23px;
      border-radius: 50%;
    }
  }
`;

const Team = styled.div`
  background-color: ${({ theme }) => theme.color.background.headerRow};
  padding: 8px 10px;
  border-radius: 10px;
  & .row {
    gap: 20px;

    & .arrow-icon {
      height: 20px;
      width: 20px;
      border-radius: 20px;
      display: grid;
      place-items: center;
      background-color: ${({ theme }) => theme.color.text.dim + '4d'};
      cursor: pointer;

      &.left-icon {
        transform: rotate(90deg);
      }
      &.right-icon {
        transform: rotate(-90deg);
      }
    }
  }

  & .captain-row {
    background-color: ${({ theme }) => theme.color.background.headerRow};
    height: 50px;
    padding-inline: 15px;
    border-radius: 4px;
    margin-block: 11px 8px;
    & .row {
      gap: 10px;

      & .image {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.background.row};
      }
    }
  }

  & .users {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;

    & .user {
      & .image {
        aspect-ratio: 1/1;
        background-color: ${({ theme }) => theme.color.background.row};
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 5px;
        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
`;

const TeamSmallView = styled.div`
  background-color: ${({ theme }) => theme.color.background.row};
  border-radius: 12px;
  padding: 20px 5px;

  & .divider {
    border-top: 1px solid ${({ theme }) => theme.color.text.dim + '33'};
    margin-block: 20px;
  }

  & .count {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    margin-bottom: 15px;

    & .count-key {
      height: 20px;
      width: 20px;
      border-radius: 4px;
      background-color: ${({ theme }) =>
        theme.color.background.button.primary.bg};
      color: ${({ theme }) => theme.color.background.button.primary.color};
      display: grid;
      place-items: center;
    }
  }

  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;

    & .user {
      aspect-ratio: 1/1;
      background-color: ${({ theme }) => theme.color.background.headerRow};
      border-radius: 4px;
    }
  }
`;
