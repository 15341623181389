import React, { FC } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { darkTheme, lightTheme, lightTheme as theme } from '../../theme';
import { ClipLoader } from 'react-spinners';

interface IProps {
  variant: 'primary' | 'outlined';
  className?: string;
  label: string;
  loading?: boolean;
  [x: string]: any;
}

const Button: FC<IProps> = ({
  variant,
  className,
  label,
  loading,
  ...rest
}) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const theme = activeTheme === 'light' ? lightTheme : darkTheme;

  return (
    <StyledButton className={classNames(variant, className)} {...rest}>
      {loading ? (
        <div className='loader'>
          <ClipLoader
            className='load'
            size={20}
            color={theme.color.background.button.primary.color}
          />
        </div>
      ) : (
        <>{label}</>
      )}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button`
  height: 44px;
  width: 100%;
  max-width: 350px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.2s ease;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  position: relative;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  & .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.primary {
    background-color: ${({ theme }) =>
      theme.color.background.button.primary.bg};
    color: ${({ theme }) => theme.color.background.button.primary.color};
  }

  &.outlined {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.color.text.primary};
    color: ${({ theme }) => theme.color.text.primary};
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    height: 55px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    max-width: 439px;
  }
`;
