import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';
import { RootState } from '../../store';

import { lightTheme, darkTheme } from '../../theme/index';

interface IProps {
  [x: string]: any;
}

const CheckboxUncheck: FC<IProps> = ({ ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const theme: DefaultTheme = activeTheme === 'light' ? lightTheme : darkTheme;
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M16 1H4C2.34315 1 1 2.34315 1 4V16C1 17.6569 2.34315 19 4 19H16C17.6569 19 19 17.6569 19 16V4C19 2.34315 17.6569 1 16 1Z'
        stroke={theme.color.text.primary}
        strokeWidth='1'
      />
    </svg>
  );
};

export default CheckboxUncheck;
