import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

import ActionButton from '../../../../../components/ActionButton/ActionButton';
import AddGameMaster from '../../../../../Dialogs/AddGameMaster/AddGameMaster';
import AddGameCaptain from '../../../../../Dialogs/AddGameCaptain/AddGameCaptain';
import ManageTeam from '../../../../../Dialogs/ManageTeam/ManageTeam';
import CaptainRow from './CaptainRow';
import GameMasterRow from './GameMasterRow';

import {
  getGuildGameCaptains,
  getUserById,
  guildGameMasters,
} from '../../../../../api';
import {
  IGameCaptain,
  IGameMaster,
  IUser,
} from '../../../../../types/interface';
import assets from '../../../../../assets';
import { features, modulesEnum } from '../../../../../types/enums';
import WithPermissions from '../../../../../components/WithPermissions/WithPermissions';
import TransferScholars from '../../../../../Dialogs/TransferScholars/TransferScholars';

interface IProps {}

const Management: FC<IProps> = () => {
  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );

  const loadData = useSelector((state: RootState) => state.guilds.loadData);

  const [addMaster, setAddMaster] = useState(false);
  const [addCaptain, setAddCaptain] = useState(false);
  const [manageTeam, setMangeTeam] = useState(false);
  const [transferScholars, setTransferScholars] = useState(false);

  const [guildManager, setGuildManager] = useState<IUser | null>(null);
  const [masters, setMasters] = useState<IGameMaster[]>([]);
  const [captains, setCaptains] = useState<IGameCaptain[]>([]);

  const handleAddMaster = () => {
    setAddMaster(true);
  };

  const handleAddCaptain = () => {
    setAddCaptain(true);
  };

  const handleTransferScholars = () => {
    setTransferScholars(true);
  };

  const handleGetMastersAndCaptains = async () => {
    try {
      if (currentGuild) {
        const [res1, res2, res3] = await Promise.all([
          guildGameMasters(currentGuild.id),
          getGuildGameCaptains(currentGuild.id),
          getUserById(currentGuild.user_id),
        ]);
        setMasters(res1.data);
        setCaptains(res2.data);
        setGuildManager(res3.data);
      }
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    handleGetMastersAndCaptains();
    // eslint-disable-next-line
  }, [currentGuild, loadData]);

  return (
    <ManagementContainer>
      <p className='font-size-15 text-primary text-semibold'>Management</p>
      <div className='management'>
        <div className='manager'>
          <p className='font-size-16 text-primary text-semibold text-center'>
            Guild Manager
          </p>
          <div className='image'>
            <img
              src={guildManager?.image_url || assets.userPreview}
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              alt=''
            />
          </div>
          <p className='font-size-18 text-primary text-bold text-center'>
            {guildManager?.username} {guildManager?.last_name}
          </p>
        </div>
        <div className='other'>
          <div className='row justify-space-between title-bar'>
            <p className='font-size-16 text-primary text-semibold'>
              Game Master
            </p>
            <WithPermissions
              moduleName={modulesEnum.Guilds}
              featureName={features.addGameMaster}
              action
            >
              <ActionButton
                onClick={handleAddMaster}
                variant='primary'
                className='btn'
              >
                Add Game Master
              </ActionButton>
            </WithPermissions>
          </div>
          {masters.map(master => (
            <GameMasterRow
              master={master}
              key={master.id}
              setMangeTeam={setMangeTeam}
            />
          ))}
        </div>
        <div className='other'>
          <div className='row justify-space-between title-bar'>
            <p className='font-size-16 text-primary text-semibold'>Captain</p>

            <WithPermissions
              moduleName={modulesEnum.Guilds}
              featureName={features.addGameCaptain}
              action
            >
              <ActionButton
                onClick={handleAddCaptain}
                variant='primary'
                className='btn'
              >
                Add Captain
              </ActionButton>
            </WithPermissions>
          </div>

          {captains.map(captain => (
            <CaptainRow
              captain={captain}
              key={captain.id}
              setMangeTeam={setMangeTeam}
              handleTransferScholars={handleTransferScholars}
            />
          ))}
        </div>
      </div>
      {addMaster && <AddGameMaster open={addMaster} setOpen={setAddMaster} />}
      {manageTeam && <ManageTeam open={manageTeam} setOpen={setMangeTeam} />}
      {addCaptain && (
        <AddGameCaptain open={addCaptain} setOpen={setAddCaptain} />
      )}
      {transferScholars && (
        <TransferScholars
          open={transferScholars}
          setOpen={setTransferScholars}
        />
      )}
    </ManagementContainer>
  );
};

export default Management;

const ManagementContainer = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 10px;
  padding: 20px 25px;

  & .title-bar {
    height: 35px;
  }

  & .management {
    margin-top: 30px;
    display: flex;
    align-items: stretch;
    gap: 30px;

    & .manager {
      flex: 1;
      background-color: ${({ theme }) => theme.color.background.headerRow};
      padding: 17px 16px;
      border-radius: 10px;

      & .image {
        aspect-ratio: 1/1;
        background-color: ${({ theme }) => theme.color.background.row};
        margin-block: 8px 7px;
        border-radius: 10px;
        overflow: hidden;
        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    & .other {
      flex: 2;
      background-color: ${({ theme }) => theme.color.background.headerRow};
      padding: 12px 16px;
      border-radius: 10px;

      & .btn {
        background-color: ${({ theme }) =>
          theme.color.background.button.primary.bg};
        color: ${({ theme }) => theme.color.background.button.primary.color};
      }

      & .table-row {
        height: 50px;
        background-color: ${({ theme }) => theme.color.background.row};
        margin-top: 10px;
        border-radius: 4px;
        padding-inline: 10px;
        display: flex;
        align-items: center;
        & .justify-space-between {
          width: 100%;

          & .image {
            height: 36px;
            width: 36px;
            flex-shrink: 0;
            border-radius: 50%;
            background-color: ${({ theme }) =>
              theme.color.background.headerRow};
            margin-right: 13px;
          }
        }

        & .secondary {
          gap: 41px;
          & .more-icon {
            display: flex;
            align-items: center;
          }
          & .row {
            &.count {
              gap: 8px;
              & .count-key {
                background-color: ${({ theme }) => theme.color.text.primary};
                height: 20px;
                width: 20px;
                border-radius: 4px;
                display: grid;
                place-items: center;
                color: ${({ theme }) =>
                  theme.color.background.button.primary.color};
              }
            }
          }
        }
      }
    }
  }
`;
