import React from 'react';

import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import ProfilePopup from '../../layouts/DashbaordLayout/ProfilePopup';

import NotificationBell from '../../assets/icons/NotificationBell';

import { lightTheme as theme } from '../../theme';
import assets from '../../assets';
import useRole from '../../hooks/useRole';
import useTabs from '../../hooks/useTabs';
import CopyIcon from '../../assets/icons/CopyIcon';
import { toast } from 'react-toastify';
import { rolesEnum } from '../../types/enums';

const renderNavIcon = (
  navData: { isActive: boolean; isPending: boolean },
  element: React.FC<any>
) => {
  const Icon = element;
  return <Icon className='icon' active={navData.isActive} />;
};

const Navbar = () => {
  const { tabs } = useTabs();
  const dispatch = useDispatch<Dispatch>();
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const { role } = useRole();

  const walletAddress = useSelector(
    (state: RootState) => state.auth.starzWalletAddress
  );

  const { count } = useSelector((state: RootState) => state.notifications);

  const handleConnectWallet = async () => {
    if ((window as any).ethereum) {
      const accounts = await (window as any).ethereum.request({
        method: 'eth_requestAccounts',
      });
      dispatch.auth.handleConnectWallet(accounts[0]);
    }
  };

  const handleCopyAddress = (address: string) => {
    navigator.clipboard.writeText(address);
    toast.success('Gamefied wallet address copied');
  };

  return (
    <Nav
      style={{
        filter:
          activeTheme === 'light'
            ? 'drop-shadow(0px 8px 18px rgba(137, 151, 165, 0.49))'
            : '',
      }}
    >
      <div className='row'>
        <Profile className='row profile'>
          <Link to='/dashboard'>
            <img src={assets.mainLogo} alt='' />
          </Link>

          <p className='font-size-25 text-primary capitalize main-title'>
            {role?.name}
          </p>
        </Profile>
        <Tabs>
          {tabs.map(navItem => {
            if (navItem.label === 'Game Vault') {
              return (
                <NavLink
                  key={navItem.to}
                  to={navItem.to}
                  end={navItem.end}
                  className={navData =>
                    navData.isActive ? 'tab anchor active' : 'tab anchor'
                  }
                  children={navData => (
                    <>
                      {renderNavIcon(navData, navItem.icon)}
                      <p className='font-size-12'>Games</p>
                    </>
                  )}
                />
              );
            } else {
              return (
                <NavLink
                  key={navItem.to}
                  to={navItem.to}
                  end={navItem.end}
                  className={navData =>
                    navData.isActive ? 'tab anchor active' : 'tab anchor'
                  }
                  children={navData => (
                    <>
                      {renderNavIcon(navData, navItem.icon)}
                      <p className='font-size-12'>{navItem.label}</p>
                    </>
                  )}
                />
              );
            }
          })}
        </Tabs>
      </div>

      <Actions>
        <IconBadge
          onClick={() => dispatch.notifications.setNotificationIsOpen(true)}
        >
          <div className='icon notification-bell'>
            <NotificationBell />
            {count > 0 && (
              <div className='count'>
                <p>{count}</p>
              </div>
            )}
          </div>
        </IconBadge>

        <Seperator />
        {role?.name !== rolesEnum.Admin && (
          <WalletConnection>
            {walletAddress ? (
              <div className='row'>
                <p className='font-size-14 text-primary text-semibold wallet-address'>
                  {walletAddress}
                </p>
                <div
                  className='clickable icon'
                  onClick={() => handleCopyAddress(walletAddress)}
                >
                  <CopyIcon />
                </div>
              </div>
            ) : (
              <div className='row clickable' onClick={handleConnectWallet}>
                <span></span>
                <p className='font-size-14 text-primary text-semibold'>
                  Wallet not connected
                </p>
              </div>
            )}
          </WalletConnection>
        )}

        <ProfilePopup />
      </Actions>
    </Nav>
  );
};

export default Navbar;

const Nav = styled.div`
  padding-inline: 30px;
  height: 60px;
  background-color: ${({ theme }) => theme.color.background.card};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 10;

  & .main-title {
    color: ${({ theme }) => theme.color.background.hover}!important;
  }

  & .row {
    display: flex;
    align-items: center;
    height: 100%;
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    height: 90px;
  }
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  & img {
    width: 35px;
    object-fit: cover;
  }
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    gap: 25px;

    & img {
      width: 53px;
      height: unset;
      object-fit: cover;
    }
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 30px;
  height: 100%;

  & .tab {
    width: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .icon {
      height: 15px;
    }

    &.active {
      background-color: ${({ theme }) => theme.color.background.hover};

      & p {
        color: ${({ theme }) => theme.color.icon.active};
        margin-top: 7px;
      }
    }

    & p {
      color: ${({ theme }) => theme.color.icon.inActive};
      margin-top: 7px;
    }
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    /* gap: 15px; */
    margin-left: 75px;

    & .tab {
      width: 121px;
      & .icon {
        width: unset;
        height: unset;
      }
    }
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    gap: 15px;
  }
`;

const Seperator = styled.div`
  width: 1px;
  height: 28px;
  background-color: ${({ theme }) => theme.color.icon.inActive};
  border-radius: 2px;
  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    height: 38px;
  }
`;

const IconBadge = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: ${({ theme }) => theme.color.icon.inActive + '33'};
  cursor: pointer;

  & .icon {
    position: relative;
    & svg {
      width: 14px;
    }
  }

  & .count {
    position: absolute;
    height: 18px !important;
    padding-inline: 3px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.text.danger};
    display: flex;
    align-items: center;
    right: -5px;
    top: -10px;
    border: 1px solid ${({ theme }) => theme.color.icon.inActive};

    & p {
      color: #ffffff;
      font-size: 8px;
      font-weight: 700;
    }
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    height: 40px;
    width: 40px;

    & .icon {
      & svg {
        width: unset;
      }
    }
  }
`;

const WalletConnection = styled.div`
  padding: 10px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.color.background.headerRow};

  & .row {
    gap: 5px;

    & span {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.text.danger};
    }

    & p.wallet-address {
      max-width: 14ch;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

// const LanguageSelector = styled.div`
//   width: 70px;
//   height: 25px;
//   border-radius: 14px;
//   background-color: ${({ theme }) => theme.color.icon.inActive};
//   @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
//     width: 90px;
//     height: 35px;
//     border-radius: 18px;
//   }
// `;
