import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import ActionButton from '../../components/ActionButton/ActionButton';
import BadgeCard from '../../components/BadgeCard/BadgeCard';
// import GameDetailCard from '../../components/GameDetailCard/GameDetailCard';
import SearchBar from '../../components/SearchBar/SearchBar';
import AddNewBadge from '../../Dialogs/AddNewBadge/AddNewBadge';
import AddNewGame from '../../Dialogs/AddNewGame/AddNewGame';

import { lightTheme } from '../../theme';
import GameCard from '../../components/GameCard/GameCard';
import useRole from '../../hooks/useRole';
import { features, modulesEnum, rolesEnum } from '../../types/enums';
// import { IGame } from '../../types/api';
import { ClipLoader } from 'react-spinners';
import useTheme from '../../hooks/useTheme';
import WithPermissions from '../../components/WithPermissions/WithPermissions';

const Games: FC = () => {
  const { role } = useRole();
  const guild = useSelector((state: RootState) => state.guilds.currentGuild);
  const { theme } = useTheme();
  const dispatch = useDispatch<Dispatch>();

  const loading = useSelector((state: RootState) => state.games.loading);
  // const [topGames, setTopGames] = useState<IGame[]>();

  const badges = useSelector((state: RootState) => state.badges.badges);
  const games = useSelector((state: RootState) => state.games.games);

  const [openDialog, setOpenDialog] = useState(false);
  const [openGameDialog, setOpenGameDialog] = useState(false);

  const [search, setSearch] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  useEffect(() => {
    dispatch.badges.handleGetAllBadges();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (role?.name === rolesEnum.Admin) {
      dispatch.games.handleGetAllGames();
    } else {
      if (guild) {
        dispatch.games.handleGetAllGames(guild.id);
      }
    }
    // eslint-disable-next-line
  }, [guild, role]);

  // useEffect(() => {
  //   if (games.length > 2 && role?.name === rolesEnum.Admin) {
  //     setTopGames([games[0], games[1]]);
  //   } else if (games.length > 3) {
  //     setTopGames([games[0], games[1], games[2]]);
  //   } else {
  //     setTopGames(games);
  //   }
  //   // eslint-disable-next-line
  // }, [games]);

  return (
    <Container>
      <WithPermissions
        moduleName={modulesEnum['Game Vault']}
        featureName={features.badges}
      >
        <Badges>
          <div className='row justify-space-between'>
            <p className='font-size-25 text-primary text-semibold'>Badges</p>
            <ActionButton
              variant='primary'
              onClick={() => {
                dispatch.badges.setSelected(null);
                setOpenDialog(true);
              }}
            >
              Add New
            </ActionButton>
          </div>
          <div className='badges'>
            {badges.map(badge => (
              <BadgeCard
                key={badge.id}
                setOpenDialog={setOpenDialog}
                badge={badge}
              />
            ))}
          </div>
        </Badges>
      </WithPermissions>

      <GamesSection>
        {/* <p className='font-size-25 text-primary text-semibold section-title'>
          Top Games
        </p>
        {loading && !games.length ? (
          <div className='row justify-center' style={{ height: '300px' }}>
            <ClipLoader size={50} color={theme.color.text.primary} />
          </div>
        ) : (
          <div
            className='wrapper'
            style={{
              gridTemplateColumns:
                role?.name === rolesEnum.Admin ? '1fr 1fr' : '1fr 1fr 1fr',
            }}
          >
            {topGames?.map(game => (
              <GameDetailCard key={game.id} game={game} />
            ))}
          </div>
        )} */}

        <div className='row justify-space-between all-games-section'>
          <p className='font-size-25 text-primary text-semibold '>All Games</p>
          <div className='row'>
            <SearchBar
              containerStyles={{
                backgroundColor: theme.color.background.card,
              }}
              value={search}
              onChange={handleSearch}
              name='search'
            />
            <WithPermissions
              moduleName={modulesEnum['Game Vault']}
              featureName={features.addNewGame}
            >
              <ActionButton
                variant='primary'
                onClick={() => setOpenGameDialog(true)}
                style={{
                  background: theme.color.background.card,
                  height: '35px',
                  paddingBlock: '0px',
                }}
              >
                Add New Game
              </ActionButton>
            </WithPermissions>
          </div>
        </div>
        <WithPermissions
          moduleName={modulesEnum['Game Vault']}
          featureName={features.gamesVaultGameList}
        >
          {loading && !games.length ? (
            <div className='row justify-center' style={{ height: '300px' }}>
              <ClipLoader size={50} color={theme.color.text.primary} />
            </div>
          ) : (
            <GameGrid>
              {games.map(game => {
                if (
                  game.name.toLowerCase().indexOf(search.toLowerCase()) > -1
                ) {
                  return <GameCard game={game} key={game.id} />;
                } else {
                  return <></>;
                }
              })}
            </GameGrid>
          )}
        </WithPermissions>
      </GamesSection>
      {openDialog && <AddNewBadge open={openDialog} setOpen={setOpenDialog} />}
      {openGameDialog && (
        <AddNewGame open={openGameDialog} setOpen={setOpenGameDialog} />
      )}
    </Container>
  );
};

export default Games;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 30px;
`;

const Badges = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 12px;
  padding: 15px 20px;

  & .badges {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    & .badges {
      gap: 25px;
    }
  }
`;

const GamesSection = styled.div`
  flex: 3;

  & .wrapper {
    display: grid;
    gap: 20px;
  }

  & .section-title {
    margin-bottom: 20px;
  }

  & .all-games-section {
    margin-top: 30px;
    margin-bottom: 15px;

    & .row {
      gap: 10px;
    }
  }

  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    & .wrapper {
      gap: 25px;
    }
    & .section-title {
      margin-bottom: 26px;
    }
    & .all-games-section {
      margin-top: 40px;
      margin-bottom: 20px;

      & .row {
        gap: 15px;
      }
    }
  }
`;

const GameGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 15px;

  @media only screen and (min-width: ${lightTheme.breakpoints['2xl']}) {
    gap: 25px;
  }
`;
