export enum rolesEnum {
  Admin = 'admin',
  Scholar = 'scholar',
  GuildManager = 'guild manager',
  GameMaster = 'game master',
  GameCaptain = 'captain',
}

export enum modulesEnum {
  Dashboard = 'dashboard',
  'Game Vault' = 'gamevault',
  Guilds = 'guilds',
  Performance = 'performance',
  Payout = 'payout',
  Roles = 'roles',
  Tournaments = 'tournaments',
}

export enum permissionEnum {
  fullPermission = 'fullPermission',
  restricted = 'restricted',
  view = 'view',
}

export enum features {
  // dashboard permissions

  topEarningGuilds = 'Top earning guilds',
  topGames = 'Top games',
  topPerformingScholars = 'Top performing scholars',
  totalGames = 'Total Games',
  totalGuilds = 'Total Guilds',
  gameMasters = 'Game masters',
  captains = 'Captains',
  scholars = 'Scholars',
  tournaments = 'Tournaments',
  guildManagers = 'Guild managers',
  gameList = 'Games list',
  usersList = 'Users list',

  // game vault permissions

  badges = 'Badges',
  addNewGame = 'Add new game',
  editGame = 'Edit game',
  gamesVaultGameList = 'game list',

  // guild permissions

  allGuilds = 'All guilds',
  addNewGuild = 'Add new guild',
  guildDetails = 'Guild details',
  addSocialMediaLinks = 'Add social media links',
  addGamesInGuild = 'Add games',
  guildUserRevenueSplit = 'revenue split',
  issueNewScholarship = 'Issue new scholarship',
  acceptOrRejectScholarship = 'Accept/reject scholarship',
  addGameMaster = 'Add game master',
  addGameCaptain = 'Add game captain',
  updateGuild = 'Update guild',
  manageTeam = 'Manage team',
  editUser = 'Edit user',
  deleteUser = 'Delete user',
  createInviteLink = 'Create invite link',
  transferAssets = 'Transfer asset',
  removeAssets = 'Remove asset',
  pullbackAssets = 'pullback assets',

  // performance permission

  performanceGuilds = 'Guilds',
  performanceGames = 'Games',
  performanceUsers = 'Users',

  // payout permissions

  payoutRevenueSplit = 'Revenue split',
  payoutEarnings = 'Earnings',

  // roles permissions

  roles = 'roles',

  // tournaments permissions

  tournamentsList = 'Tournaments list',
  createTournament = 'Create tournament',
  editTournament = 'Edit tournament',
  joinTournament = 'Join tournament',
}

export enum NotificationTypes {
  applyScholarship = 'apply_scholarship',
}
