import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import UserCard from '../../../components/UserCard/UserCard';
import { RootState } from '../../../store';

import { lightTheme as theme } from '../../../theme';

interface IProps {
  search?: string;
}

const UsersGridView: FC<IProps> = ({ search = '' }) => {
  const users = useSelector((state: RootState) => state.users.dashboardUsers);
  return (
    <UserGrid>
      {users.map(user => {
        if (user.username?.toLowerCase().indexOf(search.toLowerCase()) > -1) {
          return <UserCard key={user.id} user={user} />;
        } else {
          return null;
        }
      })}
    </UserGrid>
  );
};

export default UsersGridView;

const UserGrid = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 15px;

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 18px;
  }
`;
