import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useParams } from 'react-router-dom';

import UserCard from '../../../../components/UserCard/UserCard';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import Chart from '../../../../components/Chart/Chart';
import Management from './Management/Management';
import { IGuildUser } from '../../../../types/api';
import { guildUsers, userOrGuildStats } from '../../../../api';
import { IUserOrGuildStats } from '../../../../types/interface';

dayjs.extend(utc);

interface IProps {}

const GuildDetails: FC<IProps> = () => {
  const currentGuild = useSelector(
    (state: RootState) => state.guilds.currentGuild
  );
  const guildId = useParams().id || currentGuild?.id;

  const [filters, setFilters] = useState({
    search: '',
  });

  const [players, setPlayers] = useState<IGuildUser[]>([]);
  const [guildStats, setGuildStats] = useState<IUserOrGuildStats | null>(null);

  const handleFilters = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleGetInitialData = async () => {
    if (guildId) {
      try {
        const [{ data: guildusers }, { data: stats }] = await Promise.all([
          guildUsers(guildId),
          userOrGuildStats(currentGuild!.user_id),
        ]);
        setPlayers(guildusers);
        setGuildStats(stats);
      } catch (err: any) {
        console.log(err.message);
      }
    }
  };

  useEffect(() => {
    handleGetInitialData();
    // eslint-disable-next-line
  }, [currentGuild]);

  return (
    <React.Fragment>
      <Performance>
        {guildId && <Chart guildId={guildId} games={currentGuild?.Games} />}
      </Performance>
      <PerformanceRow>
        {/* <div className='row'>
          <p className='font-size-16 text-secondary'>Wins</p>
          <p className='font-size-22 text-primary text-bold'>265</p>
        </div>
        <div className='row'>
          <p className='font-size-16 text-secondary'>Guild Rating</p>
          <p className='font-size-22 text-primary text-bold'>0</p>
        </div> */}
        <div className='row'>
          <p className='font-size-16 text-secondary'>Items in vault</p>
          <p className='font-size-22 text-primary text-bold'>
            {guildStats?.valutItems}
          </p>
        </div>
        <div className='row'>
          <p className='font-size-16 text-secondary'>Next Payout</p>
          <p className='font-size-22 text-primary text-bold'>
            {guildStats?.next_payout &&
              dayjs(guildStats.next_payout).format('DD / MM / YYYY')}
          </p>
        </div>
      </PerformanceRow>

      <Management />

      <Players>
        <div className='row justify-space-between'>
          <p className='font-size-25 text-primary text-semibold'>Players</p>
          <div className='row' style={{ gap: '20px' }}>
            <SearchBar
              value={filters.search}
              name='search'
              onChange={handleFilters}
            />
          </div>
        </div>
        <div className='players-grid'>
          {players.map((player, index) => {
            if (
              player?.username
                ?.toLowerCase()
                .indexOf(filters.search.toLowerCase()) > -1
            ) {
              return <UserCard index={index} user={player} key={player.id} />;
            } else {
              return <></>;
            }
          })}
        </div>
      </Players>
    </React.Fragment>
  );
};

export default GuildDetails;

const PerformanceRow = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 10px;
  padding: 17px 35px;
  margin-block: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .row {
    gap: 16px;
  }
`;

const Players = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 10px;
  padding: 20px 25px;
  margin-top: 20px;

  & .players-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 15px;
    margin-top: 13px;
  }
`;

const Performance = styled.div``;
