import React, { FC, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { lightTheme, darkTheme } from '../../theme';

import ChartComponent from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { IGame } from '../../types/api';
import { IPerformanceStats } from '../../types/interface';
import Select from '../Select/Select';
import { performance } from '../../api';

dayjs.extend(utc);

interface IProps {
  games?: IGame[];
  userId?: string;
  guildId: string;
}

interface ISeriesObj {
  name: string;
  data: number[];
}

const Chart: FC<IProps> = ({ games = [], userId, guildId }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const theme = activeTheme === 'light' ? lightTheme : darkTheme;

  const [categories, setCategories] = useState<string[]>([]);

  const [selectedGame, setSelectedGame] = useState('');
  const [series, setSeries] = useState<ISeriesObj[]>([]);
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedGame(e.currentTarget.value);
  };

  const options: ApexOptions = useMemo(
    () => ({
      chart: {
        height: 250,
        width: 500,
        type: 'area',
        toolbar: {
          show: false,
        },
        redrawOnParentResize: true,
        redrawOnWindowResize: true,
        animations: {
          easing: 'linear',
        },
        events: {
          mounted: chart => chart.windowResizeHandler(),
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
        width: 1,
      },
      colors: [theme.color.text.primary, '#7A7E85', '#7A7E8500'],

      legend: {
        show: false,
      },
      grid: {
        borderColor: theme.color.text.primary,
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        type: 'datetime',
        categories: [...categories],
        labels: {
          style: {
            colors: [theme.color.text.primary],
          },
          show: true,
        },
      },
      tooltip: {
        // x: {
        //   format: 'dd/MM/yy HH:mm',
        // },
      },
    }),
    // eslint-disable-next-line
    [categories, series]
  );

  const handleGetPerformance = async () => {
    try {
      const { data } = await performance({
        gameId: selectedGame,
        userId: userId,
        guildId: guildId,
        year: String(new Date().getFullYear()),
      });
      const performanceData: ISeriesObj[] = [];
      const categoryData: string[] = [];

      Object.keys(data).forEach(key => {
        categoryData.push(...data[key].map((d: IPerformanceStats) => d.date));
      });
      Object.keys(data).forEach(key => {
        const dataList: number[] = [];
        categoryData.forEach(c => {
          const d: IPerformanceStats = data[key].find(
            (d: IPerformanceStats) => d.date === c
          );
          if (d) {
            dataList.push(d.amount);
          } else {
            dataList.push(0);
          }
        });
        const obj: ISeriesObj = {
          name: key,
          data: dataList,
        };
        performanceData.push(obj);
      });
      setCategories(categoryData);
      setSeries(performanceData);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (games.length) {
      setSelectedGame(games[0].id);
    }
    // eslint-disable-next-line
  }, [games]);

  useEffect(() => {
    if (selectedGame && (guildId || userId)) {
      handleGetPerformance();
    }
    // eslint-disable-next-line
  }, [selectedGame, guildId, userId]);

  return (
    <Container>
      <div className='row justify-space-between'>
        <div className='row' style={{ gap: '15px' }}>
          <p className='font-size-25 text-primary text-semibold'>Performance</p>
          <p className='font-size-16 text-secondary text-semibold'>
            Last 30 days
          </p>
        </div>
        {games.length > 0 && (
          <Select
            containerClassName='select'
            label='Game'
            backgroundColor={theme.color.background.card}
            options={games.map(game => ({ label: game.name, value: game.id }))}
            value={selectedGame}
            onChange={handleChange}
            disabled
          />
        )}
      </div>
      <div className='chart-wrapper'>
        <ChartComponent
          options={options}
          series={series}
          width='100%'
          type='area'
          height={250}
        />
      </div>
    </Container>
  );
};

export default Chart;

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  & .chart-wrapper {
    width: 100%;
    height: 250px;
    display: block;
    overflow: hidden;
    text-align: right;
    margin-left: auto;
  }
`;
