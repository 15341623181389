import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface IProps {
  [x: string]: any;
  fill?: string;
}

const NotificationBell: FC<IProps> = ({ fill, ...rest }) => {
  const activeTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  return (
    <svg
      width='18'
      height='23'
      viewBox='0 0 18 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M8.66019 22.6884C9.02858 22.6988 9.39485 22.6294 9.73388 22.4849C10.0729 22.3404 10.3767 22.1243 10.6243 21.8514C10.872 21.5785 11.0576 21.2552 11.1686 20.9038C11.2796 20.5523 11.3132 20.1811 11.2672 19.8154H6.04319C5.99738 20.1819 6.03148 20.5539 6.14315 20.9059C6.25483 21.2579 6.44142 21.5815 6.69011 21.8546C6.93879 22.1276 7.24367 22.3435 7.58375 22.4874C7.92384 22.6314 8.29108 22.6999 8.66019 22.6884Z'
        fill={fill || activeTheme === 'light' ? '#353940' : '#353940'}
      />
      <path
        d='M17.1219 17.062C16.2869 15.962 14.6449 15.316 14.6449 10.389C14.6449 5.331 12.4119 3.298 10.3299 2.811C10.1299 2.762 9.99391 2.697 9.99391 2.491V2.333C9.99877 2.15627 9.96816 1.98035 9.90389 1.81565C9.83961 1.65095 9.74298 1.5008 9.61969 1.37408C9.4964 1.24736 9.34897 1.14663 9.1861 1.07785C9.02322 1.00907 8.84822 0.973633 8.67142 0.973633C8.49462 0.973633 8.31961 1.00907 8.15674 1.07785C7.99386 1.14663 7.84643 1.24736 7.72314 1.37408C7.59986 1.5008 7.50322 1.65095 7.43894 1.81565C7.37467 1.98035 7.34405 2.15627 7.34892 2.333V2.49C7.34892 2.69 7.20792 2.761 7.01292 2.81C4.92592 3.303 2.69791 5.331 2.69791 10.388C2.69791 15.316 1.05492 15.955 0.220915 17.061C0.100265 17.2209 0.026661 17.4114 0.00839069 17.6109C-0.00987962 17.8104 0.0279094 18.011 0.117503 18.1902C0.207096 18.3694 0.344936 18.52 0.5155 18.6251C0.686064 18.7302 0.882579 18.7856 1.08292 18.785H16.2649C16.4647 18.7845 16.6603 18.7284 16.83 18.623C16.9996 18.5176 17.1366 18.367 17.2256 18.1882C17.3145 18.0094 17.3519 17.8093 17.3336 17.6104C17.3153 17.4115 17.242 17.2216 17.1219 17.062Z'
        fill={fill || activeTheme === 'light' ? '#353940' : '#353940'}
      />
    </svg>
  );
};

export default NotificationBell;
