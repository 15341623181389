import React, { FC, useState } from 'react';

import styled from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';

import MoreIcon from '../../../../assets/icons/MoreIcon';

import { lightTheme as theme } from '../../../../theme';
import { IGame } from '../../../../types/api';

interface IProps {
  game: IGame;
  setShowAxie: React.Dispatch<React.SetStateAction<boolean>>;
  setGame: React.Dispatch<React.SetStateAction<string>>;
}

const GamePerformanceCard: FC<IProps> = ({ game, setShowAxie, setGame }) => {
  const [open, setOpen] = useState(false);

  const handleToggleMenu = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShow = () => {
    if (game.isAxie) {
      setShowAxie(true);
      setGame(game.id);
    }
  };

  return (
    <Container>
      <div className='row'>
        <GameInfo>
          <div className='image'>
            <img src={game.logo} alt='' />
          </div>
          <div className='game-name'>
            <p className='font-size-25 text-primary text-center text-bold capitalize'>
              {game.name}
            </p>
          </div>
        </GameInfo>
        <PerformanceInfo>
          <div className='row justify-space-between'>
            <div className='col'>
              <p className='font-size-12 text-secondary text-semibold'>
                Lifetime Games
              </p>
              <p className='font-size-30 text-primary text-bold'>250</p>
            </div>
            <div className='divider'></div>
            <div className='col'>
              <p className='font-size-12 text-secondary text-semibold'>
                Lifetime Games
              </p>
              <p className='font-size-30 text-primary text-bold'>250</p>
            </div>
            <div className='divider'></div>
            <div className='col'>
              <p className='font-size-12 text-secondary text-semibold'>
                Lifetime Games
              </p>
              <p className='font-size-30 text-primary text-bold'>250</p>
            </div>
          </div>

          <div className='stats-info row'>
            <div className='column'>
              <div className='circle'>
                <p className='font-size-12 text-center text-secondary text-semibold'>
                  Damage <br /> Dealt
                </p>
                <p className='font-size-30 text-primary text-bold'>23965</p>
              </div>
              <div>
                <p className='font-size-12 text-secondary text-center'>
                  Win Rate
                </p>
                <p className='font-size-20 text-primary text-bold text-center'>
                  15.61%
                </p>
              </div>
            </div>
            <div className='column'>
              <div className='circle'>
                <p className='font-size-12 text-secondary text-semibold'>K/D</p>
                <p className='font-size-30 text-primary text-bold'>0.71</p>
              </div>
              <div>
                <p className='font-size-12 text-secondary text-center'>
                  Win Rate
                </p>
                <p className='font-size-20 text-primary text-bold text-center'>
                  15.61%
                </p>
              </div>
            </div>
          </div>
        </PerformanceInfo>
      </div>
      <div className='icon clickable'>
        <ClickAwayListener onClickAway={handleClose}>
          <Menu onClick={handleToggleMenu}>
            <MoreIcon />
            {open && (
              <div className='menu'>
                <div className='item' onClick={handleShow}>
                  <p className='font-size-12 text-primary'>Game Details</p>
                </div>
              </div>
            )}
          </Menu>
        </ClickAwayListener>
      </div>
    </Container>
  );
};

export default GamePerformanceCard;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.card};
  border-radius: 10px;
  padding: 20px;
  position: relative;

  & .icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  & .row {
    gap: 30px;
    align-items: stretch;
  }
`;

const GameInfo = styled.div`
  flex: 1;
  position: relative;
  & .image {
    height: 100%;
    background-color: ${({ theme }) => theme.color.background.row};
    aspect-ratio: 1/1;
    border-radius: 10px;
    overflow: hidden;
    max-width: 280px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .game-name {
    position: absolute;
    bottom: 10px;
    width: calc(100% - 20px);
    padding: 4px;
    background-color: ${({ theme }) => theme.color.background.card};
    margin-inline: 10px;
    border-radius: 8px;

    & p {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 15ch;
      white-space: nowrap;
    }
  }
`;

const PerformanceInfo = styled.div`
  flex: 1.45;
  padding-top: 17px;
  & .row {
    align-items: center;
    & .col {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & .divider {
      width: 0.5px;
      height: 35px;
      background-color: ${({ theme }) => theme.color.text.secondary};
    }
  }

  & .stats-info {
    justify-content: center;
    gap: 50px;
    margin-top: 20px;

    & .column {
      display: flex;
      flex-direction: column;
      gap: 20px;
      & .circle {
        border: 2px solid ${({ theme }) => theme.color.text.primary};
        border-radius: 50%;
        height: 110px;
        width: 110px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 14px;
        gap: 5px;
      }
    }
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    flex: 1.15;
    margin-top: 7px;

    & .stats-info {
      justify-content: center;
      gap: 40px;
      margin-top: 10px;

      & .column {
        gap: 10px;
        & .circle {
        }
      }
    }
  }

  @media only screen and (min-width: ${theme.breakpoints['3xl']}) {
    flex: 1.5;

    & .stats-info {
      justify-content: center;
      gap: 40px;
      margin-top: 20px;

      & .column {
        gap: 20px;
        & .circle {
        }
      }
    }
  }
`;

const Menu = styled.div`
  position: relative;

  & .menu {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 105px;
    background-color: ${({ theme }) => theme.color.background.row};
    border-radius: 4px;

    & .item {
      padding: 16px 6px;
      height: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
