import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../../store';
import { object, string, mixed, InferType, number } from 'yup';
import { useParams } from 'react-router-dom';

import InputField from '../../../../components/InputField/InputField';
import Select from '../../../../components/Select/Select';
import ImagePicker from '../../../AddNewGame/ImagePicker';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import { FormErrors, validateData } from '../../../../utils/validations';
import { gameBadges } from '../../../../api';
import { IGameBadge } from '../../../../types/interface';

const schema = object({
  name: string().required().label('Name'),
  guild_id: string().required().label('Guild'),
  game_id: string().required().label('Game'),
  badge_id: string().required().label('Badge'),
  spots: number().min(1).required().label('Spots'),
  image: mixed().required().label('Image'),
});

interface IFormData extends InferType<typeof schema> {}

const NewScholarship = () => {
  const dispatch = useDispatch<Dispatch>();
  const guild = useSelector((state: RootState) => state.guilds.currentGuild);
  const guildId = useParams().id || guild?.id || '';

  const games = useSelector((state: RootState) => state.games.games);

  const [formData, setFormData] = useState({
    name: '',
    guild_id: guildId || '',
    game_id: '',
    badge_id: '',
    spots: '',
  });

  const [image, setImage] = useState<File | null>(null);

  const [errors, setErrors] = useState<FormErrors<IFormData | null>>(null);

  const [badgeOptions, setBadgeOptions] = useState<IGameBadge[]>([]);

  const handleCloseClick = () => {
    dispatch.scholarships.setIssueScholarship(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: null }));
    if (name === 'game_id') {
      handleGetGameBadges(value);
    }
  };

  const handleCreateScholarship = async () => {
    const { errors: validationErrors } = await validateData<IFormData>(schema, {
      ...formData,
      spots: +formData.spots,
      image,
    });

    if (validationErrors) {
      console.log(validationErrors);
      return setErrors(validationErrors);
    }

    if (image) {
      dispatch.scholarships.handleAddScholarship({
        data: { ...formData, spots: +formData.spots },
        image,
      });
    }
  };

  const handleGetGameBadges = async (gameId: string) => {
    try {
      const { data } = await gameBadges(gameId);
      setBadgeOptions(data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    dispatch.games.handleGetAllGames();
    dispatch.badges.handleGetAllBadges();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (image) {
      setErrors(prev => ({ ...prev, image: '' }));
    }
  }, [image]);

  return (
    <Container>
      <div className='grid'>
        <InputField
          label='Name'
          placeholder=''
          type='text'
          onChange={handleChange}
          value={formData.name}
          name='name'
          error={errors?.name}
        />
        <Select
          label='select game'
          title='Select Game'
          options={games.map(game => ({ label: game.name, value: game.id }))}
          onChange={handleChange}
          value={formData.game_id}
          name='game_id'
          error={errors?.game_id}
        />
        {badgeOptions.length > 0 && (
          <Select
            label='select badge'
            title='Badge'
            options={badgeOptions.map(badge => ({
              label: badge.badge_name,
              value: badge.badge_id,
            }))}
            onChange={handleChange}
            value={formData.badge_id}
            name='badge_id'
            error={errors?.badge_id}
          />
        )}

        <InputField
          label='Spots'
          placeholder=''
          type='text'
          onChange={handleChange}
          value={formData.spots}
          name='spots'
          error={errors?.spots}
        />
        <ImagePicker
          file={image}
          setFile={setImage}
          placeholder='Click to upload or drag
and drop game logo'
          error={errors?.image}
        />
      </div>
      <ButtonContainer>
        <ActionButton variant='primary' onClick={handleCloseClick}>
          Cancel
        </ActionButton>
        <ActionButton variant='primary' onClick={handleCreateScholarship}>
          Save
        </ActionButton>
      </ButtonContainer>
      <DeleteButton>
        <ActionButton variant='primary' onClick={handleCloseClick}>
          Delete
        </ActionButton>
      </DeleteButton>
    </Container>
  );
};

export default NewScholarship;

const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;

  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px 40px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  & button {
    height: 45px;
    width: 146px;

    &:last-of-type {
      background-color: ${({ theme }) =>
        theme.color.background.button.primary.bg};
      color: ${({ theme }) => theme.color.background.button.primary.color};
    }
  }
`;

const DeleteButton = styled.div`
  position: absolute;
  top: 14px;
  right: 30px;

  & button {
    height: 36px;
    width: 91px;
    background-color: ${({ theme }) =>
      theme.color.background.button.primary.bg}!important;
    color: ${({ theme }) =>
      theme.color.background.button.primary.color}!important;
  }
`;
