import React, { FC, useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import SliderArrow from '../../assets/icons/SliderArrow';
import { lightTheme as theme } from '../../theme';
import { IScreenshot } from '../../types/interface';

interface IProps {
  screenshots: IScreenshot[];
}

const Screenshots: FC<IProps> = ({ screenshots }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [next, setNext] = useState(0);
  const [nNext, setNNext] = useState(0);
  const [prev, setPrev] = useState(0);
  const [pPrev, setPPrev] = useState(0);

  const container = useRef<HTMLDivElement>(null);

  const handleNext = () => {
    if (activeIndex === screenshots.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };
  const handlePrev = () => {
    if (activeIndex === 0) {
      setActiveIndex(screenshots.length - 1);
    } else {
      setActiveIndex(activeIndex - 1);
    }
  };

  const handleResize = () => {
    if (container.current) {
      const firstChild = container.current.firstElementChild?.firstElementChild;
      container.current.style.height = firstChild?.clientHeight + 'px';
    }
  };

  useEffect(() => {
    if (screenshots.length === 3) {
      if (activeIndex === 0) {
        setNext(1);
        setPrev(screenshots.length - 1);
        return;
      } else if (activeIndex === screenshots.length - 1) {
        setNext(0);
        setPrev(screenshots.length - 2);
        return;
      } else if (activeIndex === 1) {
        setNext(2);
        setPrev(1);
        return;
      }
    }
    if (activeIndex === 0) {
      setPrev(screenshots.length - 1);
      setPPrev(screenshots.length - 2);
    } else if (activeIndex === 1) {
      setPrev(0);
      setPPrev(screenshots.length - 1);
    } else {
      setPrev(activeIndex - 1);
      setPPrev(activeIndex - 2);
    }

    if (activeIndex === screenshots.length - 2) {
      setNext(screenshots.length - 1);
      setNNext(0);
    } else if (activeIndex === screenshots.length - 1) {
      setNext(0);
      setNNext(1);
    } else {
      setNext(activeIndex + 1);
      setNNext(activeIndex + 2);
    }
    // eslint-disable-next-line
  }, [activeIndex]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      <p className='font-size-30 text-bold text-primary text-center'>
        In-Game Screenshots
      </p>
      <Slider ref={container}>
        <ScreenShotContainer>
          {screenshots.map((screenshot, index) => {
            let className = '';
            let render = true;
            if (index === activeIndex) {
              className = 'active';
            } else if (index === next) {
              className = 'next';
            } else if (index === nNext) {
              className = 'nNext';
            } else if (index === prev) {
              className = 'prev';
            } else if (index === pPrev) {
              className = 'pPrev';
            } else {
              render = false;
            }
            if (render) {
              return (
                <div key={index} className={classNames('slide', className)}>
                  <img src={screenshot.screenshot_url} alt='' />
                </div>
              );
            } else {
              return null;
            }
          })}
        </ScreenShotContainer>
        <ButtonContainer>
          <div className='btn btn-left' onClick={handlePrev}>
            <SliderArrow />
          </div>
          <div className='btn btn-right'>
            <SliderArrow onClick={handleNext} />
          </div>
        </ButtonContainer>
      </Slider>
      <Pagination>
        {screenshots.map((_, index) => (
          <div
            key={index}
            className={classNames(
              'pagination',
              index === activeIndex && 'active'
            )}
          ></div>
        ))}
      </Pagination>
    </Container>
  );
};

export default Screenshots;

const Container = styled.div`
  margin-block: 39px 90px;
`;

const ScreenShotContainer = styled.div`
  position: relative;
  height: 100%;
  margin-top: 27px;
  transform-style: preserve-3d;
  perspective: 1200px;
  width: 55%;
  margin: 0px auto;
  & .slide {
    width: 100%;
    aspect-ratio: 1.7/1;
    position: absolute;
    /* left: 50%;
    transform: translateX(-50%); */
    border-radius: 20px;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    transition: 0.6s ease;

    &.active {
      /* z-index: 5; */
      box-shadow: 0 13px 26px rgba(0, 0, 0, 0.3), 0 12px 6px rgba(0, 0, 0, 0.2);
      transform: translate3d(0%, 0, 0px);
    }

    &.next {
      transform: scale(0.8) translateX(-42%);
      /* opacity: 0.5; */
      z-index: 4;
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
      transform: translate3d(20%, 0, -250px);
    }
    &.nNext {
      transform: scale(0.6) translateX(-30%);
      /* opacity: 0.5; */
      z-index: 3;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
      transform: translate3d(35%, 0, -450px);
    }
    &.prev {
      transform: scale(0.8) translateX(-83%);
      /* opacity: 0.5; */
      z-index: 4;
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
      transform: translate3d(-20%, 0, -250px);
    }
    &.pPrev {
      transform: scale(0.6) translateX(-135%);
      /* opacity: 0.5; */
      z-index: 3;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
      transform: translate3d(-35%, 0, -450px);
    }
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  padding-inline: 88px;
  z-index: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .btn {
    cursor: pointer;
    & svg {
      width: 50px;
    }
  }

  & .btn-left {
    transform: rotate(180deg);
  }

  @media only screen and (min-width: ${theme.breakpoints['2xl']}) {
    & .btn {
      & svg {
        width: unset;
      }
    }
  }
`;

const Slider = styled.div`
  position: relative;
  height: 500px;
  margin-block: 27px 50px;
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;

  & .pagination {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.color.text.primary};
    transition: 0.6s ease;

    &.active {
      width: 70px;
    }
  }
`;
